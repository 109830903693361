import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../../redux/slices/affiliates';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';

export interface IConfirmAffiliateDeleteModalProps {
  id: string;
  defaultStatus: number;
  pageNumber: number;
  pageSize: number;
  sortOrderKey?: string;
  sortOrderValue?: boolean;
  name?: string;
}

const ConfirmAffiliateDeleteModal = ({
  id,
  defaultStatus,
  pageNumber,
  pageSize,
  sortOrderKey,
  sortOrderValue,
  name,
}: IConfirmAffiliateDeleteModalProps) => {
  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateDeleteModal));
  };

  const onConfirm = () => {
    dispatch(
      affiliatesMiddleware.fetchDeleteAffiliate(
        id ?? '0',
        defaultStatus,
        pageNumber,
        pageSize,
        sortOrderKey,
        sortOrderValue,
        name,
      ),
    );
  };
  const isDeleteAffiliateLoading = useAppSelector(affiliatesSelector.isDeleteAffiliateLoading);

  return (
    <Dialog data-test="confirm-affiliate-delete-modal" open onClose={onClose}>
      <Grid sx={{ width: '600px' }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
              >
                Are you sure you want to delete this source?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton data-test="confirm-affiliate-delete-modal-button-x" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                {`After confirming this action, the source will be removed
                permanently and changes can't be undone.`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <CustomButton
                  data-test="confirm-affiliate-delete-modal-button-confirm"
                  disabled={isDeleteAffiliateLoading}
                  loading={isDeleteAffiliateLoading}
                  onClick={onConfirm}
                >
                  Confirm
                </CustomButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAffiliateDeleteModal;
