import * as Yup from 'yup';

import { HTMLValidation } from '../../types/regExp';
import { HTMLValidationError, RequiredError } from '../validationErrors';

export const inviteNewUserModalValidationSchema = Yup.object().shape({
  roleId: Yup.string().required(RequiredError('Role')),
  firstName: Yup.string().required(RequiredError('First Name')).matches(HTMLValidation, HTMLValidationError),
  lastName: Yup.string().required(RequiredError('Last Name')).matches(HTMLValidation, HTMLValidationError),
  email: Yup.string()
    .email('Must be a valid email')
    .required(RequiredError('Email'))
    .matches(HTMLValidation, HTMLValidationError),
});
