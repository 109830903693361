import { createContext, ReactNode, useCallback, useMemo } from 'react';
import { PaletteMode } from '@mui/material';
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
import { CustomizationProps } from 'types/config';

const initialState: CustomizationProps = {
  ...defaultConfig,
  onChangeMenuType: () => {},
  onChangePresetColor: () => {},
  onChangeLocale: () => {},
  onChangeRTL: () => {},
  onChangeContainer: () => {},
  onChangeFontFamily: () => {},
  onChangeBorderRadius: () => {},
  onChangeOutlinedField: () => {},
};

const ConfigContext = createContext(initialState);

interface ConfigProviderProps {
  children: ReactNode;
}

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [config, setConfig] = useLocalStorage('berry-config', {
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius,
    outlinedFilled: initialState.outlinedFilled,
    navType: initialState.navType,
    presetColor: initialState.presetColor,
    locale: initialState.locale,
    rtlLayout: initialState.rtlLayout,
  });

  const onChangeMenuType = useCallback(
    (navType: PaletteMode) => {
      setConfig({
        ...config,
        navType,
      });
    },
    [config, setConfig],
  );

  const onChangePresetColor = useCallback(
    (presetColor: string) => {
      setConfig({
        ...config,
        presetColor,
      });
    },
    [config, setConfig],
  );

  const onChangeLocale = useCallback(
    (locale: string) => {
      setConfig({
        ...config,
        locale,
      });
    },
    [config, setConfig],
  );

  const onChangeRTL = useCallback(
    (rtlLayout: boolean) => {
      setConfig({
        ...config,
        rtlLayout,
      });
    },
    [config, setConfig],
  );

  const onChangeContainer = useCallback(() => {
    setConfig({
      ...config,
      container: !config.container,
    });
  }, [config, setConfig]);

  const onChangeFontFamily = useCallback(
    (fontFamily: string) => {
      setConfig({
        ...config,
        fontFamily,
      });
    },
    [config, setConfig],
  );

  const onChangeBorderRadius = useCallback(
    (event: Event, newValue: number | number[]) => {
      setConfig({
        ...config,
        borderRadius: newValue as number,
      });
    },
    [config, setConfig],
  );

  const onChangeOutlinedField = useCallback(
    (outlinedFilled: boolean) => {
      setConfig({
        ...config,
        outlinedFilled,
      });
    },
    [config, setConfig],
  );

  const contextProps = useMemo(
    () => ({
      ...config,
      onChangeMenuType,
      onChangePresetColor,
      onChangeLocale,
      onChangeRTL,
      onChangeContainer,
      onChangeFontFamily,
      onChangeBorderRadius,
      onChangeOutlinedField,
    }),
    [
      config,
      onChangeBorderRadius,
      onChangeContainer,
      onChangeFontFamily,
      onChangeLocale,
      onChangeMenuType,
      onChangeOutlinedField,
      onChangePresetColor,
      onChangeRTL,
    ],
  );

  return <ConfigContext.Provider value={contextProps}>{children}</ConfigContext.Provider>;
};

export { ConfigContext, ConfigProvider };
