import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.funding;

const fundingList = createSelector([selector], (state) => state.fundingList);
const fundingLoading = createSelector([selector], (state) => state.fundingLoading);
const fundingListFilters = createSelector([selector], (state) => state.fundingListFilters);
const fundersList = createSelector([selector], (state) => state.fundersList);
const fundersListLoading = createSelector([selector], (state) => state.fundersListLoading);
const isDownloadFundingReportLoading = createSelector([selector], (state) => state.isDownloadFundingReportLoading);

export default {
  fundingList,
  fundingLoading,
  fundingListFilters,
  fundersList,
  fundersListLoading,
  isDownloadFundingReportLoading,
};
