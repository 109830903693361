import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { IDocumentList } from '@types';

import DocumentsTable from './DocumentsTable';
import Filters from './Filters';

const DocumentsTab = ({ data, leadId }: { data: IDocumentList[]; leadId: string }) => {
  const [filteredData, setFilteredData] = useState<IDocumentList[]>([]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Box>
      <Box marginBottom="32px">
        <Filters leadId={leadId} data={data} setData={setFilteredData} />
      </Box>
      <Box>
        <DocumentsTable data={filteredData} />
      </Box>
    </Box>
  );
};

export default DocumentsTab;
