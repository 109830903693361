import { PropsWithChildren, useEffect } from 'react';
import { Box } from '@mui/material';

const NavigationScroll = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return <Box>{children}</Box>;
};

export default NavigationScroll;
