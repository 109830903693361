import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ICountriesState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<ICountriesState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  getCountriesSuccess(state, action) {
    state.countries = action.payload;
  },
  getStateProvincesSuccess(state, action) {
    state.states = action.payload;
  },
});

export default reducers;
