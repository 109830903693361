import { dispatch } from 'redux/hooks';

import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import { API_SYSTEM_FIELDS } from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';

import slice from './slice';

const { getSystemFieldsSuccess, hasError, getLoading } = slice.actions;

const fetchGetSystemFields = () => async () => {
  try {
    dispatch(getLoading(true));

    const { data: response } = await DataService.getJson(API_SYSTEM_FIELDS());

    dispatch(getSystemFieldsSuccess(response));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
  } finally {
    dispatch(getLoading(false));
  }
};

export default {
  fetchGetSystemFields,
};
