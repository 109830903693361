import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';

import { IUser } from '../../../redux/slices/userManagement/initialState';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

export interface IDeleteUserModalProps {
  user: IUser;
}

const DeleteUserModal = ({ user }: IDeleteUserModalProps) => {
  const deleteLoading = useAppSelector(userManagementSelector.deleteLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteUserModal));
  };

  const handleDelete = () => {
    dispatch(userManagementMiddleware.deleteUser(user.id));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <Title
          title="Delete User"
          sx={{
            margin: 0,
            padding: '10px',
            display: 'block',
            textAlign: 'center',
            width: '100%',
          }}
        />
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            {`Please confirm you want to delete "${user.fullName}" user․`}
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" loading={deleteLoading} onClick={handleDelete}>
              Delete User
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteUserModal;
