import { useEffect } from 'react';
import { Controller, FieldArray, FieldArrayMethodProps, useFieldArray, useForm } from 'react-hook-form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';

import { CustomButton, CustomPlusButton } from '../../shared';
import Title from '../../UI/Title';

import useAddPromotionModal from './hooks/useAddPromotionModal';

export interface IFieldArray {
  type: string;
  fields: any[];
}

export interface IAddPromotionModalProps {
  appendField: (
    value: Partial<FieldArray<IFieldArray, 'fields'>> | Partial<FieldArray<IFieldArray, 'fields'>>[],
    options?: FieldArrayMethodProps,
  ) => void;
}

const types = [
  { value: 1, label: 'Interest Waiver With Minimum payment' },
  { value: 2, label: 'Interest3 Waiver With Deferred First Monthly Payment' },
  { value: 3, label: 'Equal Payments' },
];

const AddPromotionModal = ({ appendField }: IAddPromotionModalProps) => {
  const { onClose, onConfirm } = useAddPromotionModal({ appendField });
  const { control, handleSubmit, watch } = useForm<IFieldArray>({
    mode: 'onSubmit',
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
  });

  const removeField = (index: number) => {
    remove(index);
  };

  const addField = () => {
    append({});
  };

  useEffect(() => {
    if (watch('type') && !fields?.length) {
      addField();
    }
  }, [watch('type')]);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '600px' }}>
        <DialogTitle>
          <Title
            title="Adding Promotion"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box onSubmit={handleSubmit(onConfirm)} component="form">
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel>Promotion Type</InputLabel>
                      <Select {...field}>
                        {types?.map((el) => (
                          <MenuItem key={el.value} value={el.value}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {fields?.map((el, index) => (
                  <Grid container>
                    <Grid item xs={2.8} paddingRight="5px">
                      <Controller
                        control={control}
                        name={`fields.${index}.loan`}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>Loan Term</InputLabel>
                            <TextField sx={{ minWidth: '10px !important' }} {...field} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.8} paddingRight="5px">
                      <Controller
                        control={control}
                        name={`fields.${index}.promo`}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>Promo Term</InputLabel>
                            <TextField sx={{ minWidth: '10px !important' }} {...field} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.8} paddingRight="5px">
                      <Controller
                        control={control}
                        name={`fields.${index}.discount`}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>Dealers Discount</InputLabel>
                            <TextField sx={{ minWidth: '10px !important' }} {...field} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2.8}>
                      <Controller
                        control={control}
                        name={`fields.${index}.amount`}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>Payment Amount</InputLabel>
                            <TextField sx={{ minWidth: '10px !important' }} {...field} />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={0.8} textAlign="center" paddingTop="35px">
                      <DeleteOutlineIcon sx={{ cursor: 'pointer' }} onClick={() => removeField(index)} />
                    </Grid>
                  </Grid>
                ))}
                {(fields?.length || watch('type')) && (
                  <CustomPlusButton size="medium" title="Add Another" onClick={addField} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} marginTop="10px" alignItems="center" justifyContent="center">
                  <CustomButton variant="outlined" onClick={onClose}>
                    Cancel
                  </CustomButton>
                  <CustomButton disabled={!fields?.length} type="submit">
                    Confirm
                  </CustomButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default AddPromotionModal;
