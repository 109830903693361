import { useAppSelector } from 'redux/hooks';
import { viewsSelector } from 'redux/slices/views';

import { ToastNotification } from './index';

export const ToastNotificationsController = () => {
  const toastNotificationPopUp = useAppSelector(viewsSelector.toastNotificationPopUp);

  return !toastNotificationPopUp.open ? null : <ToastNotification />;
};
