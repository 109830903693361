import { CSSProperties } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconPlus } from '@tabler/icons';

export interface PlusButtonProps extends MuiButtonProps {
  text?: string;
  title?: string;
  style?: CSSProperties;
}

interface ButtonSizeStyles {
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  padding: string;
  height: string;
  width?: string;
}

const mapSizeToStyles: Record<NonNullable<MuiButtonProps['size']>, ButtonSizeStyles> = {
  small: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    height: '40px',
    padding: '0',
    width: '20px',
  },
  medium: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '10px 18px',
    height: '44px',
  },
  large: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '12px 20px',
    height: '48px',
  },
};

const ButtonRoot = styled(MuiButton)(({ size = 'medium' }) => ({
  outline: 'none',
  border: 0,
  margin: '20px auto',
  boxSizing: 'unset',
  width: '100%',
  color: '#27e7ad',
  background: 'none',
  ...mapSizeToStyles[size],
  '& button': {
    background: '#27e7ad',
    marginRight: '10px',
    color: 'white',
    borderRadius: '10px',
  },
  '&:hover': {
    background: 'none',
  },
}));

// @TODO Anoush button can not be a child of the button

const CustomPlusButton = ({ title, sx, ...props }: PlusButtonProps) => (
  <ButtonRoot sx={sx ?? {}} data-testid="button-root" {...props}>
    <IconButton>
      <IconPlus />
    </IconButton>
    {title ?? null}
  </ButtonRoot>
);

export { CustomPlusButton };
