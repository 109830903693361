import { ILeadsDetails } from '@types';

import { IFundingList } from '../redux/slices/funding/initialState';
import { ITag } from '../redux/slices/tags/initialState';

export const UpdateTableData = (
  type: 'add' | 'create' | 'remove' | 'delete' | 'update',
  tableData: ILeadsDetails[] | IFundingList[],
  tagName: string,
  color: number,
  targetId: string,
  oldName?: string,
  idKey: string = 'id',
) => {
  if (type === 'add') {
    return tableData.map((el: { id: string; tags: ITag[] }) => {
      if (el[idKey as 'id'] === targetId) {
        return {
          ...el,
          tags: [
            ...el.tags,
            {
              name: tagName,
              color,
              targetId,
            },
          ],
        };
      }

      return el;
    });
  }

  if (type === 'remove') {
    return tableData.map((el: { id: string; tags: ITag[] }) => {
      if (el[idKey as 'id'] === targetId) {
        return {
          ...el,
          tags: el.tags.filter((tag) => tag.name !== tagName),
        };
      }

      return el;
    });
  }

  if (type === 'delete') {
    return tableData.map((el: { id: string; tags: ITag[] }) => {
      if (el.tags.filter((tag) => tag.name === tagName).length) {
        return {
          ...el,
          tags: el.tags.filter((tag) => tag.name !== tagName),
        };
      }

      return el;
    });
  }

  if (type === 'update') {
    return tableData.map((el: { id: string; tags: ITag[] }) => {
      if (el.tags.filter((tag) => tag.name === oldName).length) {
        return {
          ...el,
          tags: el.tags.map((tag) => (tag.name === oldName ? { ...tag, name: tagName, color } : tag)),
        };
      }

      return el;
    });
  }

  return [];
};

export const UpdateFundingsTableData = (
  tableData: IFundingList[],
  targetId: string,
  newOptionId: number | string,
  setLoading: (value: boolean) => void,
  newOptionValue?: string,
) => {
  const newTableData = tableData.map((el: { id: string }) => {
    if (el.id === targetId) {
      return { ...el, reviewerId: newOptionId === 0 ? null : newOptionId, reviewedBy: newOptionValue ?? null };
    }

    return el;
  });

  setLoading(false);

  return newTableData;
};
