import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useGenerateTableData } from 'components/Hooks';
import { DenseTable } from 'components/Tables';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';
import { dateTime } from 'helpers';
import { dispatch, useAppSelector } from 'redux/hooks';

import { Permissions } from '../../../components/Permissions/constants';
import { hasPermission } from '../../../components/Permissions/Permission';
import { CampaignsHistoryTableHeaders } from '../../../lib/constants/tableHeaders';
import { campaignsMiddleware, campaignsSelector } from '../../../redux/slices/campaigns';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { IHistory } from '../../../types/campaigns';

const HistoryPage = () => {
  const history = useAppSelector(campaignsSelector.history);
  const isCampaignHistoryLoading = useAppSelector(campaignsSelector.isCampaignHistoryLoading);
  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    isLoading,
    paginationCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: history?.data,
    paginationCount: history?.itemsCount,
  });

  const currentCampaignById = useAppSelector(campaignsSelector.currentCampaignById);

  const [modifiedTableData, setModifiedTableData] = useState(sortTableData);

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(campaignsMiddleware.fetchCampaignHistory(currentCampaignById?.id ?? '0', newPage + 1, rowsPerPage));
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(campaignsMiddleware.fetchCampaignHistory(currentCampaignById?.id ?? '0', 1, newRowsPerPage));
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  useEffect(() => {
    dispatch(campaignsMiddleware.fetchCampaignHistory(currentCampaignById?.id ?? '0'));
  }, []);

  useEffect(() => {
    const tempArr = sortTableData.filter((item: IHistory) => Object.keys(item).length > 0);

    if (tempArr.length > 0) {
      const newArr = tempArr.map((item: IHistory) => ({
        ...item,
        id: item.timeStamp, // unique id for rowKey default property (discussed with BE)
        timeStamp: dateTime(item?.timeStamp),
      }));

      setModifiedTableData(newArr);
    }
  }, [sortTableData]);

  const handleCustomCallback = (cell: string, historyRow: IHistory) => {
    if (hasPermission(Permissions.viewProductHistoryDetails)) {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmCampaignHistoryModal,
          props: {
            historyDetails: historyRow,
          },
        }),
      );
    }
  };

  if (isLoading || isCampaignHistoryLoading) {
    return <Loading />;
  }

  return (
    <Box width="100%" marginTop="24px">
      {!history?.data ? (
        <Title sx={{ padding: 0 }} title="No History" />
      ) : (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            width: '100%',
            '& .MuiTableContainer-root': {
              padding: 0,
            },
          }}
        >
          <DenseTable
            dataTest="campaign-details-page-tab-history"
            dataTestId="entityId"
            selectedTableHeaders={CampaignsHistoryTableHeaders}
            setOrder={setOrder}
            isDeleted={false}
            setOrderBy={setOrderBy}
            sortTableData={modifiedTableData}
            data={tableData}
            editButton={false}
            setTableData={setTableData}
            order={order as string}
            isAction={false}
            orderBy={orderBy}
            action={{
              type: 'Click',
              id: CampaignsHistoryTableHeaders[0].value,
              callback: () => null,
            }}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            createSortHandler={createSortHandler}
            handleChangeRowsPerPage={handleChangeRowsPerPageServer}
            handleChangePage={handleChangePageServer}
            handleCustomCallback={(cell: string, row?: Record<string, unknown>): void =>
              handleCustomCallback(cell, row as unknown as IHistory)
            }
            paginationCount={paginationCount}
          />
        </Box>
      )}
    </Box>
  );
};

export default HistoryPage;
