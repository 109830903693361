import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import TitleWithBackButton from 'components/shared/Title/TitleWithBackButton';

const linkSX = {
  display: 'flex',
  color: '#707EAE',
  textDecoration: 'none',
  alignContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
};

interface ITitleWithBreadcrumbsProps {
  title: string;
  dataTest: string;
  link: string;
  linkTitle: string;
  currentTitle: string;
  children?: ReactNode;
}

const TitleWithBreadcrumbs = ({
  title,
  link,
  linkTitle,
  currentTitle,
  dataTest,
  children,
}: ITitleWithBreadcrumbsProps) => (
  <Box>
    <MuiBreadcrumbs aria-label="breadcrumb">
      <Typography component={Link} to={link} variant="subtitle1" sx={linkSX}>
        {linkTitle}
      </Typography>
      <Typography color="inherit" variant="subtitle1" sx={linkSX}>
        {currentTitle}
      </Typography>
    </MuiBreadcrumbs>
    <TitleWithBackButton dataTest={dataTest} title={title}>
      {children}
    </TitleWithBackButton>
  </Box>
);

export default TitleWithBreadcrumbs;
