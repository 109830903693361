import { IExternalServiceState } from '../../../types';

export const getInitialState = (): IExternalServiceState => ({
  error: null,
  isLoading: false,
  isExternalServiceDeleteLoading: false,
  isExternalServiceUpdateLoading: false,
  isFieldByDataTypeExternalServicesLoading: false,
  isExternalServiceMatchingCampaignFieldsLoading: false,
  isExternalServiceDetailsLoading: false,
  isExternalServicesCampaignFieldsAutoMatchLoading: false,
  isServiceFieldByDataTypeExternalServicesLoading: false,
  isExternalServicesCreated: false,
  externalServiceCampaignFieldsMatchingValues: null,
  externalServiceCampaignFieldsAutoMatchValues: null,
  externalServiceDetails: null,
  externalService: null,
  externalServiceList: null,
  simplifiedExternalServices: null,
  externalServiceCount: '0',
  externalServiceFields: null,
  externalServiceResponseFields: null,
});
