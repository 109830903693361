import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import CustomRangePicker from 'components/CustomRangePicker/CustomRangePicker';

import { DateRange } from '@types';

import FormAutocomplete, { ISelectOption } from '../../formComponents/FormAutocomplete';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { userManagementMiddleware, userManagementSelector } from '../../redux/slices/userManagement';
import { IRolesList } from '../../redux/slices/userManagement/initialState';
import { CustomButton } from '../shared';

export interface UserListFilterData {
  fullName: string;
  email: string;
  joined: DateRange;
  roles: string[];
}

interface UserListFiltersProps {
  resetFilters: () => void;
  applyFilters: (data: UserListFilterData) => void;
}

const UserListFilters = ({ applyFilters, resetFilters }: UserListFiltersProps) => {
  const [joined, setJoined] = useState<DateRange | undefined | null>(null);
  const rolesList = useAppSelector(userManagementSelector.rolesList);
  const roleDeleteId = useAppSelector(userManagementSelector.roleDeleteId);

  const methods = useForm<{ fullName: string; email: string; roles: IRolesList[] }>({
    mode: 'onSubmit',
    defaultValues: {
      fullName: '',
      email: '',
      roles: [],
    },
  });

  const { control, handleSubmit, reset, setValue } = methods;

  const onResetFilters = () => {
    setJoined(null);

    if (roleDeleteId) {
      dispatch(userManagementMiddleware.changeRoleDeleteId(null));
    }

    reset();
    resetFilters();
  };

  const onSubmit = (data: any) => {
    setValue('fullName', (data.fullName = data.fullName.trim()));
    setValue('email', (data.email = data.email.trim()));

    applyFilters({ ...data, joined });
  };

  useEffect(() => {
    if (roleDeleteId) {
      setValue('roles', [roleDeleteId]);
      handleSubmit(onSubmit)();
    }
  }, [roleDeleteId]);

  return (
    <FormProvider {...methods}>
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Grid container display="flex" alignItems="flex-end" gap="30px">
          <Grid item>
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Full Name</InputLabel>
                  <TextField {...field} placeholder="Full Name" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Email</InputLabel>
                  <TextField {...field} placeholder="Email" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <CustomRangePicker
              key={`${joined?.startDate}${joined?.endDate}`}
              label="Joined"
              dateTimeState={joined}
              setDateTimeState={setJoined}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete title="Role" options={rolesList as ISelectOption[]} name="roles" />
          </Grid>
          <Grid item display="flex" gap="30px">
            <Box display="flex" alignItems="flex-end">
              <CustomButton type="submit">Apply</CustomButton>
            </Box>
            <Box display="flex" alignItems="flex-end">
              <CustomButton variant="outlined" type="reset" onClick={onResetFilters}>
                Reset
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default UserListFilters;
