import { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { Box, Grid, Tooltip } from '@mui/material';
import { AntSwitch } from 'components/UI/AntSwitch';
import Loading from 'components/UI/Loading';
import { isMe } from 'helpers';
import moment from 'moment';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUser, userStatuses } from 'redux/slices/userManagement/initialState';

import { CustomButton } from '../../components';
import { DenseTable } from '../../components/Tables';
import { IActionMenuItem } from '../../components/Tables/CustomDynamicTableDense';
import Title from '../../components/UI/Title';
import UserListFilters, { UserListFilterData } from '../../components/UserManagement/UserListFilters';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

let timeout: NodeJS.Timeout | undefined;

const UsersList = () => {
  const usersList = useAppSelector(userManagementSelector.usersList);
  const isLoading = useAppSelector(userManagementSelector.isLoading);

  const [filteredUsersList, setFilteredUsersList] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(userManagementMiddleware.fetchUsersList());
    dispatch(userManagementMiddleware.getRolesList());

    return () => {
      clearTimeout(timeout);
      setFilteredUsersList([]);
      dispatch(userManagementMiddleware.changeRoleDeleteId(null));
    };
  }, []);

  useEffect(() => {
    if (usersList) {
      setFilteredUsersList(usersList);
    }
  }, [usersList]);

  const [actionState, setActionState] = useState<string>('-1');

  const mockLoading = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const resetFilters = () => {
    setLoading(true);
    setFilteredUsersList(usersList);
    mockLoading();
  };

  const applyFilters = (data: UserListFilterData) => {
    const lowerCaseFullName = data.fullName.toLowerCase();
    const lowerCaseEmail = data.email.toLowerCase();
    const filteredUsers = usersList.filter((user) => {
      let found = false;

      found = !data.fullName ? true : user.fullName.toLowerCase().includes(lowerCaseFullName);

      if (!found) {
        return false;
      }

      found = !data.email ? true : user.email.toLowerCase().includes(lowerCaseEmail);

      if (!found) {
        return false;
      }

      if (data.joined?.startDate && data.joined?.endDate) {
        const startDate = moment(data.joined.startDate);
        const endDate = moment(data.joined.endDate);
        const userDate = moment(user.joined);

        found = endDate > userDate && startDate < userDate;

        if (!found) {
          return false;
        }
      }

      found = !data.roles || !data.roles.length ? true : data.roles.some((role: any) => role.id === user.role);

      if (!found) {
        return false;
      }

      return found;
    });

    if (data.fullName) {
      filteredUsers.sort((user) => (user.fullName.toLocaleLowerCase() === lowerCaseFullName ? -1 : 0));
    }

    setFilteredUsersList(filteredUsers);
  };

  const actionMenuItemsData = (user: IUser): IActionMenuItem[] => {
    const cantResetPassword = user.userStatus === 'Invitation Pending' || user.userStatus === 'Inactive';
    const isPendingUser = user.userStatus === 'Invitation Pending';
    const isActiveUser = user.userStatus === 'Active';

    if (isMe(user.id)) {
      return [
        {
          title: "You can't change your own role.",
          icon: <EditOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
          action: () => {},
        },
      ];
    }

    const options = [
      ...(isActiveUser
        ? [
            {
              title: 'Change Role',
              icon: (
                <Tooltip title="You can't change your own role." disableHoverListener={!isMe(user.id)}>
                  <EditOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </Tooltip>
              ),
              action: () => {
                dispatch(
                  viewsMiddleware.openModal({
                    name: ModalName.EditUserRoleModal,
                    props: { user },
                  }),
                );
              },
            },
          ]
        : []),
      {
        title: 'Delete',
        icon: (
          <Tooltip title="You can't delete yourself." disableHoverListener={!isMe(user.id)}>
            <DeleteOutlineIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
          </Tooltip>
        ),
        action: () => {
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.DeleteUserModal,
              props: { user },
            }),
          );
        },
      },
      ...(isPendingUser
        ? [
            {
              title: 'Resend Invitation',
              icon: (
                <Tooltip title="You can't resend invitation to non pending user." disableHoverListener={isPendingUser}>
                  <RestartAltIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </Tooltip>
              ),
              action: () => {
                dispatch(
                  viewsMiddleware.openModal({
                    name: ModalName.ResendInviteUserModal,
                    props: { user },
                  }),
                );
              },
            },
          ]
        : []),
      ...(!cantResetPassword
        ? [
            {
              title: 'Reset Password',
              icon: (
                <Tooltip title="You can't reset password." disableHoverListener={!cantResetPassword}>
                  <RefreshIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </Tooltip>
              ),
              action: () => {
                dispatch(
                  viewsMiddleware.openModal({
                    name: ModalName.ResetPasswordModal,
                    props: { user },
                  }),
                );
              },
            },
          ]
        : []),
    ];

    if ([userStatuses[2], userStatuses[1], userStatuses[6]].includes(user.userStatus)) {
      options.push({
        title: user.userStatus === userStatuses[2] ? 'Deactivate' : 'Activate',
        icon: (
          <Tooltip title="You can't deactivate yourself." disableHoverListener={!isMe(user.id)}>
            <Box component="span">
              <AntSwitch disabled={isMe(user.id)} checked={user.userStatus === userStatuses[2]} />
            </Box>
          </Tooltip>
        ),
        action: () => {
          dispatch(
            viewsMiddleware.openModal({
              name: ModalName.DeactivateUserModal,
              props: { user },
            }),
          );
        },
      });
    }

    return options;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container gap={2} width="100%" flexDirection="column">
      <Grid item display="flex" justifyContent="space-between" alignItems="center">
        <Title title="Users List" />
        <Box display="flex" justifyContent="end">
          <CustomButton
            variant="outlined"
            onClick={() => {
              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.DomainRestrictionsModal,
                  props: {},
                }),
              );
            }}
          >
            <VpnLockIcon
              sx={{
                width: '16px !important',
                marginRight: '10px',
              }}
            />
            Domain Restrictions
          </CustomButton>
          <CustomButton
            onClick={() => {
              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.InviteNewUserModal,
                  props: {},
                }),
              );
            }}
          >
            Add New User
          </CustomButton>
        </Box>
      </Grid>
      {loading ? (
        <Loading />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Box marginBottom="20px">
            <UserListFilters applyFilters={applyFilters} resetFilters={resetFilters} />
          </Box>
          <Box>
            <DenseTable
              actionState={actionState}
              setActionState={setActionState}
              actionMenuItems={actionMenuItemsData}
              data={[]}
              internalData
              isAction
              isDeleted={false}
              editButton={false}
              isDropdownButtons
              isMoreDropDown
              setTableData={() => {}}
              // page={0}
              // rowsPerPage={10}
              handleRequestSort={() => {}}
              // TODO remove ts-ignore after Dance table refactor
              // @ts-ignore
              sortTableData={filteredUsersList}
              selectedTableHeaders={[
                { value: 'fullName', title: 'Full Name', sortDisabled: true },
                { value: 'email', title: 'Email', sortDisabled: true },
                { value: 'joined', title: 'Joined', sortDisabled: true },
                { value: 'roleName', title: 'Role', sortDisabled: true },
                { value: 'lastLogin', title: 'Last Login', sortDisabled: true },
                { value: 'userStatus', title: 'Status', sortDisabled: true },
              ]}
              // TODO ADD Pagination after BE will be ready
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              order=""
              orderBy="ABC"
              setOrder={() => {}}
              setOrderBy={() => {}}
            />
          </Box>
        </Box>
      )}
    </Grid>
  );
};

export default UsersList;
