import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#707EAE',
});

export const StyledTableLink = styled(Link)({
  textDecoration: 'none',
  color: '#454256',
});
