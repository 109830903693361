// routes
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DASHBOARD_PATH, RECOVERY_PASSWORD, SET_PASSWORD } from 'config';

import { useAppSelector } from '../redux/hooks';
import { authSelector } from '../redux/slices/auth';

import AuthenticationRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export const Routes = () => {
  const isLoggedIn = useAppSelector(authSelector.isLoggedIn);
  const pathSections = useLocation().pathname.split('/');
  const navigate = useNavigate();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoggedIn && (pathSections[1] === SET_PASSWORD || pathSections[1] === RECOVERY_PASSWORD)) {
      return navigate(DASHBOARD_PATH, { replace: true });
    }
  }, []);

  return <Box>{isLoggedIn ? <MainRoutes /> : <AuthenticationRoutes />}</Box>;
};
