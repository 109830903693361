import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { NavItemType } from '@types';

import { menuItemsMap, MenuProps } from '../../../../../../menu-items/menu-links';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

export interface NavGroupProps {
  item: MenuProps;
}

const NavGroup = ({ item }: { item: MenuProps }) => {
  const [selectedTab, setSelectedTab] = useState<string>('dashboard');
  const location = useLocation();
  const [selectedTabNested, setSelectedTabNested] = useState<string>('');

  useEffect(() => {
    const currentPath = `/${location.pathname.split('/')[1]}`;

    if (menuItemsMap[currentPath]) {
      setSelectedTab(menuItemsMap[currentPath].parent);
      setSelectedTabNested(menuItemsMap[currentPath].child ?? '');
    }
  }, [location]);

  const theme = useTheme();
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return menu.showMenuCollapse?.filter((el) => hasPermission(el))?.length ? (
          <Box key={menu.id}>
            <NavCollapse
              menu={menu as unknown as MenuProps}
              level={1}
              selectedTab={selectedTab}
              selectedTabNested={selectedTabNested}
            />
          </Box>
        ) : null;
      case 'item':
        return (
          <Permission key={menu.id} permission={menu.permission}>
            <NavItem
              key={menu.id}
              item={menu as NavItemType}
              level={1}
              selectedTab={selectedTab}
              selectedTabNested={selectedTabNested}
            />
          </Permission>
        );
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title && (
          <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
            {item.title}
            {item.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )}
          </Typography>
        )
      }
    >
      <Box>{items}</Box>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </List>
  );
};

export default NavGroup;
