import { memo, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import menuItem from 'menu-items';
import { dispatch } from 'redux/hooks';
import { notificationMiddleware } from 'redux/slices/notification';

import NavGroup from './NavGroup';

const MenuList = () => {
  const intervalRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (hasPermission(Permissions.viewManualReviewList) || hasPermission(Permissions.viewFundList)) {
      dispatch(notificationMiddleware.fetchNotificationCounts());

      // fetch counts every 30 second
      intervalRef.current = setInterval(() => {
        dispatch(notificationMiddleware.fetchNotificationCounts());
      }, 30000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
      }}
    >
      {navItems}
    </Box>
  );
};

export default memo(MenuList);
