import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.externalServices;

const isExternalServiceDeleteLoading = createSelector([selector], (state) => state.isExternalServiceDeleteLoading);
const isFieldByDataTypeExternalServicesLoading = createSelector(
  [selector],
  (state) => state.isFieldByDataTypeExternalServicesLoading,
);
const isServiceFieldByDataTypeExternalServicesLoading = createSelector(
  [selector],
  (state) => state.isServiceFieldByDataTypeExternalServicesLoading,
);
const isLoading = createSelector([selector], (state) => state.isLoading);
const simplifiedExternalServices = createSelector([selector], (state) => state.simplifiedExternalServices);
const isExternalServiceDetailsLoading = createSelector([selector], (state) => state.isExternalServiceDetailsLoading);
const externalServiceCampaignFieldsMatchingValues = createSelector(
  [selector],
  (state) => state.externalServiceCampaignFieldsMatchingValues,
);
const externalServiceCampaignFieldsAutoMatchValues = createSelector(
  [selector],
  (state) => state.externalServiceCampaignFieldsAutoMatchValues,
);
const isExternalServicesCampaignFieldsAutoMatchLoading = createSelector(
  [selector],
  (state) => state.isExternalServicesCampaignFieldsAutoMatchLoading,
);
const isExternalServiceMatchingCampaignFieldsLoading = createSelector(
  [selector],
  (state) => state.isExternalServiceMatchingCampaignFieldsLoading,
);
const externalServiceList = createSelector([selector], (state) => state.externalServiceList);
const externalService = createSelector([selector], (state) => state.externalService);
const externalServiceFields = createSelector([selector], (state) => state.externalServiceFields);
const externalServiceResponseFields = createSelector([selector], (state) => state.externalServiceResponseFields);
const externalServiceDetails = createSelector([selector], (state) => state.externalServiceDetails);
const error = createSelector([selector], (state) => state.error);
const isExternalServicesCreated = createSelector([selector], (state) => state.isExternalServicesCreated);
const externalServiceCount = createSelector([selector], (state) => state.externalServiceCount);

export default {
  isExternalServiceDeleteLoading,
  isFieldByDataTypeExternalServicesLoading,
  isServiceFieldByDataTypeExternalServicesLoading,
  isLoading,
  simplifiedExternalServices,
  isExternalServiceDetailsLoading,
  externalServiceCampaignFieldsMatchingValues,
  externalServiceCampaignFieldsAutoMatchValues,
  isExternalServicesCampaignFieldsAutoMatchLoading,
  isExternalServiceMatchingCampaignFieldsLoading,
  externalServiceList,
  externalService,
  externalServiceFields,
  externalServiceResponseFields,
  externalServiceDetails,
  error,
  externalServiceCount,
  isExternalServicesCreated,
};
