import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'redux';

import store, { RootState } from './store';

export type AppDispatch = typeof store.dispatch;

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const { dispatch } = store;

const getStateBySelector = (selectorName: string) => {
  const state: CombinedState<{ [key: string]: any }> = store.getState();

  return state[selectorName];
};

export { dispatch, getStateBySelector, useAppDispatch, useAppSelector };
