import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuardProps } from 'types';

import { RECOVERY_PASSWORD, SET_PASSWORD, SIGN_IN_PATH } from '../../config';
import { useAppSelector } from '../../redux/hooks';
import { authSelector } from '../../redux/slices/auth';

const AuthGuard = ({ children }: GuardProps) => {
  const isLoggedIn = useAppSelector(authSelector.isLoggedIn);
  const navigate = useNavigate();
  const pathSections = useLocation().pathname.split('/');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isLoggedIn && pathSections[1] !== SET_PASSWORD && pathSections[1] !== RECOVERY_PASSWORD) {
      return navigate(SIGN_IN_PATH, { replace: true });
    }
  }, [isLoggedIn]);

  return children;
};

export default AuthGuard;
