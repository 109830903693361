import { CookieManager } from 'helpers/CookieManager';

import { Permissions } from './constants';

/**
 *
 * @param permission
 * @returns boolean based on given permission, in case of there is no given permission,
 *          it means there is no any concern to move forward
 */
export const hasPermission = (permission?: Permissions) => {
  const role = CookieManager.getUser()?.role; // JSON.parse(localStorage.getItem('user') ?? '')?.role;

  const rolePermissions = localStorage.getItem('permissions')?.split(',');

  if (!role || !rolePermissions) {
    return false;
  }

  if (!permission) {
    return true;
  }

  return rolePermissions.includes(permission);
};

/**
 *
 * @param param
 * @returns children component in the case of the user role has a permission to access it,
 *          otherwise returning fallback if given, otherwise null
 */
const Permission = ({
  permission,
  fallbackComponent,
  children,
}: {
  permission?: Permissions;
  fallbackComponent?: JSX.Element;
  children: JSX.Element;
}) => {
  if (hasPermission(permission)) {
    return children;
  }

  return fallbackComponent ?? null;
};

export default Permission;
