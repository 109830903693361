const extensionFromName = (name: string) => name.split('.').pop() ?? '';

const downloadTypeFromFileName = (name: string) => {
  const extension = extensionFromName(name);

  if (extension.toLowerCase() === 'pdf') {
    return 'application/pdf';
  }

  if (extension === 'png') {
    return 'image/png';
  }

  if (extension?.toLowerCase() === 'jpg' || extension.toLowerCase() === 'jpeg') {
    return 'image/jpeg';
  }

  if (extension.toLowerCase() === 'json') {
    return 'application/json';
  }

  if (extension.toLowerCase() === 'txt') {
    return 'text/plain';
  }

  return '';
};

export class FileManager {
  static createUrlFromStream(file: File, name: string): string {
    return URL.createObjectURL(new Blob([file], { type: downloadTypeFromFileName(name) }));
  }

  static download(file: File, name: string): void {
    const url = this.createUrlFromStream(file, name);

    (function download() {
      const a = document.createElement('a');

      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })();
  }

  static preview(file: File, name: string, callback?: (url: string) => void): void {
    const url = this.createUrlFromStream(file, name);

    window.open(url, name);
    callback?.(url);
    // URL.revokeObjectURL(url); Revoke part has been implemented from preview component unmount life cycle
  }
}
