import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useShareManualReviewModal from './hooks/useShareManualReviewModal';

export interface IShareManualReviewModalProps {
  id: number;
}

const ShareManualReviewModal = () => {
  const { onClose, onConfirm } = useShareManualReviewModal();

  return (
    <Dialog open onClose={onClose}>
      <Box sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Reject Application"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 5 }}>
          <Box marginY="20px">
            <Typography>Share via email</Typography>
            <Select fullWidth>
              {[
                { title: 'alexa.m@gmail.com ', value: '1' },
                { title: 'jacob.smith@gmail.com', value: '2' },
              ].map((el) => (
                <MenuItem key={el.value}>{el.title}</MenuItem>
              ))}
            </Select>
          </Box>
          <Typography marginY="10px">
            Your teammates will receive an email where they will able to make a manual review.
          </Typography>
          <FormControlLabel
            sx={{ marginTop: '10px' }}
            control={<Switch />}
            label="Approve the decision invited user makes before changing the status"
          />
          <Box display="flex" justifyContent="space-around" marginTop="20px">
            <CustomButton fullWidth variant="contained" onClick={onConfirm}>
              Share via email
            </CustomButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ShareManualReviewModal;
