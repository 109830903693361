import { IListsState } from '../../../types';

export const getInitialState = (): IListsState => ({
  error: null,
  lists: [],
  states: null,
  isLoading: false,
  isCreateListValuesLoading: false,
  isEditListValueLoading: false,
  isSelectedListsLoading: false,
  uploadedLists: null,
  selectedLists: [],
});
