import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.campaigns;

const currentCampaignById = createSelector([selector], (state) => state.currentCampaignById);

const createCampaignsWithStepper = createSelector([selector], (state) => state.createCampaignsWithStepper);

const successfully = createSelector([selector], (state) => state.successfully);
const isCreateCampaignLoading = createSelector([selector], (state) => state.isCreateCampaignLoading);

const validators = createSelector([selector], (state) => state.validators);

const fieldByDataType = createSelector([selector], (state) => state.fieldByDataType);

const simplifiedCampaigns = createSelector([selector], (state) => state.simplifiedCampaigns);

const isCampaignsListLoading = createSelector([selector], (state) => state.isCampaignsListLoading);

const isDeleteCampaignLoading = createSelector([selector], (state) => state.isDeleteCampaignLoading);

const isFieldByDataTypeLoading = createSelector([selector], (state) => state.isFieldByDataTypeLoading);

const campaignFields = createSelector([selector], (state) => state.campaignFields);

const isCreateAffiliateLoading = createSelector([selector], (state) => state.isCreateAffiliateLoading);

const isAffiliatesLoading = createSelector([selector], (state) => state.isAffiliatesLoading);

const currentCampaignByIdCampaignFields = createSelector(
  [selector],
  (state) => state.currentCampaignByIdCampaignFields,
);

const campaigns = createSelector([selector], (state) => state.campaigns);

const isCampaignCreated = createSelector([selector], (state) => state.isCampaignCreated);

const fieldByDataTypeString = createSelector([selector], (state) => state.fieldByDataTypeString);

const isLoading = createSelector([selector], (state) => state.isLoading);

const isValidatorsLoading = createSelector([selector], (state) => state.isValidatorsLoading);

const isCampaignFieldsLoading = createSelector([selector], (state) => state.isCampaignFieldsLoading);

const isUpdateCampaignLoading = createSelector([selector], (state) => state.isUpdateCampaignLoading);

const isCurrentCampaignByIdLoading = createSelector([selector], (state) => state.isCurrentCampaignByIdLoading);

const isUpdateCampaignDetailsLoading = createSelector([selector], (state) => state.isUpdateCampaignDetailsLoading);
const history = createSelector([selector], (state) => state.history);

const campaignsCount = createSelector([selector], (state) => state.campaignsCount);

const isCampaignHistoryLoading = createSelector([selector], (state) => state.isCampaignHistoryLoading);

const isTimeFormatSuccessLoading = createSelector([selector], (state) => state.isTimeFormatSuccessLoading);

const isUpdateCampaignStatusLoading = createSelector([selector], (state) => state.isUpdateCampaignStatusLoading);

const timeFormats = createSelector([selector], (state) => state.timeFormats);

const campaignFilters = createSelector([selector], (state) => state.campaignFilters);

export default {
  validators,
  currentCampaignById,
  createCampaignsWithStepper,
  successfully,
  fieldByDataType,
  simplifiedCampaigns,
  isCampaignsListLoading,
  isDeleteCampaignLoading,
  isFieldByDataTypeLoading,
  campaignFields,
  isCreateAffiliateLoading,
  isAffiliatesLoading,
  currentCampaignByIdCampaignFields,
  campaigns,
  isCampaignCreated,
  fieldByDataTypeString,
  isLoading,
  isValidatorsLoading,
  isCampaignFieldsLoading,
  isUpdateCampaignLoading,
  isCurrentCampaignByIdLoading,
  isUpdateCampaignDetailsLoading,
  history,
  campaignsCount,
  isCampaignHistoryLoading,
  isTimeFormatSuccessLoading,
  isUpdateCampaignStatusLoading,
  timeFormats,
  isCreateCampaignLoading,
  campaignFilters,
};
