import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.notification;

const fundingCount = createSelector([selector], (state) => state.fundingCount);
const manualReviewCount = createSelector([selector], (state) => state.manualReviewCount);
const notifications = createSelector([selector], (state) => state.notifications);
const notificationsLoaded = createSelector([selector], (state) => state.notificationsLoaded);
const notificationsByIdLoading = createSelector([selector], (state) => state.notificationsByIdLoading);
const notificationsList = createSelector([selector], (state) => state.notificationsList);
const isNotificationListEnd = createSelector([selector], (state) => state.isNotificationListEnd);

export default {
  fundingCount,
  manualReviewCount,
  notifications,
  notificationsLoaded,
  notificationsByIdLoading,
  notificationsList,
  isNotificationListEnd,
};
