import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export const JourneyStepDescription = ({ message }: { message: string }) => (
  <Box
    sx={{
      paddingTop: '8px',
      display: 'flex',
    }}
  >
    <Box
      sx={{
        marginRight: '8px',
      }}
    >
      <InfoOutlined
        sx={{
          marginTop: '0',
          marginBottom: '0',
          width: '16px',
          height: '16px',
          color: '#707EAE',
        }}
      />
    </Box>

    <Typography
      sx={{
        fontFamily: 'Inter',
        fontSize: '11px',
        fontWeight: '500',
        lineHeight: '13px',
        color: '#707EAE',
        letterSpacing: '0em',
        textAlign: 'left',
      }}
    >
      {message}
    </Typography>
  </Box>
);
