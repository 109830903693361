import { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

import { IFunds } from '@types';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useFundingRequestRejectModal from './hooks/useFundingRequestRejectModal';

export interface IFundingRequestRejectModalProps {
  fund: IFunds;
  setMode: (mode: string) => void;
}

const FundingRequestRejectModal = ({ fund, setMode }: IFundingRequestRejectModalProps) => {
  const [reason, setReason] = useState<string>('');

  const { onClose, onConfirm } = useFundingRequestRejectModal(fund, setMode);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Reject Funding Request"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <TextField
            sx={{ paddingX: '20px', marginBottom: '40px' }}
            fullWidth
            label="Reject Reason *"
            onChange={(e) => {
              setReason(e.target.value);
            }}
          />
          <Box display="flex" justifyContent="space-around">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onConfirm(reason);
              }}
            >
              Reject
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default FundingRequestRejectModal;
