import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IAffiliateState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IAffiliateState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  hasSuccessfully(state, action) {
    state.successfully = action.payload;
  },
  getAffiliatesListSuccess(state, action) {
    state.affiliates = action.payload;
  },
  setSimplifiedAffiliates(state, action) {
    state.simplifiedAffiliates = action.payload;
  },
  setCreditUnions(state, action) {
    state.creditUnions = action.payload;
  },
  setCreditUnionsLoading(state, action) {
    state.creditUnionsLoading = action.payload;
  },
  getAffiliatesListCount(state, action) {
    state.affiliatesCount = action.payload;
  },
  getCurrentAffiliateIdSuccess(state, action) {
    state.currentAffiliateById = action.payload;
  },
  getAffiliateChannelByIdSuccess(state, action) {
    state.channel = action.payload;
  },
  getCurrentAffiliateChannelIdSuccess(state, action) {
    state.currentAffiliateByChannelId = action.payload;
  },
  getAffiliateChannelPostingDetailsSuccess(state, action) {
    state.affiliateChannelPostingDetails = action.payload;
  },
  getLoading(state, action) {
    state.isLoading = action.payload;
  },
  setAffiliatesLoading(state, action) {
    state.isAffiliatesLoading = action.payload;
  },
  setUpdateAffiliateStatusLoading(state, action) {
    state.isUpdateAffiliateStatusLoading = action.payload;
  },
  setAffiliateChannelsLoading(state, action) {
    state.isAffiliateChannelsLoading = action.payload;
  },
  setCreateAffiliateLoading(state, action) {
    state.isCreateAffiliateLoading = action.payload;
  },
  setDeleteAffiliateLoading(state, action) {
    state.isDeleteAffiliateLoading = action.payload;
  },
  setUpdateAffiliateChannelLoading(state, action) {
    state.isUpdateAffiliateChannelLoading = action.payload;
  },
  setCreateAffiliateChannelLoading(state, action) {
    state.isCreateAffiliateChannelLoading = action.payload;
  },
  setUpdateAffiliateChannelStatusLoading(state, action) {
    state.isUpdateAffiliateChannelStatusLoading = action.payload;
  },
  setDeleteAffiliateChannelLoading(state, action) {
    state.isDeleteAffiliateChannelLoading = action.payload;
  },
  setAffiliateChannelPostingDetailsLoading(state, action) {
    state.isAffiliateChannelPostingDetailsLoading = action.payload;
  },
  setAffiliateChannelHistoryLoading(state, action) {
    state.isAffiliateChannelHistoryLoading = action.payload;
  },
  getAffiliateChannelListSuccess(state, action) {
    state.affiliateChannels = action.payload;
  },
  setSimplifiedAffiliatesChannel(state, action) {
    state.simplifiedAffiliateChannels = action.payload;
  },
  getAffiliateChannelListCount(state, action) {
    state.affiliateChannelsCount = action.payload;
  },
  getDataFormatsSuccess(state, action) {
    state.dataFormats = action.payload;
  },
  createAffiliateChannelSuccess(state, action) {
    state.createdAffiliateChannel = action.payload;
  },
  setAffiliateChannelHistory(state, action) {
    state.affiliateChannelHistory = action.payload;
  },
  getExternalServiceByIdSuccess(state, action) {
    state.externalServiceById = action.payload;
  },
  setCampaignsAffiliateChannelsCount(state, action) {
    state.campaignsAffiliateChannelsCount = action.payload;
  },
  setAffiliateChannelHistoryCount(state, action) {
    state.affiliateChannelHistoryCount = action.payload;
  },
  setAffiliateFilters(state, action) {
    state.affiliateFilters = action.payload;
  },
  setAffiliateChannelFilters(state, action) {
    state.affiliateChannelFilters = action.payload;
  },
  setAffiliateChannelDetailsTabs(state, action) {
    state.affiliateChannelDetailsTabs = action.payload;
  },
});

export default reducers;
