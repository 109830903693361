import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { CustomButton } from 'components/shared';
import Title from 'components/UI/Title';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from '../../../redux/slices/manualReviews';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';

export interface ICreateReasonModalProps {
  id: string;
  name: string;
}

const CreateReasonModal = ({ id, name }: ICreateReasonModalProps) => {
  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.CreateReasonModal));
  };
  const aanReasonsLoading = useAppSelector(manualReviewSelector.aanReasonsLoading);

  const onReasonCreate = () => {
    dispatch(manualReviewMiddleware.createAanReason(id, name));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Create a reason"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box textAlign="center">
            <Typography>You are creating a reason that can’t be edited and will be visible to everyone</Typography>
          </Box>
          <Box display="flex" justifyContent="space-around" marginTop="20px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton onClick={onReasonCreate} loading={aanReasonsLoading}>
              Create
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default CreateReasonModal;
