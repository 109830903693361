import moment from 'moment/moment';
import { IFundingFilters } from 'types/funding';

import { ILeadsFilters } from '@types';

import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { IManualReviewListFilters } from '../../redux/slices/manualReviews/initialState';

export const FUND_LIST_KEY = 'fundList';
export const MANUAL_REVIEW_LIST_KEY = 'manualReviewList';
export const APPLICATIONS_KEY = 'applicationList';

export interface PersistData {
  version: number;
  [FUND_LIST_KEY]: IFundingFilters;
  [MANUAL_REVIEW_LIST_KEY]: IManualReviewListFilters;
  [APPLICATIONS_KEY]: ILeadsFilters;
}

const fundListDefault = {
  filterBorrowerName: '',
  searchModel: {
    pageNumber: 1,
    pageSize: DEFAULT_ROWS_PAGE,
  },
};

const manualReviewDefault = {
  applicationId: '',
  borrowerName: '',
  searchModel: {
    pageNumber: 1,
    pageSize: DEFAULT_ROWS_PAGE,
    sortOrder: { key: 'Id', value: false },
  },
};

const applicationsDefault = {
  dateFrom: moment().subtract(1, 'year').startOf('day').format(),
  dateTo: moment().endOf('day').format(),
  status: 0,
  filterAffiliateIds: [],
  filterAffiliateChannelIds: [],
  filterCampaignIds: [],
  filterLeadIds: [],
  filterTags: [],
  filterEmail: [],
  filterBorrowerName: '',
  searchModel: {
    pageNumber: 1,
    pageSize: DEFAULT_ROWS_PAGE,
    sortOrder: { key: 'Id', value: false },
  },
};

export const persistData: PersistData = {
  version: 0,
  [FUND_LIST_KEY]: { ...fundListDefault },
  [MANUAL_REVIEW_LIST_KEY]: { ...manualReviewDefault },
  [APPLICATIONS_KEY]: { ...applicationsDefault },
};

export class PersistManager {
  static setData(key: string, data: any) {
    try {
      const jsonData = JSON.stringify(data);

      localStorage.setItem(key, jsonData);
    } catch {
      console.error('Error setting persist data');
    }
  }

  static getData(key: keyof PersistData) {
    const data = localStorage.getItem(key);

    return data && this.versionMatch() ? JSON.parse(data) : persistData[key];
  }

  static setVersion() {
    this.setData('version', persistData.version);
  }

  static versionMatch() {
    try {
      return JSON.parse(localStorage.getItem('version') ?? '') === persistData.version;
    } catch (error) {
      return '';
    }
  }

  static clear() {
    localStorage.clear();
  }

  static removeData(key: keyof PersistData) {
    localStorage.removeItem(key);
  }
}
