import { useState } from 'react';

import ManualReviewDetails from '../../components/ManualReview/ManualReviewDetails';
import ManualReviewTable from '../../components/ManualReview/ManualReviewTable';

const ManualReview = ({ leadId }: { leadId: string | undefined }) => {
  const [mode, setMode] = useState<string>('table');
  const [selectedRow, setSelectedRow] = useState<string>('');

  return (
    <>
      {mode === 'table' && <ManualReviewTable setMode={setMode} setSelectedRow={setSelectedRow} leadId={leadId} />}
      {mode === 'edit' && <ManualReviewDetails setMode={setMode} id={selectedRow} leadId={leadId} />}
    </>
  );
};

export default ManualReview;
