import { useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControl, Switch } from '@mui/material';

import { IAction, IPermission, IPermissionsList } from '../../../redux/slices/userManagement/initialState';

interface PermissionsTableProps {
  data: IPermissionsList;
  type: string;
}

const PermissionsTable = ({ data, type }: PermissionsTableProps) => {
  const { setValue, watch, register } = useFormContext();

  const onSwitchChange = (checked: boolean, item: IPermission) => {
    setValue(`permissions.${item.key}`, checked);

    if (!checked && item.actions) {
      item.actions.forEach((el) => {
        setValue(`permissions.${el.key}`, false);
      });
    }
  };

  return (
    <Box
      sx={{
        fontWeight: 600,
        fontSize: '15px',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#FAFAFA',
          borderBottom: '1px solid rgba(149, 161, 177, 0.20)',
          borderTop: '1px solid rgba(149, 161, 177, 0.20)',
          display: 'flex',
          marginTop: '16px',
        }}
      >
        <Box
          sx={{
            width: '75%',
            padding: '16px 12px',
            borderRight: '1px solid rgba(149, 161, 177, 0.20)',
          }}
        >
          {type}
        </Box>
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Edit
        </Box>
      </Box>
      {data[type].map((item: IPermission) => (
        <Box key={item.key}>
          <Box sx={{ display: 'flex', borderBottom: '1px solid rgba(149, 161, 177, 0.20)' }}>
            <Box
              sx={{
                width: '14%',
                paddingTop: '6px',
                borderRight: '1px solid rgba(149, 161, 177, 0.20)',
              }}
            >
              <FormControl>
                <Switch
                  color="secondary"
                  checked={watch(`permissions.${item.key}`)}
                  onChange={(event, checked) => onSwitchChange(checked, item)}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: '61%', padding: '16px' }}>{item.name}</Box>
            <Box sx={{ width: '25%', borderLeft: '1px solid rgba(149, 161, 177, 0.20)' }} />
          </Box>
          <Box>
            {item.actions?.length && watch(`permissions.${item.key}`)
              ? item.actions.map((action: IAction) => (
                  <Box key={action.key} sx={{ display: 'flex', borderBottom: '1px solid rgba(149, 161, 177, 0.20)' }}>
                    <Box sx={{ width: '14%', borderRight: '1px solid rgba(149, 161, 177, 0.20)' }} />
                    <Box
                      sx={{
                        width: '61%',
                        padding: '16px',
                      }}
                    >
                      {action.name}
                    </Box>
                    <Box
                      sx={{
                        width: '25%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderLeft: '1px solid rgba(149, 161, 177, 0.20)',
                      }}
                    >
                      <Checkbox
                        {...register(`permissions.${action.key}`)}
                        checked={watch(`permissions.${item.key}`) && watch(`permissions.${action.key}`)}
                        color="secondary"
                      />
                    </Box>
                  </Box>
                ))
              : null}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PermissionsTable;
