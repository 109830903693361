// material-ui
import { AlertProps, SnackbarOrigin } from '@mui/material';

// ==============================|| SNACKBAR TYPES  ||============================== //

export interface ISnackbarProps {
  action: boolean;
  open: boolean;
  message: string;
  anchorOrigin: SnackbarOrigin;
  variant: string;
  alert: IOpenedAlert;
  transition: string;
  close: boolean;
  actionButton: boolean;
}

export interface IOpenedAlert {
  color: SeveritiesType;
  variant: AlertProps['variant'];
}

export enum SeveritiesType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}
