import { ValidationTreeActionType } from '../../types';

export enum ValidationTreeModalType {
  Description = 'Description',
  Aan = 'AAN',
}

export interface IValidationTreeFormValues {
  [key: string]: IValidationTreeModel[];
}

export interface IValidationTreeModel {
  FilterType: ValidationTreeActionType;
  Field: string;
  Condition: number | string;
  Value: string;
  Action: string | number;
  // TODO set type for Schedule
  Schedule: {}[];
  Name?: string;
  CampaignField?: string;
  // TODO set type for Cap
  Cap: any;
  Pause: boolean;
  description: string;
  aanDescription: string;
  isActionActive: boolean;
  filters: IValidationTreeModelChildren[] | [];
  trees: number[];
  id?: string;
  Reason?: string;
  Expression?: string;
}

export interface IValidationTreeModelChildren {
  Field: string;
  Condition: number | string;
  Value: string;
  Action: string | number;
  // TODO set type for Schedule
  Schedule?: {}[];
  // TODO set type for Cap
  Cap?: any;
  Pause?: boolean;
  Name?: string;
  CampaignField?: string;
}

export const validationTreeModel = {
  FilterType: ValidationTreeActionType.CampaignFields,
  Field: '',
  Condition: '',
  Value: '',
  Action: '',
  Schedule: [{}],
  Cap: '',
  Pause: false,
  isActionActive: false,
  filters: [],
  schedule: [] as {}[],
};

export const validationTreeFilterModel = {
  Field: '',
  Condition: '',
  Value: '',
  Action: '',
};
