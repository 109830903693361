import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from 'redux/slices/manualReviews';

import { MANUAL_REVIEW_LIST_KEY, PersistManager } from '../../helpers/PersistManager';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { ManualReviewStatuses } from '../../lib/constants/statuses';
import { leadsMiddleware } from '../../redux/slices/leads';
import { tagsMiddleware, tagsSelector } from '../../redux/slices/tags';
import { ApplicationTabs, TableHeader } from '../../types';
import { useGenerateTableData } from '../Hooks';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';

const ManualReviewListTable = ({
  page,
  setPage,
  selectedTableHeaders,
}: {
  page: number;
  setPage: (value: number) => void;
  selectedTableHeaders: TableHeader[];
}) => {
  const manualReviewsList = useAppSelector(manualReviewSelector.manualReviewsList);
  const isLoading = useAppSelector(manualReviewSelector.isLoading);
  const tags = useAppSelector(tagsSelector.tags);
  const navigate = useNavigate();
  const {
    tableData,
    setTableData,
    order,
    orderBy,
    sortTableData,
    setOrder,
    setOrderBy,
    rowsPerPage,
    handleRequestSort,
    setRowsPerPage,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: manualReviewsList?.data,
    paginationCount: manualReviewsList?.itemsCount,
  });

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      manualReviewMiddleware.fetchManualReviewsList({
        searchModel: {
          pageNumber: 1,
          pageSize: newRowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      }),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      manualReviewMiddleware.fetchManualReviewsList({
        searchModel: {
          pageNumber: newPage + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      }),
    );
    setPage(newPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      manualReviewMiddleware.fetchManualReviewsList({
        searchModel: {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: property === 'borrower' ? 'firstName' : property, value: newOrder },
        },
      }),
    );
  };

  const handleChangePageWithProps = (row: Record<string, string>) => {
    navigate(`/applications/${row.leadId}`);
    dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.manualReview));
  };

  useEffect(() => {
    dispatch(manualReviewMiddleware.fetchManualReviewsList());

    const orderData = PersistManager.getData(MANUAL_REVIEW_LIST_KEY)?.searchModel;

    setOrder(orderData?.sortOrder?.value ? 'asc' : 'desc');
    setOrderBy(orderData?.sortOrder?.key ?? 'Id');
    setPage(Number(orderData?.pageNumber) - 1 ?? 0);
    setRowsPerPage(orderData?.pageSize ?? DEFAULT_ROWS_PAGE);

    dispatch(tagsMiddleware.fetchTags());

    return () => {
      dispatch(manualReviewMiddleware.fetchManualReviewsList());
    };
  }, [setOrder, setOrderBy, setPage, setRowsPerPage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <DenseTable
        dataTest="manual-reviews-page"
        data={tableData}
        editButton={false}
        setTableData={setTableData}
        order={order as string}
        orderBy={orderBy}
        isAction={false}
        page={page}
        isDeleted={false}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPageServer}
        handleChangePage={handleChangePageServer}
        handleSort={handleSortServer}
        handleRequestSort={handleRequestSort}
        sortTableData={sortTableData}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        selectedTableHeaders={selectedTableHeaders}
        handleChangePageWithProps={handleChangePageWithProps}
        Statuses={ManualReviewStatuses}
        statusMode="BADGE"
        paginationCount={paginationCount}
        tags={tags ?? []}
        tagsPage={2}
        tagsKey="leadId"
        handleSortCount={handleSortCount}
      />
    </Box>
  );
};

export default ManualReviewListTable;
