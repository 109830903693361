import { useCallback } from 'react';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useTurnOnIntegrationModal = () => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.TurnOnIntegrationModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.TurnOnIntegrationModal));
  }, []);

  return {
    onClose,
    onConfirm,
  };
};

export default useTurnOnIntegrationModal;
