import { Box, Typography } from '@mui/material';

import { ILeadsManualReviewReasonDocument } from '@types';

import { ManualReviewUploadedDocumentStatuses } from '../../lib/constants/statuses';
import { DocumentTypes } from '../../pages/Leads/Documents';
import { useAppSelector } from '../../redux/hooks';
import { manualReviewSelector } from '../../redux/slices/manualReviews';
import FileAttachment, { IFile } from '../FileUploadModal/FileAttachment';
import { StatusBadgeTitle } from '../StatusBadgeTitle';

export interface ISingleFileAttachmentProps {
  file: ILeadsManualReviewReasonDocument;
  showStatus: boolean;
  deleteFile?: (file: IFile) => void;
  canView?: boolean;
}

const SingleFileAttachment = ({ file, showStatus, deleteFile, canView }: ISingleFileAttachmentProps) => {
  const documentTypes = useAppSelector(manualReviewSelector.documentTypes);
  const documentType = documentTypes?.find((el) => el.id === file.documentTypeId) ?? {
    name: '',
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>{DocumentTypes[documentType.name] ?? documentType.name}</Typography>
        <StatusBadgeTitle Statuses={ManualReviewUploadedDocumentStatuses} status={showStatus ? file.status : 0} />
      </Box>
      <Box>
        <FileAttachment
          key={file.name}
          file={file as unknown as IFile}
          index={1}
          deleteFile={deleteFile}
          canView={canView}
        />
      </Box>
    </Box>
  );
};

export default SingleFileAttachment;
