import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { IFile } from 'components/FileUploadModal/FileAttachment';
import { CustomButton } from 'components/shared';
import Title from 'components/UI/Title';
import { fundingMiddleware } from 'redux/slices/funding';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from '../../../redux/slices/manualReviews';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';

export interface IDeleteFileModalProps {
  file: IFile;
  reasonId: string;
  modalType: 'ManualReview' | 'Funding';
}

const DeleteFileModal = ({ file, reasonId, modalType }: IDeleteFileModalProps) => {
  const deleteFileLoading = useAppSelector(manualReviewSelector.deleteFileLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteFileModal));
  };

  const onDelete = () => {
    if (modalType === 'ManualReview') {
      dispatch(manualReviewMiddleware.deleteManualReviewReasonFile(file.id, reasonId));
    } else if (modalType === 'Funding') {
      dispatch(fundingMiddleware.deleteFundingFile(file.documentId, reasonId));
    }
  };

  return (
    <Dialog data-test="reset password-modal" open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Title
            title="Delete File"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3, textAlign: 'center' }}>
          <Typography fontSize="18px" marginBottom="30px" component="p">
            Please confirm that you want to delete {file.name} Document
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              loading={deleteFileLoading}
              onClick={onDelete}
              variant="contained"
              color="error"
              sx={{ backgroundColor: '#FF497F', borderColor: '#FF497F' }}
            >
              Delete
            </CustomButton>
          </Stack>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteFileModal;
