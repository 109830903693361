import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Dialog, FormControl } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import CustomTextArea from 'components/shared/CustomInputs/CustomTextArea';
import Title from 'components/UI/Title';

import { dispatch } from '../../../redux/hooks';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';

export interface IDescriptionModalProps {
  description: string;
  setDescription: (description: string) => void;
}

const DescriptionModal = ({ description, setDescription }: IDescriptionModalProps) => {
  const [descriptionMessage, setDescriptionMessage] = useState(description);
  const methods = useForm({
    mode: 'onSubmit',
  });

  const { handleSubmit } = methods;

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DescriptionModal));
  };

  const onSubmit = () => {
    setDescription(descriptionMessage.trim());
    onClose();
  };

  return (
    <Dialog data-test="decision-tree-description-modal" open onClose={onClose} maxWidth="lg">
      <Box sx={{ padding: '12px 24px', overflowY: 'hidden', overflowX: 'auto', width: '500px' }}>
        <Title
          dataTest="decision-tree-description-modal-title"
          title="Add Description"
          sx={{ fontSize: '16px', textAlign: 'center' }}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ marginTop: '16px', maxHeight: '400px', overflowX: 'hidden', overflowY: 'auto' }}>
            <FormControl fullWidth>
              <CustomTextArea
                data-test="decision-tree-description-textarea"
                aria-label=" "
                placeholder="Type..."
                defaultValue={descriptionMessage}
                label="Description"
                maxRows={2}
                onChange={(event) => setDescriptionMessage(event.target.value)}
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              />
            </FormControl>
          </Box>
          <Permission permission={Permissions.changeDecisionTreeFilter}>
            <Box display="flex" justifyContent="space-between" marginTop="24px" gap="16px">
              <CustomButton
                data-test="decision-tree-description-modal-button-cancel"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test="decision-tree-description-modal-button-submit"
                type="submit"
                disabled={!descriptionMessage?.length}
              >
                Add
              </CustomButton>
            </Box>
          </Permission>
        </form>
      </Box>
    </Dialog>
  );
};

export default DescriptionModal;
