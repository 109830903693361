// types
import { ConfigProps } from 'types/config';

export const BASE_PATH = '/';

export const DASHBOARD_PATH = '/';

export const AUTH_PATH = '/auth';
export const SET_PASSWORD = 'user-set-password';
export const RECOVERY_PASSWORD = 'recover';
export const SIGN_IN_PATH = `${AUTH_PATH}/sign-in`;
export const SIGN_UP_PATH = `${AUTH_PATH}/sign-up`;
export const FORGOT_PASSWORD_PATH = `${AUTH_PATH}/forgot-password`;
export const RESET_PASSWORD_PATH = `${AUTH_PATH}/reset-password`;
export const RECOVERY_PASSWORD_PATH = `/${RECOVERY_PASSWORD}/:token`;
export const SET_PASSWORD_PATH = `/${SET_PASSWORD}/:token`;

const config: ConfigProps = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: 'light', // light, dark
  presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export default config;
