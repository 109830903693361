import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.tenant;

const logo = createSelector([selector], (state) => state.logo);

export default {
  logo,
};
