import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export interface ClientNameCardProps {
  firstName: string;
  lastName: string;
}

const ClientNameCardRoot = styled(Box)(() => ({
  width: '30px',
  height: '30px',
  borderRadius: '8px',
  backgroundColor: '#7B61FF',
  color: '#ffffff',
  fontWeight: 800,
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '12px',
}));

const ClientNameCard = ({ firstName, lastName }: ClientNameCardProps) => {
  const Initials = firstName[0].toUpperCase() + lastName[0].toUpperCase();

  return <ClientNameCardRoot>{Initials}</ClientNameCardRoot>;
};

export default ClientNameCard;
