import { useFormContext } from 'react-hook-form';
import { Checkbox, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface IFormCheckboxWithTitleProps {
  name: string;
  title: string;
  description?: string;
}

const FormCheckboxWithTitle = ({ name, title, description }: IFormCheckboxWithTitleProps) => {
  const { watch, register } = useFormContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E5E8F0',
        '&:last-child': {
          border: 'none',
        },
        marginY: '4px',
      }}
    >
      <Box>
        <Typography sx={{ fontSize: '14px', color: '#454256', marginY: '5px' }}>{title}</Typography>
        <Typography sx={{ fontSize: '14px', color: '#687585', marginY: '5px' }}>{description}</Typography>
      </Box>
      <Checkbox color="secondary" checked={watch(name)} {...register(name)} />
    </Box>
  );
};

export default FormCheckboxWithTitle;
