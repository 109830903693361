import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ButtonStyles {
  background?: string;
  borderColor?: string;
  color?: string;
}

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  dataTest?: string;
}

interface ButtonSizeStyles {
  fontSize: string;
  fontWeight: number;
  lineHeight: string;
  padding: string;
  height: string;
}

const mapSizeToStyles: Record<NonNullable<MuiButtonProps['size']>, ButtonSizeStyles> = {
  small: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    padding: '12px 10px',
    height: '40px',
  },
  medium: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '10px 18px',
    height: '44px',
  },
  large: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '12px 20px',
    height: '48px',
  },
};

const mapVariantToDisabledStyles: Record<NonNullable<MuiButtonProps['variant']>, ButtonStyles | null> = {
  contained: {
    background: '#AAABD1',
    borderColor: '#AAABD1',
    color: '#FFFFFF',
  },
  outlined: {
    background: '#FFFFFF',
    borderColor: '#E4E7EC',
    color: '#D0D5DD',
  },
  text: { color: '#7B61FF' },
};

const ButtonRoot = styled(MuiButton)(({ size = 'small', variant = 'contained' }) => ({
  textTransform: 'none',
  boxSizing: 'border-box',
  marginBottom: '0 !important',
  marginTop: '0 !important',
  boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1),0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  ...mapSizeToStyles[size],
  ...mapVariantToDisabledStyles[variant],

  '& .MuiButton-contained': {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },

  '&.MuiButton-text': {
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: 'none',
    },
  },

  '& input': {
    minWidth: '0 !important',
  },

  '& p': {
    fontWeight: 600,
  },

  '&:disabled': {
    backgroundColor: '#9e9e9e',
  },
  '&:hover': {
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.1),0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
  },
}));

const CustomButton = ({ loading, variant = 'contained', children, ...props }: ButtonProps) => (
  <ButtonRoot variant={variant} {...props}>
    {loading ? <CircularProgress color="inherit" size={25} /> : children}
  </ButtonRoot>
);

export { CustomButton };
