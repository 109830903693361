import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useTwoFactorAuthenticationDisableModal from './hooks/useTwoFactorAuthenticationDisableModal';

export interface ITwoFactorAuthenticationDisableModalProps {
  setToggle: (value: boolean) => void;
}

const TwoFactorAuthenticationDisableModal = ({ setToggle }: ITwoFactorAuthenticationDisableModalProps) => {
  const { onClose, onConfirm } = useTwoFactorAuthenticationDisableModal(setToggle);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          paddingTop: 0,
        },
      }}
    >
      <Grid sx={{ width: '450px' }}>
        <DialogTitle
          sx={{
            backgroundColor: 'rgba(249, 248, 254, 1)',
          }}
        >
          <Title
            title="Two-Factor Authentication"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '18px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '30px',
            }}
          >
            <Typography sx={{ fontWeight: 400, fontSize: '15px', width: '350px', textAlign: 'center' }}>
              Are you sure you want to{' '}
              <Box component="span" sx={{ fontWeight: 700 }}>
                disable
              </Box>{' '}
              Two-Factor Authentication?
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" marginTop="40px">
            <CustomButton onClick={onClose} variant="outlined">
              Cancel
            </CustomButton>
            <CustomButton onClick={onConfirm}>Disable</CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TwoFactorAuthenticationDisableModal;
