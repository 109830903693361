import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ITagsState } from './initialState';

const createReducer = <T extends SliceCaseReducers<ITagsState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setTagsLoading(state, action) {
    state.tagsLoading = action.payload;
  },
  setTags(state, action) {
    state.tags = action.payload;
  },
});

export default reducers;
