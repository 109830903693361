import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Typography } from '@mui/material';
import ClientNameCard from 'components/shared/DropdownButton/NameCard';
import { isMe } from 'helpers';
import moment from 'moment';

import { INote } from '@types';

import { ReactComponent as NoteReasonIcon } from '../../assets/images/NoteReasonIcon.svg';
import { dispatch } from '../../redux/hooks';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

import NoteDocumentAttachment from './NoteDocumentAttachment';

export interface INoteProps {
  note: INote;
  leadId: string;
  page: number;
  rowsPerPage: number;
  selectedUser?: number;
}

const Note = ({ note, leadId, page, rowsPerPage, selectedUser }: INoteProps) => {
  const onEdit = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.AddNoteModal,
        props: { leadId, mode: 'Edit', defaultNote: note, page, rowsPerPage, selectedUser },
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DeleteNoteModal,
        props: { note, leadId, page, rowsPerPage, selectedUser },
      }),
    );
  };

  return (
    <Box
      sx={{
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid #E2E8F0',
        color: '#454256',
        marginTop: '16px',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ClientNameCard firstName={note.userName.split(' ')[0][0]} lastName={note.userName.split(' ')[1][0] ?? ' '} />
          <Box component="span">
            <Typography component="span" fontWeight="700">
              {note.userName}
            </Typography>
            {note.reason ? (
              <Typography component="span" sx={{ color: '#687585' }}>
                {' '}
                on <NoteReasonIcon /> {note.reason}
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography>{moment(note.updatedAt ?? note.createdAt).format('MMM Do YYYY')}</Typography>
          <Typography color="#95A1B1">{moment(note.updatedAt ?? note.createdAt).fromNow()}</Typography>
          {isMe(note.userId) && !note.reason ? (
            <Box display="flex" gap="8px">
              <Box
                sx={{
                  padding: '2px',
                  borderRadius: '4px',
                  border: '1px solid rgba(112, 118, 146, 0.16)',
                  cursor: 'pointer',
                }}
                onClick={onEdit}
              >
                <EditOutlinedIcon sx={{ color: '#99A2BB', width: '18px', height: '18px' }} />
              </Box>
              <Box
                sx={{
                  padding: '2px',
                  borderRadius: '4px',
                  border: '1px solid rgba(112, 118, 146, 0.16)',
                  cursor: 'pointer',
                }}
                onClick={onDelete}
              >
                <DeleteOutlineOutlinedIcon sx={{ color: '#99A2BB', width: '18px', height: '18px' }} />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            padding: '8px 10px',
            backgroundColor: isMe(note.userId) ? '#F3F0FF' : '#F4F7FE',
            borderRadius: '8px',
            marginTop: '4px',
            marginLeft: '40px',
          }}
        >
          {note.note}
        </Box>
      </Box>
      {note.documents ? (
        <Box sx={{ paddingTop: '10px' }}>
          {note.documents.map((doc) => (
            <Box
              key={doc.id}
              sx={{
                padding: '8px 10px',
                backgroundColor: isMe(note.userId) ? '#F3F0FF' : '#F4F7FE',
                borderRadius: '8px',
                marginTop: '4px',
                marginLeft: '40px',
              }}
            >
              <NoteDocumentAttachment name={doc.name} status={doc.status} />
              <Typography>{doc.note}</Typography>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default Note;
