import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IListsState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IListsState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  setLoading(state, action) {
    state.isLoading = action.payload;
  },
  setPostListValuesLoading(state, action) {
    state.isCreateListValuesLoading = action.payload;
  },
  setEditListValueLoading(state, action) {
    state.isEditListValueLoading = action.payload;
  },
  setSelectedListsLoading(state, action) {
    state.isSelectedListsLoading = action.payload;
  },
  setListsSuccess(state, action) {
    state.lists = action.payload;
  },
  setUploadedListsSuccess(state, action) {
    state.uploadedLists = action.payload;
  },
  setSelectedListsSuccess(state, action) {
    state.selectedLists = action.payload;
  },
});

export default reducers;
