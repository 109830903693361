export interface ITagColor {
  name: string;
  hex: string;
  border: string;
  id: number;
}

export enum ColorNumbers {
  LightGray = 0,
  Orange = 1,
  Blue = 2,
  Brown = 3,
  Purple = 4,
  Gray = 5,
  Yellow = 6,
  Red = 7,
  Pink = 8,
}

export const colors = [
  { name: 'LightGray', hex: '#D7D7D5', border: '#C4C4C3', id: ColorNumbers.LightGray },
  { name: 'Orange', hex: '#EFD0B6', border: '#E2C6AE', id: ColorNumbers.Orange },
  { name: 'Blue', hex: '#B6D4E0', border: '#A3BFCA', id: ColorNumbers.Blue },
  { name: 'Brown', hex: '#D2C9C5', border: '#B9B1AD', id: ColorNumbers.Brown },
  { name: 'Purple', hex: '#CBBFDD', border: '#B2A9C1', id: ColorNumbers.Purple },
  { name: 'Gray', hex: '#C1C1BF', border: '#AAAAA8', id: ColorNumbers.Gray },
  { name: 'Yellow', hex: '#EFE0AD', border: '#DED0A0', id: ColorNumbers.Yellow },
  { name: 'Red', hex: '#EFC1C1', border: '#DAACAC', id: ColorNumbers.Red },
  { name: 'Pink', hex: '#E1B8D1', border: '#C7A4B9', id: ColorNumbers.Pink },
];
