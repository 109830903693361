import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useGenerateTableData } from 'components/Hooks';
import { PagesSearch } from 'components/Layout/MainLayout/Header/SearchSection';
import { DenseTable } from 'components/Tables';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';

import { TableHeader } from '@types';

import { CustomButton } from '../../components';
import { Permissions } from '../../components/Permissions/constants';
import Permission, { hasPermission } from '../../components/Permissions/Permission';
import SelectTableHeaders from '../../components/UI/SelectTableHeaders';
import { AffiliatesTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

const AffiliatesPage: FC = () => {
  const navigate = useNavigate();

  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(AffiliatesTableHeaders);
  const [searchText, setSearchText] = useState<string>('');
  const [defaultStatus, setDefaultStatus] = useState<number>(-1);
  const affiliates = useAppSelector(affiliatesSelector.affiliates);
  const affiliatesCount = useAppSelector(affiliatesSelector.affiliatesCount);
  const isAffiliatesLoading = useAppSelector(affiliatesSelector.isAffiliatesLoading);
  const isUpdateAffiliateStatusLoading = useAppSelector(affiliatesSelector.isUpdateAffiliateStatusLoading);
  const affiliateFilters = useAppSelector(affiliatesSelector.affiliateFilters);

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: affiliates,
    paginationCount: +(affiliatesCount ?? 0),
  });

  const handleChangeStatus = (row: { [p: string]: string }, checked: number) => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateStatus(
        row.id,
        checked,
        defaultStatus,
        page + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliates(
        defaultStatus,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      affiliatesMiddleware.fetchAffiliates(defaultStatus, 1, newRowsPerPage, orderBy, order === 'asc', searchText),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliates(defaultStatus, page + 1, rowsPerPage, property, newOrder, searchText),
    );
  };

  const handleDeleteAction = (row: { [p: string]: string }) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateDeleteModal,
        props: {
          id: row.id,
          defaultStatus,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrderKey: orderBy,
          sortOrderValue: order === 'asc',
          searchText,
        },
      }),
    );
  };

  const handleStatusChange = (status: number) => {
    setDefaultStatus(status);
    dispatch(affiliatesMiddleware.fetchAffiliates(status, 1, rowsPerPage, 'Id', false, searchText));
    setPage(0);
  };

  const handleSearch = (value: string) => {
    dispatch(affiliatesMiddleware.fetchAffiliates(defaultStatus, 1, rowsPerPage, 'Id', false, value));
    setSearchText(value);
    setPage(0);
  };

  useEffect(() => {
    if (affiliateFilters) {
      dispatch(
        affiliatesMiddleware.fetchAffiliates(
          affiliateFilters.status,
          affiliateFilters.pageNumber,
          affiliateFilters.pageSize,
          affiliateFilters.sortOrderKey,
          affiliateFilters.sortOrderValue,
          affiliateFilters.name,
        ),
      );
      setPage(Number(affiliateFilters.pageNumber) - 1);
      setRowsPerPage(affiliateFilters.pageSize);
      setOrder(affiliateFilters.sortOrderValue ? 'asc' : 'desc');
      setOrderBy(affiliateFilters.sortOrderKey ?? 'Id');
      setDefaultStatus(affiliateFilters.status);
      setSearchText(affiliateFilters.name ?? '');
    } else {
      dispatch(affiliatesMiddleware.fetchAffiliates(-1, 1, rowsPerPage));
    }
  }, []);

  if (isAffiliatesLoading || isUpdateAffiliateStatusLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ marginTop: '24px', marginBottom: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '24px' }}>
          <Title dataTest="affiliates-page-header-affiliates-list" title="Sources List" />
        </Grid>
        <Grid item container xs={12}>
          <Grid item md={3}>
            <PagesSearch
              sx={{ margin: 0, maxWidth: '200px' }}
              handleSearch={handleSearch}
              title="Search"
              defaultValue={searchText}
            />
          </Grid>
          <Grid item md={3}>
            <SelectTableHeaders
              dataTest="affiliates-page-dropdown-custom-columns"
              tableHeaders={AffiliatesTableHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
            />
          </Grid>
          <Grid item md={3}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="ShowPartData-select" shrink>
                Show
              </InputLabel>
              <Select
                data-test="affiliates-page-dropdown-show"
                notched
                label="Show"
                labelId="ShowPartData-select"
                id="ShowPartData"
                name="ShowPartData"
                value={defaultStatus}
                onChange={(e) => {
                  handleStatusChange(Number(e.target.value));
                }}
              >
                <MenuItem value="-1">Show All</MenuItem>
                <MenuItem value="0">Only Inactives</MenuItem>
                <MenuItem value="1">Only Actives</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Permission permission={Permissions.createSource}>
            <Grid item md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
                <CustomButton data-test="affiliates-page-button-create-affiliate" onClick={() => navigate('create')}>
                  Create Source
                </CustomButton>
              </Box>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DenseTable
          dataTest="affiliates-page"
          selectedTableHeaders={selectedTableHeaders}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          sortTableData={sortTableData}
          data={tableData}
          editButton={false}
          isAction={hasPermission(Permissions.deleteSource)}
          isDeleted={hasPermission(Permissions.deleteSource)}
          setTableData={setTableData}
          order={order as string}
          orderBy={orderBy}
          page={page}
          action={{
            type: 'Link',
            linkKeys: ['id', 'name'],
          }}
          rowsPerPage={rowsPerPage}
          handleRequestSort={handleRequestSort}
          createSortHandler={createSortHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPageServer}
          handleChangePage={handleChangePageServer}
          handleSort={handleSortServer}
          handleChangeStatus={handleChangeStatus}
          handleDeleteAction={handleDeleteAction}
          paginationCount={paginationCount}
          handleSortCount={handleSortCount}
          changeStatusPermission={Permissions.changeIntegrationFields}
        />
      </Grid>
    </Grid>
  );
};

export default AffiliatesPage;
