import React from 'react';
import { Box, CircularProgress } from '@mui/material';

import { gridSpacing } from '../../lib/constants/grid';

import MainCard from './MainCard';

const Loading = ({
  background,
  height = 350,
  width = '100%',
  sx,
}: {
  background?: string;
  height?: number;
  width?: string;
  sx?: React.CSSProperties;
}) => (
  <MainCard
    sx={{
      display: 'flex',
      margin: '0 auto',
      justifyContent: 'center',
      alignItems: 'center',
      ...{ ...({ background } ?? {}) },
      ...sx,
      height,
      width,
    }}
    border={null}
    direction="column"
    spacing={gridSpacing}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        ...{ background },
      }}
    >
      {' '}
      <CircularProgress color="primary" />
    </Box>
  </MainCard>
);

export default Loading;
