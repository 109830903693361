import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';

const selector = (state: RootState) => state.settings;

const qrInfo = createSelector([selector], (state) => state.qrInfo);
const settingsLoading = createSelector([selector], (state) => state.settingsLoading);
const verificationError = createSelector([selector], (state) => state.verificationError);
const verificationEnabled = createSelector([selector], (state) => state.verificationEnabled);
const settingsStatus = createSelector([selector], (state) => state.settingsStatus);
const initializationLoading = createSelector([selector], (state) => state.settingsStatus);
const platformSettings = createSelector([selector], (state) => state.platformSettings);
const platformSettingsLoading = createSelector([selector], (state) => state.platformSettingsLoading);
const tenantSettings = createSelector([selector], (state) => state.tenantSettings);
const tenantSettingsLoading = createSelector([selector], (state) => state.tenantSettingsLoading);
const notificationTypes = createSelector([selector], (state) => state.notificationTypes);
const duplicatedNotificationTypes = createSelector([selector], (state) => state.duplicatedNotificationTypes);
const notificationTypesLoading = createSelector([selector], (state) => state.notificationTypesLoading);

export default {
  qrInfo,
  settingsLoading,
  verificationError,
  verificationEnabled,
  settingsStatus,
  initializationLoading,
  platformSettings,
  platformSettingsLoading,
  tenantSettings,
  tenantSettingsLoading,
  notificationTypes,
  duplicatedNotificationTypes,
  notificationTypesLoading,
};
