import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Button, Popover, Typography } from '@mui/material';

import { ReactComponent as BellIcon } from '../../assets/images/BellIcon.svg';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { notificationMiddleware, notificationSelector } from '../../redux/slices/notification';
import { ISingleNotification } from '../../redux/slices/notification/initialState';
import ALlCaughtUp from '../Icons/AllCaughtUp.svg';
import { AntSwitch } from '../UI/AntSwitch';

import Notification from './Notification';

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [haveUnreadNotification, setHaveUnreadNotification] = useState<boolean>(false);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [filteredData, setFilteredData] = useState<ISingleNotification[]>([]);
  const notifications = useAppSelector(notificationSelector.notifications);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(notificationMiddleware.fetchNotifications());
  }, []);

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllAsRead = () => {
    if (!markAllAsRead && haveUnreadNotification) {
      dispatch(notificationMiddleware.onMarkAllAsReadClick());
    }

    setMarkAllAsRead((prev) => !prev);
  };

  const handleSwitchNotifications = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked && notifications) {
      setFilteredData(notifications?.filter((el) => el.status === 1));
    } else {
      setFilteredData(notifications ?? []);
    }
  };

  const goToNotificationsPage = () => {
    navigate('/notifications');
    handleClose();
  };

  useEffect(() => {
    if (notifications) {
      setFilteredData(notifications);

      setHaveUnreadNotification(!!notifications.filter((el) => el.status === 1).length);
    }
  }, [notifications]);

  const id = anchorEl ? 'simple-popover' : undefined;

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          width: '32px',
          height: '32px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}
      >
        <Button
          sx={{
            padding: 0,
            minWidth: 'auto',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            animation: haveUnreadNotification ? '5s bell-vibrate ease-in-out infinite' : 'none',
            transition: 'background-color 0.3s',
            ':hover': {
              cursor: 'pointer',
              backgroundColor: 'rgba(112, 118, 146, 0.08)',
            },
          }}
        >
          <BellIcon onClick={(e) => handleClick(e)} />
        </Button>
        {haveUnreadNotification && (
          <Box
            sx={{
              backgroundColor: 'rgba(211, 47, 47, 1)',
              width: '7px',
              height: '7px',
              borderRadius: '50%',
              position: 'absolute',
              top: '3px',
              right: '4px',
            }}
          />
        )}
      </Box>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '.css-55h3fy-MuiPaper-root-MuiPopover-paper': {
            boxShadow: '0px 4px 4px 0px #C5C8D2',
          },
        }}
      >
        <Box
          sx={{
            width: '470px',
          }}
        >
          <Box padding="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }}
                onClick={goToNotificationsPage}
              >
                <Typography>Notifications</Typography>
                <OpenInNewIcon />
              </Box>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <AntSwitch sx={{ margin: '0 5px 0 15px' }} onChange={handleSwitchNotifications} />
                <Typography>Only show unread</Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleMarkAllAsRead}>
                <Typography>Mark all as read</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: 'auto',
              maxHeight: '500px',
            }}
          >
            {filteredData?.map((el) => (
              <Notification key={el.id} nameTag={el.assigner || 'N A'} notification={el} handleClose={handleClose} />
            ))}
            {!filteredData?.length && (
              <Box textAlign="center" padding="16px">
                <img src={ALlCaughtUp} alt="Notifications" height={70} />
                <Typography>You have read all your notifications from the last 30 days.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default Notifications;
