import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../../../redux/hooks';
import { menuMiddleware } from '../../../../redux/slices/menu';
import { userManagementMiddleware, userManagementSelector } from '../../../../redux/slices/userManagement';
import { IRolesList } from '../../../../redux/slices/userManagement/initialState';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';
import { BoldTypography } from '../../../Notifications/Notification';
import { CustomButton } from '../../../shared';
import Title from '../../../UI/Title';

export interface IDeleteRoleModalProps {
  role: IRolesList;
}

const DeleteRoleModal = ({ role }: IDeleteRoleModalProps) => {
  const roleDeleteLoading = useAppSelector(userManagementSelector.roleDeleteLoading);
  const navigate = useNavigate();

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteRoleModal));
  };

  const onDelete = () => {
    dispatch(userManagementMiddleware.deleteRole(role));
  };

  const reviewUsersList = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteRoleModal));
    dispatch(userManagementMiddleware.changeRoleDeleteId(role));
    dispatch(menuMiddleware.setActiveItem(['user-list']));
    navigate('/user-list');
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <Box>
          <Title
            title={`Delete Role - ${role.name}`}
            sx={{
              marginBottom: 0,
              padding: 0,
              marginTop: '16px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{ p: 3 }}>
          <Box>
            {+role.usersCount !== 0 ? (
              <Box textAlign="center">
                <Typography>
                  The role <BoldTypography>{role.name}</BoldTypography> was assigned to {role.usersCount} team members.
                </Typography>
                <Typography>
                  Before deleting the role <BoldTypography>{role.name}</BoldTypography>, please review the list of users
                  and assign new roles.
                </Typography>
                <Typography
                  sx={{
                    display: 'inline-block',
                    marginTop: '16px',
                    fontWeight: 'bold',
                    color: '#7B61FF',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={reviewUsersList}
                >
                  Review Users List
                </Typography>
              </Box>
            ) : (
              <Box textAlign="center">
                <Typography>
                  Are you sure you want to delete this role – <BoldTypography>{role.name}</BoldTypography>?
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="space-around" marginTop="24px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              loading={roleDeleteLoading}
              variant="contained"
              type="submit"
              onClick={onDelete}
              disabled={+role.usersCount !== 0}
            >
              Delete
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteRoleModal;
