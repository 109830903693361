import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import Disabled from '../../../assets/images/Disabled.png';
import Enabled from '../../../assets/images/Enabled.png';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useTwoFactorAuthenticationResultModal from './hooks/useTwoFactorAuthenticationResultModal';

export interface ITwoFactorAuthenticationResultModalProps {
  enabled: boolean;
}

const TwoFactorAuthenticationResultModal = ({ enabled }: ITwoFactorAuthenticationResultModalProps) => {
  const { onClose } = useTwoFactorAuthenticationResultModal();

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          paddingTop: 0,
        },
      }}
    >
      <Grid sx={{ width: '500px' }}>
        <DialogTitle
          sx={{
            backgroundColor: 'rgba(249, 248, 254, 1)',
          }}
        >
          <Title
            title="Two-Factor Authentication"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '18px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box textAlign="center" marginTop="30px">
            {enabled ? <img src={Enabled} alt="Enabled" /> : <img src={Disabled} alt="Disabled" />}
          </Box>
          <Box textAlign="center" marginY="20px">
            <Typography>
              {enabled ? 'Two-Factor Authentication is verified.' : 'Two-Factor Authentication was disabled.'}
            </Typography>
          </Box>
          <Box>
            <CustomButton fullWidth onClick={onClose}>
              Close
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TwoFactorAuthenticationResultModal;
