import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ICampaignProps } from '../../../types';

const createReducer = <T extends SliceCaseReducers<ICampaignProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  // HAS ERROR Message
  hasErrorMessage(state, action) {
    state.errorMessages = action.payload;
  },
  hasSuccessfully(state, action) {
    state.successfully = action.payload;
  },
  // GET Time Formats
  setTimeFormatSuccess(state, action) {
    state.timeFormats = action.payload;
  },
  setHistory(state, action) {
    state.history = action.payload;
  },

  // LOADINGS
  setValidatorsLoading(state, action) {
    state.isValidatorsLoading = action.payload;
  },
  setCampaignHistoryLoading(state, action) {
    state.isCampaignHistoryLoading = action.payload;
  },
  setCampaignsListLoading(state, action) {
    state.isCampaignsListLoading = action.payload;
  },
  setTimeFormatSuccessLoading(state, action) {
    state.isTimeFormatSuccessLoading = action.payload;
  },
  setDeleteCampaignLoading(state, action) {
    state.isDeleteCampaignLoading = action.payload;
  },
  setUpdateCampaignStatusLoading(state, action) {
    state.isUpdateCampaignStatusLoading = action.payload;
  },
  setCreateCampaignLoading(state, action) {
    state.isCreateCampaignLoading = action.payload;
  },
  setUpdateCampaignLoading(state, action) {
    state.isUpdateCampaignLoading = action.payload;
  },
  setUpdateCampaignDetailsLoading(state, action) {
    state.isUpdateCampaignDetailsLoading = action.payload;
  },
  setAffiliateChannelsByCampaignIdLoading(state, action) {
    state.isAffiliateChannelsByCampaignIdLoading = action.payload;
  },
  setSystemFieldsByCampaignIdLoading(state, action) {
    state.isSystemFieldsByCampaignIdLoading = action.payload;
  },
  setCampaignFieldsLoading(state, action) {
    state.isCampaignFieldsLoading = action.payload;
  },
  setFieldByDataTypeLoading(state, action) {
    state.isFieldByDataTypeLoading = action.payload;
  },
  setCurrentCampaignByIdLoading(state, action) {
    state.isCurrentCampaignByIdLoading = action.payload;
  },

  // GET Validators
  setValidatorsSuccess(state, action) {
    state.validators = action.payload;
  },

  // GET Fields
  setFieldsByDataTypeSuccess(state, action) {
    state.fieldByDataType = action.payload;
  },

  // GET Fields
  setCampaignFields(state, action) {
    state.campaignFields = action.payload;
  },

  // GET CurrentCampaignById
  setCurrentCampaignByIdSuccess(state, action) {
    state.currentCampaignById = action.payload;
  },

  // GET CurrentCampaignById
  setCurrentCampaignByIdCampaignFieldsSuccess(state, action) {
    state.currentCampaignByIdCampaignFields = action.payload;
  },

  // GET Campaigns
  setCampaignsList(state, action) {
    state.campaigns = action.payload;
  },
  setSimplifiedCampaigns(state, action) {
    state.simplifiedCampaigns = action.payload;
  },
  setCampaignsListCount(state, action) {
    state.campaignsCount = action.payload;
  },

  createCampaignSuccess(state, action) {
    state.createCampaignsWithStepper = action.payload;
  },

  setIsCampaignCreated(state, action) {
    state.isCampaignCreated = action.payload;
  },

  setFieldByDataTypeString(state, action) {
    state.fieldByDataTypeString = action.payload;
  },

  setAffiliateChannelsByCampaignId(state, action) {
    state.affiliateChannels = action.payload;
  },

  setCampaignFilters(state, action) {
    state.campaignFilters = action.payload;
  },
});

export default reducers;
