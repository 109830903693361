import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { newDomainNameSchema } from 'validation/userManagement/newDomainName';

import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { domainWithEmplyValidation } from '../../../types/regExp';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

export interface IAddNewDomainForm {
  newDomain: string;
}

const DomainRestrictionsModal = () => {
  const [restrictAllowed, setRestrictAllowed] = useState('');
  const [domains, setDomains] = useState<string[]>([]);

  const restrictDomainsLoading = useAppSelector(userManagementSelector.restrictDomainsLoading);
  const domainsData = useAppSelector(userManagementSelector.domainsData);

  const methods = useForm<IAddNewDomainForm>({
    mode: 'onSubmit',
    defaultValues: {
      newDomain: '',
    },
    resolver: yupResolver(newDomainNameSchema),
  });

  const { handleSubmit, control, setValue, setError } = methods;

  useEffect(() => {
    dispatch(userManagementMiddleware.getRestrictDomainsData());

    return () => {
      dispatch(userManagementMiddleware.resetRestrictedDomains());
    };
  }, []);

  useEffect(() => {
    if (domainsData) {
      setRestrictAllowed(domainsData.restrictionEnabled ? 'enabled' : 'disabled');
      setDomains(domainsData.domains);
    }
  }, [domainsData]);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DomainRestrictionsModal));
  };

  const onDomainAdd = (data: IAddNewDomainForm) => {
    if (domains.find((el) => el === data.newDomain.trim())) {
      setError('newDomain', { message: 'This domain is already on the list' });

      return;
    }

    if (domainWithEmplyValidation.test(data.newDomain.trim()) && data.newDomain.trim() !== '') {
      setDomains([...domains, data.newDomain.trim()]);
      setValue('newDomain', '');
    }
  };

  const onRemoveDomain = (domain: string) => {
    setDomains(domains.filter((el) => el !== domain));
  };

  const onSave = () => {
    dispatch(userManagementMiddleware.restrictDomains({ restrictionEnabled: restrictAllowed === 'enabled', domains }));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px', position: 'relative' }}>
        <Title
          title="Domain Restrictions"
          sx={{
            margin: 0,
            padding: '10px',
            display: 'block',
            textAlign: 'center',
            width: '100%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            right: '10px',
          }}
        >
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogContent sx={{ p: 3, color: '#454256' }}>
          <Box>
            <Typography sx={{ fontSize: '15px' }}>Restrict allowed domains</Typography>
            <Typography sx={{ fontSize: '13px', color: '#95A1B1' }}>
              Restrict invitations to email addresses at specific domains
            </Typography>
            {restrictAllowed && (
              <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} defaultValue={restrictAllowed}>
                <FormControlLabel
                  control={
                    <Radio
                      onClick={() => setRestrictAllowed('disabled')}
                      value="disabled"
                      color="secondary"
                      name="cap"
                    />
                  }
                  label="Disabled"
                />
                <FormControlLabel
                  control={
                    <Radio onClick={() => setRestrictAllowed('enabled')} value="enabled" color="secondary" name="cap" />
                  }
                  label="Enabled"
                />
              </RadioGroup>
            )}
            <form onSubmit={handleSubmit(onDomainAdd)}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'startend',
                  justifyContent: 'space-between',
                  gap: '16px',
                  marginY: '24px',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Typography>Add new domain</Typography>
                  <Controller
                    control={control}
                    name="newDomain"
                    render={({ field, formState }) => (
                      <FormControl sx={{ marginBottom: '16px' }} fullWidth>
                        <TextField sx={{ width: '280px' }} {...field} />
                        <FormHelperText error sx={{ m: 0 }}>
                          {formState.errors.newDomain?.message ? formState.errors.newDomain?.message : ' '}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box sx={{ marginTop: '18px' }}>
                  <CustomButton type="submit">Add</CustomButton>
                </Box>
              </Box>
            </form>
            {domains?.length ? (
              <Box>
                <Typography>Approved domains:</Typography>
                <Box>
                  {domains?.map((el) => (
                    <Box
                      key={el}
                      sx={{
                        padding: '10px 17px',
                        borderRadius: '8px',
                        backgroundColor: '#FAFAFA',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '280px',
                        marginTop: '10px',
                      }}
                    >
                      <Typography>{el}</Typography>
                      <Typography sx={{ color: '#7B61FF', cursor: 'pointer' }} onClick={() => onRemoveDomain(el)}>
                        Remove
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box display="flex" justifyContent="space-between" marginTop="32px">
            <CustomButton variant="outlined" onClick={onClose} disabled={restrictDomainsLoading}>
              Cancel
            </CustomButton>
            <CustomButton onClick={onSave} loading={restrictDomainsLoading}>
              Save
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DomainRestrictionsModal;
