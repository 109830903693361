import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { FUND_LIST_KEY, PersistManager } from 'helpers/PersistManager';

import { CookieManager } from '../../helpers/CookieManager';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { LeadsFundingStatuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { fundingMiddleware, fundingSelector } from '../../redux/slices/funding';
import { leadsMiddleware } from '../../redux/slices/leads';
import { tagsMiddleware, tagsSelector } from '../../redux/slices/tags';
import { ApplicationTabs, TableHeader } from '../../types';
import { useGenerateTableData } from '../Hooks';
import { Permissions } from '../Permissions/constants';
import { hasPermission } from '../Permissions/Permission';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';

const FundingListTable = ({
  page,
  setPage,
  selectedTableHeaders,
  dataTest,
}: {
  page: number;
  setPage: (value: number) => void;
  selectedTableHeaders: TableHeader[];
  dataTest?: string;
}) => {
  const fundingList = useAppSelector(fundingSelector.fundingList);
  const fundingLoading = useAppSelector(fundingSelector.fundingLoading);
  const tags = useAppSelector(tagsSelector.tags);
  const fundersList = useAppSelector(fundingSelector.fundersList);
  const user = CookieManager.getUser();

  const navigate = useNavigate();
  const {
    tableData,
    setTableData,
    order,
    orderBy,
    sortTableData,
    setOrder,
    setOrderBy,
    rowsPerPage,
    handleRequestSort,
    setRowsPerPage,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: fundingList?.data,
    paginationCount: fundingList?.itemsCount,
  });

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      fundingMiddleware.fetchGetFundingList({
        searchModel: {
          pageNumber: 1,
          pageSize: newRowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      }),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      fundingMiddleware.fetchGetFundingList({
        searchModel: {
          pageNumber: newPage + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: orderBy === 'borrower' ? 'firstName' : orderBy, value: order === 'asc' },
        },
      }),
    );
    setPage(newPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      fundingMiddleware.fetchGetFundingList({
        searchModel: {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrder: { key: property === 'borrower' ? 'firstName' : property, value: newOrder },
        },
      }),
    );
  };

  const handleChangePageWithProps = (row: Record<string, string>) => {
    navigate(`/applications/${row.leadId}`);
    dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.fundingRequests));
  };

  useEffect(() => {
    dispatch(fundingMiddleware.fetchGetFundingList());

    if ((hasPermission(Permissions.changeFunder) || hasPermission(Permissions.fundSelfAssignUnassign)) && user) {
      dispatch(fundingMiddleware.getFundersList());
    }

    const orderData = PersistManager.getData(FUND_LIST_KEY)?.searchModel;

    setOrder(orderData?.sortOrder?.value ? 'asc' : 'desc');
    setOrderBy(orderData?.sortOrder?.key ?? 'Id');
    setPage(Number(orderData?.pageNumber) - 1 ?? 0);
    setRowsPerPage(orderData?.pageSize ?? DEFAULT_ROWS_PAGE);
    dispatch(tagsMiddleware.fetchTags());

    return () => {
      dispatch(fundingMiddleware.fetchGetFundingList());
    };
  }, []);

  if (fundingLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <DenseTable
        dataTest={dataTest ?? ''}
        data={tableData}
        editButton={false}
        setTableData={setTableData}
        order={order as string}
        orderBy={orderBy}
        isAction={false}
        page={page}
        isDeleted={false}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPageServer}
        handleChangePage={handleChangePageServer}
        handleSort={handleSortServer}
        handleRequestSort={handleRequestSort}
        sortTableData={sortTableData}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        selectedTableHeaders={selectedTableHeaders}
        handleChangePageWithProps={handleChangePageWithProps}
        Statuses={LeadsFundingStatuses}
        statusMode="BADGE"
        paginationCount={paginationCount}
        tags={tags ?? []}
        tagsPage={4}
        tagsKey="leadId"
        handleSortCount={handleSortCount}
        fundersList={fundersList ?? []}
      />
    </Box>
  );
};

export default FundingListTable;
