import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControl, Grid, InputLabel, TextField } from '@mui/material';
import CustomRangePicker from 'components/CustomRangePicker/CustomRangePicker';
import { CustomButton } from 'components/shared';
import moment from 'moment';

import { DateRange, IDateRange } from '@types';

import FormAutocomplete from '../../formComponents/FormAutocomplete';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { fundingMiddleware, fundingSelector } from '../../redux/slices/funding';

export interface IFundingReportFilters {
  dateFrom: IDateRange;
  dateTo: IDateRange;
  applicationId: string;
  affiliates: string[];
  creditUnions: string[];
}

const FundingReport = () => {
  const creditUnions = useAppSelector(affiliatesSelector.creditUnions);
  const affiliateList = useAppSelector(affiliatesSelector.simplifiedAffiliates);
  const isDownloadFundingReportLoading = useAppSelector(fundingSelector.isDownloadFundingReportLoading);
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: moment().subtract(1, 'year').format(),
    endDate: moment().format(),
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      applicationId: '',
      affiliates: [],
      creditUnions: [],
    },
  });

  const { handleSubmit, control, setValue } = methods;

  const onSubmit = (data: any) => {
    setValue('applicationId', (data.applicationId = data.applicationId.trim()));

    dispatch(
      fundingMiddleware.fetchDownloadFundingReport({
        dateFrom: moment(dateTimeState?.startDate).startOf('day').format(),
        dateTo: moment(dateTimeState?.endDate).endOf('day').format(),
        applicationId: data.applicationId,
        affiliates: data.affiliates?.map((el: { id: string }) => el.id) ?? [],
        creditUnions: data.creditUnions?.map((el: { id: string }) => el.id) ?? [],
      }),
    );
  };

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(affiliatesMiddleware.fetchCreditUnions('funding'));
  }, []);

  return (
    <FormProvider {...methods}>
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Grid container gap="30px" alignItems="end">
          <Grid item>
            <CustomRangePicker
              key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
              dateTimeState={dateTimeState}
              setDateTimeState={setDateTimeState}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="applicationId"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel>Application ID</InputLabel>
                  <TextField sx={{ marginTop: '0 !important' }} {...field} placeholder="Search" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete title="Source" options={affiliateList ?? []} name="affiliates" />
          </Grid>
          <Grid item>
            <FormAutocomplete title="Credit Unions" options={creditUnions ?? []} name="creditUnions" />
          </Grid>
          <Grid item>
            <CustomButton type="submit" loading={isDownloadFundingReportLoading}>
              Download
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FundingReport;
