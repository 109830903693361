import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { AffiliateChannelFormValues } from '../../../../pages/Affiliates/AffiliateChannelCreateOrEditPage';
import { affiliatesMiddleware } from '../../../../redux/slices/affiliates';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmAffiliateChannelEditSaveControls = (data: AffiliateChannelFormValues, affiliateChannelId: string) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelEditSaveModal));
  }, []);

  const onConfirm = useCallback(async () => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateChannel(affiliateChannelId, {
        campaignId: data.campaignId,
        name: data.channelName,
        affiliateId: data.affiliateId,
        dataFormat: data.dataFormat,
        status: data.status,
        affiliateChannelKey: '',
        affiliateChannelPassword: '',
        affiliateChannelActions: [],
        notes: data.notes,
      }),
    );
    setTimeout(() => {
      dispatch(affiliatesMiddleware.fetchIsAffiliateSuccessfully(false));
    }, 2000);
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelEditSaveModal));
  }, [data, affiliateChannelId]);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmAffiliateChannelEditSaveControls;
