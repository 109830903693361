import React, { useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { DateRangePicker } from 'materialui-daterange-picker';

import { DateRange } from '@types';

import useCustomRangePicker from '../Hooks/useCustomRangePicker';

import classes from './CustomRangePicker.module.scss';

const CustomRangePicker = ({
  dateTimeState,
  setDateTimeState,
  dataTest,
  sx,
  label = 'Date Range',
}: {
  dateTimeState?: DateRange | undefined | null;
  setDateTimeState?: React.Dispatch<DateRange | undefined | null>;
  dataTest?: string;
  sx?: React.CSSProperties;
  label?: string;
}) => {
  const { state, setState, open, setOpen } = useCustomRangePicker(dateTimeState?.startDate, dateTimeState?.endDate);

  const changeTime = (range: DateRange) => {
    if (range.startDate && range.endDate) {
      const startOfDay = new Date(range.startDate);

      startOfDay.setHours(0, 0, 0, 0);

      const endOfDay = new Date(range.endDate);

      endOfDay.setHours(23, 59, 59, 999);

      return { startDate: startOfDay, endDate: endOfDay };
    }

    return { startDate: range.startDate, endDate: range.endDate };
  };

  useEffect(() => {
    if (setDateTimeState) {
      setDateTimeState(state);
    }
  }, [state]);

  return (
    <Box className={classes.root}>
      <TextField
        label={label}
        data-test={dataTest ?? ''}
        InputLabelProps={{ shrink: true }}
        onFocus={() => setOpen(true)}
        placeholder="Select Date"
        value={`${state?.startDate ? `${new Date(state?.startDate)?.toLocaleDateString()}-` : ''}${
          state?.endDate ? new Date(state?.endDate)?.toLocaleDateString() : ''
        }`}
        sx={{ borderRight: 0, ...sx }}
      />
      <Box className={classes.rangePicker}>
        <DateRangePicker
          open={open}
          wrapperClassName={classes.rangePickerWrapper}
          toggle={() => setOpen(!open)}
          onChange={(range) => setState(() => changeTime(range) as DateRange)}
        />
      </Box>
    </Box>
  );
};

export default CustomRangePicker;
