import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, Typography } from '@mui/material';

interface IUploadFileButtonProps {
  onClick: () => void;
}

const UploadFileButton = ({ onClick }: IUploadFileButtonProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'end',
      gap: '10px',
      px: '16px',
      py: '10px',
      borderRadius: '12px',
      cursor: 'pointer',
      color: '#7B61FF',
      transition: '0.4s',
      '&:hover': {
        backgroundColor: '#7B61FF',
        color: 'white',
      },
    }}
    onClick={onClick}
  >
    <IosShareIcon />
    <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>Upload Files</Typography>
  </Box>
);

export default UploadFileButton;
