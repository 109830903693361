import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { CustomButton } from 'components/shared';
import CustomTextArea from 'components/shared/CustomInputs/CustomTextArea';
import Title from 'components/UI/Title';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';

export interface IRevertApplicationModalProps {
  id: string;
  applicationId: string;
}

const RevertApplicationModal = ({ id, applicationId }: IRevertApplicationModalProps) => {
  const [reason, setReason] = useState('');
  const revertLoading = useAppSelector(leadsSelector.revertLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.RevertApplicationModal));
  };

  const onSubmit = () => {
    dispatch(leadsMiddleware.revertApplication(id, applicationId, reason));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Revert To Manual Review"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box textAlign="center" marginY="30px">
            <Typography fontSize="16px">Ready to send the application back for manual review?</Typography>
            <Typography fontSize="16px">Please share a detailed reason for the reversion</Typography>
          </Box>
          <CustomTextArea onChange={(e) => setReason(e.target.value)} label="Revert Reason *" placeholder="Type..." />
          <Box display="flex" justifyContent="space-around" marginTop="20px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" disabled={!reason.length} onClick={onSubmit} loading={revertLoading}>
              Revert
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default RevertApplicationModal;
