import { Box, Typography } from '@mui/material';
import ClientNameCard from 'components/shared/DropdownButton/NameCard';
import moment from 'moment';

import { INote } from '@types';

import { ReactComponent as NoteReasonIcon } from '../../assets/images/NoteReasonIcon.svg';

export interface INoteCancelProps {
  note: INote;
}

const NoteCancel = ({ note }: INoteCancelProps) => (
  <Box
    sx={{
      padding: '8px',
      borderRadius: '8px',
      border: '1px solid #E2E8F0',
      color: '#454256',
      marginTop: '16px',
    }}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <ClientNameCard firstName={note.userName.split(' ')[0][0]} lastName={note.userName.split(' ')[1][0] ?? ' '} />
        <Box component="span">
          <Typography component="span" fontWeight="700">
            {note.userName}
          </Typography>
          <Typography component="span" sx={{ color: '#687585' }}>
            {' '}
            on <NoteReasonIcon />{' '}
            <Typography component="span" sx={{ color: '#FF497F' }}>
              Application canceled
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gap="8px" alignItems="center">
        <Typography>{moment(note.updatedAt ?? note.createdAt).format('MMM Do YYYY')}</Typography>
        <Typography color="#95A1B1">{moment(note.updatedAt ?? note.createdAt).fromNow()}</Typography>
      </Box>
    </Box>
    <Box>
      <Box
        sx={{
          padding: '8px 10px',
          backgroundColor: '#F4E6F1',
          borderRadius: '8px',
          marginTop: '4px',
          marginLeft: '40px',
        }}
      >
        {note.note}
      </Box>
    </Box>
  </Box>
);

export default NoteCancel;
