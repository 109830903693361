import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Loading from 'components/UI/Loading';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { IInputTag, ITenantSettings } from '@types';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../redux/slices/settings';
import { emailValidation } from '../../types/regExp';

/**
  We don't use form here, because in InputWithTags component we always have form, where we trigger Enter button
 */

const Tags = ({ data, handleDelete }: { data: string; handleDelete: (value: string) => void }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#F4F7FE',
      color: '#454256',
      margin: '5px 20px',
    }}
  >
    <Typography>{data}</Typography>
    <CloseIcon
      fontSize="small"
      sx={{ cursor: 'pointer', color: '#95A1B1' }}
      onClick={() => {
        handleDelete(data);
      }}
    />
  </Box>
);

const TenantSettings = ({ settings }: { settings: ITenantSettings }) => {
  const { dailyCapReached, dailyCapReachedDate } = settings;
  const errorMessage = `Daily CAP has been reached at ${dailyCapReachedDate}`;
  const tagRef: MutableRefObject<HTMLInputElement | undefined> = useRef();

  const tenantSettingsLoading = useAppSelector(settingsSelector.tenantSettingsLoading);
  const [tags, setTags] = useState<IInputTag[]>(settings.tenantEmailAddress);
  const [cap, setCap] = useState(settings.dailyCapEnabled ? 'enabled' : 'disabled');
  const [amount, setAmount] = useState<number | null>(settings.dailyCap);
  const [isError, setIsError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);

  const onSave = () => {
    const sendingTags = [...tags];

    if (amount && amount >= 1000) {
      if (tagRef.current && tagRef.current.value) {
        const isValidEmail = emailValidation.test(tagRef.current.value);

        if (isValidEmail) {
          sendingTags.push({ email: tagRef.current.value });
          setIsEmailError(false);

          dispatch(
            settingsMiddleware.updateTenantSettings({
              dailyCap: amount,
              dailyCapEnabled: cap === 'enabled',
              dailyCapReached,
              dailyCapReachedDate,
              tenantEmailAddress: sendingTags,
            }),
          );
        } else {
          setIsEmailError(true);
        }
      } else {
        dispatch(
          settingsMiddleware.updateTenantSettings({
            dailyCap: amount,
            dailyCapEnabled: cap === 'enabled',
            dailyCapReached,
            dailyCapReachedDate,
            tenantEmailAddress: sendingTags,
          }),
        );
      }

      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    setTags(settings.tenantEmailAddress);
  }, [settings.tenantEmailAddress]);

  const handleDisable = () => {
    setCap('disabled');

    if (!amount || amount < 1000) {
      setAmount(settings.dailyCap);
      setIsError(false);
    }
  };

  const openConfirm = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ResetDailyCapModal,
        props: {},
      }),
    );
  };

  const handleDelete = (value: string) => {
    const newTags = tags.filter((val) => val.email !== value);

    setTags(newTags);
  };
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tagRef.current && tagRef.current.value) {
      const isValidEmail = emailValidation.test(tagRef.current.value);

      if (isValidEmail) {
        setTags([...tags, { email: tagRef.current.value }]);
        tagRef.current.value = '';
        setIsEmailError(false);
      } else {
        setIsEmailError(true);
      }
    }
  };

  if (tenantSettingsLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        p: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '15px',
        boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
      }}
    >
      <Typography fontSize="20px" fontWeight={700}>
        Tenant Settings
      </Typography>
      <Box marginTop="20px">
        <Typography>Daily CAP</Typography>
        <Box>
          <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }} defaultValue={cap}>
            <FormControlLabel
              control={<Radio onClick={handleDisable} value="disabled" color="secondary" name="cap" />}
              label="Disabled"
            />
            <FormControlLabel
              control={<Radio onClick={() => setCap('enabled')} value="enabled" color="secondary" name="cap" />}
              label="Enabled"
            />
          </RadioGroup>
          {dailyCapReached && dailyCapReachedDate && cap === 'enabled' && (
            <>
              <Alert severity="error">
                <AlertTitle
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    color: '#FF497F',
                  }}
                >
                  {errorMessage}
                </AlertTitle>
              </Alert>
              <Box textAlign="right">
                <Button variant="text" color="secondary" onClick={openConfirm}>
                  Reset
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box>
        <FormControl required fullWidth>
          <InputLabel>CAP amount</InputLabel>
          <OutlinedInput
            disabled={cap === 'disabled'}
            value={amount}
            startAdornment="$"
            type="number"
            onChange={(e) => setAmount(e.target.value ? Number(e.target.value) : null)}
          />
          {isError && <FormHelperText error>A minimum CAP amount of $1,000 is required.</FormHelperText>}
        </FormControl>
      </Box>
      <Box marginTop="20px">
        <InputLabel>External Recipients</InputLabel>
        <Box overflow="hidden">
          <form onSubmit={onSubmit}>
            <TextField
              disabled={cap === 'disabled'}
              inputRef={tagRef}
              fullWidth
              placeholder="Type email"
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              }}
              InputProps={{
                startAdornment: (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {tags.map((data) => (
                      <Tags data={data.email} handleDelete={handleDelete} key={data.email} />
                    ))}
                  </Box>
                ),
              }}
            />
            {isEmailError && <FormHelperText error>Must be valid email</FormHelperText>}
          </form>
        </Box>
      </Box>
      <Box>
        <Button variant="outlined" color="secondary" onClick={onSave}>
          SAVE
        </Button>
      </Box>
    </Box>
  );
};

export default TenantSettings;
