import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { IValidationTreeFormValues } from '../../../components/dataModels/validationTree';
import { useValidationProviderState } from '../../../Providers/ValidationTreeProvider';

import classes from './validationTree.module.scss';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 314,
      left: 79,
    },
  },
};

const FilterSubItem = ({
  dataTest,
  register,
  rootName,
  control,
  index,
  rootId,
  serviceName,
  campaignFields,
  conditions,
  watch,
  mockFieldData,
}: // TODO integrate interface for VT sub values
any) => {
  const { setRemove, setAppend, setUpdate } = useValidationProviderState();

  const { fields, remove, append, update } = useFieldArray<IValidationTreeFormValues>({
    control,
    name: `${rootName}.${index}.filters`,
  });

  useEffect(() => {
    register(`${rootName}.${index}.filters`);
  }, [register, index]);

  useEffect(() => {
    if (append) {
      // FIXME type
      // @ts-ignore
      setAppend(append, rootId);
    }
  }, [append, rootId]);

  useEffect(() => {
    if (update) {
      // FIXME type
      // @ts-ignore
      setUpdate(update, rootId);
    }
  }, [update, rootId]);

  useEffect(() => {
    setRemove(remove, rootId);
  }, [remove, rootId]);

  return (
    <Box className={fields.length ? classes.filtersRoot : ''} key={rootId}>
      {fields.length ? (
        <Box className={classes.subHeader} sx={{ gridTemplateColumns: '2fr 1.5fr 1.5fr 1.5fr' }}>
          {serviceName !== 'Expression' ? (
            <Typography className={classes.headerTitle}>Product Fields</Typography>
          ) : null}
          {serviceName !== 'Expression' ? <Typography className={classes.headerTitle}>Condition</Typography> : null}
          <Typography className={classes.headerTitle}>Value</Typography>
        </Box>
      ) : null}
      {fields?.map((item, filterIndex) => (
        <Box
          key={item.id}
          className={classes.filters}
          sx={
            serviceName !== 'Expression'
              ? {
                  gridTemplateColumns: '2.4fr 1.4fr 1.5fr 1.2fr',
                }
              : {
                  gridTemplateColumns: '5fr 1.5fr',
                }
          }
        >
          {filterIndex === 0 ? (
            <Typography className={classes.filtersTitle}>All of the following are true…</Typography>
          ) : (
            ''
          )}
          <FormControl
            className={classes.item}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: ' #1E1E2D',
                fontSize: '15px',
                marginRight: '10px',
              }}
            >
              And
            </Typography>
            <Box width="100%">
              <Select
                data-test={dataTest ? `${dataTest}-select-${serviceName}` : ''}
                placeholder="Field"
                sx={{ whiteSpace: 'nowrap' }}
                fullWidth
                {...register(`${rootName}.${index}.filters.${filterIndex}.Field`)}
                defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.Field`)}
                MenuProps={MenuProps}
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              >
                {(campaignFields.length ? campaignFields : mockFieldData)?.map(
                  (filterItem: { id: string; name: string }) => {
                    if (filterItem.id === '0') {
                      return (
                        <MenuItem disabled key={filterItem.id}>
                          {filterItem.name}
                        </MenuItem>
                      );
                    }

                    return <MenuItem value={filterItem.id}>{filterItem.name}</MenuItem>;
                  },
                )}
              </Select>
            </Box>
          </FormControl>
          {serviceName !== 'Expression' && (
            <FormControl className={classes.item}>
              <Select
                data-test={dataTest ? `${dataTest}-select-conditions` : ''}
                placeholder="Conditions"
                sx={{ whiteSpace: 'nowrap' }}
                defaultValue={watch(`${rootName}.${index}.filters.${filterIndex}.Condition`)}
                fullWidth
                {...register(`${rootName}.${index}.filters.${filterIndex}.Condition`)}
                MenuProps={MenuProps}
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              >
                {conditions?.map((condition: { id: string; name: string }) => (
                  <MenuItem value={condition.id}>{condition.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {serviceName !== 'Expression' && (
            <FormControl fullWidth className={classes.subItem}>
              <TextField
                data-test={dataTest ? `${dataTest}-${serviceName}-value` : ''}
                id={`${rootName}.${index}.filters.${filterIndex}.Value`}
                placeholder="Enter a value"
                sx={{ borderRight: '1px solid #E2E8F0' }}
                {...register(`${rootName}.${index}.filters.${filterIndex}.Value`)}
                fullWidth
                disabled={!hasPermission(Permissions.changeDecisionTreeFilter)}
              />
            </FormControl>
          )}
          <Permission permission={Permissions.changeDecisionTreeFilter}>
            <FormControl
              className={classes.subItem}
              sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 0.5fr 0.5fr',
                flexWrap: 'nowrap',
                flexDirection: 'row',
              }}
              fullWidth
            >
              <Box
                sx={{
                  width: '100%',
                  borderRadius: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRight: '1px solid #E2E8F0',
                }}
              >
                <IconButton
                  data-test={dataTest ? `${dataTest}-button-remove` : ''}
                  onClick={() => {
                    remove(filterIndex);
                  }}
                >
                  <DeleteOutlineOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                </IconButton>
              </Box>
            </FormControl>
          </Permission>
        </Box>
      ))}
    </Box>
  );
};

export default FilterSubItem;
