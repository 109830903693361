import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Menu, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import DropdownItem from './DropdownItem';
import { IOption } from './index';

interface IDropdownWithSearchProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  filteredData: IOption[];
  selectedOption: IOption | undefined;
  onMenuItemClick: (option: IOption) => void;
}

const DropdownSearch = styled(TextField)(() => ({
  '.MuiInput-input': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18.15px',
    marginTop: 0,
  },
  '& .MuiInput-input::placeholder': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18.15px',
    color: 'rgba(163, 174, 208, 1)',
  },
}));

const DropdownSearchIcon = styled(SearchIcon)(() => ({
  color: 'rgba(163, 174, 208, 1)',
  marginLeft: '18px',
  width: '19.6px',
  height: '18px',
}));

const DropdownWithSearch = ({
  anchorEl,
  open,
  handleClose,
  handleSearch,
  filteredData,
  selectedOption,
  onMenuItemClick,
}: IDropdownWithSearchProps) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    sx={{ width: '250px', '& .css-6hp17o-MuiList-root-MuiMenu-list': { padding: 0 } }}
  >
    <DropdownSearch
      variant="standard"
      autoFocus
      onChange={handleSearch}
      placeholder="Search..."
      InputProps={{ startAdornment: <DropdownSearchIcon /> }}
    />
    <Box sx={{ maxHeight: '200px', overflow: 'auto', width: '100%' }}>
      {filteredData.map((option: IOption) => (
        <DropdownItem
          key={option.id}
          selectedOption={selectedOption}
          option={option}
          onMenuItemClick={onMenuItemClick}
        />
      ))}
    </Box>
  </Menu>
);

export default DropdownWithSearch;
