// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';

import { ICampaignField } from '../types/campaigns';

export const removeIdFromObject = (obj: ICampaignField) => {
  const { id, ...returnObj } = { ...obj };

  return returnObj;
};

export const CampaignFieldsUpdate = (oldFields: ICampaignField[], newFields: ICampaignField[]) => {
  let returningArray: any = [];
  let equalsCount = 0;

  newFields.forEach((item: any) => {
    const copyField = oldFields.find((el) => el.templateField === item.templateField);

    if (copyField) {
      equalsCount += 1;

      const oldField = removeIdFromObject(copyField);
      const newField: ICampaignField = {
        ...removeIdFromObject(item),
      };

      if (!_.isEqual(oldField, newField)) {
        newField.id = copyField.id;
        returningArray.push(newField);
      } else {
        item.isEqual = true;
        returningArray.push(item);
      }
    } else {
      returningArray.push(item);
    }
  });

  if (equalsCount === 0) {
    returningArray = [...newFields, ...oldFields.map((el) => ({ ...el, isDeleted: true }))];
  } else {
    oldFields.forEach((item: any) => {
      const existingField = returningArray.find(
        (el: any) => el.templateField === item.templateField || el.id === item.id,
      );

      if (!existingField) {
        const objectWithIsDeletedField = {
          ...item,
          isDeleted: true,
        };

        returningArray.push(objectWithIsDeletedField);
      }
    });
  }

  return returningArray.filter((el: any) => !el.isEqual);
};
