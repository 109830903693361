import { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Avatar,
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { menuMiddleware, menuSelector } from 'redux/slices/menu';
import { notificationSelector } from 'redux/slices/notification';
import { LinkTarget, NavItemType } from 'types';

import { dispatch, useAppSelector } from '../../../../../../redux/hooks';
import CountBadge from '../CountBadge';

interface NavItemProps {
  item: NavItemType;
  level: number;
  selectedTab: string;
  selectedTabNested: string;
}

interface IItemProps {
  component: string | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>;
  href?: string;
  target?: LinkTarget;
}

const itemProps = (item: NavItemType, itemTarget: '_blank' | '_self'): IItemProps => {
  let itemComponent: IItemProps = {
    component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${item.url!}`} target={itemTarget} />),
  };

  if (item?.external) {
    itemComponent = { component: 'a', href: item.url, target: itemTarget };
  }

  return itemComponent;
};

const NavItem = ({ item, level, selectedTab, selectedTabNested }: NavItemProps) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const openItem = useAppSelector(menuSelector.openItem);
  const manualReviewsCount = useAppSelector(notificationSelector.manualReviewCount);
  const fundingCount = useAppSelector(notificationSelector.fundingCount);
  const Icon = item?.icon!;
  let count = null;

  if (item.id === 'funding-list') {
    count = fundingCount;
  } else if (item.id === 'manual-reviews') {
    count = manualReviewsCount;
  }

  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: openItem.findIndex((id: string | undefined) => id === item?.id) > -1 ? 8 : 6,
        height: openItem.findIndex((id: string | undefined) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget: LinkTarget = '_self';

  if (item.target) {
    itemTarget = '_blank';
  }

  const listItemProps = itemProps(item, itemTarget);

  const itemHandler = (id: string) => {
    dispatch(menuMiddleware.setActiveItem([id]));

    if (matchesSM) {
      dispatch(menuMiddleware.setOpenDrawer(false));
    }
  };

  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);

    if (currentIndex > -1) {
      dispatch(menuMiddleware.setActiveItem([item.id!]));
    }
  }, [item.id]);

  return (
    <ListItemButton
      data-test={`menu-link-${item.id}`}
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: '5px',
        mb: 0.5,
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        '&.Mui-selected .css-12d2mry': {
          color: '#5e35b1',
          fontWeight: 700,
        },
        '&.css-2t8riz-MuiGrid-root .MuiTypography-root': {
          color: item.breadcrumbs ? '#555' : '#5E35B1',
        },
      }}
      selected={item.breadcrumbs ? selectedTabNested === item.id : selectedTab === item.id}
      onClick={() => itemHandler(item.id!)}
    >
      <Box className="sidemenu-item" width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <ListItemIcon sx={{ minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant={openItem?.findIndex((id: string | undefined) => id === item.id) > -1 ? 'h5' : 'body1'}
              color="inherit"
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {item.caption}
              </Typography>
            )
          }
        />
        <CountBadge count={count} />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </Box>
    </ListItemButton>
  );
};

export default NavItem;
