import { TableHeader } from '../../types';

export const LeadsTableHeaders: TableHeader[] = [
  { value: 'applicationId', title: 'Application ID', disabled: true, fixedColumn: true },
  { value: 'borrower', title: 'Borrower' },
  { value: 'affiliateChannelName', title: 'Source Channel' },
  { value: 'affiliateName', title: 'Source' },
  { value: 'campaignName', title: 'Product' },
  { value: 'merchantName', title: 'Merchant Name' },
  { value: 'createdUtc', title: 'Application Date' },
  { value: 'status', title: 'Response Status' },
  { value: 'email', title: 'Email' },
  { value: 'tags', title: 'Tags' },
  { value: 'loanAmount', title: 'Requested Amount', align: 'right' },
  { value: 'approvedLoanAmount', title: 'Approved Amount', align: 'right' },
  { value: 'lastUpdatedDate', title: 'Last Updated Date', sortDisabled: true },
];

export const CampaignDetailsTableHeaders: TableHeader[] = [
  { value: 'id', title: 'ID' },
  { value: 'name', title: 'Name' },
  { value: 'affiliateName', title: 'Source Name' },
  { value: 'status', title: 'Status' },
];

export const CampaignsTableHeaders: TableHeader[] = [
  { value: 'id', title: 'ID', disabled: true, sortDisabled: true },
  { value: 'name', title: 'Name', disabled: true },
  { value: 'statusName', title: 'Status Name' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'createdDate', title: 'Created Date' },
  { value: 'updatedDate', title: 'Updated Date' },
  { value: 'status', title: 'Status' },
];

export const CampaignsHistoryTableHeaders: TableHeader[] = [
  { value: 'userName', title: 'User', sortDisabled: true },
  { value: 'timeStamp', title: 'Modified Date', sortDisabled: true },
  { value: 'actionType', title: 'Action', sortDisabled: true },
];

export const AffiliateChannelHistoryTableHeaders: TableHeader[] = [
  { value: 'userName', title: 'User', sortDisabled: true },
  { value: 'timeStamp', title: 'Modified Date', sortDisabled: true },
  { value: 'actionType', title: 'Action', sortDisabled: true },
];

// TODO: Need to recheck this
export const AffiliateDetailsTableHeaders: TableHeader[] = [
  { value: 'id', title: 'ID' },
  { value: 'name', title: 'Name' },
  { value: 'affiliateName', title: 'Source Name' },
  { value: 'campaignName', title: 'Product Name' },
  { value: 'createdDate', title: 'Created Date' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'updatedDate', title: 'Updated Date' },
  { value: 'status', title: 'Status' },
];

export const AffiliateChannelsTableHeaders: TableHeader[] = [
  { value: 'id', title: 'ID', disabled: true, sortDisabled: true },
  { value: 'name', title: 'Name', disabled: true },
  { value: 'affiliateName', title: 'Source Name' },
  { value: 'campaignName', title: 'Product Name' },
  { value: 'createdDate', title: 'Created Date' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'updatedDate', title: 'Updated Date' },
  { value: 'status', title: 'Status' },
];

export const AffiliatesTableHeaders: TableHeader[] = [
  { value: 'id', title: 'ID', disabled: true, sortDisabled: true },
  { value: 'name', title: 'Source Name', disabled: true },
  { value: 'createdDate', title: 'Creation Date' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'updatedDate', title: 'Updated Date' },
  { value: 'allChannels', title: 'Channel', align: 'center' },
  { value: 'status', title: 'Status' },
];

export const ExternalServiceDashboardTableHeaders: TableHeader[] = [
  { value: 'serviceName', title: 'Service Name' },
  { value: 'requestCount', title: 'Request Count' },
  { value: 'cost', title: 'Cost' },
  { value: 'latency', title: 'Latency(ms)' },
];

export const ExternalServiceTableHeaders: TableHeader[] = [
  { value: 'name', title: 'Name' },
  { value: 'status', title: 'Status' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'createdDate', title: 'Created Date' },
];

export const ReportsTableHeaders: TableHeader[] = [
  { value: 'campaign', title: 'Product' },
  { value: 'affiliate', title: 'Source' },
  { value: 'affiliateChannel', title: 'Source Channel' },
  { value: 'receivedLeads', title: 'Received Applications', align: 'center' },
  { value: 'acceptedLeads', title: 'Accepted Applications', align: 'center' },
  { value: 'acceptedLeadsRate', title: 'Accepted Applications Rate', align: 'center' },
  { value: 'rejectedLeads', title: 'Rejected Applications', align: 'center' },
  { value: 'rejectedLeadsRate', title: 'Rejected Applications Rate', align: 'center' },
  { value: 'fundedAmount', title: 'Funded Amount', align: 'right' },
];

export const ErrorApplicantsReportTableHeaders: TableHeader[] = [
  { value: 'creationDate', title: 'Creation Date' },
  { value: 'country', title: 'Country' },
  { value: 'source', title: 'Source' },
  { value: 'sourceChannel', title: 'Source Channel' },
  { value: 'rejectedApplicants', title: 'Rejected Applications' },
  { value: 'reason', title: 'Reason' },
];

export const OfferReportTableHeaders: TableHeader[] = [
  { value: 'offer', title: 'Offer', sortDisabled: true },
  { value: 'rate', title: 'Rate', sortDisabled: true },
  { value: 'APR', title: 'APR', sortDisabled: true },
  { value: 'sent', title: 'Sent', sortDisabled: true },
  { value: 'accepted', title: 'Accepted', sortDisabled: true },
  { value: 'declined', title: 'Declined', sortDisabled: true },
  { value: 'sourceChannel', title: 'Source Channel', sortDisabled: true },
];

export const MerchantActivityReportTableHeaders: TableHeader[] = [
  { value: 'date', title: 'Date', sortDisabled: true },
  { value: 'merchant', title: 'Merchant', sortDisabled: true },
  { value: 'source', title: 'Source', sortDisabled: true },
  { value: 'delinquencyRate', title: 'Delinquency Rate', sortDisabled: true },
  { value: 'receivedApplicants', title: 'Received Applications', sortDisabled: true },
  { value: 'sold', title: 'Sold', sortDisabled: true },
  { value: 'accepted', title: 'Accepted', sortDisabled: true },
  { value: 'rejected', title: 'Rejected', sortDisabled: true },
  { value: 'fraud', title: 'Fraud', sortDisabled: true },
];

export const ServiceCategoryReportTableHeaders: TableHeader[] = [
  { value: 'creationDate', title: 'Creation Date' },
  { value: 'serviceCategory', title: 'Service Category' },
  { value: 'source', title: 'Source' },
  { value: 'receivedApplicants', title: 'Received Applications' },
  { value: 'sold', title: 'Sold' },
  { value: 'accepted', title: 'Accepted' },
  { value: 'rejected', title: 'Rejected' },
  { value: 'fraud', title: 'Fraud' },
];

export const ValidationTreeTableHeaders: TableHeader[] = [
  { value: 'ValidationTreeName', title: 'Decision Rules Name' },
  { value: 'LeadsQty', title: 'Applications Qty' },
  { value: 'PercSplit', title: 'PercSplit' },
  { value: 'CreationDate', title: 'CreationDate' },
  { value: 'CreatedBy', title: 'CreatedBy' },
  { value: 'UpdatedDate', title: 'UpdatedDate' },
  { value: 'CampaignIntegration', title: 'Product Integration' },
];

export const DecisionTreeTableHeaders: TableHeader[] = [
  { value: 'name', title: 'Decision Tree Name' },
  { value: 'createdDate', title: 'Creation Date' },
  { value: 'createdByName', title: 'Created By', sortDisabled: true },
  { value: 'updatedDate', title: 'Updated Date' },
];

export const ListIntegrationDetailsTableHeaders: TableHeader[] = [{ value: 'value', title: 'Value' }];

export const ListIntegrationTableHeaders: TableHeader[] = [{ value: 'name', title: 'Name' }];

export const RequestFieldsTableHeader: TableHeader[] = [
  { value: 'campaignName', title: 'Product Field', sortDisabled: true },
  { value: 'dataType', title: 'Data Type', sortDisabled: true },
  { value: 'description', title: 'Description', sortDisabled: true },
  { value: 'required', title: 'Required Field', sortDisabled: true },
];

export const UnderwritingTableHeaders: TableHeader[] = [
  { value: 'serviceName', title: 'Outbound API Name' },
  { value: 'campaignName', title: 'Product Name' },
  { value: 'createdBy', title: 'Created By' },
  { value: 'createdUtc', title: 'Date' },
  { value: 'status', title: 'Status' },
];

export const OutboundLogsTableHeaders: TableHeader[] = [
  { value: 'productName', title: 'Product Name' },
  { value: 'sentTo', title: 'Sent to' },
  { value: 'sentBy', title: 'Sent by' },
  { value: 'date', title: 'Date' },
];

export const ErrorReportsTableHeaders: TableHeader[] = [
  { value: 'CreationDate', title: 'CreationDate' },
  { value: 'Country', title: 'Country' },
  { value: 'Affiliate', title: 'Source' },
  { value: 'AffiliateChannel', title: 'Source Channel' },
  { value: 'RejectedLeads', title: 'Rejected Applications' },
  { value: 'Reason', title: 'Reason' },
];

export const ManualReviewListTableHeaders: TableHeader[] = [
  { value: 'applicationId', title: 'Application ID', disabled: true, fixedColumn: true },
  { value: 'sourceChannelName', title: 'Source Channel' },
  { value: 'borrower', title: 'Borrower' },
  { value: 'merchantName', title: 'Merchant Name' },
  { value: 'date', title: 'Date' },
  { value: 'status', title: 'Status' },
  { value: 'tags', title: 'Tags' },
  { value: 'requestAmount', title: 'Requested Amount', align: 'right' },
];

export const FundingListHeaders: TableHeader[] = [
  { value: 'applicationId', title: 'Application ID', disabled: true, fixedColumn: true },
  { value: 'sourceChannel', title: 'Source Channel' },
  { value: 'borrower', title: 'Borrower' },
  { value: 'source', title: 'Source' },
  { value: 'merchantName', title: 'Merchant Name' },
  { value: 'date', title: 'Date' },
  { value: 'status', title: 'Status' },
  { value: 'tags', title: 'Tags' },
  { value: 'requestAmount', title: 'Requested Amount', align: 'right' },
  { value: 'reviewedBy', title: 'Funder' },
];

export const RolesListHeaders: TableHeader[] = [
  { value: 'name', title: 'Role', sortDisabled: true },
  { value: 'usersCount', title: 'Users', sortDisabled: true },
  { value: 'latestUpdate', title: 'Latest Update', sortDisabled: true },
  { value: 'updatedBy', title: 'Updated By', sortDisabled: true },
];
