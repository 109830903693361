import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmExternalServiceSuccessControls = () => {
  const navigate = useNavigate();
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmExternalServiceSuccessModal));
    navigate('/integrations/third-party-APIs');
  }, []);

  return {
    onClose,
  };
};

export default useConfirmExternalServiceSuccessControls;
