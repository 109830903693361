import { dispatch } from 'redux/hooks';
import { viewsMiddleware } from 'redux/slices/views';
import { SeveritiesType } from 'types/snackbar'; // @todo need to move here

export type ErrorStatusCode = 400 | 401 | 403 | 404 | 429 | 500 | 501 | 502 | 503 | 504;

export interface IResponseError {
  code: ErrorStatusCode;
  errorType: number;
  description: 'string';
}

export interface IResponseModel {
  isSuccess: boolean;
  error: null | IResponseError;
  data: any;
}

export const responseErrorHandler = (error: any, message?: string) => {
  const errorText = error?.response?.data?.error?.description;
  const errorMessage = typeof errorText === 'string' ? errorText : message ?? 'Something went wrong';

  dispatch(
    viewsMiddleware.setToastNotificationPopUpState({
      open: true,
      props: {
        severityType: SeveritiesType.error,
        description: errorMessage,
      },
    }),
  );
};
