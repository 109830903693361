import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useDeleteOutboundAPIModal from './hooks/useDeleteOutboundAPIModal';

export interface IDeleteOutboundAPIModal {
  service: string;
}

const DeleteOutboundAPIModal = ({ service }: IDeleteOutboundAPIModal) => {
  const { onClose, onConfirm } = useDeleteOutboundAPIModal();

  return (
    <Dialog data-test="delete-outbound-api-modal" open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title={`Are you sure you want to delete ${service} Outbound API integration?`}
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            After confirming this action, the external service will be removed permanently and changes cant be undone.
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton data-test="delete-outbound-api-modal-button-cancel" variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton data-test="delete-outbound-api-modal-button-delete" variant="contained" onClick={onConfirm}>
              Delete
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteOutboundAPIModal;
