import { Box, Typography } from '@mui/material';

const TableNoFilteredData = ({
  noFoundHeader = 'We couldn’t find any results',
  noFoundBody = 'Please consider using different terms or simplify your search.',
}: {
  noFoundHeader?: string;
  noFoundBody?: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%!important',
      marginTop: '40px',
    }}
  >
    <Typography fontWeight={500} fontSize="18px" color="#454256">
      {noFoundHeader}
    </Typography>
    <Typography color="#687585" marginTop="10px" marginBottom="40px">
      {noFoundBody}
    </Typography>
  </Box>
);

export default TableNoFilteredData;
