import * as Yup from 'yup';

export const solarPanelModalValidationSchema = (keys: string[]): Yup.ObjectSchema<Record<string, Yup.StringSchema>> => {
  const dynamicSchema = keys.reduce<Record<string, Yup.StringSchema>>((schema, key) => {
    schema[key] = Yup.string()
      .trim()
      .required('The field cannot be empty.')
      .max(1000, 'Your input is too long. It should not exceed 1000 characters.');

    return schema;
  }, {});

  return Yup.object().shape(dynamicSchema);
};
