import { IDashboardState } from '../../../types';

export const getInitialState = (): IDashboardState => ({
  reportsLeads: {
    dates: [],
    acceptedLeads: [],
    rejectedLeads: [],
    manualReviews: [],
  },
  error: null,
  isReportsLeadsLoading: false,
  reportsNoLeads: {
    data: [],
    itemsCount: 0,
    pagesCount: 0,
  },
  isReportsNoLeadsLoading: false,
  reportsExternalServices: {
    data: [],
    itemsCount: 0,
    pagesCount: 0,
  },
  isReportsExternalServicesLoading: false,
  reportsComparison: null,
  isReportsComparisonLoading: false,
  reportsTopLocations: [],
  isReportsTopLocationsLoading: false,
});
