import React, { useState } from 'react';
import { FormHelperText, TablePagination, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import TableTextField from '../shared/TextField/TableTextField';

import CustomDynamicTablePaginationActions from './CustomDynamicTablePaginationActions';

const useStyles = makeStyles({
  root: {
    '& .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
});

interface ICustomTablePaginationProps {
  count: number;
  dataTest?: string;
  page: number;
  rowsPerPage: number;
  sx: {
    margin: string | number;
  };
  rowsPerPageOptions: (number | { value: number; label: string })[];
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTablePagination: React.FC<ICustomTablePaginationProps> = ({
  count,
  dataTest,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onChangeRowsPerPage,
}) => {
  const [jumpToPage, setJumpToPage] = useState<number | null>(page + 1);
  const [error, setError] = useState<boolean>(false);
  const classes = useStyles();

  const handleJumpToPage = () => {
    const checkDot = /\d*\.\d+/;

    if (jumpToPage === 0 || jumpToPage) {
      let selectedPageNumber = 1;
      const totalPages = Math.ceil(count / (rowsPerPage ?? DEFAULT_ROWS_PAGE));

      if (checkDot.test(`${jumpToPage}`)) {
        selectedPageNumber = Math.trunc(jumpToPage);
      } else if (jumpToPage <= 0) {
        selectedPageNumber = 1;
      } else {
        selectedPageNumber = jumpToPage > totalPages ? totalPages : jumpToPage;
      }

      setJumpToPage(selectedPageNumber);

      setError(false);
      onPageChange(null, selectedPageNumber - 1);
    } else {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100% !important',
        overflow: 'auto',
      }}
    >
      <Typography sx={{ display: 'flex' }}>
        Total&nbsp;
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {count}&nbsp;items
        </Box>
      </Typography>
      <Box sx={{ display: 'flex', alignItems: error ? 'end' : 'center', margin: '2px', textAlign: 'right' }}>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ whiteSpace: 'nowrap', marginLeft: 20 }}>Jump To Page</Typography>
            <TableTextField
              placeholder="Page"
              value={jumpToPage}
              dataTest={dataTest ? `${dataTest}-table-footer-jump-to-page` : ''}
              onChange={(e) => setJumpToPage(e.target.value ? +e.target.value : null)}
              onKeyDown={(e) => {
                if (['e', 'E', '-', '+', '.'].includes(e.key)) {
                  e.preventDefault();
                }

                if (e.key === 'Enter') {
                  handleJumpToPage();
                }
              }}
              type="number"
              min={1}
              inputLabelProps={{
                shrink: true,
              }}
              className="short-input"
            />
          </Box>
          {error ? (
            <FormHelperText sx={{ textAlign: 'right', marginTop: 0 }} error>
              Invalid input: Please enter a valid page number.
            </FormHelperText>
          ) : null}
        </Box>
        <TablePagination
          component="div"
          data-test={dataTest ? `${dataTest}-table-footer-items-per-page` : ''}
          classes={{ root: classes.root }}
          count={count}
          labelRowsPerPage="Items per page"
          page={page}
          // eslint-disable-next-line react/no-unstable-nested-components
          ActionsComponent={(props) => (
            <CustomDynamicTablePaginationActions
              {...props}
              dataTest={dataTest ?? ''}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onPageChange}
            />
          )}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default CustomTablePagination;
