import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IDashboardState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IDashboardState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  getDashboardLeadsSuccess(state, action) {
    state.reportsLeads = action.payload;
  },
  setDashboardReportsLeadsLoadingSuccess(state, action) {
    state.isReportsLeadsLoading = action.payload;
  },
  getDashboardReportsNoLeadsSuccess(state, action) {
    state.reportsNoLeads = action.payload;
  },
  setDashboardReportsNoLeadsLoadingSuccess(state, action) {
    state.isReportsNoLeadsLoading = action.payload;
  },
  getDashboardReportsExternalServicesSuccess(state, action) {
    state.reportsExternalServices = action.payload;
  },
  setDashboardReportsExternalServicesLoadingSuccess(state, action) {
    state.isReportsExternalServicesLoading = action.payload;
  },
  getDashboardReportsComparisonSuccess(state, action) {
    state.reportsComparison = action.payload;
  },
  setDashboardReportsComparisonLoadingSuccess(state, action) {
    state.isReportsComparisonLoading = action.payload;
  },
  getDashboardReportsTopLocationsSuccess(state, action) {
    state.reportsTopLocations = action.payload;
  },
  setDashboardReportsTopLocationsLoadingSuccess(state, action) {
    state.isReportsTopLocationsLoading = action.payload;
  },
});

export default reducers;
