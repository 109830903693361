import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { CookieManager } from 'helpers/CookieManager';
import { ACCESS_TOKEN } from 'lib/constants';
import { userTimeZone } from 'utils/index';

import { handleApiError } from '../helpers';

import configs from './core/configs';

class AxiosInstanceChanger {
  axiosInstance: AxiosInstance | undefined;

  handleErrors: AxiosError | undefined;

  axiosInstanceUploadFile: AxiosInstance | undefined;

  constructor(extraProps: { handleErrors?: AxiosError } = {}) {
    this.handleErrors = extraProps.handleErrors;

    this.setupInstance();
  }

  setupInstance() {
    this.axiosInstance = axios.create({
      baseURL: configs.connection.server_url,
      headers: {
        'User-Time-Zone': userTimeZone,
        'Content-Type': 'application/json',
        authorization: `Bearer ${CookieManager.getCookie(ACCESS_TOKEN)}`,
      },
      withCredentials: false,
    });
    this.axiosInstanceUploadFile = axios.create({
      baseURL: configs.connection.server_url,
      headers: {
        'User-Time-Zone': userTimeZone,
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${CookieManager.getCookie(ACCESS_TOKEN)}`,
      },
      withCredentials: false,
    });
  }

  getJson(url = '', params = {}, headers = {}, resOption?: string) {
    return this.axiosInstance
      ?.get(url, { params, headers })
      .then((response: any) => (resOption ? response?.data[resOption] : response?.data))
      .catch((error) => {
        handleApiError(error);
      });
  }

  getFile(url = '', params = {}, headers = {}, rest = { responseType: 'blob' }) {
    return this.axiosInstance
      ?.get(url, { params, headers, ...rest } as AxiosRequestConfig)
      .then((response: { data: BlobPart }) => response)
      .catch((error) => {
        handleApiError(error);
      });
  }

  getFileWithFilters(url = '', params = {}, rest = { responseType: 'blob' }) {
    return this.axiosInstance
      ?.post(url, params, rest as AxiosRequestConfig)
      .then((response: { data: BlobPart }) => response)
      .catch((error) => {
        handleApiError(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  post<T>(url: string = '', data: T, params = {}) {
    return this.axiosInstance
      ?.post(url, data, params)
      .then((response: any) => response?.data)
      .catch((error) => {
        handleApiError(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  postFile<T>(url: string = '', file: T) {
    return this.axiosInstanceUploadFile
      ?.post(url, file)
      .then((response: any) => response?.data)
      .catch((error) => {
        handleApiError(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last,consistent-return
  deleteFile<T>(url: string = '', file: T) {
    try {
      return this.axiosInstanceUploadFile
        ?.delete(url, { data: file })
        .then((response: any) => response?.data)
        .catch((error) => {
          handleApiError(error);
        });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  postStatus<T>(url: string = '', data: T, params = {}) {
    return this.axiosInstance
      ?.post(url, data, params)
      .then((response: any) => response)
      .catch((error) => {
        handleApiError(error);
      });
  }

  put(url = '', params = {}) {
    return this.axiosInstance
      ?.put(url, params)
      .then((response: any) => response?.data)
      .catch((error) => {
        handleApiError(error);
      });
  }

  patch(url = '', params = {}) {
    return this.axiosInstance
      ?.patch(url, params)
      .then((response: any) => response?.data)
      .catch((error) => {
        handleApiError(error);
      });
  }

  putStatus(url = '', params = {}) {
    return this.axiosInstance
      ?.put(url, params)
      .then((response: any) => response)
      .catch((error) => {
        handleApiError(error);
      });
  }

  delete(url = '', data = {}, params = {}) {
    return this.axiosInstance
      ?.delete(url, { data, params })
      .then((response: any) => response?.data)
      .catch((error) => {
        handleApiError(error);
      });
  }
}

export default AxiosInstanceChanger;
