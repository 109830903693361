import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const PageNotFound = () => {
  const theme = useTheme();

  return (
    <Grid container display="flex" justifyContent="center" sx={{ width: '100%', height: '100%', alignItems: 'center' }}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        flexDirection="column"
        sx={{ width: '40%', height: '40%' }}
      >
        <Typography
          sx={{
            fontSize: '120px',
            color: theme.palette.secondary.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontSize: '30px',
            color: theme.palette.secondary.dark,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          PAGE NOT FOUND
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
