import { Box } from '@mui/material';
import GeneralInformationSection from 'components/ApplicationGeneralInforamtion/GeneralInformationSection';

import { useAppSelector } from '../../redux/hooks';
import { leadsSelector } from '../../redux/slices/leads';

const GeneralInformation = () => {
  const generalInformationData = useAppSelector(leadsSelector.generalInformationData);

  return (
    <Box marginTop="10px">
      {generalInformationData &&
        Object.keys(generalInformationData).map((category) => <GeneralInformationSection key={category} category={category} />)}
    </Box>
  );
};

export default GeneralInformation;
