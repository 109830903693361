import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { MenuProps } from '../../../types/reduxTypes/menu';

const createReducer = <T extends SliceCaseReducers<MenuProps>>(reducer: T) => ({
  ...reducer,
});

const reducers = createReducer({
  activeItem(state, action) {
    state.openItem = action.payload;
  },

  openDrawer(state, action) {
    state.drawerOpen = action.payload;
  },
});

export default reducers;
