import React from 'react';

const LineDownSvg = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6491 15.8906C27.4995 16.0365 27.2583 16.0365 27.104 15.8906L20.4321 9.41624L14.2765 15.4577C14.1269 15.6036 13.8809 15.6036 13.7314 15.4577L0.44558 2.5043C0.29603 2.35844 0.29603 2.11847 0.44558 1.97261L2.35596 0.109349C2.42849 0.0392971 2.52644 0 2.62852 0C2.73059 0 2.82855 0.0392971 2.90108 0.109349L13.9967 10.9266L20.1427 4.89454C20.2152 4.82449 20.3132 4.78519 20.4152 4.78519C20.5173 4.78519 20.6153 4.82449 20.6878 4.89454L29.5546 13.491C29.6265 13.5617 29.6667 13.6572 29.6667 13.7568C29.6667 13.8564 29.6265 13.9519 29.5546 14.0226L27.6491 15.8906Z"
      fill="#FF497F"
    />
  </svg>
);

export default LineDownSvg;
