import { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import Title from 'components/UI/Title';
import ToggleFilters from 'components/UI/ToggleFilters';

interface HeaderWithFiltersProps {
  title: string;
  children: ReactNode;
  dataTest?: string;
}

const HeaderWithFilters = ({ title, children, dataTest }: HeaderWithFiltersProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Title title={title} dataTest={dataTest ?? ''} />
        <ToggleFilters showFilters={showFilters} setShowFilters={setShowFilters} />
      </Box>
      <Box display={showFilters ? 'block' : 'none'}>{children}</Box>
    </Box>
  );
};

export default HeaderWithFilters;
