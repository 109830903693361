import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '@mui/system';

interface OutlineInputStyleProps {
  isFocused: boolean;
  error: boolean;
}

const getOutlineBorderStyle = ({ error, isFocused }: OutlineInputStyleProps) => {
  if (error) {
    return '#F44336';
  }

  if (isFocused) {
    return '#7B61FF';
  }

  return 'rgba(0, 0.0667, 0.1333, 0.23)';
};

const OutlineInputStyle = styled(
  ({ isFocused, error, ...otherProps }: OutlineInputStyleProps & React.ComponentProps<typeof OutlinedInput>) => (
    <OutlinedInput {...otherProps} />
  ),
)<OutlineInputStyleProps>(({ theme, isFocused, error }) => ({
  width: 434,
  border: `1px solid ${getOutlineBorderStyle({ error, isFocused })}`,
  marginLeft: 16,
  padding: '7px 12px',
  backgroundColor: '#fff',
  '& input': {
    background: 'transparent !important',
    padding: '0 !important',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '&::placeholder': {
      color: 'rgba(186, 182, 182, 1)',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
  },

  [theme.breakpoints.down('lg')]: {
    width: 250,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 4,
    background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  '&:hover': {
    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
  },
  width: '24px',
  height: '24px',
  background: 'transparent !important',
  color: '#fff !important',
}));

export const PagesSearch = ({
  sx,
  minChar = 3,
  handleSearch,
  placeholder,
  dataTest,
  error = false,
  title,
  defaultValue,
}: {
  sx?: React.CSSProperties;
  minChar?: number;
  placeholder?: string;
  handleSearch?(text: string): void;
  dataTest?: string;
  error?: boolean;
  title?: string;
  defaultValue?: string;
}) => {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const search = () => {
    if (handleSearch) {
      handleSearch(value.length >= minChar ? value.trim() : '');
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box sx={{ display: 'block' }} data-test={dataTest ?? ''}>
      {title && <InputLabel>{title}</InputLabel>}
      <OutlineInputStyle
        id="custom-outline-input"
        value={value}
        isFocused={isFocused}
        error={error}
        sx={sx}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyUp={(e) => {
          e.preventDefault();

          if (e.key === 'Enter') {
            search();
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder ?? 'Search'}
        endAdornment={
          <HeaderAvatarStyle variant="rounded">
            <SearchIcon onClick={() => search()} sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          </HeaderAvatarStyle>
        }
        aria-describedby="search-helper-text"
        inputProps={{ 'aria-label': 'weight' }}
      />
    </Box>
  );
};
