import { ChangeEvent, KeyboardEvent, WheelEvent } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Box, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { InputBaseProps } from '@mui/material/InputBase';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export interface InputTextFieldProps {
  id?: string;
  name?: string;
  title?: string;
  type?: string;
  autoComplete?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onWheel?: (e: WheelEvent<HTMLInputElement>) => void;
  onBlur?: InputBaseProps['onBlur'];
  InputProps?: Partial<StandardInputProps>;
  inputProps?: InputBaseProps['inputProps'];
  sx?: SxProps<Theme>;
  labelRequired?: boolean;
  className?: string;
  register?: UseFormRegisterReturn<string>;
}

const TextFieldRoot = styled(TextField)({
  '& .MuiInputAdornment-positionEnd': {
    cursor: 'pointer',
  },
});

const InputTextField = ({
  id,
  name,
  title,
  type = 'text',
  autoComplete,
  label,
  value,
  defaultValue,
  onChange,
  onKeyDown,
  onWheel,
  onBlur,
  InputProps,
  disabled,
  inputProps,
  placeholder,
  fullWidth,
  sx,
  labelRequired,
  className,
  register,
  ...props
}: InputTextFieldProps) => (
  <Box>
    {label ? (
      <Grid xs={12} item>
        <FormLabel required={labelRequired}>{label}</FormLabel>{' '}
      </Grid>
    ) : null}
    {title ? (
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          mt: '10px',
          mb: '10px',
          textAlign: 'start',
          fontWeight: 'bold',
          color: '#707D9D',
        }}
      >
        {title}
      </Typography>
    ) : null}
    <TextFieldRoot
      id={id}
      name={name}
      type={type}
      autoComplete={autoComplete}
      fullWidth={fullWidth ?? false}
      placeholder={placeholder ?? 'Type...'}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onWheel={onWheel}
      onBlur={onBlur}
      disabled={disabled}
      InputProps={{ ...InputProps, inputProps }}
      className={className}
      sx={sx}
      {...register}
      {...props}
    />
  </Box>
);

export default InputTextField;
