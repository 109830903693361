import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

import { Permissions } from '../../Permissions/constants';
import { hasPermission } from '../../Permissions/Permission';

interface ControlledTextFieldWithPermissionsProps {
  label?: string;
  name: string;
  width?: string;
  placeholder?: string;
  permission?: Permissions;
  labelSx?: SystemCssProperties<Theme>;
  required?: boolean;
  dataTest?: string;
  disabled?: boolean;
  rowCount?: number;
}

const FormTextField = ({
  label,
  name,
  width,
  placeholder,
  permission,
  labelSx,
  required = false,
  dataTest,
  disabled = false,
  rowCount,
}: ControlledTextFieldWithPermissionsProps) => {
  const { control } = useFormContext();

  const disableState = () => {
    if (disabled) {
      return true;
    }

    return permission ? !hasPermission(permission) : false;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ width }}>
          {label ? <Typography sx={labelSx}>{label}</Typography> : null}
          <TextField
            aria-rowcount={rowCount}
            required={required}
            {...field}
            placeholder={placeholder}
            data-test={dataTest}
            disabled={disableState()}
          />
          <FormHelperText error>{error?.message ? error.message : null}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormTextField;
