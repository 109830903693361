import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IManualReviews } from './initialState';

const createReducer = <T extends SliceCaseReducers<IManualReviews>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setIsLoading(state, action) {
    state.isLoading = action.payload;
  },
  setManualReviewsList(state, action) {
    state.manualReviewsList = action.payload;
  },
  setManualReviews(state, action) {
    state.manualReviews = action.payload;
  },
  setManualReviewsLoading(state, action) {
    state.manualReviewsLoading = action.payload;
  },
  setManualReviewReason(state, action) {
    state.manualReviewReason = action.payload;
  },
  setManualReviewReasonLoading(state, action) {
    state.manualReviewReasonLoading = action.payload;
  },
  setManualReviewUnderwriters(state, action) {
    state.manualReviewUnderwriters = action.payload;
  },
  setManualReviewUnderwritersLoading(state, action) {
    state.manualReviewUnderwritersLoading = action.payload;
  },
  setMerchantNames(state, action) {
    state.merchantNames = action.payload;
  },
  setMerchantNamesLoading(state, action) {
    state.merchantNamesLoading = action.payload;
  },
  setManualReviewListFilters(state, action) {
    state.manualReviewListFilters = action.payload;
  },
  setDocumentTypes(state, action) {
    state.documentTypes = action.payload;
  },
  setDocumentTypesLoading(state, action) {
    state.documentTypesLoading = action.payload;
  },
  setUpdateSolarPanelDetailsLoading(state, action) {
    state.updateSolarPanelDetailsLoading = action.payload;
  },
  setDeleteFileLoading(state, action) {
    state.deleteFileLoading = action.payload;
  },
  setAanReasons(state, action) {
    state.aanReasons = action.payload;
  },
  setAanReasonsLoading(state, action) {
    state.aanReasonsLoading = action.payload;
  },
});

export default reducers;
