import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ISettingsState } from '@types';

const createReducer = <T extends SliceCaseReducers<ISettingsState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },

  setQRInfo(state, action) {
    state.qrInfo = action.payload;
  },

  setSettingsLoading(state, action) {
    state.settingsLoading = action.payload;
  },

  setVerificationError(state, action) {
    state.verificationError = action.payload;
  },

  setVerificationEnabled(state, action) {
    state.verificationEnabled = action.payload;
  },
  setSettingsStatus(state, action) {
    state.settingsStatus = action.payload;
  },
  setInitializationLoading(state, action) {
    state.initializationLoading = action.payload;
  },
  setPlatformSettings(state, action) {
    state.platformSettings = action.payload;
  },
  setPlatformSettingsLoading(state, action) {
    state.platformSettingsLoading = action.payload;
  },
  setTenantSettings(state, action) {
    state.tenantSettings = action.payload;
  },
  setTenantSettingsLoading(state, action) {
    state.tenantSettingsLoading = action.payload;
  },
  setNotificationTypes(state, action) {
    state.notificationTypes = action.payload;
  },
  setDuplicatedNotificationTypes(state, action) {
    state.duplicatedNotificationTypes = action.payload;
  },
  setNotificationTypesLoading(state, action) {
    state.notificationTypesLoading = action.payload;
  },
});

export default reducers;
