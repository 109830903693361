import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.manualReview;

const isLoading = createSelector([selector], (state) => state.isLoading);
const manualReviewsList = createSelector([selector], (state) => state.manualReviewsList);
const manualReviews = createSelector([selector], (state) => state.manualReviews);
const manualReviewsLoading = createSelector([selector], (state) => state.manualReviewsLoading);
const manualReviewReason = createSelector([selector], (state) => state.manualReviewReason);
const manualReviewReasonLoading = createSelector([selector], (state) => state.manualReviewReasonLoading);
const manualReviewUnderwriters = createSelector([selector], (state) => state.manualReviewUnderwriters);
const manualReviewUnderwritersLoading = createSelector([selector], (state) => state.manualReviewUnderwritersLoading);
const merchantNames = createSelector([selector], (state) => state.merchantNames);
const merchantNamesLoading = createSelector([selector], (state) => state.merchantNamesLoading);
const manualReviewListFilters = createSelector([selector], (state) => state.manualReviewListFilters);
const documentTypes = createSelector([selector], (state) => state.documentTypes);
const documentTypesLoading = createSelector([selector], (state) => state.documentTypesLoading);
const updateSolarPanelDetailsLoading = createSelector([selector], (state) => state.updateSolarPanelDetailsLoading);
const deleteFileLoading = createSelector([selector], (state) => state.deleteFileLoading);
const aanReasons = createSelector([selector], (state) => state.aanReasons);
const aanReasonsLoading = createSelector([selector], (state) => state.aanReasonsLoading);

export default {
  isLoading,
  manualReviewsList,
  manualReviews,
  manualReviewsLoading,
  manualReviewReason,
  manualReviewReasonLoading,
  manualReviewUnderwriters,
  manualReviewUnderwritersLoading,
  merchantNames,
  merchantNamesLoading,
  manualReviewListFilters,
  documentTypes,
  documentTypesLoading,
  updateSolarPanelDetailsLoading,
  deleteFileLoading,
  aanReasons,
  aanReasonsLoading,
};
