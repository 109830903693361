import { useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, CircularProgress, Typography } from '@mui/material';
import CustomIconButton from 'components/shared/Button/IconButton';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';

export interface IFile extends File {
  documentId: string;
  id: string;
}

export interface IFileAttachmentProps {
  file: IFile;
  index: number;
  removeFile?: (index: number) => void;
  fileState?: number;
  deleteFile?: (file: IFile) => void;
  canView?: boolean;
}

let shouldRevokeUrl: string;

const FileAttachment = ({ file, index, removeFile, fileState = 0, deleteFile, canView }: IFileAttachmentProps) => {
  const filePreviewLoading = useAppSelector(leadsSelector.filePreviewLoading);
  const fileDownloadLoading = useAppSelector(leadsSelector.fileDownloadLoading);

  useEffect(
    () => () => {
      if (shouldRevokeUrl) {
        URL.revokeObjectURL(shouldRevokeUrl);
      }
    },
    [],
  );

  const handleOnDownloadClick = (id: string, name: string) => {
    dispatch(leadsMiddleware.downloadFile(id, name));
  };

  const handleOnPreviewClick = (id: string, name: string) => {
    dispatch(
      leadsMiddleware.previewFile(id, name, (url) => {
        shouldRevokeUrl = url;
      }),
    );
  };

  const InQueueFile = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 20px',
          backgroundColor: '#F4F8FD',
          border: '1px solid #E2E8F0',
          borderRadius: '12px',
          margin: '8px 0 16px 0',
        }}
      >
        <Box display="flex" alignItems="center">
          <DescriptionOutlinedIcon sx={{ color: '#7B61FF' }} />
          <Typography sx={{ fontSize: '15px', color: '#454256', marginLeft: '16px' }}>{file.name}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography sx={{ color: '#95A1B1' }}>{(file.size / 1048576).toFixed(2)}Mb</Typography>
          {canView && (
            <Box>
              <CustomIconButton loading={!!filePreviewLoading[file.documentId]}>
                <RemoveRedEyeOutlinedIcon
                  sx={{ color: '#A3AED0' }}
                  onClick={() => handleOnPreviewClick(file.documentId, file.name)}
                />
              </CustomIconButton>
              <CustomIconButton loading={!!fileDownloadLoading[file.documentId]}>
                <SaveAltIcon
                  sx={{ color: '#A3AED0' }}
                  onClick={() => handleOnDownloadClick(file.documentId, file.name)}
                />
              </CustomIconButton>
            </Box>
          )}
          {removeFile && <CloseIcon fontSize="small" onClick={() => removeFile(index)} sx={{ cursor: 'pointer' }} />}
          {deleteFile && (
            <CustomIconButton loading={!!fileDownloadLoading[file.documentId]}>
              <CloseIcon sx={{ color: '#A3AED0' }} onClick={() => deleteFile(file)} />
            </CustomIconButton>
          )}
        </Box>
      </Box>
    ),
    [],
  );

  const DownloadingFile = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '6px 20px',
          backgroundColor: '#F4F8FD',
          border: '1px solid #146C43',
          color: '#146C43',
          borderRadius: '12px',
          margin: '16px 0',
        }}
      >
        <Box display="flex" alignItems="center">
          <DescriptionOutlinedIcon sx={{ color: '#7B61FF' }} />
          <Typography sx={{ fontSize: '15px', color: '#454256', marginLeft: '16px' }}>{file.name}</Typography>
        </Box>
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    ),
    [],
  );

  const SuccessDownloadedFile = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 20px',
          backgroundColor: '#F4F8FD',
          border: '1px solid #146C43',
          color: '#146C43',
          borderRadius: '12px',
          margin: '16px 0',
        }}
      >
        <Box display="flex" alignItems="center">
          <DescriptionOutlinedIcon sx={{ color: '#7B61FF' }} />
          <Typography sx={{ fontSize: '15px', color: '#454256', marginLeft: '16px' }}>{file.name}</Typography>
        </Box>
        <Typography>Uploaded</Typography>
      </Box>
    ),
    [],
  );

  const ErrorDownloadedFile = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 20px',
          backgroundColor: '#FAEAF2',
          border: '1px solid #AB296A',
          color: '#AB296A',
          borderRadius: '12px',
          margin: '16px 0',
        }}
      >
        <Box display="flex" alignItems="center">
          <DescriptionOutlinedIcon sx={{ color: '#7B61FF' }} />
          <Typography sx={{ fontSize: '15px', color: '#454256', marginLeft: '16px' }}>{file.name}</Typography>
        </Box>
        <Typography>Error</Typography>
      </Box>
    ),
    [],
  );

  switch (fileState) {
    case 0: {
      return InQueueFile;
    }

    case 1: {
      return DownloadingFile;
    }

    case 2: {
      return SuccessDownloadedFile;
    }

    case 3: {
      return ErrorDownloadedFile;
    }

    default: {
      return InQueueFile;
    }
  }
};

export default FileAttachment;
