import { useState } from 'react';
import { Box } from '@mui/material';
import HeaderWithFilters from 'components/HeaderWithFilters';

import { TableHeader } from '@types';

import ManualReviewListFilters from '../../components/ManualReviewList/ManualReviewListFilters';
import ManualReviewListTable from '../../components/ManualReviewList/ManualReviewListTable';
import { ManualReviewListTableHeaders } from '../../lib/constants/tableHeaders';

const ManualReviewList = () => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(ManualReviewListTableHeaders);
  const [page, setPage] = useState(0);

  return (
    <Box>
      <HeaderWithFilters title="Manual Reviews List">
        <ManualReviewListFilters
          setPage={setPage}
          selectedTableHeaders={selectedTableHeaders}
          setSelectedTableHeaders={setSelectedTableHeaders}
        />
      </HeaderWithFilters>
      <ManualReviewListTable page={page} setPage={setPage} selectedTableHeaders={selectedTableHeaders} />
    </Box>
  );
};

export default ManualReviewList;
