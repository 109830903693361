import { IValidationTreeState } from '../../../types';

export const getInitialState = (): IValidationTreeState => ({
  error: null,
  validationTrees: null,
  conditions: null,
  listConditions: null,
  validationTree: { id: '', name: '', creditUnion: 0, channelTreeType: 0 },
  singleValidationTree: [],
  validationTreesById: { data: [], itemsCount: 0, pagesCount: 0 },
  validationTreeNameSuccess: false,
  validationTreeActions: null,
  validationTreeLoading: false,
  validationTreeSuccessfully: false,
  validationTreeUpdateLoading: false,
  isValidationTreesByIdLoading: false,
  isValidationTreeDeleteLoading: false,
  manualReviewReasonsList: [],
  manualReviewReasonsListLoading: false,
  experianFields: [],
  validationTreeTypes: null,
  validationTreeTypesLoading: false,
  downloadJsonIsLoading: false,
  uploadJsonIsLoading: false,
});
