import { useEffect, useRef, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FileAttachment, { IFile } from 'components/FileUploadModal/FileAttachment';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import UploadFileButton from 'components/shared/Button/UploadFileButton';
import { dateTime, isMe } from 'helpers';

import { IFunds } from '@types';

import { LeadsFundingStatuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { CustomButton } from '../shared';
import { StatusBadgeTitle } from '../StatusBadgeTitle';

interface IManualReviewEdit {
  fundId: string;
  setMode: (mode: string) => void;
}

const FundingRequestView = ({ fundId, setMode }: IManualReviewEdit) => {
  const defaultFundItem = {
    id: '',
    leadId: '',
    requestedDate: '',
    approvedDate: '',
    rejectedDate: '',
    status: 0,
    reviewedBy: '',
    requestedAmount: '',
    reviewerId: '',
  };

  const fundAttributesNames = (key: string) => {
    switch (key) {
      case 'FullName':
        return 'Your full name';

      case 'CompletedDate':
        return 'Date project was fully installed/completed (mm.dd.yyyy)';

      case 'ProjectId':
        return 'Project ID';

      default:
        return key;
    }
  };

  const funds = useAppSelector(leadsSelector.funds);
  const [fund, setFund] = useState<IFunds>(defaultFundItem);
  const fundsLoading = useAppSelector(leadsSelector.fundsLoading);
  const leadsDetails = useAppSelector(leadsSelector.leadsDetails);
  const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    height: '30px',
    marginBottom: '20px',
  });
  const canReviewReason = isMe(fund?.reviewerId ?? '0');
  const fundsLoadingType = useRef('');

  const onApprove = () => {
    fundsLoadingType.current = 'approve';
    dispatch(leadsMiddleware.fetchChangeFundStatus(fund.leadId, fund.id, 1, setMode));
  };

  const onReject = () => {
    fundsLoadingType.current = 'reject';
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.FundingRequestRejectModal,
        props: {
          fund,
          setMode,
        },
      }),
    );
  };

  const onUpload = () => {
    fundsLoadingType.current = '';
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DocumentsUploadFilesModal,
        props: {
          leadId: fund.leadId,
          reasonId: fund.id,
          mode: 'Fundings',
          documentCategoryId: fund?.documentCategoryId,
          documentTypeId: fund?.documentTypeId,
        },
      }),
    );
  };

  const onDeleteFile = (file: IFile) => {
    fundsLoadingType.current = '';
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DeleteFileModal,
        props: { file, reasonId: fund.leadId, modalType: 'Funding' },
      }),
    );
  };

  useEffect(() => {
    setFund(funds.find((fnd) => fnd.id === fundId) ?? defaultFundItem);
  }, [funds]);

  return (
    <Grid container key={fund.id} sx={{ mt: 3 }}>
      <Grid item xs={2}>
        <Box
          data-test="lead-details-page-tab-funding-request-view-button-back"
          onClick={() => setMode('table')}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <ArrowBackIosIcon />
          <Typography marginLeft="5px">Back</Typography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ minWidth: '500px', display: 'flex', gap: '30px' }}>
          <Box flexDirection="initial">
            <Box
              sx={{
                borderRight: '1px solid #dedede',
                paddingRight: '50px',
              }}
            >
              <StyledBox>
                <Typography width="110px">Status:</Typography>
                <StatusBadgeTitle Statuses={LeadsFundingStatuses} status={`${fund.status}`} />
              </StyledBox>

              <StyledBox>
                <Typography width="110px">Amount:</Typography>
                <Typography fontWeight={700}>
                  ${new Intl.NumberFormat('en-US').format(+fund.requestedAmount)}
                </Typography>
              </StyledBox>
            </Box>

            {/* For the approved funding requests */}
            {fund.status === 1 ? (
              <Box
                sx={{
                  marginBottom: 0,
                  paddingRight: '50px',
                }}
              >
                <StyledBox>
                  <Typography width="110px">Accepted Time:</Typography>
                  <Typography fontWeight={700}>{dateTime(fund.approvedDate)}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography width="110px">Approved By:</Typography>
                  <Typography fontWeight={700}>{fund.reviewedBy}</Typography>
                </StyledBox>
              </Box>
            ) : null}
            {fund.status === -1 ? (
              <Box
                sx={{
                  marginBottom: 0,
                  paddingRight: '50px',
                }}
              >
                <StyledBox>
                  <Typography width="110px">Rejected Time:</Typography>
                  <Typography fontWeight={700}>{fund.rejectedDate ? dateTime(fund.rejectedDate) : '-'}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography width="110px">Rejected By:</Typography>
                  <Typography fontWeight={700}>{fund.reviewedBy}</Typography>
                </StyledBox>
                <StyledBox>
                  <Typography width="110px">Reject Reason:</Typography>
                  <Typography fontWeight={700}>{fund.rejectReason}</Typography>
                </StyledBox>
              </Box>
            ) : null}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <StyledBox>
              <Typography width="110px">Merchant Name:</Typography>
              <Typography fontWeight={700}>{leadsDetails?.merchantName}</Typography>
            </StyledBox>
            <StyledBox>
              <Typography width="110px">Source:</Typography>
              <Typography fontWeight={700}>{leadsDetails?.affiliateName}</Typography>
            </StyledBox>
          </Box>
        </Box>
        {fund.attributes?.length ? (
          <Box>
            <Typography sx={{ fontWeight: 700, marginTop: '16px', marginBottom: '16px' }}>Additional Info</Typography>
            <Box sx={{ padding: '10px 20px', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
              {fund.attributes.map((attribute, index) => (
                <Box
                  sx={{
                    display: 'flex',
                    padding: '16px 0',
                    borderBottom: (fund.attributes?.length ?? 1) - 1 !== index ? '1px solid #E6EBF1' : '0',
                  }}
                >
                  <Box sx={{ width: '50%' }}>{fundAttributesNames(attribute.key)}</Box>
                  <Box sx={{ width: '50%', fontWeight: 700 }}>{attribute.value}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}
        <Box>
          <Typography sx={{ fontWeight: 700, marginTop: '16px', marginBottom: '8px' }}>
            Manually Uploaded Files
          </Typography>
          <Box sx={{ border: '1px solid #E2E8F0', borderRadius: '12px', padding: '20px' }}>
            {fund.fundingDocuments ? (
              <Box className="last_block_m_0">
                {fund.fundingDocuments?.length !== 0 ? (
                  fund.fundingDocuments?.map((file) => {
                    const newFile = { ...file, documentId: file.id };

                    return (
                      <FileAttachment
                        key={newFile.id}
                        file={newFile as unknown as IFile}
                        index={1}
                        deleteFile={fund.status === 0 ? onDeleteFile : undefined}
                        canView
                      />
                    );
                  })
                ) : (
                  <Box textAlign="center">
                    <Typography sx={{ color: '#95A1B1', fontSize: '16px', fontWeight: 400 }}>
                      No files have been uploaded yet
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : null}
            {fund.status === 0 && canReviewReason && hasPermission(Permissions.reviewFund) && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <UploadFileButton onClick={onUpload} />
              </Box>
            )}
          </Box>
        </Box>
        {fund.status === 0 && isMe(fund.reviewerId) ? (
          <Permission permission={Permissions.reviewFund}>
            <Box display="flex" justifyContent="space-between" marginTop="30px">
              <CustomButton
                data-test="lead-details-page-tab-funding-request-view-button-reject"
                disabled={fundsLoading}
                loading={fundsLoading && fundsLoadingType.current === 'reject'}
                variant="contained"
                color="error"
                sx={{
                  backgroundColor: '#FF497F',
                  borderColor: fundsLoading ? '' : '#FF497F',
                  opacity: fundsLoading ? 0.5 : 1,
                }}
                onClick={() => onReject()}
              >
                Reject
              </CustomButton>
              <CustomButton
                data-test="lead-details-page-tab-funding-request-view-button-approve"
                disabled={fundsLoading}
                loading={fundsLoading && fundsLoadingType.current === 'approve'}
                variant="contained"
                onClick={() => onApprove()}
              >
                Approve
              </CustomButton>
            </Box>
          </Permission>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FundingRequestView;
