import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import MainCard from 'components/UI/MainCard';
import { dispatch } from 'redux/hooks';
import { isValidUrl } from 'utils/index';

import { CustomButton } from '../../components';
import { StyledLink } from '../../components/shared/StyledComponents';
import InputTextField from '../../components/UI/InputTextField';
import Loading from '../../components/UI/Loading';
import { trimProperties } from '../../helpers';
import { useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { countriesMiddleware, countriesSelector } from '../../redux/slices/countries';
import { ICountriesProps, ICreateAffiliateReqTemplate, IStatesProps } from '../../types';
import { RemoveExponentFromNumberInput } from '../../utils/UsefullFunctions';

import classes from '../../assets/scss/campaigns.module.scss';

interface FormValues {
  id?: string;
  name: string;
  countryId: string;
  stateProvinceId?: number;
  city: string;
  zipPostalCode: string;
  addressLine2: string;
  addressLine1: string;
  countryCode: string;
  companyPhone: string;
  webhookToken: string;
  statusWebhookUrl: string;
  offersWebhookUrl: string;
  stipulationsWebhookUrl: string;
  companyEmail: string;
  website?: string;
  status?: string;
}

const getUrlValidationMessage = (value: string) => {
  if (isValidUrl(value)) {
    return true;
  }

  return 'URL should be valid.';
};

const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line

const AffiliatesCreatePage = ({ defaultValue }: { defaultValue?: FormValues }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const countries = useAppSelector(countriesSelector.countries);
  const states = useAppSelector(countriesSelector.states);
  const successfully = useAppSelector(affiliatesSelector.successfully);
  const isCreateAffiliateLoading = useAppSelector(affiliatesSelector.isCreateAffiliateLoading);

  const stringRegexWithoutNumbers = /^[^0-9]+$/g;

  const defaultPropsCountries = {
    options: countries,
    getOptionLabel: (option: ICountriesProps) => option.name ?? option.id,
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    resetField,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      name: defaultValue?.name ?? '',
      countryId: defaultValue?.countryId ?? 'kB',
      stateProvinceId: defaultValue?.stateProvinceId ?? 0,
      city: defaultValue?.city ?? '',
      zipPostalCode: defaultValue?.zipPostalCode ?? '',
      addressLine2: defaultValue?.addressLine2 ?? '',
      addressLine1: defaultValue?.addressLine1 ?? '',
      countryCode: defaultValue?.countryCode ?? '',
      companyPhone: defaultValue?.companyPhone ?? '',
      companyEmail: defaultValue?.companyEmail ?? '',
      webhookToken: defaultValue?.webhookToken ?? '',
      statusWebhookUrl: defaultValue?.statusWebhookUrl ?? '',
      offersWebhookUrl: defaultValue?.offersWebhookUrl ?? '',
      stipulationsWebhookUrl: defaultValue?.stipulationsWebhookUrl ?? '',
    },
    mode: 'onChange',
  });

  const countryId = watch('countryId');

  useEffect(() => {
    if (countryId) {
      dispatch(countriesMiddleware.fetchStates(countryId));
    }
  }, [countryId]);

  useEffect(() => {
    if (successfully) {
      dispatch(affiliatesMiddleware.fetchHasSuccessfully(false));
      setTimeout(() => {
        navigate('/sources', { replace: true });
        dispatch(affiliatesMiddleware.fetchIsAffiliateSuccessfully(false));
      }, 2000);
    }
  }, [successfully]);

  useEffect(() => {
    if (!countries || !countries.length) {
      setIsLoading(true);
      dispatch(countriesMiddleware.fetchCountries());
      setIsLoading(false);
    } else {
      const countryUS = countries?.find((item: { code: string; id: string }) => item.code === 'US');

      setValue('countryId', countryUS?.id ?? '0');
    }
  }, [countries]);

  useEffect(() => {
    Object.keys(defaultValue ?? {})?.forEach((item) => {
      register(item as keyof FormValues);
      setValue(item as keyof FormValues, (defaultValue ?? {})[item as keyof FormValues]);
    });
  }, []);

  useEffect(() => {
    if (states && states.length) {
      setValue('stateProvinceId', states[0].id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      resetField('stateProvinceId', {
        keepError: false,
        keepDirty: true,
        defaultValue: 0,
      });
    }
  }, [states]);

  const onSubmit = (values: FormValues) => {
    dispatch(
      affiliatesMiddleware.fetchCreateAffiliate(
        trimProperties(values, [
          'name',
          'webhookToken',
          'city',
          'countryCode',
          'addressLine1',
          'addressLine2',
        ]) as ICreateAffiliateReqTemplate,
      ),
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ backgroundColor: '#F4F7FE', border: 0 }}>
      <Breadcrumbs
        sx={{
          color: '#707EAE',
          marginTop: '10px',
        }}
      >
        <StyledLink to="/sources">Sources</StyledLink>
        <Typography sx={{ fontWeight: 'bold' }}>Create Source</Typography>
      </Breadcrumbs>
      <MainCard contentClass={classes.root} sx={{ backgroundColor: '#F4F7FE', border: 0 }} border={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MainCard sx={{ border: 0, padding: '50px' }}>
            <Typography
              component="p"
              sx={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#454256',
                marginBottom: '30px',
              }}
            >
              Identification
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputTextField
                  data-test="affiliate-create-page-input-affiliate-name"
                  labelRequired
                  label="Source Name"
                  fullWidth
                  placeholder="Name"
                  register={register('name', {
                    required: 'This field is required',
                    setValueAs: (value) => value.trimStart(),
                  })}
                />
                <FormHelperText error>{errors?.name?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-status-webhook-url"
                  labelRequired
                  label="Status Webhook URL"
                  register={register('statusWebhookUrl', {
                    required: 'This field is required',
                    validate: getUrlValidationMessage,
                  })}
                  placeholder="Status Webhook URL"
                  fullWidth
                />
                <FormHelperText error>{errors?.statusWebhookUrl?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-offers-webhook-url"
                  labelRequired
                  label="Offers Webhook URL"
                  register={register('offersWebhookUrl', {
                    required: 'This field is required',
                    validate: getUrlValidationMessage,
                  })}
                  placeholder="Offers Webhook URL"
                  fullWidth
                />
                <FormHelperText error>{errors?.offersWebhookUrl?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-stipulations-webhook-url"
                  labelRequired
                  label="Stipulations Webhook URL"
                  register={register('stipulationsWebhookUrl', {
                    required: 'This field is required',
                    validate: getUrlValidationMessage,
                  })}
                  placeholder="Stipulations Webhook URL"
                  fullWidth
                />
                <FormHelperText error>{errors?.stipulationsWebhookUrl?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-webhook-token"
                  label="Webhook Token"
                  register={register('webhookToken')}
                  placeholder="Webhook Token"
                  fullWidth
                />
                <FormHelperText error>{errors?.webhookToken?.message ?? ' '}</FormHelperText>
              </Grid>
            </Grid>
            <Typography
              component="p"
              sx={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#454256',
                marginBottom: '30px',
              }}
            >
              Contact Information
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
              {countries?.length ? (
                <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                  <FormLabel required>Country</FormLabel>
                  <Autocomplete
                    data-test="affiliate-create-page-dropdown-country"
                    sx={{ maxHeight: '40px' }}
                    clearOnEscape
                    disableClearable
                    defaultValue={countries?.find((item: { code: string }) => item.code === 'US')}
                    onChange={(event, newValue: { id: string; code: string }) => {
                      if (newValue) {
                        setValue('countryId', newValue.id, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      } else {
                        setValue('countryId', 'kB', {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <InputTextField
                        {...params}
                        placeholder="Country"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            padding: '0 !important',
                          },
                        }}
                      />
                    )}
                    {...defaultPropsCountries}
                  />
                  <FormHelperText error>{errors?.countryId?.message ?? ' '}</FormHelperText>
                </Grid>
              ) : (
                <Loading />
              )}
              {states?.length ? (
                <Grid item xs={2} sm={2} md={3} columnSpacing={1} maxHeight="40px">
                  <FormLabel>State</FormLabel>
                  <Autocomplete
                    data-test="affiliate-create-page-dropdown-state"
                    defaultValue={states[0]}
                    clearOnEscape
                    options={states}
                    disableClearable
                    getOptionLabel={(option: IStatesProps) => option.name ?? option.id}
                    onChange={(event, newValue) => {
                      setValue('stateProvinceId', newValue?.id ?? 0, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                    renderInput={(params) => (
                      <InputTextField
                        {...params}
                        placeholder="State"
                        id="stateProvinceId"
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            padding: '0 !important',
                          },
                        }}
                      />
                    )}
                  />
                  <FormHelperText error>{errors?.stateProvinceId?.message && 'This field is required'}</FormHelperText>
                </Grid>
              ) : null}
              <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-city"
                  labelRequired
                  label="City"
                  register={register('city', {
                    required: 'This field is required',
                    setValueAs: (value) => value.trimStart(),
                    pattern: {
                      value: stringRegexWithoutNumbers,
                      message: "City name can't contain number",
                    },
                  })}
                  placeholder="City"
                  fullWidth
                  sx={{ marginTop: '5px !important' }}
                />
                <FormHelperText error>{errors?.city?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-zip-code"
                  labelRequired
                  label="Zip Code"
                  register={register('zipPostalCode', {
                    required: 'This field is required',
                  })}
                  placeholder="Zip Code"
                  onKeyDown={RemoveExponentFromNumberInput}
                  inputProps={{ type: 'number', min: 0 }}
                  fullWidth
                />
                <FormHelperText error>{errors?.zipPostalCode?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={2} sm={4} md={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-primary-address"
                  labelRequired
                  label="Primary Address"
                  register={register('addressLine1', {
                    required: 'This field is required',
                    setValueAs: (value) => value.trimStart(),
                  })}
                  id="addressLine1"
                  placeholder="Primary Address"
                  fullWidth
                />
                <FormHelperText error>{errors?.addressLine1?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={2} sm={4} md={4} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-secondary-address"
                  label="Secondary Address"
                  labelRequired={false}
                  register={register('addressLine2')}
                  id="addressLine2"
                  placeholder="Secondary Address"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4} columnSpacing={1} />
              <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-company-email"
                  labelRequired
                  label="Company Email"
                  register={register('companyEmail', {
                    required: 'This field is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Invalid email',
                    },
                  })}
                  id="companyEmail"
                  placeholder="Email"
                  fullWidth
                />
                <FormHelperText error>{errors?.companyEmail?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-country-code"
                  labelRequired
                  label="Country Code"
                  register={register('countryCode', {
                    required: 'This field is required',
                    onChange: (e) => {
                      setValue('countryCode', e.target.value.length <= 3 ? e.target.value : e.target.value.slice(0, 3));
                    },
                  })}
                  onKeyDown={RemoveExponentFromNumberInput}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                    inputProps: { type: 'number', min: 0 },
                  }}
                  placeholder="Country Code"
                  fullWidth
                />
                <FormHelperText error>{errors?.countryCode?.message ?? ' '}</FormHelperText>
              </Grid>
              <Grid item xs={2} sm={2} md={3} columnSpacing={1}>
                <InputTextField
                  data-test="affiliate-create-page-input-phone-number"
                  labelRequired
                  label="Phone Number"
                  register={register('companyPhone', {
                    required: 'This field is required',
                  })}
                  inputProps={{ type: 'number', min: 0 }}
                  placeholder="Phone Number"
                  onKeyDown={RemoveExponentFromNumberInput}
                  fullWidth
                />
                <FormHelperText error>{errors?.companyPhone?.message ?? ' '}</FormHelperText>
              </Grid>
            </Grid>
          </MainCard>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
              marginTop: '53px',
            }}
          >
            <CustomButton
              data-test="affiliate-create-page-button-cancel"
              variant="outlined"
              onClick={() => navigate('/sources', { replace: true })}
            >
              Cancel
            </CustomButton>
            <CustomButton
              data-test="affiliate-create-page-button-create"
              loading={isCreateAffiliateLoading}
              disabled={!isValid || isCreateAffiliateLoading}
              type="submit"
            >
              Create
            </CustomButton>
          </Box>
        </form>
      </MainCard>
    </Box>
  );
};

export default AffiliatesCreatePage;
