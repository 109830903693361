import { dispatch } from 'redux/hooks';

import {
  API_ACTION_WITH_BWLIST_VALUE,
  API_DELETE_LISTS,
  API_GET_LIST_BY_ID,
  API_GET_LISTS,
  API_GET_LISTS_VALUES_BY_ID,
  API_GET_UPLOADED_LISTS,
  API_POST_BWLIST_VALUES,
  API_POST_LISTS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { IListIntegration } from '../../../types';
import { SeveritiesType } from '../../../types/snackbar';
import store from '../../store';
import { viewsMiddleware } from '../views';

import slice from './slice';

const {
  setLoading,
  setListsSuccess,
  hasError,
  setPostListValuesLoading,
  setEditListValueLoading,
  setUploadedListsSuccess,
  setSelectedListsSuccess,
  setSelectedListsLoading,
} = slice.actions;

const fetchGetLists = (type: number) => async () => {
  try {
    dispatch(setLoading(true));

    const response = await DataService.getJson(API_GET_LISTS(), {
      type,
    });

    dispatch(setListsSuccess(response));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLoading(false));
  }
};

const fetchDeleteList = (id: string, type: number) => async () => {
  try {
    dispatch(setLoading(true));
    await DataService.delete(API_GET_LIST_BY_ID(id));

    const response = await DataService.getJson(API_GET_LISTS(), {
      type,
    });

    dispatch(setListsSuccess(response));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLoading(false));
  }
};

const fetchUpdateList = (list: IListIntegration) => async () => {
  try {
    dispatch(setLoading(true));
    await DataService.put(API_GET_LIST_BY_ID(list.id), {
      id: list.id,
      name: list.value,
      condition: list.condition,
      listType: list.listType,
    });

    const response = await DataService.getJson(API_GET_LISTS(), {
      type: list.type,
    });

    dispatch(setListsSuccess(response));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setLoading(false));
  }
};

const fetchSelectedLists = (id: string) => async () => {
  try {
    dispatch(setSelectedListsLoading(true));

    const response = await DataService.getJson(API_GET_LISTS_VALUES_BY_ID(id));

    dispatch(setSelectedListsSuccess(response));
    dispatch(setSelectedListsLoading(false));
  } catch (error) {
    await dispatch(setSelectedListsSuccess([]));
    dispatch(hasError(error));
    dispatch(setSelectedListsLoading(false));
  }
};

const postListValues =
  ({ bwListId, value }: { bwListId: string; value: string }) =>
  async () => {
    try {
      dispatch(setPostListValuesLoading(true));
      await DataService.post(API_POST_BWLIST_VALUES(), {
        bwListId,
        value,
      });
      dispatch(fetchSelectedLists(bwListId));
      dispatch(setPostListValuesLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setPostListValuesLoading(false));
    }
  };

const deleteListValue = (id: string, bwListId: string) => async () => {
  try {
    await DataService.delete(API_ACTION_WITH_BWLIST_VALUE(id));
    dispatch(fetchSelectedLists(bwListId));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchCreateList = (name: string) => async () => {
  try {
    await DataService.post(API_POST_LISTS(), {
      id: 0,
      name,
      listType: 1,
      condition: 1,
    });
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'List Added Successfully',
        },
      }),
    );
    await dispatch(fetchGetLists(0));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Something went wrong.',
        },
      }),
    );
  }
};

const editListValue = (field: IListIntegration) => async () => {
  try {
    dispatch(setEditListValueLoading(true));
    await DataService.put(API_ACTION_WITH_BWLIST_VALUE(field.id), {
      id: field.id,
      value: field.value,
    });
    await dispatch(fetchSelectedLists(field?.bwListId ?? '0'));
    dispatch(setEditListValueLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setEditListValueLoading(false));
  }
};

const fetchListUploadAdd = (file: File, id: string) => async () => {
  try {
    dispatch(setEditListValueLoading(true));

    const response = await DataService.postFile(API_GET_UPLOADED_LISTS(id), { file });
    const listsData = store.getState().lists.selectedLists;

    dispatch(setUploadedListsSuccess(response));

    if (listsData && listsData.length) {
      dispatch(setSelectedListsSuccess([...listsData, ...response]));
    } else {
      dispatch(setSelectedListsSuccess([...response]));
    }

    dispatch(setEditListValueLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setEditListValueLoading(false));
  }
};

const fetchListUnloadRemove = (file: File, id: string) => async () => {
  try {
    dispatch(setEditListValueLoading(true));

    const response = await DataService.deleteFile(API_DELETE_LISTS(id), { file });

    dispatch(setUploadedListsSuccess(response));
    dispatch(fetchSelectedLists(id));
    dispatch(setEditListValueLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setEditListValueLoading(false));
  }
};

export default {
  fetchGetLists,
  fetchDeleteList,
  fetchUpdateList,
  postListValues,
  deleteListValue,
  fetchCreateList,
  editListValue,
  fetchListUploadAdd,
  fetchListUnloadRemove,
  fetchSelectedLists,
};
