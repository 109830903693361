import axios from 'axios';
import moment from 'moment';
import { dispatch } from 'redux/hooks';

import { responseErrorHandler } from '../../../helpers/ErrorHandler';
import {
  API_EXTERNAL_SERVICES,
  API_EXTERNAL_SERVICES_CAMPAIGN_FIELDS,
  API_EXTERNAL_SERVICES_FIELDS,
  API_EXTERNAL_SERVICES_RESPONSE_FIELDS,
  API_FIELDS,
  API_SIMPLIFIED_EXTERNAL_SERVICES,
} from '../../../lib/constants/api.constants.simple';
import { IResponseFields } from '../../../pages/Integrations/ExternalServiceAdd';
import DataService from '../../../service/data.service';
import {
  ICreateExternalServiceReqBody,
  IExternalService,
  IExternalServiceField,
  IExternalServiceResponseField,
  IExternalServiceUpdateReqBody,
} from '../../../types';
import { IExternalServiceCampaignFieldsMatchingCreate } from '../../../types/campaigns';
import { SeveritiesType } from '../../../types/snackbar';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { externalServicesMiddleware } from './index';
import slice from './slice';

// @deprecated

const {
  getLoading,
  hasError,
  setExternalServiceListSuccess,
  setExternalServiceListCount,
  setSimplifiedExternalServices,
  setExternalServiceSuccess,
  setExternalServiceFieldsSuccess,
  setExternalServiceUpdateLoading,
  setExternalServiceDetailsLoading,
  setExternalServiceDetailsSuccess,
  setFieldByDataTypeExternalServicesLoading,
  setServiceFieldByDataTypeExternalServicesLoading,
  setExternalServiceResponseFieldsSuccess,
  setExternalServiceDeleteLoading,
  setExternalServiceMatchingCampaignFieldsLoading,
  setExternalServiceCampaignFieldsMatchingValues,
  setExternalServicesCampaignFieldsAutoMatchLoading,
  setExternalServiceCampaignFieldsAutoMatchValues,
  setIsExternalServicesCreated,
} = slice.actions;

export const fetchExternalServicesList =
  (status = -1, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(getLoading(true));

      const externalServicesList = await DataService.getData(API_EXTERNAL_SERVICES(), {
        status,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
      });

      externalServicesList.data?.forEach((item: IExternalService) => {
        item.updatedDate = moment(item.updatedDate).format('MMM D, yyyy HH:mm');
        item.createdDate = moment(item.createdDate).format('MMM D, yyyy HH:mm');
      });

      dispatch(setExternalServiceListSuccess(externalServicesList.data));
      dispatch(setExternalServiceListCount(externalServicesList.itemsCount));
      dispatch(getLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(getLoading(false));
    }
  };

export const fetchSimplifiedExternalServices = () => async () => {
  try {
    dispatch(getLoading(true));

    const response = await DataService.getData(API_SIMPLIFIED_EXTERNAL_SERVICES());

    const options = response.map((item: { name: string; id: string }) => ({
      label: item.name,
      id: item.id,
    }));

    dispatch(setSimplifiedExternalServices(options));
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(getLoading(false));
  }
};

export const fetchCreateExternalService = (data: ICreateExternalServiceReqBody) => async () => {
  try {
    dispatch(getLoading(true));

    const response = await DataService.postStatus(API_EXTERNAL_SERVICES(), data);

    await dispatch(setExternalServiceSuccess(response?.data));

    dispatch(getLoading(false));

    if (response?.status === 200) {
      dispatch(setIsExternalServicesCreated(true));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      dispatch(hasError(error));
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error?.response?.data,
          },
        }),
      );
      dispatch(getLoading(false));
    }
  }
};

export const fetchExternalServiceFields = (id: string, fields: IExternalServiceField[]) => async () => {
  try {
    dispatch(getLoading(true));

    await DataService.post(
      API_EXTERNAL_SERVICES_FIELDS(id),
      fields.map((item: IExternalServiceField) => {
        item.externalServiceId = id;

        return item;
      }),
    );

    dispatch(setExternalServiceFieldsSuccess(JSON.parse(JSON.stringify(fields))));

    dispatch(getLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(getLoading(false));
  }
};

export const fetchExternalServiceResponseFields = (id: string, responseFields: IResponseFields[]) => async () => {
  try {
    dispatch(getLoading(true));

    await DataService.post(
      API_EXTERNAL_SERVICES_RESPONSE_FIELDS(id),
      responseFields.map((item: IResponseFields) => ({
        id: 0,
        externalServiceId: id,
        templateField: item.fieldName,
        parentField: '',
        validator: 0,
        validatorSettings: [],
      })),
    );

    dispatch(getLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(getLoading(false));
  }
};

export const fetchUpdateExternalService =
  (data: IExternalServiceUpdateReqBody, externalServiceId: string) => async () => {
    try {
      dispatch(setExternalServiceUpdateLoading(true));

      const response = await DataService.putStatus(API_EXTERNAL_SERVICES(externalServiceId), data);

      if (response?.status === 200) {
        dispatch(
          viewsMiddleware.setToastNotificationPopUpState({
            open: true,
            props: {
              severityType: SeveritiesType.success,
              description: '3rd party APIs successfully updated',
            },
          }),
        );
      }

      dispatch(setExternalServiceUpdateLoading(false));
    } catch (error) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: '3rd party APIs has not been updated',
          },
        }),
      );
      dispatch(hasError(error));
      dispatch(setExternalServiceUpdateLoading(false));
    }
  };

export const fetchGetExternalServiceFields = (externalServiceId: string) => async () => {
  try {
    const response = await DataService.getData(API_EXTERNAL_SERVICES(externalServiceId));

    dispatch(setExternalServiceFieldsSuccess(response.fields));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const fetchUpdateExternalServiceFields =
  (data: IExternalServiceField[], externalServiceId: string) => async () => {
    try {
      dispatch(getLoading(true));
      await DataService.post(API_EXTERNAL_SERVICES_FIELDS(externalServiceId), data);
      dispatch(externalServicesMiddleware.fetchGetExternalServiceFields(externalServiceId));
      dispatch(externalServicesMiddleware.fetchExternalServiceDetailsById(externalServiceId ?? ''));
      dispatch(getLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(getLoading(false));
    }
  };

export const fetchUpdateExternalServiceResponseFields =
  (data: IExternalServiceResponseField[], externalServiceId: string) => async () => {
    try {
      dispatch(getLoading(true));
      await DataService.post(API_EXTERNAL_SERVICES_RESPONSE_FIELDS(externalServiceId), data);

      dispatch(getLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(getLoading(false));
    }
  };

export const fetchExternalServiceDetailsById = (id: string) => async () => {
  try {
    dispatch(setExternalServiceDetailsLoading(true));

    const { ...response } = await DataService.getData(API_EXTERNAL_SERVICES(id));

    await dispatch(setExternalServiceDetailsSuccess(response));
    dispatch(setExternalServiceFieldsSuccess(response.fields));
    dispatch(setExternalServiceDetailsLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setExternalServiceDetailsLoading(false));
  }
};

export const fetchFieldByDataTypeExternalServices = (XML: string) => async () => {
  try {
    dispatch(setFieldByDataTypeExternalServicesLoading(true));

    if (!XML) {
      return;
    }

    const { data: response } = await DataService.post(API_FIELDS(), {
      dataFieldType: 2,
      dataTemplate: `${XML}`,
      entityId: '0',
    });

    if (response && response.fields) {
      dispatch(setExternalServiceFieldsSuccess(response.fields));
    }
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
  } finally {
    dispatch(setFieldByDataTypeExternalServicesLoading(false));
  }
};

export const fetchResponseFieldByDataTypeExternalServices = (XML: string) => async () => {
  try {
    dispatch(setServiceFieldByDataTypeExternalServicesLoading(true));

    if (!XML) {
      return;
    }

    const { data: response } = await DataService.post(API_FIELDS(), {
      dataFieldType: 3,
      dataTemplate: `${XML}`,
      entityId: '0',
    });

    if (response && response.fields && response.fields?.length) {
      await dispatch(setExternalServiceResponseFieldsSuccess(response.fields));
    }
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
  } finally {
    dispatch(setServiceFieldByDataTypeExternalServicesLoading(false));
  }
};

export const fetchClearExternalServiceFields = () => async () => {
  try {
    dispatch(hasError(null));
    dispatch(setExternalServiceFieldsSuccess(null));
    dispatch(setExternalServiceResponseFieldsSuccess(null));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export const fetchIsExternalServiceCreated = (isExternalServiceCreated: boolean) => async () => {
  try {
    dispatch(hasError(null));
    dispatch(setIsExternalServicesCreated(isExternalServiceCreated));
    dispatch(setExternalServiceResponseFieldsSuccess(null));
  } catch (error) {
    dispatch(setIsExternalServicesCreated(isExternalServiceCreated));
    dispatch(hasError(error));
  }
};

export const fetchDeleteExternalService =
  (id: string, pageNumber = 1, pageSize = 10, sortOrderKey?: string, sortOrderValue?: boolean) =>
  async () => {
    try {
      dispatch(setExternalServiceDeleteLoading(true));
      await DataService.delete(API_EXTERNAL_SERVICES(id));

      const responseUpdatedExternalServices = await DataService.getData(API_EXTERNAL_SERVICES(), {
        status: -1,
        pageNumber,
        pageSize,
        'SortOrder.Key': sortOrderKey,
        'sortOrder.Value': sortOrderValue,
      });

      responseUpdatedExternalServices.data?.forEach((item: IExternalService) => {
        item.updatedDate = moment(item.updatedDate).format('MMM D, yyyy HH:mm');
        item.createdDate = moment(item.createdDate).format('MMM D, yyyy HH:mm');
      });
      dispatch(setExternalServiceListSuccess(responseUpdatedExternalServices.data));
      dispatch(setExternalServiceListCount(responseUpdatedExternalServices.itemsCount));
      dispatch(setExternalServiceDeleteLoading(false));
    } catch (error) {
      dispatch(hasError(error));
    } finally {
      dispatch(viewsMiddleware.closeModal(ModalName.ConfirmExternalServiceDeleteModal));
      dispatch(setExternalServiceDeleteLoading(false));
    }
  };

export const fetchGetExternalServicesMatchingValues = (id: string, campaignId: string) => async () => {
  try {
    dispatch(setExternalServiceMatchingCampaignFieldsLoading(true));

    const externalServiceMatchingCampaignFields = await DataService.getData(API_EXTERNAL_SERVICES_CAMPAIGN_FIELDS(id), {
      campaignId,
    });

    dispatch(setExternalServiceCampaignFieldsMatchingValues(externalServiceMatchingCampaignFields));
    dispatch(setExternalServiceMatchingCampaignFieldsLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setExternalServiceMatchingCampaignFieldsLoading(false));
  }
};

export const fetchCreateExternalServicesMatchingValues =
  (id: string, campaignId: string, data: IExternalServiceCampaignFieldsMatchingCreate[]) => async () => {
    try {
      dispatch(setExternalServiceMatchingCampaignFieldsLoading(true));
      await DataService.post(API_EXTERNAL_SERVICES_CAMPAIGN_FIELDS(id), data);
      await dispatch(fetchGetExternalServicesMatchingValues(id, campaignId));
      dispatch(setExternalServiceMatchingCampaignFieldsLoading(false));
    } catch (error) {
      dispatch(hasError(error));
      dispatch(setExternalServiceMatchingCampaignFieldsLoading(false));
    }
  };

export const fetchExternalServicesCampaignFieldsAutoMatch = (id: string, campaignId: string) => async () => {
  try {
    dispatch(setExternalServicesCampaignFieldsAutoMatchLoading(true));

    const externalServicesCampaignFieldsAutoMatchResponse = await DataService.post(
      API_EXTERNAL_SERVICES_CAMPAIGN_FIELDS(id, `/auto-match?campaignId=${campaignId}`),
      {},
    );

    dispatch(setExternalServiceCampaignFieldsAutoMatchValues(externalServicesCampaignFieldsAutoMatchResponse));
    dispatch(setExternalServicesCampaignFieldsAutoMatchLoading(false));
  } catch (error) {
    dispatch(hasError(error));
    dispatch(setExternalServicesCampaignFieldsAutoMatchLoading(false));
  }
};

const emptyTheExternalServiceIntegrationFields = () => async () => {
  dispatch(setExternalServiceCampaignFieldsMatchingValues(null));
  dispatch(setExternalServiceCampaignFieldsAutoMatchValues(null));
};

export default {
  fetchExternalServicesList,
  fetchSimplifiedExternalServices,
  fetchCreateExternalService,
  fetchExternalServiceFields,
  fetchExternalServiceResponseFields,
  fetchUpdateExternalService,
  fetchUpdateExternalServiceFields,
  fetchUpdateExternalServiceResponseFields,
  fetchExternalServiceDetailsById,
  fetchFieldByDataTypeExternalServices,
  fetchResponseFieldByDataTypeExternalServices,
  fetchClearExternalServiceFields,
  fetchDeleteExternalService,
  fetchGetExternalServicesMatchingValues,
  fetchCreateExternalServicesMatchingValues,
  fetchExternalServicesCampaignFieldsAutoMatch,
  fetchIsExternalServiceCreated,
  fetchGetExternalServiceFields,
  emptyTheExternalServiceIntegrationFields,
};
