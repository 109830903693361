import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.systemFields;

const systemFieldsList = createSelector([selector], (state) => state.systemFieldsList);

const isLoading = createSelector([selector], (state) => state.isLoading);

export default {
  systemFieldsList,
  isLoading,
};
