import { ReactNode } from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';

export interface ActionItemProps {
  title: string;
  icon: ReactNode;
  action: () => void;
}

export interface ActionProps {
  items: ActionItemProps[];
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: (id: HTMLButtonElement | null) => void;
  id: string | null;
}

const Action = ({ items, anchorEl, setAnchorEl, id }: ActionProps) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={id as string}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <List>
        {items?.map((item) => (
          <ListItem key={item.title} disablePadding onClick={item.action}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="h5" sx={{ whiteSpace: 'nowrap' }}>
                    {item.title}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default Action;
