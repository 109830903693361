import { useCallback } from 'react';

import { dispatch } from '../../../../redux/hooks';
import { settingsMiddleware } from '../../../../redux/slices/settings';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useTwoFactorAuthenticationDisableModal = (setToggle: (value: boolean) => void) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.TwoFactorAuthenticationDisableModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(settingsMiddleware.fetchDisableVerification());
    setToggle(false);
  }, []);

  return {
    onClose,
    onConfirm,
  };
};

export default useTwoFactorAuthenticationDisableModal;
