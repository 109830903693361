import { createSlice } from '@reduxjs/toolkit';
import reducers from 'redux/slices/lists/reducers';

import { getInitialState } from './initialState';

const slice = createSlice({
  name: 'lists',
  initialState: getInitialState(),
  reducers,
});

export default slice;
