import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';
import { Action } from 'redux';

import { IOpenedAlert, IOpenedModal, ModalName, RedirectionProps, ViewsProps } from './initialState';

const createReducer = <T extends SliceCaseReducers<ViewsProps>>(reducer: T) => ({ ...reducer });

export interface IAction<P> extends Action<string> {
  payload: P;
}

const reducers = createReducer({
  addModalToList<P>(state: ViewsProps, action: IAction<IOpenedModal<P>>) {
    state.modals.push(action.payload);
  },
  removeModalFromList(state: ViewsProps, action: IAction<ModalName>) {
    state.modals = state.modals.filter((modal) => modal.name !== action.payload);
  },
  setRedirection(state, action: IAction<RedirectionProps>) {
    state.redirection = action.payload;
  },
  updateToastNotificationState<P>(state: ViewsProps, action: IAction<IOpenedAlert<P>>) {
    state.toastNotificationPopUp = action.payload ? action.payload : { open: false, props: {} };
  },
});

export default reducers;
