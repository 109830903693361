import { SyntheticEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Fade, Grow, IconButton, Slide, SlideProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { snackbarMiddleware } from 'redux/slices/snackbar';
import { KeyedObject } from 'types';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { snackbarSelector } from '../../redux/slices/snackbar';
import { CustomButton } from '../shared';

const TransitionSlideLeft = (props: SlideProps) => {
  const { children } = props;

  return (
    <Slide {...props} direction="left">
      {children}
    </Slide>
  );
};

const TransitionSlideUp = (props: SlideProps) => {
  const { children } = props;

  return (
    <Slide {...props} direction="up">
      {children}
    </Slide>
  );
};

const TransitionSlideRight = (props: SlideProps) => {
  const { children } = props;

  return (
    <Slide {...props} direction="right">
      {children}
    </Slide>
  );
};

const TransitionSlideDown = (props: SlideProps) => {
  const { children } = props;

  return (
    <Slide {...props} direction="down">
      {children}
    </Slide>
  );
};

const GrowTransition = (props: SlideProps) => {
  const { children } = props;

  return <Grow {...props}>{children}</Grow>;
};

const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade,
};

const Snackbar = () => {
  const snackbar = useAppSelector(snackbarSelector.snackbar);
  const { actionButton, anchorOrigin, alert, close, message, open, transition, variant } = snackbar;

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(snackbarMiddleware.fetchCloseSnackbar());
  };

  return (
    <>
      {variant === 'default' && (
        <MuiSnackbar
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
          TransitionComponent={animation[transition]}
          action={
            <>
              <CustomButton size="small" onClick={handleClose}>
                UNDO
              </CustomButton>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      )}

      {variant === 'alert' && (
        <MuiSnackbar
          TransitionComponent={animation[transition]}
          anchorOrigin={anchorOrigin}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            variant={alert.variant}
            color={alert.color}
            action={
              <>
                {actionButton && (
                  <CustomButton size="small" onClick={handleClose}>
                    UNDO
                  </CustomButton>
                )}
                {close && (
                  <IconButton
                    sx={{ color: 'background.paper', marginTop: '-3px' }}
                    size="small"
                    aria-label="close"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            }
            sx={{
              ...(alert.variant === 'outlined' && {
                bgcolor: 'background.paper',
              }),
            }}
          >
            {message}
          </Alert>
        </MuiSnackbar>
      )}
    </>
  );
};

export default Snackbar;
