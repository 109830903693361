import { IDuplicatedEmailNotifications, IEmailNotificationsSettings, IEmailNotificationTypes } from '@types';

export enum NotificationType {
  None = 0,
  CreateManualReview = 1,
  AssigneManualReview = 2,
  UnassignedManualReview = 3,
  FundingApproved = 4,
  FundingRejected = 5,
  FundingAdded = 6,
  ManualReviewApproved = 7,
  ManualReviewRejected = 8,
  AssignFundingReview = 9,
  UnassignFundingReview = 10,
  StipulationsAdded = 11,
  SelfAssignManualReview = 12,
  SelfAssignFundingReview = 13,
}

export const createEmailNotificationsData = (response: IEmailNotificationsSettings[]) => {
  const notificationTypes: IEmailNotificationTypes = { manualReview: [], fundingRequest: [] };

  const manualReviewTypes = [
    NotificationType.CreateManualReview,
    NotificationType.AssigneManualReview,
    NotificationType.UnassignedManualReview,
    NotificationType.SelfAssignManualReview,
    NotificationType.StipulationsAdded,
    NotificationType.ManualReviewApproved,
    NotificationType.ManualReviewRejected,
  ];

  const fundingRequestTypes = [
    NotificationType.FundingAdded,
    NotificationType.AssignFundingReview,
    NotificationType.UnassignFundingReview,
    NotificationType.SelfAssignFundingReview,
    NotificationType.FundingApproved,
    NotificationType.FundingRejected,
  ];

  const duplicatedTypes: IDuplicatedEmailNotifications = {};

  response.forEach((item) => {
    if (manualReviewTypes.includes(item.type)) {
      const duplicatedType = notificationTypes.manualReview.find((el) => el.title === item.title);

      if (duplicatedType) {
        duplicatedTypes[duplicatedType.id] = item;
      } else {
        notificationTypes.manualReview.push(item);
      }
    } else if (fundingRequestTypes.includes(item.type)) {
      const duplicatedType = notificationTypes.fundingRequest.find((el) => el.title === item.title);

      if (duplicatedType) {
        duplicatedTypes[duplicatedType.id] = item;
      } else {
        notificationTypes.fundingRequest.push(item);
      }
    }
  });

  return { notificationTypes, duplicatedTypes };
};
