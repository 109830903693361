import { createSlice } from '@reduxjs/toolkit';
import reducers from 'redux/slices/leads/reducers';

import { getInitialState } from './initialState';

const slice = createSlice({
  name: 'leads',
  initialState: getInitialState(),
  reducers,
});

export default slice;
