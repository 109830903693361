import { SyntheticEvent, useEffect, useState } from 'react';
import { CloseOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { ISimplifiedList } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../../redux/slices/campaigns';
import { CustomButton } from '../../shared';
import Loading from '../../UI/Loading';

import useConfirmCampaignCloneControls from './hooks/useConfirmCampaignCloneControls';

export interface IConfirmCampaignCloneModalProps {
  mode: string;
}

const ConfirmCampaignCloneModal = ({ mode }: IConfirmCampaignCloneModalProps) => {
  const [cloneId, setCloneId] = useState<string | null>(null);

  const currentCampaignById = useAppSelector(campaignsSelector.currentCampaignById);
  const campaigns = useAppSelector(campaignsSelector.simplifiedCampaigns);
  const createCampaignsWithStepper = useAppSelector(campaignsSelector.createCampaignsWithStepper);
  const isCampaignsListLoading = useAppSelector(campaignsSelector.isCampaignsListLoading);

  const [campaignOptions, setCampaignOptions] = useState<ISimplifiedList[] | null>(null);

  const { onConfirm, onClose } = useConfirmCampaignCloneControls(cloneId);

  useEffect(() => {
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
  }, []);

  useEffect(() => {
    if (campaigns?.length) {
      setCampaignOptions(
        campaigns?.filter(
          (item: ISimplifiedList) =>
            item.id !== (mode === 'EDIT' ? currentCampaignById?.id : createCampaignsWithStepper?.id),
        ) ?? [],
      );
    }
  }, [campaigns]);

  return (
    <Dialog data-test="confirm-campaign-clone-modal" open onClose={onClose}>
      <Stack width="400px">
        <DialogTitle>
          <Typography textAlign="center" fontSize="20px">
            Product
          </Typography>
        </DialogTitle>
        <Box
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
        >
          <IconButton data-test="confirm-campaign-clone-modal-button-x" onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <DialogActions sx={{ padding: '10px' }}>
          <Stack width="100%" alignItems="center" spacing={3}>
            <Box width="80%">
              {isCampaignsListLoading ? (
                <Loading height={100} />
              ) : (
                // TODO Create Separate component Autocomplete
                <Autocomplete
                  data-test="confirm-campaign-clone-modal-dropdown-campaigns"
                  options={campaignOptions ?? []}
                  getOptionLabel={(option) => option?.name ?? option}
                  onChange={(event: SyntheticEvent, newValue: ISimplifiedList | null) => {
                    setCloneId(newValue?.id ?? null);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: 0,
                    },
                  }}
                  renderInput={(params) => <TextField {...params} value={cloneId} />}
                />
              )}
            </Box>
            <Box width="100%" textAlign="center">
              <CustomButton
                data-test="confirm-campaign-clone-modal-button-clone"
                disabled={!cloneId}
                onClick={onConfirm}
              >
                Clone
              </CustomButton>
            </Box>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmCampaignCloneModal;
