import { useNavigate } from 'react-router-dom';
import { Box, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IGeneralInformationCellData } from '@types';

import { renderValue } from '../../helpers/renderValue';

interface IGeneralInformationSectionItemProps {
  item: IGeneralInformationCellData;
}

const TitleTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { title, children } = props;

  return (
    <Tooltip title={title} placement="top" classes={{ popper: className }}>
      {children}
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F4F7FE',
    border: '1px solid #707692',
    color: '#687585',
    fontSize: 14,
    lineHeight: '17px',
  },
}));

const GeneralInformationSectionItem = ({ item }: IGeneralInformationSectionItemProps) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Typography sx={{ fontSize: '14px', color: '#707EAE', fontWeight: 500, lineHeight: '24px' }}>
        {item.title}
      </Typography>
      <Box>
        <TitleTooltip title={item.value?.length > 20 ? item.value : ''}>
          <Typography
            sx={{
              fontSize: '14px',
              color: item.color ?? '#454256',
              fontWeight: 700,
              lineHeight: '17px',
              wordBreak: 'break-all',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '90%',
              overflow: 'hidden',
              cursor: item.link ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (item.link) {
                navigate(item.link);
              }
            }}
          >
            {renderValue(item.value)}
          </Typography>
        </TitleTooltip>
      </Box>
    </Box>
  );
};

export default GeneralInformationSectionItem;
