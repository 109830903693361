import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { Price } from '../../lib/constants/format';

import classes from '../../assets/scss/locations.module.scss';

const TopLocations = ({ data }: { data?: { [key: string]: string }[] }) => (
  <TableContainer>
    <Table className={classes.root}>
      <TableHead>
        <TableRow className={classes.header}>
          <TableCell>
            <Typography className={classes.headerTitle} align="left">
              State
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.headerTitle} align="center">
              Applications
            </Typography>
          </TableCell>
          <TableCell>
            <Typography className={classes.headerTitle} align="center">
              % Accepted Applications
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          ? data?.map((item: { [key: string]: string }, index) => (
              <TableRow
                data-test={`dashboard-page-top-locations-table-id-${index}`}
                key={item?.name}
                sx={{
                  cursor: 'default !important',
                }}
                className={classes.item}
              >
                <TableCell className={classes.itemTitle} align="left">
                  <Typography className={classes.itemTitle}>
                    <Typography component="span" className={classes.circle} sx={{ background: item.color }} />
                    {item?.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.itemTitle} align="center">
                    {Price()?.format(Number(item?.leadsCount))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.itemTitle} align="center">
                    {item?.acceptedLeadsRate}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TopLocations;
