import { useEffect, useState } from 'react';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Menu, MenuItem, TextField } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

export interface IOption {
  key: string | null;
  value: string;
  prefix?: () => JSX.Element;
}

export interface DropdownButtonProps {
  data: IOption[];
  defaultValue?: IOption;
  handleItemChoose?: (value: IOption) => void;
  buttonSx?: SystemCssProperties<Theme>;
}

const DropdownSearch = styled(TextField)(() => ({
  '.MuiInput-input': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18.15px',
  },
  '& .MuiInput-input::placeholder': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '18.15px',
    color: 'rgba(163, 174, 208, 1)',
  },
}));

const DropdownSearchIcon = styled(SearchIcon)(() => ({
  color: 'rgba(163, 174, 208, 1)',
  marginLeft: '18px',
  width: '19.6px',
  height: '18px',
}));

const DropdownButton = ({ data, defaultValue, handleItemChoose, buttonSx }: DropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [filteredData, setFilteredData] = useState<IOption[]>([]);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (option: IOption) => {
    setSelectedOption(option);

    if (handleItemChoose) {
      handleItemChoose(option);
    }
  };

  const handleSearch = (event: any) => {
    const { value } = event.target;

    setFilteredData(data.filter((el) => el.value.toLowerCase().includes(value.toLowerCase())));
  };

  const onMenuItemClick = (option: IOption) => {
    handleClose();
    handleChoose(option);
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  return (
    <Box>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={buttonSx ?? { color: '#454256', fontWeight: 700, fontSize: '15px', backgroundColor: '#F4F7FE' }}
      >
        {selectedOption?.value}
        {selectedOption?.key === null && <PersonAddOutlinedIcon sx={{ marginLeft: '20px', color: '#7B61FF' }} />}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <DropdownSearch
          variant="standard"
          autoFocus
          onChange={handleSearch}
          placeholder="Search..."
          InputProps={{ startAdornment: <DropdownSearchIcon /> }}
        />
        <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
          {filteredData.map((option: IOption) => (
            <MenuItem sx={{ margin: '4px 0' }} key={option.key} onClick={() => onMenuItemClick(option)}>
              {option.prefix?.()}
              {option.value}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export default DropdownButton;
