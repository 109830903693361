/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import MoreVertIcon from '@material-ui/icons/MoreVert';
// eslint-disable-next-line import/no-extraneous-dependencies
import ClickAwayListener from '@mui/base/ClickAwayListener';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
  Box,
  IconButton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import TableNoFilteredData from 'components/shared/TableNoFilteredData';
// import RevertIcon from 'components/shared/RevertIcon';
import { ROWS_PER_PAGE_OPTIONS } from 'lib/constants';

import { renderValue } from '../../helpers/renderValue';
import { dispatch } from '../../redux/hooks';
import { menuMiddleware } from '../../redux/slices/menu';
import { ITag } from '../../redux/slices/tags/initialState';
import { userManagementMiddleware } from '../../redux/slices/userManagement';
import { IRolesList } from '../../redux/slices/userManagement/initialState';
import { ArrangementOrder, Status, TableHeader } from '../../types';
import { CustomButton } from '../shared';
import FundsDropdownButton from '../shared/FundsDropdownButton';
import { StyledTableLink } from '../shared/StyledComponents';
import { StatusBadgeTitle } from '../StatusBadgeTitle';
import TagsSelect from '../Tags/TagsSelect';
import Action, { ActionItemProps } from '../UI/Action';

import CustomTablePagination from './CustomDynamicTableFooter';

export interface IActionMenuItem {
  title: string;
  icon: ReactNode;
  action: (id: string) => void;
}

interface CustomDynamicTableDenseProps<T, E> {
  statusMode?: 'BADGE' | null;
  isEditable?: boolean;
  data: T;
  error?: E;
  action?: {
    type: 'Link' | 'Click';
    linkKeys?: string[];
    callback?: () => void;
    main?: string;
    id?: string | number;
  } | null;
  hover?: boolean;
  editButton: boolean;
  isLoading?: boolean;
  setTableData: Dispatch<SetStateAction<T>>;
  order: string;
  orderBy: string;
  page?: number;
  isMoreDropDown?: boolean;
  rowsPerPage?: number;
  rowKey?: string;
  handleRequestSort: (event: React.SyntheticEvent, property: string) => void;
  createSortHandler?: (item: string) => (e: React.SyntheticEvent) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
  handleSort?: (event: React.SyntheticEvent, property: string, newOrder: boolean) => void;
  sortTableData: T;
  setOrder: Dispatch<SetStateAction<ArrangementOrder>>;
  setOrderBy: Dispatch<SetStateAction<string>>;
  isDeleted?: boolean;
  handleCustomCallback?: (cell: string, row?: Record<string, unknown>) => void;
  handleChangeStatus?: (row: Record<string, string>, checked: number) => void;
  handleDeleteAction?: (row: Record<string, string>) => void;
  isAction?: boolean;
  isDropdownButtons?: boolean;
  selectedTableHeaders: TableHeader[];
  actionMenuItems?: IActionMenuItem[] | ((entity: any) => IActionMenuItem[]);
  paginationCount?: number;
  actionState?: string;
  setActionState?: Dispatch<SetStateAction<string>>;
  handleRowClick?: (id: string) => void;
  handleViewClick?: (id: number) => void;
  dataTest?: string;
  dataTestId?: string;
  Statuses?: { [key: string | number]: Status };
  handleChangePageWithProps?: (row: Record<string, string>) => void;
  tags?: ITag[];
  tagsPage?: number;
  onTagSelect?: () => void;
  tagsKey?: string;
  handleSortCount?: (
    header: string,
    handleSort: (event: React.SyntheticEvent, property: string, newOrder: boolean) => void,
    e: React.SyntheticEvent,
    getCorrectTableHeader: (value: string) => string,
    rowKey: string,
  ) => void;
  fundersList?: {
    id: string | number;
    value: string;
  }[];
  changeStatusPermission?: Permissions;
  noFoundHeader?: string;
  noFoundBody?: string;
  internalData?: boolean;
}

// TODO : We can refactor CustomDynamicTableDense later
const CustomDynamicTableDense = ({
  statusMode,
  data: tableData,
  isDeleted = true,
  isAction = true,
  order,
  isEditable,
  orderBy,
  page = -1,
  isMoreDropDown,
  action,
  hover = true,
  rowsPerPage = -1,
  rowKey = 'id',
  handleChangeRowsPerPage,
  handleChangePage,
  handleSort,
  editButton,
  sortTableData,
  handleChangeStatus,
  handleDeleteAction,
  handleCustomCallback,
  selectedTableHeaders,
  actionMenuItems: moreOptions,
  actionState,
  setActionState,
  paginationCount,
  handleRowClick,
  handleViewClick,
  dataTest,
  dataTestId,
  Statuses,
  handleChangePageWithProps,
  tags,
  tagsPage,
  tagsKey = 'id',
  handleSortCount,
  fundersList,
  changeStatusPermission,
  noFoundHeader,
  noFoundBody,
  internalData = false,
}: CustomDynamicTableDenseProps<Record<string, string>[], Record<string, string>>) => {
  const navigate = useNavigate();
  const showActionColumn = !!editButton || !!isEditable || !!isMoreDropDown || !!isDeleted;

  const tableRef = useRef<HTMLDivElement>(null);
  const [tabHeight, setTableHeight] = useState(window.innerHeight);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const id = anchorEl ? 'simple-popover' : null;

  useEffect(() => {
    const handleScroll = () => {
      const tableRect = tableRef.current?.getBoundingClientRect();
      const height = window.innerHeight - (tableRect?.top ?? 0) - 100;

      setTableHeight(height > 0 ? height : 0);
    };

    const handleFilterToggleClick = () => {
      setTableHeight(0);
      setTimeout(handleScroll, 0);
    };

    setTimeout(handleScroll, 0);
    window.addEventListener('resize', handleScroll);

    const toggleButton = document.getElementById('toggle-filters');

    toggleButton?.addEventListener('click', handleFilterToggleClick);

    return () => {
      window.removeEventListener('resize', handleScroll);
      toggleButton?.removeEventListener('click', handleFilterToggleClick);
    };
  }, []);

  const handleCustomClick = (row: { [p: string]: string }, tableHeader: TableHeader) => {
    if (handleCustomCallback) {
      handleCustomCallback(row[tableHeader.value], row);
    }
  };

  const handleCellClick = (
    e: SyntheticEvent,
    row: Record<string, string>,
    actionMenuItems: IActionMenuItem[] | undefined,
    tableHeaderName: string,
  ) => {
    if (action?.type === 'Link' && tableHeaderName !== 'tags') {
      e.preventDefault();

      if (actionMenuItems) {
        actionMenuItems[0].action(row.id);

        return;
      }

      navigate(
        `${
          action?.main
            ? `/${action?.main}/${row[action?.linkKeys ? action?.linkKeys[0] : 'id']}`
            : `${row[action?.linkKeys ? action?.linkKeys[0] : 'id']}`
        }`,
      );
    }
  };

  const getCorrectTableHeader = (value: string) => {
    if (value === 'statusName' && selectedTableHeaders.some((e) => e.value === 'status')) {
      return 'status';
    }

    return value;
  };

  // if (
  //   (tableData && tableData.length === 0 && !internalData) ||
  //   (sortTableData && sortTableData.length === 0 && internalData)
  // ) {
  //   return <TableNoFilteredData noFoundHeader={noFoundHeader} noFoundBody={noFoundBody} />;
  // }

  return (
    <>
      <TableContainer
        ref={tableRef}
        className="custom-dynamic-table"
        id={`${dataTest}-table`}
        style={{ height: tabHeight }}
        sx={{ overflow: 'auto', width: '100% !important' }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          data-test={`${dataTest}-table`}
          stickyHeader
          sx={{
            '& .MuiTableRow-root .MuiTableCell-root': {
              height: '45px',
            },
          }}
        >
          <TableHead>
            <TableRow data-test={`${dataTest}-table-header`}>
              {selectedTableHeaders?.map((selectedTableHeader: TableHeader, index: number) => (
                <Permission key={selectedTableHeader.value} permission={selectedTableHeader.permission}>
                  <TableCell
                    className={selectedTableHeader.fixedColumn ? 'fixed-column' : ''}
                    key={selectedTableHeader.value}
                    sx={{
                      padding: 1,
                      paddingLeft: index ? 1 : 0,
                    }}
                    align={selectedTableHeader.align}
                  >
                    <TableSortLabel
                      data-test={
                        !selectedTableHeader.sortDisabled
                          ? `${dataTest}-table-sort-header-${selectedTableHeader.title}`
                          : null
                      }
                      disabled={selectedTableHeader.sortDisabled}
                      active={orderBy === getCorrectTableHeader(selectedTableHeader.value)}
                      direction={
                        orderBy === getCorrectTableHeader(selectedTableHeader.value) ? (order as 'asc' | 'desc') : 'asc'
                      }
                      onClick={(e) => {
                        if (handleSortCount && handleSort) {
                          handleSortCount(selectedTableHeader.value, handleSort, e, getCorrectTableHeader, rowKey);
                        }
                      }}
                    >
                      <>
                        {selectedTableHeader.title}
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      </>
                    </TableSortLabel>
                  </TableCell>
                </Permission>
              ))}
              {isAction ? (
                <TableCell align="center" sx={{ padding: 1, width: '1px' }}>
                  Action
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {(tableData && tableData.length !== 0 && !internalData) ||
            (sortTableData && sortTableData.length !== 0 && internalData) ? (
              sortTableData?.map((row) => {
                const actionMenuItems = typeof moreOptions === 'function' ? moreOptions(row) : moreOptions;

                return (
                  <TableRow
                    data-test={
                      dataTestId ? `${dataTest}-table-id-${row?.[dataTestId]}` : `${dataTest}-table-id-${row?.id}`
                    }
                    hover={!!handleRowClick || action?.type === 'Link' || hover}
                    sx={{
                      cursor:
                        !!handleChangePageWithProps || !!handleRowClick || action?.type === 'Link'
                          ? 'pointer'
                          : 'default !important',
                    }}
                    key={row[rowKey]}
                    {...(handleRowClick
                      ? {
                          onClick: (e: SyntheticEvent) => {
                            e.preventDefault();
                            handleRowClick(row.id);
                          },
                        }
                      : {})}
                    {...(handleChangePageWithProps
                      ? {
                          onClick: (e: SyntheticEvent) => {
                            e.preventDefault();
                            handleChangePageWithProps(row);
                          },
                        }
                      : {})}
                  >
                    {selectedTableHeaders?.map((tableHeader: TableHeader, index: number) => (
                      <Permission key={tableHeader.value} permission={tableHeader.permission}>
                        <TableCell
                          key={tableHeader.value}
                          className={tableHeader.fixedColumn ? 'fixed-column' : ''}
                          sx={{ padding: 1, pl: index ? 1 : 0 }}
                          align={tableHeader.align}
                          onClick={(e: SyntheticEvent) => handleCellClick(e, row, actionMenuItems, tableHeader.value)}
                        >
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {tableHeader.value === 'status' ? (
                            statusMode === 'BADGE' && Statuses ? (
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                width="max-content"
                              >
                                <StatusBadgeTitle Statuses={Statuses} status={row[tableHeader.value]} />
                                {/* {hasPermission(Permissions.revertApplication) && row.revert && ( should be opened after release
                            <IconButton sx={{ marginLeft: '3px' }} onClick={(e: SyntheticEvent) => e.stopPropagation()}>
                              <RevertIcon id={row.id} applicationId={row.applicationId} />
                            </IconButton>
                          )} */}
                              </Stack>
                            ) : (
                              <IconButton
                                sx={{ p: 0 }}
                                color="primary"
                                size="large"
                                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                              >
                                <Switch
                                  data-test={
                                    dataTestId
                                      ? `${dataTest}-table-id-change-status-button-${row?.[dataTestId]}`
                                      : `${dataTest}-table-id-change-status-button-${row?.id}`
                                  }
                                  value={row[tableHeader.value]}
                                  checked={!!row[tableHeader.value]}
                                  disabled={changeStatusPermission ? !hasPermission(changeStatusPermission) : false}
                                  onChange={(e) => {
                                    if (handleChangeStatus) {
                                      handleChangeStatus(row, e.target.checked ? 1 : 0);
                                    }
                                  }}
                                />
                              </IconButton>
                            )
                          ) : // eslint-disable-next-line no-nested-ternary
                          tableHeader.value === 'tags' && tags && tagsPage && Array.isArray(tags) ? (
                            <Box onClick={(e) => e.stopPropagation()}>
                              <TagsSelect
                                tags={tags}
                                page={tagsPage}
                                targetId={row[tagsKey]}
                                defaultValues={(row.tags as unknown as ITag[]) ?? []}
                              />
                            </Box>
                          ) : // eslint-disable-next-line no-nested-ternary
                          tableHeader.value === 'reviewedBy' && fundersList ? (
                            <Box onClick={(e) => e.stopPropagation()}>
                              <FundsDropdownButton
                                data={fundersList}
                                defaultValue={{ id: row.reviewerId, value: row.reviewedBy }}
                                fundId={row.id}
                                mode="list"
                                status={row.status}
                              />
                            </Box>
                          ) : // eslint-disable-next-line no-nested-ternary
                          action?.type === 'Click' && tableHeader.value === (action.id || 'id') ? (
                            <CustomButton variant="text" onClick={() => handleCustomClick(row, tableHeader)}>
                              {row[tableHeader.value]}
                            </CustomButton>
                          ) : // eslint-disable-next-line no-nested-ternary
                          tableHeader.value === 'name' && row.isSystemDefined ? (
                            <Box display="flex" alignItems="center" gap="8px">
                              <Typography>{row[tableHeader.value]}</Typography>
                              <Box
                                sx={{
                                  backgroundColor: 'rgba(149, 161, 177, 0.20)',
                                  padding: '0 6px',
                                  borderRadius: '3px',
                                  color: '#68707B',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                System Defined
                              </Box>
                            </Box>
                          ) : tableHeader.value === 'usersCount' ? (
                            <Box
                              component="span"
                              sx={{ color: '#7B61FF', cursor: 'pointer' }}
                              onClick={() => {
                                dispatch(userManagementMiddleware.changeRoleDeleteId(row as unknown as IRolesList));
                                dispatch(menuMiddleware.setActiveItem(['user-list']));
                                navigate('/user-list');
                              }}
                            >
                              {row[tableHeader.value]}
                            </Box>
                          ) : (
                            <Typography>{renderValue(row[tableHeader.value])}</Typography>
                          )}
                        </TableCell>
                      </Permission>
                    ))}
                    {showActionColumn && (
                      <TableCell align="center" sx={{ padding: 1 }}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ position: 'relative' }}
                        >
                          <>
                            {editButton ? (
                              <IconButton
                                sx={{ p: 0 }}
                                size="large"
                                onClick={(e: SyntheticEvent) => {
                                  e.stopPropagation();
                                }}
                              >
                                <EditOutlinedIcon />
                              </IconButton>
                            ) : null}
                            {isEditable ? (
                              <StyledTableLink to={row.id ? `${row.id}` : ''}>
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="primary"
                                  size="large"
                                  onClick={(e: SyntheticEvent) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </StyledTableLink>
                            ) : null}
                            {isMoreDropDown ? (
                              <ClickAwayListener
                                onClickAway={() => {
                                  if (setActionState) {
                                    setActionState('-1');
                                  }
                                }}
                              >
                                <Box>
                                  <IconButton
                                    sx={{ p: 0 }}
                                    aria-describedby={id as string}
                                    onClick={(e: SyntheticEvent) => {
                                      e.stopPropagation();

                                      if (setActionState) {
                                        setAnchorEl(e.currentTarget as HTMLButtonElement);
                                        setActionState((prev) => (prev === row.id ? '-1' : row.id));
                                      }
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  {actionState === row.id ? (
                                    <Action
                                      items={actionMenuItems as ActionItemProps[]}
                                      anchorEl={anchorEl}
                                      setAnchorEl={setAnchorEl}
                                      id={id}
                                    />
                                  ) : null}
                                </Box>
                              </ClickAwayListener>
                            ) : null}

                            {isDeleted ? (
                              <IconButton
                                sx={{ p: 0 }}
                                data-test={`${dataTest}-table-delete-button`}
                                color="inherit"
                                size="large"
                                onClick={(e: SyntheticEvent) => {
                                  e.stopPropagation();

                                  if (handleDeleteAction) {
                                    handleDeleteAction(row);
                                  }
                                }}
                              >
                                <DeleteOutlineOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />
                              </IconButton>
                            ) : null}
                          </>
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow data-test={`${dataTest}-table-no-data`} sx={{ cursor: 'default !important' }}>
                <TableCell colSpan={selectedTableHeaders.length} sx={{ borderBottom: 0 }}>
                  <TableNoFilteredData noFoundHeader={noFoundHeader} noFoundBody={noFoundBody} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {![page, rowsPerPage].includes(-1) && (
        <CustomTablePagination
          dataTest={dataTest ?? ''}
          count={paginationCount ?? tableData.length}
          page={page}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          sx={{
            margin: 20,
          }}
        />
      )}
    </>
  );
};

CustomDynamicTableDense.displayName = 'Small Rows Table';
/*
TODO:Make table custom date to redux dataTable action
TODO:Move table interface to full generic type for better type than this
TODO:Make JSX to full dynamic creation content
* */
export default CustomDynamicTableDense;
