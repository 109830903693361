import { useEffect, useState } from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IconSquareMinus } from '@tabler/icons';
import { ExtendedComponent } from 'components/ObjectReader';

import { LeadsLogsStatusBadgeTitle } from '../../components/StatusBadgeTitle';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';

export const LogsTable = ({ leadId }: { leadId: string | undefined }) => {
  const [content, setContent] = useState<string>('');

  const logs = useAppSelector(leadsSelector.logs);
  const logsLoading = useAppSelector(leadsSelector.logsLoading);

  useEffect(() => {
    if (leadId) {
      dispatch(leadsMiddleware.fetchLogs(leadId));
    }
  }, [leadId]);

  const handleExtendClick = (text: string) => {
    setContent(text);
  };

  if (logsLoading) {
    return <Loading />;
  }

  if (logs?.length === 0) {
    return (
      <div>
        <Title title="No Logs" />
      </div>
    );
  }

  if (content) {
    return (
      <ExtendedComponent
        content={content}
        onExtendClick={() => setContent('')}
        extended
        sxStyles={{
          width: '100%',
          maxHeight: '1110px',
          display: 'flex',
          justifyContent: 'flex-start',
          overflow: 'scroll',
          backgroundColor: '#F4F7FE',
          padding: '10px',
        }}
      />
    );
  }

  return (
    <Box sx={{ width: '100%', marginTop: '30px' }}>
      <Box sx={{ width: '100%', '& .MuiTableContainer-root': { padding: '0' } }}>
        <TableContainer component={Paper}>
          <Box
            sx={{
              border: '1px solid rgba(163, 174, 208, 0.7)',
              borderRadius: '16px',
              width: '100%',
              overflow: 'scroll',
            }}
          >
            <Table data-test="logs-table">
              <TableHead>
                <TableRow
                  sx={{
                    cursor: 'default !important',
                    '& .MuiTableCell-head': {
                      color: '#A3AED0 !important',
                      fontWeight: '400 !important',
                      fontSize: '15px !important',
                    },
                  }}
                >
                  <TableCell align="left">Workflow step</TableCell>
                  <TableCell align="left">Date and Time</TableCell>
                  <TableCell align="left">Posted</TableCell>
                  <TableCell align="left">Response</TableCell>
                  <TableCell align="center">Response Time (ms)</TableCell>
                  <TableCell>Response Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((item, index) => (
                  <TableRow
                    data-test={`leads-page-tab-logs-table-id-${index}`}
                    key={item.dateTime}
                    sx={{ verticalAlign: 'top', cursor: 'default !important' }}
                  >
                    <TableCell>
                      <Box display="flex" alignItems="center" gap="14px">
                        <IconSquareMinus />
                        {item.stepName}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" gap="7px">
                        {/* TODO: fix after BE fix */}
                        {/* {item.up ? ( */}
                        <ArrowCircleUpIcon sx={{ rotate: '0.25turn' }} color="success" />
                        {/* ) : ( */}
                        {/*  <ArrowCircleUpIcon style={{ rotate: '0.75turn' }} color="primary" /> */}
                        {/* )} */}
                        {item.dateTime}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {item.referenceRequestData?.length && item.referenceRequestData !== '{}' ? (
                        <Box
                          sx={{
                            backgroundColor: '#F4F7FE',
                            borderRadius: '5px',
                            padding: '7px',
                            maxWidth: '220px',
                            maxHeight: '310px',
                          }}
                        >
                          <ExtendedComponent
                            content={item.referenceRequestData}
                            onExtendClick={() => handleExtendClick(item?.referenceRequestData)}
                            sxStyles={{
                              maxHeight: '300px',
                              maxWidth: '200px',
                              minHeight: '100px',
                              minWidth: '190px',
                              overflowY: 'scroll',
                            }}
                          />
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item.referenceResponseData?.length && item.referenceResponseData !== '{}' ? (
                        <Box
                          sx={{
                            backgroundColor: '#F4F7FE',
                            borderRadius: '5px',
                            padding: '7px',
                            maxWidth: '220px',
                            maxHeight: '310px',
                          }}
                        >
                          <ExtendedComponent
                            content={item.referenceResponseData}
                            onExtendClick={() => handleExtendClick(item?.referenceResponseData)}
                            sxStyles={{
                              maxHeight: '300px',
                              maxWidth: '200px',
                              minHeight: '100px',
                              minWidth: '190px',
                              overflowY: 'scroll',
                            }}
                          />
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                        {item.executionTime}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <LeadsLogsStatusBadgeTitle status={item.status} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      </Box>
    </Box>
  );
};
