import { IValidationTreeModel } from '../components/dataModels/validationTree';
import { IValidationTreeFields, ValidationTreeActionId } from '../types';

export interface ICreateShowingValidationTreeListProps {
  singleValidationTree: IValidationTreeModel[];
  validationTree: { id: number; name: string };
  validationTreesById: {
    createdById: number;
    createdByName: string;
    createdDate: string;
    id: number;
    name: string;
    selected: boolean;
    updatedById: string;
    updatedByName: string;
    updatedDate: string;
  }[];
}

export const CreateValidationTreeFields = (data: IValidationTreeFields[]) => {
  if (data?.length) {
    return data.map((item) => ({
      id: item.id,
      FilterType: item.actionType,
      Field: item.actionSettings?.find((el: { name: string }) => el.name === ValidationTreeActionId[item.actionType])
        ?.value,
      Condition: item.actionSettings?.find((el: { name: string }) => el.name === 'Condition')?.value,
      Value: item.actionSettings?.find((el: { name: string }) => el.name === 'Values')?.value,
      CampaignField:
        item.actionType === 2 &&
        item.actionSettings?.find((el: { name: string }) => el.name === 'CampaignFieldId')?.value,
      Action: item.actionSettings?.find((el: { name: string }) => el.name === 'ActionResult')?.value,
      CAP: '',
      Schedule: '',
      trees: item.trees,
      description: item.description,
      aanDescription: item.aanDescription,
      Expression: item.actionSettings?.find((el) => el.name === 'Expression')?.value,
      Reason: item.actionSettings?.find((el: { name: string }) => el.name === 'Reason')?.value,
      Name: item.actionSettings?.find((el: { name: string }) => el.name === 'ExternalServiceId')?.value,
      isActionActive: false,
      filters: item?.children?.map(
        (child: { actionSettings: { name: string; value: string | number }[]; id: number }) => ({
          Field: child.actionSettings?.find((el) => el.name === ValidationTreeActionId[item.actionType])?.value,
          Condition: child.actionSettings?.find((el) => el.name === 'Condition')?.value,
          Value: child.actionSettings?.find((el) => el.name === 'Values')?.value,
          Name: child.actionSettings?.find((el) => el.name === 'ExternalServiceId')?.value,
          Expression: child.actionSettings?.find((el) => el.name === 'Expression')?.value,
          Action: '',
          CampaignField:
            item.actionType === 2 &&
            child.actionSettings?.find((el: { name: string }) => el.name === 'CampaignFieldId')?.value,
          id: child.id,
        }),
      ),
    }));
  }

  return [];
};

export const createShowingValidationTreeList = ({
  validationTreesById,
  singleValidationTree,
  validationTree,
}: ICreateShowingValidationTreeListProps) => {
  const fullData = [...validationTreesById];

  fullData.shift();

  const returningData = fullData.map((el) => ({
    label: el.name,
    value: el.id,
    selected: el.selected,
    selectedId: singleValidationTree?.find((item) => item?.trees.includes(el.id))
      ? singleValidationTree?.find((item) => item.trees.includes(el.id))?.id
      : -1,
  }));

  return returningData.filter((item) => item.value !== validationTree.id);
};
