import { useCallback } from 'react';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';
import { IAddPromotionModalProps } from '../AddPromotionModal';

const useAddPromotionModal = ({ appendField }: IAddPromotionModalProps) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.AddPromotionModal));
  }, []);

  // TODO change 'any' after BE integration
  const onConfirm = useCallback((data: any) => {
    appendField(data);
    dispatch(viewsMiddleware.closeModal(ModalName.AddPromotionModal));
  }, []);

  return {
    onClose,
    onConfirm,
  };
};

export default useAddPromotionModal;
