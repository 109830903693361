import { Box, Dialog, DialogActions, DialogTitle, Grid, Stack, Typography } from '@mui/material';

import { IAffiliateProps } from '@types';

import { trimProperties } from '../../../helpers';
import { FormValues } from '../../../pages/Affiliates/Details';
import { dispatch, useAppSelector } from '../../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../../redux/slices/affiliates';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';

export interface IConfirmAffiliateDetailsSaveChangesModalProps {
  values: FormValues;
  affiliateId: string;
}

const ConfirmAffiliateDetailsSaveChangesModal = ({
  values,
  affiliateId,
}: IConfirmAffiliateDetailsSaveChangesModalProps) => {
  const isUpdateAffiliateChannelStatusLoading = useAppSelector(
    affiliatesSelector.isUpdateAffiliateChannelStatusLoading,
  );
  const isLoading = useAppSelector(affiliatesSelector.isLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateDetailsSaveChangesModal));
  };

  const onConfirm = () => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateByName(trimProperties(values, ['name']) as IAffiliateProps, affiliateId),
    );
  };

  return (
    <Dialog data-test="confirm-affiliate-details-save-changes-modal" open onClose={onClose}>
      <Stack sx={{ width: '350px' }}>
        <DialogTitle>
          <Stack width="100%" justifyContent="center" alignItems="center">
            <Typography fontSize="1.25rem" fontWeight="700">
              Save Changes
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography textAlign="center">Do you want to save your changes?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomButton
                  data-test="confirm-affiliate-details-save-changes-modal-button-cancel"
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  data-test="confirm-affiliate-details-save-changes-modal-button-save"
                  loading={isUpdateAffiliateChannelStatusLoading || isLoading}
                  disabled={isUpdateAffiliateChannelStatusLoading || isLoading}
                  onClick={onConfirm}
                >
                  Save
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmAffiliateDetailsSaveChangesModal;
