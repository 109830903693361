import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUser } from 'redux/slices/userManagement/initialState';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { CustomButton } from '../../shared';

export interface IResendInviteUserModalProps {
  user: IUser;
}

const ResendInviteUserModal = ({ user }: IResendInviteUserModalProps) => {
  const resendInviteLoading = useAppSelector(userManagementSelector.resendInviteLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ResendInviteUserModal));
  };

  const submit = () => {
    dispatch(userManagementMiddleware.resendInvitation(user.email));
  };

  return (
    <Dialog data-test="resend-invite-modal" open onClose={onClose}>
      <Grid sx={{ width: '600px' }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
              >
                Resend Invitation
              </Typography>
            </Grid>
            <Grid item>
              <IconButton data-test="cancel-resend-invite-button-x" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            {`Please confirm you want to resend the invitation to the "${user.fullName}" user.`}
          </Typography>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <CustomButton variant="outlined" onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomButton
                  data-test="cancel-resend-modal-button-confirm"
                  disabled={resendInviteLoading}
                  loading={resendInviteLoading}
                  onClick={submit}
                >
                  Resend
                </CustomButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ResendInviteUserModal;
