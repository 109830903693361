import { combineReducers } from 'redux';

import { affiliatesSlice } from './slices/affiliates';
import { authSlice } from './slices/auth';
import { campaignsSlice } from './slices/campaigns';
import { countriesSlice } from './slices/countries';
import { dashboardSlice } from './slices/dashboard';
import { externalServicesSlice } from './slices/externalServices';
import { fundingSlice } from './slices/funding';
import { leadsSlice } from './slices/leads';
import { listsSlice } from './slices/lists';
import { manualReviewSlice } from './slices/manualReviews';
import { menuSlice } from './slices/menu';
import { notificationSlice } from './slices/notification';
import { reportsSlice } from './slices/reports';
import { settingsSlice } from './slices/settings';
import { snackbarSlice } from './slices/snackbar';
import { systemFieldsSlice } from './slices/systemFields';
import { tagsSlice } from './slices/tags';
import { tenantSlice } from './slices/tenant';
import { userManagementSlice } from './slices/userManagement';
import { validationTreeSlice } from './slices/validationTree';
import { viewsSlice } from './slices/views';

const rootReducer = combineReducers({
  campaigns: campaignsSlice.reducer,
  countries: countriesSlice.reducer,
  affiliates: affiliatesSlice.reducer,
  menu: menuSlice.reducer,
  snackbar: snackbarSlice.reducer,
  validationTrees: validationTreeSlice.reducer,
  dashboard: dashboardSlice.reducer,
  views: viewsSlice.reducer,
  reports: reportsSlice.reducer,
  lists: listsSlice.reducer,
  systemFields: systemFieldsSlice.reducer,
  leads: leadsSlice.reducer,
  externalServices: externalServicesSlice.reducer,
  tenant: tenantSlice.reducer,
  userManagement: userManagementSlice.reducer,
  manualReview: manualReviewSlice.reducer,
  settings: settingsSlice.reducer,
  auth: authSlice.reducer,
  funding: fundingSlice.reducer,
  notification: notificationSlice.reducer,
  tags: tagsSlice.reducer,
});

export default rootReducer;
