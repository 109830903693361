import { IApplicationJourneyStep } from '../../helpers/CreateApplicationJourneyData';
import { dispatch } from '../../redux/hooks';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { CustomButton } from '../shared';

interface ApplicationJourneyResendLoanDocumentProps {
  step: IApplicationJourneyStep;
}

const ApplicationJourneyResendLoanDocument = ({ step }: ApplicationJourneyResendLoanDocumentProps) => {
  const onResendClick = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ResendDocumentModal,
        props: {},
      }),
    );
  };

  if (
    (step.id === 'WaitForDocumentToBeSigned' || step.id === 'ChangeOrderDocumentSignStep') &&
    step.status === 'InProgress'
  ) {
    return (
      <CustomButton sx={{ width: '100%', marginTop: '10px !important' }} onClick={onResendClick}>
        Resend
      </CustomButton>
    );
  }

  return null;
};

export default ApplicationJourneyResendLoanDocument;
