import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.countries;

const countries = createSelector([selector], (state) => state.countries);
const states = createSelector([selector], (state) => state.states);

export default {
  countries,
  states,
};
