export const isValidUrl = (url: string) => {
  try {
    return new URL(url).protocol === 'https:';
  } catch (e) {
    return false;
  }
};

export const camelCaseToSeparateWords = (str: string) =>
  str.replace(/([A-Z])/g, ' $1').replace(/Docu Sign/, 'DocuSign');

export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const beautifyJson = (jsonData: string) => {
  try {
    jsonData = JSON.parse(jsonData);

    return JSON.stringify(jsonData, null, 2);
  } catch (error) {
    return jsonData;
  }
};
