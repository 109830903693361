import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.userManagement;

const isLoading = createSelector([selector], (state) => state.isLoading);

const usersList = createSelector([selector], (state) => state.usersList);

const isUserValid = createSelector([selector], (state) => state.isUserValid);

const editRoleLoading = createSelector([selector], (state) => state.editRoleLoading);

const resendInviteLoading = createSelector([selector], (state) => state.resendInviteLoading);

const resetPasswordLoading = createSelector([selector], (state) => state.resetPasswordLoading);

const deactivateLoading = createSelector([selector], (state) => state.deactivateLoading);

const sendInvitationLoading = createSelector([selector], (state) => state.sendInvitationLoading);

const deleteLoading = createSelector([selector], (state) => state.deleteLoading);

const restrictDomainsLoading = createSelector([selector], (state) => state.restrictDomainsLoading);

const domainsData = createSelector([selector], (state) => state.domainsData);

const userPermissionsLoading = createSelector([selector], (state) => state.userPermissionsLoading);

const permissionsList = createSelector([selector], (state) => state.permissionsList);

const permissionsListLoading = createSelector([selector], (state) => state.permissionsListLoading);

const rolesList = createSelector([selector], (state) => state.rolesList);

const rolesListLoading = createSelector([selector], (state) => state.rolesListLoading);

const rolePermissions = createSelector([selector], (state) => state.rolePermissions);

const roleDeleteLoading = createSelector([selector], (state) => state.roleDeleteLoading);

const roleDeleteId = createSelector([selector], (state) => state.roleDeleteId);

export default {
  isLoading,
  usersList,
  isUserValid,
  editRoleLoading,
  resendInviteLoading,
  resetPasswordLoading,
  deactivateLoading,
  sendInvitationLoading,
  deleteLoading,
  restrictDomainsLoading,
  domainsData,
  userPermissionsLoading,
  permissionsList,
  permissionsListLoading,
  rolesList,
  rolesListLoading,
  rolePermissions,
  roleDeleteLoading,
  roleDeleteId,
};
