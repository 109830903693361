import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUser } from 'redux/slices/userManagement/initialState';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';
import * as Yup from 'yup';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

const validationSchema = Yup.object().shape({
  roleId: Yup.string().required('Role is required'),
});

export interface IEditUserRoleModalProps {
  user: IUser;
}

const EditUserRoleModal = ({ user }: IEditUserRoleModalProps) => {
  const editRoleLoading = useAppSelector(userManagementSelector.editRoleLoading);
  const rolesList = useAppSelector(userManagementSelector.rolesList);
  const rolesListLoading = useAppSelector(userManagementSelector.rolesListLoading);

  const { control, handleSubmit, formState } = useForm<{ roleId: string }>({
    mode: 'onSubmit',
    defaultValues: { roleId: user.role },
    resolver: yupResolver(validationSchema),
  });

  const { isDirty } = formState;

  const submit = (userInfo: { roleId: string }) => {
    if (isDirty) {
      dispatch(userManagementMiddleware.editUserRole({ ...userInfo, id: user.id }));
    }
  };

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.EditUserRoleModal));
  };

  useEffect(() => {
    if (!rolesList) {
      dispatch(userManagementMiddleware.getRolesList());
    }
  }, []);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '560px' }}>
        <DialogTitle>
          <Title
            component="span"
            title={`Change ${user.fullName}'s role`}
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {rolesListLoading ? (
            <Box display="flex" justifyContent="center" margin="20px">
              <CircularProgress />
            </Box>
          ) : (
            <Box onSubmit={handleSubmit(submit)} component="form">
              <Grid container>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="roleId"
                    render={({ field }) => (
                      <FormControl fullWidth required>
                        <InputLabel>Role</InputLabel>
                        <Select {...field}>
                          {rolesList?.map((el) => (
                            <MenuItem key={el.id} value={el.id}>
                              {el.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} marginTop="24px">
                  <CustomButton loading={editRoleLoading} fullWidth type="submit">
                    Edit Role
                  </CustomButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default EditUserRoleModal;
