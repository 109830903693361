import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Box, Button, CircularProgress, Divider, Tooltip, Typography } from '@mui/material';

import { CookieManager } from '../../../helpers/CookieManager';
import { dispatch } from '../../../redux/hooks';
import { fundingMiddleware } from '../../../redux/slices/funding';
import { Permissions } from '../../Permissions/constants';
import { hasPermission } from '../../Permissions/Permission';

import DropdownWithSearch from './DropdownWithSearch';

export interface IOption {
  id: string | number;
  value: string;
}

interface IFundsDropdownButtonProps {
  data: IOption[];
  defaultValue?: IOption;
  fundId: string;
  mode: 'list' | 'request';
  status: number | string;
}

const FundsDropdownButton = ({ data, defaultValue, fundId, mode, status }: IFundsDropdownButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [hover, setHover] = useState(false);
  const [filteredData, setFilteredData] = useState<IOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const user = CookieManager.getUser();

  const canEdit =
    (hasPermission(Permissions.changeFunder) || hasPermission(Permissions.fundSelfAssignUnassign)) && status === 0;

  const activeColor = canEdit ? '#7B61FF' : '#95A1B1';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (option: IOption) => {
    if (selectedOption?.id !== option.id) {
      setLoading(true);
      dispatch(
        fundingMiddleware.updateFunderAssign(
          fundId,
          option.id,
          setLoading,
          mode,
          option.id === user?.id ? user.name : option.value,
        ),
      );
    }
  };

  const handleDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setLoading(true);
    dispatch(fundingMiddleware.updateFunderAssign(fundId, 0, setLoading, mode));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    setFilteredData(data.filter((el) => el.value.toLowerCase().includes(value.toLowerCase())));
  };

  const onMenuItemClick = (option: IOption) => {
    handleClose();
    handleChoose(option);
  };

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  if (loading) {
    return (
      <Box textAlign="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Box>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          color: '#454256',
          fontWeight: 700,
          fontSize: '15px',
          width: '200px',
          display: 'flex',
          justifyContent: 'start',
          gap: '8px',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <Box>
          {selectedOption?.value ? (
            <Tooltip title={selectedOption.value} placement="bottom">
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '120px',
                  overflow: 'hidden',
                }}
              >
                {selectedOption.value}
              </Typography>
            </Tooltip>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PersonAddAltOutlinedIcon
                sx={{ color: activeColor, marginRight: '8px', width: '22px', height: '22px' }}
              />
              <Typography sx={{ color: activeColor }}>Assign</Typography>
            </Box>
          )}
        </Box>
        {canEdit && selectedOption?.id && (
          <Box display="flex" alignItems="center">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        )}
        <Box>
          {hover && selectedOption?.id && canEdit && (
            <Box display="flex" gap="8px" alignItems="center">
              <Divider orientation="vertical" sx={{ height: '18px' }} />
              <CloseIcon onClick={handleDelete} sx={{ color: '#718096' }} />
            </Box>
          )}
        </Box>
      </Button>
      {open && canEdit && (
        <DropdownWithSearch
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleSearch={handleSearch}
          filteredData={filteredData}
          selectedOption={selectedOption}
          onMenuItemClick={onMenuItemClick}
        />
      )}
    </Box>
  );
};

export default FundsDropdownButton;
