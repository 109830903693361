import React, { useLayoutEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { DateRange } from '@types';

import { dispatch } from '../../redux/hooks';
import { snackbarMiddleware } from '../../redux/slices/snackbar';
import { SeveritiesType } from '../../types/snackbar';

interface CustomRangePickerProps {
  state: DateRange;
  setState: React.Dispatch<React.SetStateAction<DateRange>>;
  error: Error | AxiosError | null;
  setError: React.Dispatch<React.SetStateAction<Error | AxiosError | null>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useCustomRangePicker = (startDate: Date | string = '', endDate: Date | string = ''): CustomRangePickerProps => {
  const [state, setState] = useState<DateRange>({
    startDate,
    endDate,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<Error | AxiosError | null>(null);

  useLayoutEffect(() => {
    if (error && error?.message) {
      dispatch(
        snackbarMiddleware.fetchOpenSnackbar({
          open: true,
          message: error?.message,
          variant: 'alert',
          alert: {
            color: SeveritiesType.success,
            variant: 'filled',
          },
          close: true,
          action: false,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transition: 'Fade',
          actionButton: false,
        }),
      );
    }
  }, [error]);

  return {
    state,
    setState,
    error,
    setError,
    open,
    setOpen,
  };
};

export default useCustomRangePicker;
