import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';
import { dispatch, useAppSelector } from 'redux/hooks';
import { settingsMiddleware, settingsSelector } from 'redux/slices/settings';

import PlatformSettings from '../../components/SettingsPage/PlatformSettings';
import Security from '../../components/SettingsPage/Security';
import TenantSettings from '../../components/TenantSettings';
import Title from '../../components/UI/Title';

const Settings = () => {
  const tenantSettings = useAppSelector(settingsSelector.tenantSettings);

  useEffect(() => {
    if (hasPermission(Permissions.tenantSettings)) {
      dispatch(settingsMiddleware.getTenantSettings());
    }
  }, []);

  return (
    <>
      <Title title="Settings" />
      <Grid container>
        {/* // TODO change after BE is ready */}
        {/* <Grid item xs={4} padding="5px"> */}
        {/*  <ProfileInformation /> */}
        {/* </Grid> */}
        {/* <Grid item xs={4} padding="5px"> */}
        {/*  <CompanyInformation /> */}
        {/* </Grid> */}
        <Grid item xs={4} padding="5px">
          <PlatformSettings />
        </Grid>
        {/* <Grid item xs={4} padding="5px"> */}
        {/*  <ChangePassword /> */}
        {/* </Grid> */}
        <Grid item xs={4} padding="5px">
          <Security />
        </Grid>
        {tenantSettings ? (
          <Permission permission={Permissions.tenantSettings}>
            <Grid item xs={4} padding="5px">
              <TenantSettings settings={tenantSettings} />
            </Grid>
          </Permission>
        ) : null}
      </Grid>
    </>
  );
};

export default Settings;
