import { ChannelTabs } from '../../../pages/Affiliates/Details/Channels';
import { IAffiliateState } from '../../../types';

export const getInitialState = (): IAffiliateState => ({
  error: null,
  affiliates: null,
  simplifiedAffiliates: null,
  affiliatesCount: null,
  affiliateChannels: null,
  simplifiedAffiliateChannels: null,
  affiliateChannelsCount: null,
  currentAffiliateById: null,
  currentAffiliateByChannelId: null,
  creditUnions: [],
  creditUnionsLoading: false,
  affiliateChannelPostingDetails: null,
  isLoading: false,
  isAffiliatesLoading: false,
  isUpdateAffiliateStatusLoading: false,
  isAffiliateChannelsLoading: false,
  isCreateAffiliateLoading: false,
  isDeleteAffiliateLoading: false,
  isUpdateAffiliateChannelLoading: false,
  isCreateAffiliateChannelLoading: false,
  isUpdateAffiliateChannelStatusLoading: false,
  isDeleteAffiliateChannelLoading: false,
  isAffiliateChannelPostingDetailsLoading: false,
  isAffiliateChannelHistoryLoading: false,
  successfully: false,
  successfullyCreated: false,
  received: null,
  channel: null,
  dataFormats: null,
  createdAffiliateChannel: null,
  affiliateChannelHistory: null,
  externalServiceById: null,
  campaignsAffiliateChannelsCount: 0,
  affiliateChannelHistoryCount: 0,
  affiliateFilters: null,
  affiliateChannelFilters: null,
  affiliateChannelDetailsTabs: ChannelTabs.information,
});
