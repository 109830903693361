import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { CustomButton } from '..';

interface FilterToggleProps {
  dataTest?: string;
  sx?: CSSProperties;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
}

const ToggleFilters = ({ dataTest, sx = {}, showFilters, setShowFilters }: FilterToggleProps) => (
  <CustomButton
    id="toggle-filters"
    sx={{
      color: '#454256',

      '& .css-1gnd1fd-MuiButton-endIcon': {
        fontSize: '16px',
        fontWeight: '700',
      },
      backgroundColor: 'transparent',
      ...sx,
    }}
    data-test={dataTest}
    variant="text"
    endIcon={showFilters ? <ExpandLess /> : <ExpandMore />}
    onClick={() => {
      setShowFilters((value) => !value);
    }}
  >
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: '700',
      }}
      component="h5"
    >
      Filters
    </Typography>
  </CustomButton>
);

export default ToggleFilters;
