export const defaultFilterValues = (list: any[], ids: string[] = [], key: string = 'id') =>
  ids.reduce((newList, id) => {
    const newItem = list.find((item) => item[key] === id);

    if (newItem) {
      newList.push(newItem);
    }

    return newList;
  }, [] as any);
