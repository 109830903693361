import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { listsMiddleware } from '../../../../redux/slices/lists';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmListIntegrationAddModalControls = (newListName: string) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationAddModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationAddModal));
    dispatch(listsMiddleware.fetchCreateList(newListName));
  }, [newListName]);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmListIntegrationAddModalControls;
