import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ISnackbarProps } from '../../../types/snackbar';

const createReducer = <T extends SliceCaseReducers<ISnackbarProps>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  openSnackbar(state, action) {
    const { message, anchorOrigin, variant, transition, close, actionButton } = action.payload;

    state.action = !state.action;
    state.open = true;
    state.message = message;
    state.anchorOrigin = anchorOrigin;
    state.variant = variant;
    state.transition = transition;
    state.close = Boolean(close);
    state.actionButton = actionButton;
  },
  closeSnackbar(state) {
    state.open = false;
  },
});

export default reducers;
