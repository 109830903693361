import { Box, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ILeadJourneyGroup, ILeadJourneyStep } from '@types';

import { JourneyExpressionMessageCreator } from '../../helpers/JourneyExpressionMessageCreator';

import { JourneyStepStatus } from './JourneyStepStatus';

import classes from '../../assets/scss/journey.module.scss';

const MessageTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { title, children } = props;

  return (
    <Tooltip title={title} placement="bottom" classes={{ popper: className }}>
      {children}
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F4F7FE',
    border: '1px solid #c4cbe2',
    color: '#000',
    fontSize: 16,
  },
}));

export const JourneyExpressionStep = ({
  group,
  step,
  index,
}: {
  group: ILeadJourneyGroup;
  step: ILeadJourneyStep;
  index: number;
}) => (
  <Box className={`${classes.step} ${step.className}`}>
    <MessageTooltip title={JourneyExpressionMessageCreator(step.title)}>
      <Box className={classes.stepContainer}>
        <Box className={`${classes.stepHeader}`}>
          <Typography className={classes.stepHeaderTitle}>Expression</Typography>
          {step.desc ? <Typography className={classes.stepHeaderDesc}>{step.desc}</Typography> : null}
        </Box>
      </Box>
    </MessageTooltip>
    {group.steps[index + 1] ? <JourneyStepStatus className={step.className} /> : null}
  </Box>
);
