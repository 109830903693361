import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';
import { CustomButton } from 'components/shared';
import TitleWithBreadcrumbs from 'components/shared/TitleWithBreadcrumbs';
import { StatusBadgeTitle } from 'components/StatusBadgeTitle';
import TagsSelect from 'components/Tags/TagsSelect';
import { amountFormat } from 'helpers';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import Loading from '../../components/UI/Loading';
import BaseTabs from '../../components/UI/Tabs';
import { Statuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { tagsMiddleware, tagsSelector } from '../../redux/slices/tags';
import { ApplicationTabs } from '../../types';

import LeadsJourney from './ApplicationJourney';
import Documents from './Documents';
import FundingRequests from './FundingRequests';
import GeneralInformation from './GeneralInformation';
import { LogsTable } from './Logs';
import ManualReview from './ManualReview';
import MindMap from './MindMap';
import Notes from './Notes';

const LeadsDetails = () => {
  const boxStyles = {
    sx: {
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      borderRadius: '12px',
      padding: '20px 30px',
    },
  };
  const leadsDetails = useAppSelector(leadsSelector.leadsDetails);
  const isLeadsDetailsLoading = useAppSelector(leadsSelector.isLeadsDetailsLoading);
  const leadDetailsInitialTab = useAppSelector(leadsSelector.leadDetailsInitialTab);
  const generalInformationData = useAppSelector(leadsSelector.generalInformationData);
  const tags = useAppSelector(tagsSelector.tags);
  const [tabValue, setTabValue] = useState<number | string>(leadDetailsInitialTab ?? ApplicationTabs.generalInfo);

  const { id: leadId } = useParams();

  useEffect(() => {
    if (leadId) {
      dispatch(leadsMiddleware.fetchLeadsDetails(leadId));
      dispatch(tagsMiddleware.fetchTags());
    }

    return () => {
      dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.generalInfo));
      dispatch(leadsMiddleware.clearLeadsDetails());
      dispatch(leadsMiddleware.clearGeneralInformationData());
    };
  }, [leadId]);

  useEffect(() => {
    if (leadDetailsInitialTab !== ApplicationTabs.generalInfo) {
      setTabValue(leadDetailsInitialTab);
    }
  }, [leadDetailsInitialTab]);

  const handleCancelClick = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.CancelApplicationModal,
        props: { id: leadId, applicationId: leadsDetails?.applicationId ? leadsDetails?.applicationId : '' },
      }),
    );
  };

  const generateTitle = () => {
    const borrower = generalInformationData?.['Personal Details']?.[0]?.value ?? '';
    const applicationId = leadsDetails?.applicationId ? `, Application ${leadsDetails.applicationId}` : '';

    return `${borrower}${applicationId}`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <TitleWithBreadcrumbs
        title={generateTitle()}
        dataTest="leads-edit-page-header-leads-list"
        link="/applications"
        linkTitle="Applications List"
        currentTitle={`Application ${leadsDetails?.applicationId ? leadsDetails?.applicationId : ''}`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box>
            {leadsDetails?.status ? <StatusBadgeTitle Statuses={Statuses} status={leadsDetails?.status} /> : ''}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TagsSelect tags={tags!} defaultValues={leadsDetails?.tags} page={5} targetId={leadsDetails?.id} />
            {leadsDetails?.applicationId && leadsDetails?.isCancelable && (
              <Permission permission={Permissions.cancelApplication}>
                <CustomButton
                  color="error"
                  sx={{ ml: '24px', backgroundColor: '#FF497F', borderColor: '#FF497F' }}
                  onClick={handleCancelClick}
                >
                  Cancel Application
                </CustomButton>
              </Permission>
            )}
          </Box>
        </Box>
      </TitleWithBreadcrumbs>
      <Box sx={{ pb: '24px' }}>
        <Typography component="span" sx={{ fontSize: '14px', color: '#707EAE', fontWeight: 500, lineHeight: '24px' }}>
          Loan Amount:
        </Typography>
        <Typography
          component="span"
          sx={{
            fontSize: '14px',
            color: '#454256',
            fontWeight: 700,
            lineHeight: '17px',
          }}
        >
          {leadsDetails && ` ${amountFormat(leadsDetails?.loanAmount)}`}
        </Typography>
      </Box>
      <Box {...boxStyles}>
        <BaseTabs
          tabs={[
            { id: 'GENERAL_INFO', label: 'General Information' },
            { id: 'LOGS', label: 'Logs', permission: Permissions.viewApplicationLogs },
            { id: 'DECISION_JOURNEY', label: 'Decision Journey', permission: Permissions.viewDecisionJourney },
            { id: 'APPLICATION_JOURNEY', label: 'Application Journey', permission: Permissions.viewAppJourney },
            // added permission for hiding tabs, in that case we don't need to do any additional change
            // need to implement after release
            { id: 'MANUAL_REVIEW', label: 'Manual Review', permission: Permissions.viewManualReview },
            { id: 'FUNDING_REQUESTS', label: 'Funding Requests', permission: Permissions.viewFunds },
            { id: 'DOCUMENTS', label: 'Documents', permission: Permissions.viewDocuments },
            { id: 'NOTES', label: 'Notes', permission: Permissions.viewNotesTab },
          ]}
          dataTest={[
            'lead-details-button-tab-general-information',
            'lead-details-button-tab-logs',
            'lead-details-button-tab-decision-journey',
            'lead-details-button-tab-applicant-journey',
            'lead-details-button-tab-manual-review',
            'lead-details-button-tab-funding-requests',
            'lead-details-button-tab-credit-report',
          ]}
          tabValue={tabValue}
          setTabValue={setTabValue}
          component="div"
        />
        <Grid>
          {tabValue === ApplicationTabs.generalInfo &&
            (isLeadsDetailsLoading || (leadsDetails && !Object.keys(leadsDetails).length) ? (
              <Loading />
            ) : (
              <GeneralInformation />
            ))}
          {tabValue === ApplicationTabs.logs && <LogsTable leadId={leadId} />}
          {tabValue === ApplicationTabs.decisionJourney && <MindMap />}
          {tabValue === ApplicationTabs.applicationJourney && <LeadsJourney />}
          {tabValue === ApplicationTabs.manualReview && <ManualReview leadId={leadId} />}
          {tabValue === ApplicationTabs.fundingRequests && <FundingRequests />}
          {tabValue === ApplicationTabs.documents && <Documents lead={leadsDetails} />}
          {tabValue === ApplicationTabs.notes && <Notes />}
        </Grid>
      </Box>
    </Box>
  );
};

export default LeadsDetails;
