import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import MainCard from 'components/UI/MainCard';
import { externalServicesMiddleware, externalServicesSelector } from 'redux/slices/externalServices';

import { IError } from '@types';

import { useAppSelector } from '../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { CustomButton } from '../shared';

import BaseModal from './BaseModal';

const XmlModal = ({
  show,
  onHide,
  dataFieldType,
  setIsUploadedFile,
  accept = ['text/xml', 'application/json'],
}: {
  show: boolean;
  onHide: Dispatch<SetStateAction<boolean>>;
  dataFieldType?: number;
  setIsUploadedFile?: (value: boolean) => void;
  accept?: string[];
}) => {
  const isFieldByDataTypeExternalServicesLoading = useAppSelector(
    externalServicesSelector.isFieldByDataTypeExternalServicesLoading,
  );
  const isServiceFieldByDataTypeExternalServicesLoading = useAppSelector(
    externalServicesSelector.isServiceFieldByDataTypeExternalServicesLoading,
  );
  const isFieldByDataTypeLoading = useAppSelector(campaignsSelector.isFieldByDataTypeLoading);

  const [loading, setLoading] = useState<boolean>(false);
  const [XML, setXML] = useState<string | ArrayBuffer | null>('');
  const [fileError, setFileError] = useState<boolean>(false);
  const [error, setError] = useState<{
    [key: string]: { [key: string]: string };
  }>({});
  const ref = useRef(null);

  const isLoading =
    isServiceFieldByDataTypeExternalServicesLoading ||
    isFieldByDataTypeExternalServicesLoading ||
    isFieldByDataTypeLoading ||
    loading;

  const handleSaveClick = async () => {
    setError({});

    if (!XML) {
      setError((prev) => ({
        ...prev,
        TEXTAREA: { field: 'TEXTAREA', message: 'XML required' },
      }));

      return;
    }

    try {
      if (dataFieldType === 2) {
        await externalServicesMiddleware.fetchFieldByDataTypeExternalServices(`${XML}`)();
      } else if (dataFieldType === 3) {
        await externalServicesMiddleware.fetchResponseFieldByDataTypeExternalServices(`${XML}`)();
      } else {
        await campaignsMiddleware.fetchFieldByDataType(`${XML}`)();
      }

      if (setIsUploadedFile) {
        setIsUploadedFile(true);
      }

      onHide(false);
    } catch (err) {
      const axiosError = err as IError;

      if (axiosError?.response?.data?.error) {
        setError((prev) => ({
          ...prev,
          AXIOS: axiosError?.response?.data?.error,
        }));
      }
    }
  };

  const onXMLUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    const file: File = selectedFiles?.[0];

    if (accept.includes(file.type)) {
      setFileError(false);

      const reader: FileReader = new FileReader();

      reader.readAsText(selectedFiles?.[0]);

      reader.onloadend = (evt: ProgressEvent) => {
        const { result } = evt.target as FileReader;

        setXML(result);
      };
    } else {
      setFileError(true);
      setXML('');
    }
  };

  useEffect(() => {
    if (isFieldByDataTypeExternalServicesLoading || isFieldByDataTypeLoading) {
      setLoading(true);
    }
  }, [isFieldByDataTypeExternalServicesLoading, isFieldByDataTypeLoading]);

  return (
    <BaseModal dataTest="xml-modal" open={show} setOpen={onHide}>
      <MainCard sx={{ minWidth: 400 }}>
        <Typography>Insert XML or Json</Typography>
        <TextField
          data-test="xml-modal-input-xml-json"
          multiline
          rows={10}
          value={XML}
          onChange={({ target: { value } }) => setXML(value)}
          placeholder="XML, Json"
          ref={ref}
          fullWidth
        />
        {error && error?.TEXTAREA ? <FormHelperText error>{error?.TEXTAREA?.message}</FormHelperText> : null}
        {fileError ? <FormHelperText error>You can only insert XML or JSON</FormHelperText> : null}
        <CustomButton data-test="xml-modal-button-upload-xml" variant="text">
          Upload XML
          <TextField
            data-test="xml-modal-input-file"
            onChange={onXMLUpload}
            type="file"
            inputProps={{ accept }}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              opacity: 0,
            }}
          />
        </CustomButton>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            data-test="xml-modal-button-cancel"
            variant="outlined"
            size="small"
            onClick={() => onHide(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            data-test="xml-modal-button-save"
            size="small"
            disabled={isLoading}
            loading={isLoading}
            onClick={handleSaveClick}
          >
            Save
          </CustomButton>
        </Box>
      </MainCard>
      {error && error?.AXIOS ? <FormHelperText error>{error?.AXIOS?.message}</FormHelperText> : null}
    </BaseModal>
  );
};

export default XmlModal;
