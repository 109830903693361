import { Dispatch, SetStateAction } from 'react';
import { Box, Grid } from '@mui/material';

import { CustomButton } from '../../components';
import CustomRangePicker from '../../components/CustomRangePicker/CustomRangePicker';
import CustomAutocomplete, { ISelectOption } from '../../components/UI/CustomAutocomplete';
import { DateRange } from '../../types';

export interface IReportFilters {
  dateTimeState: DateRange | undefined | null;
  setDateTimeState: (value: DateRange | undefined | null) => void;
  affiliateDropdownOptions: ISelectOption[];
  filteredAffiliateData: ISelectOption[];
  setFilteredAffiliateData: Dispatch<SetStateAction<ISelectOption[]>>;
  affiliatesChannelDropdownOptions: ISelectOption[];
  filteredAffiliateChannelData: ISelectOption[];
  setFilteredAffiliateChannelData: Dispatch<SetStateAction<ISelectOption[]>>;
  campaignsDropdownOptions: ISelectOption[];
  filteredCampaignsData: ISelectOption[];
  setFilteredCampaignsData: Dispatch<SetStateAction<ISelectOption[]>>;
  resetFilters: () => void;
  applyFilters: () => void;
}

export const ReportsFilters = ({
  dateTimeState,
  setDateTimeState,
  affiliateDropdownOptions,
  filteredAffiliateData,
  setFilteredAffiliateData,
  affiliatesChannelDropdownOptions,
  filteredAffiliateChannelData,
  setFilteredAffiliateChannelData,
  campaignsDropdownOptions,
  filteredCampaignsData,
  setFilteredCampaignsData,
  resetFilters,
  applyFilters,
}: IReportFilters) => (
  <Grid
    sx={{
      display: 'flex',
      alignItems: 'end',
      gap: '30px',
      flexWrap: 'wrap',
    }}
  >
    <Grid>
      <CustomRangePicker
        dataTest="reports-page-input-range"
        key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
        dateTimeState={dateTimeState}
        setDateTimeState={setDateTimeState}
      />
    </Grid>
    <Grid item>
      <CustomAutocomplete
        dataTest="reports-page-dropdown-affiliate"
        title="Source"
        data={affiliateDropdownOptions}
        filteredData={filteredAffiliateData}
        setData={setFilteredAffiliateData}
        isSelectAll
      />
    </Grid>
    <Grid item>
      <CustomAutocomplete
        dataTest="reports-page-dropdown-affiliate-channel"
        title="Source Channel"
        data={affiliatesChannelDropdownOptions}
        filteredData={filteredAffiliateChannelData}
        setData={setFilteredAffiliateChannelData}
        isSelectAll
      />
    </Grid>
    <Grid item>
      <CustomAutocomplete
        dataTest="reports-page-dropdown-campaign"
        title="Product"
        data={campaignsDropdownOptions}
        filteredData={filteredCampaignsData}
        setData={setFilteredCampaignsData}
        isSelectAll
      />
    </Grid>
    <Grid item display="flex" gap="30px">
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
        <CustomButton data-test="reports-page-button-apply" onClick={applyFilters}>
          Apply
        </CustomButton>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
        <CustomButton variant="outlined" data-test="reports-page-button-apply" onClick={resetFilters}>
          Reset
        </CustomButton>
      </Box>
    </Grid>
  </Grid>
);
