import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, MenuItem, Pagination, Select, SelectChangeEvent, Typography } from '@mui/material';
import Note from 'components/Notes/Note';
import NoteCancel from 'components/Notes/NoteCancel';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';
import { CustomButton } from 'components/shared';
import CustomSelect from 'components/shared/Select/CustomSelect';
import { IControlledSelectOptions } from 'components/shared/Select/FormSelect';
import Loading from 'components/UI/Loading';

import { ROWS_PER_PAGE_OPTIONS } from '../../lib/constants';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

const Notes = () => {
  const { id: leadId } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(-1);
  const notesList = useAppSelector(leadsSelector.notesList);
  const notesUsers = useAppSelector(leadsSelector.notesUsers);
  const notesListLoading = useAppSelector(leadsSelector.notesListLoading);

  const onAddNote = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.AddNoteModal,
        props: { leadId, mode: 'Add', selectedUser, rowsPerPage },
      }),
    );
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, pageIndex: number) => {
    if (leadId && pageIndex !== page) {
      dispatch(leadsMiddleware.getNotes(leadId, pageIndex, rowsPerPage, selectedUser ? `${selectedUser}` : null));
      setPage(pageIndex);
    }
  };

  const onRowsPerPageChange = (event: SelectChangeEvent) => {
    const newRowsPerPage = +event.target.value;

    if (leadId && newRowsPerPage !== rowsPerPage) {
      dispatch(leadsMiddleware.getNotes(leadId, 1, newRowsPerPage, selectedUser ? `${selectedUser}` : null));
      setPage(1);
      setRowsPerPage(newRowsPerPage);
    }
  };

  const onUserSelect = (user: IControlledSelectOptions) => {
    if (leadId && user?.id) {
      dispatch(leadsMiddleware.getNotes(leadId, 1, 10, `${user.id}`));
      setSelectedUser(+user.id);
      setPage(1);
      setRowsPerPage(10);
    }
  };

  useEffect(() => {
    if (leadId) {
      dispatch(leadsMiddleware.getNotes(leadId, 1, 10));
    }
  }, [leadId]);

  return (
    <Box padding="8px">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <CustomSelect
          nameKey="name"
          valueKey="id"
          options={notesUsers ?? []}
          defaultValue={selectedUser}
          onSelect={onUserSelect}
          label="User"
        />
        <Permission permission={Permissions.editNotesTab}>
          <CustomButton onClick={onAddNote}>Add Note</CustomButton>
        </Permission>
      </Box>
      {notesListLoading ? (
        <Loading />
      ) : (
        <Box height="460px" overflow="auto">
          {notesList?.userNotes?.map((note) =>
            note.isCancellationNote ? (
              <NoteCancel key={note.noteId} note={note} />
            ) : (
              <Note
                key={note.noteId}
                note={note}
                leadId={leadId ?? ''}
                page={page}
                rowsPerPage={rowsPerPage}
                selectedUser={selectedUser}
              />
            ),
          )}
        </Box>
      )}
      <Box>
        {notesList?.itemsCount ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
            <Typography sx={{ display: 'flex' }}>
              Total&nbsp;
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {notesList?.itemsCount}&nbsp;items
              </Box>
            </Typography>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Items per page</Typography>
                <Select
                  variant="standard"
                  sx={{ width: '60px !important' }}
                  value={`${rowsPerPage}`}
                  onChange={onRowsPerPageChange}
                >
                  {ROWS_PER_PAGE_OPTIONS.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Pagination
                count={notesList?.pagesCount}
                sx={{ height: '20px', 'button &': { height: '20px' }, marginTop: '10px' }}
                color="secondary"
                page={page}
                onChange={onPageChange}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Notes;
