import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.lists;

const selectedLists = createSelector([selector], (state) => state.selectedLists);

const lists = createSelector([selector], (state) => state.lists);

const isLoading = createSelector([selector], (state) => state.isLoading);
const isCreateListValuesLoading = createSelector([selector], (state) => state.isCreateListValuesLoading);
const isEditListValueLoading = createSelector([selector], (state) => state.isEditListValueLoading);
const isSelectedListsLoading = createSelector([selector], (state) => state.isSelectedListsLoading);

export default {
  selectedLists,
  lists,
  isLoading,
  isCreateListValuesLoading,
  isEditListValueLoading,
  isSelectedListsLoading,
};
