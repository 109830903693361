import { responseErrorHandler } from 'helpers/ErrorHandler';
import moment from 'moment/moment';
import { dispatch } from 'redux/hooks';

import { FileManager } from '../../../helpers/FileManager';
import {
  API_DOWNLOAD_LEAD_LIST_REPORT,
  API_GET_AFFILIATE_COMPARISON_REPORTS,
  API_GET_AFFILIATE_REPORTS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { IAffiliatesComparisonReportsReqBody, IAffiliatesReportsReqBody } from '../../../types';

import slice from './slice';

const {
  setReportsLoading,
  getAffiliateReportsSuccess,
  setComparisonReportsLoading,
  getSuccessAffiliateComparisonReports,
  hasError,
  setIsDownloadLeadListReportLoading,
} = slice.actions;

const fetchAffiliateReports = (data: IAffiliatesReportsReqBody) => async () => {
  try {
    dispatch(setReportsLoading(true));

    data.startDate = moment(data.startDate).startOf('day').format();
    data.endDate = moment(data.endDate).endOf('day').format();

    const { data: response } = await DataService.post(API_GET_AFFILIATE_REPORTS(), data);

    response.data = response.data.map(
      (item: { acceptedLeadsRate: number; rejectedLeadsRate: number; fundedAmount: number }) => ({
        ...item,
        acceptedLeadsRate: `${Math.round(item.acceptedLeadsRate * 100) / 100}%`,
        rejectedLeadsRate: `${Math.round(item.rejectedLeadsRate * 100) / 100}%`,
        fundedAmount: `$${Math.round(item.fundedAmount)}`,
      }),
    );

    dispatch(getAffiliateReportsSuccess(response));
    dispatch(setReportsLoading(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setReportsLoading(false));
  }
};

const fetchAffiliatesComparisonReports = (data: IAffiliatesComparisonReportsReqBody) => async () => {
  try {
    dispatch(setComparisonReportsLoading(true));

    const { data: response } = await DataService.post(API_GET_AFFILIATE_COMPARISON_REPORTS(), data);

    dispatch(getSuccessAffiliateComparisonReports(response));
    dispatch(setComparisonReportsLoading(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setComparisonReportsLoading(false));
  }
};

const downloadLeadListReport =
  (request: { dateFrom: string | Date | undefined; dateTo: string | Date | undefined; status: number }) => async () => {
    dispatch(setIsDownloadLeadListReportLoading(true));

    try {
      const response = await DataService.getFileWithFilters(API_DOWNLOAD_LEAD_LIST_REPORT(), request);

      FileManager.download(response?.data as File, 'Application-List-Report.xlsx');
    } catch (error) {
      dispatch(hasError(error));
    }

    dispatch(setIsDownloadLeadListReportLoading(false));
  };

export default {
  fetchAffiliateReports,
  fetchAffiliatesComparisonReports,
  downloadLeadListReport,
};
