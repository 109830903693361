export interface ISingleNotification {
  id: string;
  date: string;
  status: number;
  type: number;
  leadId: string;
  applicationId: string;
  assigner: string;
  senderId: string;
}

export interface INotification {
  fundingCount: number | null;
  manualReviewCount: number | null;
  notifications: ISingleNotification[] | null;
  notificationsLoaded: boolean;
  notificationsList: ISingleNotification[] | null;
  notificationsByIdLoading: boolean;
  isNotificationListEnd: boolean;
}
export const getInitialState = (): INotification => ({
  fundingCount: null,
  manualReviewCount: null,
  notifications: null,
  notificationsLoaded: false,
  notificationsByIdLoading: false,
  notificationsList: null,
  isNotificationListEnd: false,
});
