import NotificationService from 'helpers/Notifications/NotificationService';
import { dispatch, getStateBySelector } from 'redux/hooks';
import DataService from 'service/data.service';

import { DEFAULT_ROWS_PAGE } from '../../../lib/constants';
import {
  API_GENERAL_COUNTS,
  API_MARK_ALL_AS_READ,
  API_MARK_AS_READ,
  API_NOTIFICATIONS,
} from '../../../lib/constants/api.constants.simple';

import { ISingleNotification } from './initialState';
import slice from './slice';

const {
  setFundingCount,
  setManualReviewCount,
  setNotifications,
  setNotificationsLoaded,
  setNotificationsByIdLoading,
  setNotificationsList,
  setIsNotificationListEnd,
} = slice.actions;

const fetchNotificationCounts = () => async () => {
  try {
    const { data: response } = await DataService.getData(API_GENERAL_COUNTS());

    const { fundingCount, manualReviewCount } = response;

    dispatch(setFundingCount(fundingCount));
    dispatch(setManualReviewCount(manualReviewCount));
  } catch (error) {
    console.log(error);
  }
};

const fetchNotifications = () => async () => {
  // Need to add addReceiveMessageListener from NotificationsService, when finished implementation
  try {
    const { notificationsLoaded } = getStateBySelector('notification');

    if (notificationsLoaded) {
      return;
    }

    const { data: response } = await DataService.getData(API_NOTIFICATIONS());

    dispatch(setNotifications(response));

    dispatch(setNotificationsLoaded(true));

    await NotificationService.getInstance().startConnection();

    NotificationService.getInstance().addReceiveMessageListener((notification) => {
      let { notifications } = getStateBySelector('notification');
      let { notificationsList } = getStateBySelector('notification');

      notifications = [notification, ...notifications];

      if (notificationsList) {
        notificationsList = [notification, ...notificationsList];
        dispatch(setNotificationsList(notificationsList));
      }

      dispatch(setNotifications(notifications));
    });
  } catch (error) {
    console.log(error);
  }
};

const fetchNotificationsWithId = (id?: string) => async () => {
  try {
    dispatch(setNotificationsByIdLoading(true));

    const { notificationsList } = getStateBySelector('notification');

    const { data: response } = await DataService.getData(API_NOTIFICATIONS(id));

    if (notificationsList) {
      dispatch(setNotificationsList([...notificationsList, ...response]));
    } else {
      dispatch(setNotificationsList(response));
    }

    if (response.length < DEFAULT_ROWS_PAGE) {
      dispatch(setIsNotificationListEnd(true));
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setNotificationsByIdLoading(false));
  }
};

const clearNotificationsList = () => () => {
  dispatch(setIsNotificationListEnd(false));
  dispatch(setNotificationsList(null));
};

const onNotificationSelect = (notificationId: string) => async () => {
  try {
    await DataService.post(API_MARK_AS_READ(notificationId), {});

    const { notifications } = getStateBySelector('notification');

    const updatedNotifications = notifications.map((el: ISingleNotification) =>
      el.id === notificationId ? { ...el, status: 2 } : el,
    );

    dispatch(setNotifications(updatedNotifications));
  } catch (error) {
    console.log(error);
  }
};

const onMarkAllAsReadClick = () => async () => {
  try {
    await DataService.post(API_MARK_ALL_AS_READ(), {});

    const { notifications } = getStateBySelector('notification');
    const { notificationsList } = getStateBySelector('notification');

    const updatedNotifications = notifications.map((el: ISingleNotification) => ({ ...el, status: 2 }));

    if (notificationsList) {
      const updatedNotificationsList = notificationsList.map((el: ISingleNotification) => ({ ...el, status: 2 }));

      dispatch(setNotificationsList(updatedNotificationsList));
    }

    dispatch(setNotifications(updatedNotifications));
  } catch (error) {
    console.log(error);
  }
};

export default {
  fetchNotificationCounts,
  fetchNotifications,
  onNotificationSelect,
  onMarkAllAsReadClick,
  fetchNotificationsWithId,
  clearNotificationsList,
};
