import CheckIcon from '@mui/icons-material/Check';
import { MenuItem } from '@mui/material';
import { IOption } from 'components/shared/FundsDropdownButton/index';

interface IDropdownItemProps {
  selectedOption: IOption | undefined;
  option: IOption;
  onMenuItemClick: (option: IOption) => void;
}

const DropdownItem = ({ selectedOption, option, onMenuItemClick }: IDropdownItemProps) => (
  <MenuItem
    sx={
      selectedOption?.id === option.id
        ? {
            backgroundColor: '#F9F8FE !important',
            color: '#7B61FF',
            display: 'flex',
            justifyContent: 'space-between !important',
            margin: '4px 0',
          }
        : { margin: '4px 0' }
    }
    key={option.id}
    onClick={() => onMenuItemClick(option)}
  >
    {option.value}
    {selectedOption?.id === option.id && <CheckIcon sx={{ color: '#7B61FF' }} />}
  </MenuItem>
);

export default DropdownItem;
