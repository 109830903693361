/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box, CircularProgress, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectHeader } from 'pages/Integrations/SelectHeader';
import { camelCaseToSeparateWords } from 'utils/index';

import { IApplicationJourney } from '../../helpers/CreateApplicationJourneyData';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { CustomButton } from '..';

import ApplicationJourneyRow from './ApplicationJourneyRow';

import classes from '../../assets/scss/journey.module.scss';

export interface IRowHeight {
  [key: number]: number;
}

const ApplicationJourney = ({
  leadId,
  journey,
  isSuccess,
}: {
  leadId: string;
  journey: IApplicationJourney[];
  isSuccess: boolean;
}) => {
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [rowHeights, setRowHeights] = useState<IRowHeight>({});
  const [selectedStep, setSelectedStep] = useState<string[]>(['']);
  const leadsDetails = useAppSelector(leadsSelector.leadsDetails);
  const rerunSteps = useAppSelector(leadsSelector.rerunSteps);
  const rerunLoading = useAppSelector(leadsSelector.rerunLoading);

  useEffect(() => {
    if (leadsDetails?.applicationId) {
      dispatch(leadsMiddleware.fetchRerunSteps(leadsDetails.applicationId));
    }
  }, [leadsDetails]);

  const openRerunDialog = () => {
    setShowSelect(false);

    if (leadsDetails) {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.RerunModal,
          props: { applicationId: leadsDetails.applicationId, selectedStep: selectedStep[0] },
        }),
      );
    }
  };

  const handleSelectRerun = (event: SelectChangeEvent<string[]>) => {
    const step = event.target.value;

    if (Array.isArray(step) && step.length) {
      setSelectedStep([step[step.length - 1]]);
    }
  };

  return (
    <Box className={classes.root} sx={{ position: 'relative' }}>
      {/* <Box> */}
      {/*  {leadsDetails?.status !== 3 && ( */}
      {/*    <Grid item sx={{ mb: '20px' }}> */}
      {/*      <SelectHeader title="Re-run application" /> */}
      {/*      <Select */}
      {/*        multiple */}
      {/*        value={selectedStep} */}
      {/*        open={showSelect} */}
      {/*        variant="outlined" */}
      {/*        onOpen={() => setShowSelect(true)} */}
      {/*        onClose={() => setShowSelect(false)} */}
      {/*        sx={{ minWidth: '300px' }} */}
      {/*        onChange={handleSelectRerun} */}
      {/*      > */}
      {/*        <MenuItem sx={{ display: 'none', textAlign: 'center' }} value=""> */}
      {/*          Select step */}
      {/*        </MenuItem> */}
      {/*        {rerunLoading && !rerunSteps.length ? ( */}
      {/*          <Box sx={{ textAlign: 'center' }}> */}
      {/*            <CircularProgress color="inherit" size={25} /> */}
      {/*          </Box> */}
      {/*        ) : ( */}
      {/*          rerunSteps.map((step: string) => ( */}
      {/*            <MenuItem key={step} value={step}> */}
      {/*              {camelCaseToSeparateWords(step)} */}
      {/*            </MenuItem> */}
      {/*          )) */}
      {/*        )} */}
      {/*        {!!rerunSteps.length && ( */}
      {/*          <Box */}
      {/*            sx={{ */}
      {/*              mx: '10px', */}
      {/*              mt: '10px', */}
      {/*              p: '10px', */}
      {/*              borderTop: '1px solid #A3AED020', */}
      {/*              textAlign: 'center' */}
      {/*            }} */}
      {/*          > */}
      {/*            <CustomButton onClick={openRerunDialog} disabled={!selectedStep[0]}> */}
      {/*              <Box */}
      {/*                sx={{ */}
      {/*                  display: 'flex', */}
      {/*                  alignItems: 'center', */}
      {/*                  gap: '10px', */}
      {/*                  fontWeight: 700, */}
      {/*                  fontSize: '14px' */}
      {/*                }} */}
      {/*              > */}
      {/*                <AutorenewIcon /> */}
      {/*                Re-run */}
      {/*              </Box> */}
      {/*            </CustomButton> */}
      {/*          </Box> */}
      {/*        )} */}
      {/*      </Select> */}
      {/*    </Grid> */}
      {/*  )} */}
      {/* </Box> */}
      <Box className={classes.container}>
        {journey.map((row, rowIndex: number) => (
          <Box
            className={`${classes.row} ${!rowIndex && classes.rowStart}`}
            key={row.id}
            ref={(el: HTMLElement) => {
              if (el?.clientHeight && rowHeights[rowIndex] !== el?.clientHeight) {
                setRowHeights((prev: IRowHeight) => ({
                  ...prev,
                  ...{ [rowIndex]: el?.clientHeight },
                }));
              }
            }}
          >
            <ApplicationJourneyRow leadId={leadId} row={row} index={rowIndex} rowHeights={rowHeights} />
          </Box>
        ))}
        <Box
          className={`${classes.row} ${classes.rowEnd} ${journey.length === 1 && classes.rowEndRight} ${
            isSuccess ? classes.green : classes.gray
          }`}
        >
          <Box className={classes.step}>Outbound API</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplicationJourney;
