import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

import { Permissions } from '../../Permissions/constants';
import { hasPermission } from '../../Permissions/Permission';

import { IControlledSelectOptions } from './FormSelect';

interface SelectWithPermissionsProps {
  options: IControlledSelectOptions[];
  defaultValue?: IControlledSelectOptions | string | number;
  value?: IControlledSelectOptions | string | number;
  label?: string;
  width?: string;
  maxHeight?: string;
  nameKey?: keyof IControlledSelectOptions;
  valueKey?: keyof IControlledSelectOptions;
  permission?: Permissions;
  onSelect: (value: IControlledSelectOptions) => void;
  labelSx?: SystemCssProperties<Theme>;
  dataTest?: string;
}

const CustomSelect = ({
  options,
  defaultValue,
  value,
  label,
  width = '160px',
  nameKey = 'label',
  maxHeight = '300px',
  valueKey = 'value',
  permission,
  onSelect,
  labelSx,
  dataTest,
}: SelectWithPermissionsProps) => (
  <FormControl fullWidth sx={{ width }}>
    {label ? <Typography sx={labelSx}>{label}</Typography> : null}
    <Select
      disabled={permission ? !hasPermission(permission) : false}
      defaultValue={defaultValue}
      value={value ?? undefined}
      MenuProps={{ PaperProps: { sx: { maxHeight } } }}
      data-test={dataTest}
    >
      {options?.length ? (
        options?.map((el) => (
          // @ts-ignore
          <MenuItem key={el[valueKey]} value={el[valueKey]} onClick={() => onSelect(el)}>
            {el[nameKey]}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>No Options</MenuItem>
      )}
    </Select>
  </FormControl>
);

export default CustomSelect;
