import { Box } from '@mui/material';

import { ISolarPanelDetails } from '@types';

export enum SolarPanel {
  invertersManufacturer = 'Manufacturer of Inverters',
  batteryManufacturer = 'Manufacturer of Battery',
  estimatedAnnualProductionKW = 'Estimated Annual Production (KW)',
  systemSize = 'System Size',
  panelName = 'Manufacturer of Modules',
  addersDollarAmount = 'Total Cost of Adders',
  allAddersRelate = 'Are all adders related to the solar project',
  nonRelatedAdders = 'Explanation of non-Solar Project related adders and their costs',
}

const SolarPanelDetailsTableRow = ({ title, value }: { title: string; value: string | number }) => (
  <Box
    sx={{
      borderBottom: '1px solid #E6EBF1',
      display: 'flex',
      padding: '16px 0',
      '&:last-child': {
        border: 'none',
      },
    }}
  >
    <Box sx={{ width: '50%' }}>{title}</Box>
    <Box sx={{ width: '50%', fontWeight: 700 }}>{value}</Box>
  </Box>
);

const SolarPanelDetailsTable = ({ panelDetails }: { panelDetails: ISolarPanelDetails[] }) => (
  <Box sx={{ padding: '10px 20px', borderRadius: '12px', border: '1px solid #E2E8F0' }}>
    {panelDetails?.map((panel) => {
      if (panel.key === 'allAddersRelate') {
        return (
          <SolarPanelDetailsTableRow
            title={SolarPanel[panel.key] ?? panel.key}
            value={panel.value === 'YES' ? 'Yes' : 'No'}
          />
        );
      }

      return (
        <SolarPanelDetailsTableRow
          title={SolarPanel[panel.key as keyof typeof SolarPanel] ?? panel.key}
          value={`${panel.value}`}
        />
      );
    })}
  </Box>
);

export default SolarPanelDetailsTable;
