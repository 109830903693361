import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Grid } from '@mui/material';

import { CustomButton } from '../../components';
import { DenseTable } from '../../components/Tables';
import { IActionMenuItem } from '../../components/Tables/CustomDynamicTableDense';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { userManagementMiddleware, userManagementSelector } from '../../redux/slices/userManagement';
import { IRolesList } from '../../redux/slices/userManagement/initialState';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

const RolesList = () => {
  const [actionState, setActionState] = useState<string>('-1');

  const rolesList = useAppSelector(userManagementSelector.rolesList);
  const rolesListLoading = useAppSelector(userManagementSelector.rolesListLoading);

  const actionMenuItemsData = (role: IRolesList): IActionMenuItem[] => [
    ...(!role.isSystemDefined
      ? [
          {
            title: 'Edit',
            icon: <EditOutlinedIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
            action: () => {
              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.EditRoleModal,
                  props: {
                    role,
                  },
                }),
              );
            },
          },
        ]
      : []),
    {
      title: 'Duplicate',
      icon: <ContentCopyIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
      action: () => {
        dispatch(
          viewsMiddleware.openModal({
            name: ModalName.CreateRoleModal,
            props: {
              defaultRole: role,
            },
          }),
        );
      },
    },
    ...(!role.isSystemDefined
      ? [
          {
            title: 'Remove',
            icon: <CloseIcon width="24px" height="24px" sx={{ color: '#95A1B1' }} />,
            action: () => {
              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.DeleteRoleModal,
                  props: {
                    role,
                  },
                }),
              );
            },
          },
        ]
      : []),
  ];

  useEffect(() => {
    dispatch(userManagementMiddleware.getRolesList());
  }, []);

  if (rolesListLoading) {
    return <Loading />;
  }

  return (
    <Grid container gap={2} width="100%" flexDirection="column">
      <Grid item display="flex" justifyContent="space-between" alignItems="center">
        <Title title="Role List" />
        <Box display="flex" justifyContent="end">
          <CustomButton
            onClick={() => {
              dispatch(
                viewsMiddleware.openModal({
                  name: ModalName.CreateRoleModal,
                  props: {},
                }),
              );
            }}
          >
            Create New Role
          </CustomButton>
        </Box>
      </Grid>
      {rolesList?.length ? (
        <Box>
          <Grid item marginBottom="20px">
            {/* // TODO change after discussion  */}
            {/* <RoleListFilters applyFilters={applyFilters} resetFilters={resetFilters} /> */}
          </Grid>
          <Grid item xs={12}>
            <DenseTable
              actionState={actionState}
              setActionState={setActionState}
              actionMenuItems={actionMenuItemsData}
              data={rolesList as unknown as Record<string, string>[]}
              isAction
              isDeleted={false}
              editButton={false}
              isDropdownButtons
              isMoreDropDown
              setTableData={() => {}}
              handleRequestSort={() => {}}
              sortTableData={rolesList as unknown as Record<string, string>[]}
              selectedTableHeaders={[
                { value: 'name', title: 'Role', sortDisabled: true },
                { value: 'usersCount', title: 'Users', sortDisabled: true },
                { value: 'latestUpdate', title: 'Latest Update', sortDisabled: true },
                { value: 'updatedBy', title: 'Updated By', sortDisabled: true },
              ]}
              handleChangePage={() => {}}
              handleChangeRowsPerPage={() => {}}
              order=""
              orderBy=""
              setOrder={() => {}}
              setOrderBy={() => {}}
              noFoundBody=" "
              noFoundHeader="We couldn’t find any results"
            />
          </Grid>
        </Box>
      ) : (
        <Box>No data</Box>
      )}
    </Grid>
  );
};

export default RolesList;
