import { useCallback } from 'react';

import { IFunds } from '@types';

import { dispatch } from '../../../../redux/hooks';
import { leadsMiddleware } from '../../../../redux/slices/leads';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useFundingRequestRejectModal = (fund: IFunds, setMode: (mode: string) => void) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.FundingRequestRejectModal));
  }, []);

  const onConfirm = useCallback((reason: string) => {
    dispatch(leadsMiddleware.fetchChangeFundStatus(fund.leadId, fund.id, -1, setMode, reason));
    dispatch(viewsMiddleware.closeModal(ModalName.FundingRequestRejectModal));
  }, []);

  return {
    onClose,
    onConfirm,
  };
};

export default useFundingRequestRejectModal;
