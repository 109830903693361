import { Box } from '@mui/material';

export interface ISideTab {
  label: string;
  onClick: (value: string) => void;
  tabId: string;
  active: boolean;
}

const SideTab = ({ label, tabId, onClick, active }: ISideTab) => {
  const activeTabProps = {
    color: '#7B61FF',
    backgroundColor: '#F9F8FE',
  };

  const tabProps = {
    color: '#A3AED0',
  };

  return (
    <Box
      sx={{
        padding: '14px 16px',
        fontSize: '16px',
        margin: '6px',
        cursor: 'pointer',
        borderRadius: '8px',
        ...(active ? activeTabProps : tabProps),
      }}
      onClick={() => onClick(tabId)}
    >
      {label}
    </Box>
  );
};

export default SideTab;
