import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { CookieManager } from 'helpers/CookieManager';

class NotificationService {
  private static instance: NotificationService;

  private connection: HubConnection;

  private constructor(token: string) {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_SERVER_URL?.slice(0, -4)}/notification?token=${token}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000])
      .build();

    this.connection.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`);
    });

    this.connection.onreconnected((connectionId) => {
      if (connectionId) {
        console.info(`Connection reestablished. Connected with connectionId "${connectionId}".`);
      } else {
        console.info('Connection reestablished.');
      }
    });

    this.connection.onclose((error) => {
      console.log(`Connection closed due to error "${error}". Attempting to reconnect...`);
      setTimeout(() => this.startConnection(), 5000); // Retry connection every 5 seconds
    });
  }

  static getInstance(token?: string): NotificationService {
    if (!token && !this.instance) {
      throw new Error('Please provide token for the first initialization');
    }

    if (!NotificationService.instance && token) {
      NotificationService.instance = new NotificationService(token);
    }

    return NotificationService.instance;
  }

  startConnection = async () => {
    if (this.connection.state === HubConnectionState.Connected) {
      console.info('SignalR connected');

      return;
    }

    if (this.connection.state !== HubConnectionState.Disconnected) {
      // Specially for react strict mode error checking
      return;
    }

    try {
      await this.connection.start();
      console.info('SignalR connected');
    } catch (err) {
      console.error('Error connecting to SignalR:', err);
    }
  };

  addReceiveMessageListener = (callback?: (message: any) => void) => {
    this.connection.on('ReceiveNotification', (message: any) => {
      callback?.(message);
    });
  };

  sendMessage = async (message: string) => {
    try {
      await this.connection.invoke('SendNotification', CookieManager.getUser()?.name, message);
      console.log('Message sent:', message);
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };
}

export default NotificationService;
