import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.affiliates;

const affiliatesCount = createSelector([selector], (state) => state.affiliatesCount);

const currentAffiliateById = createSelector([selector], (state) => state.currentAffiliateById);

const creditUnions = createSelector([selector], (state) => state.creditUnions);
const creditUnionsLoading = createSelector([selector], (state) => state.creditUnionsLoading);

const externalServiceById = createSelector([selector], (state) => state.externalServiceById);

const isAffiliateChannelPostingDetailsLoading = createSelector(
  [selector],
  (state) => state.isAffiliateChannelPostingDetailsLoading,
);

const affiliateChannelPostingDetails = createSelector([selector], (state) => state.affiliateChannelPostingDetails);

const simplifiedAffiliates = createSelector([selector], (state) => state.simplifiedAffiliates);

const simplifiedAffiliateChannels = createSelector([selector], (state) => state.simplifiedAffiliateChannels);

const isLoading = createSelector([selector], (state) => state.isLoading);

const isUpdateAffiliateChannelStatusLoading = createSelector(
  [selector],
  (state) => state.isUpdateAffiliateChannelStatusLoading,
);

const isDeleteAffiliateLoading = createSelector([selector], (state) => state.isDeleteAffiliateLoading);

const isDeleteAffiliateChannelLoading = createSelector([selector], (state) => state.isDeleteAffiliateChannelLoading);

const isAffiliateChannelHistoryLoading = createSelector([selector], (state) => state.isAffiliateChannelHistoryLoading);

const affiliateChannelHistory = createSelector([selector], (state) => state.affiliateChannelHistory);

const affiliates = createSelector([selector], (state) => state.affiliates);

const isAffiliatesLoading = createSelector([selector], (state) => state.isAffiliatesLoading);

const isUpdateAffiliateStatusLoading = createSelector([selector], (state) => state.isUpdateAffiliateStatusLoading);

const affiliateChannels = createSelector([selector], (state) => state.affiliateChannels);

const isAffiliateChannelsLoading = createSelector([selector], (state) => state.isAffiliateChannelsLoading);

const dataFormats = createSelector([selector], (state) => state.dataFormats);

const successfully = createSelector([selector], (state) => state.successfully);

const isUpdateAffiliateChannelLoading = createSelector([selector], (state) => state.isUpdateAffiliateChannelLoading);

const isCreateAffiliateChannelLoading = createSelector([selector], (state) => state.isCreateAffiliateChannelLoading);

const isCreateAffiliateLoading = createSelector([selector], (state) => state.isCreateAffiliateLoading);

const channel = createSelector([selector], (state) => state.channel);
const affiliateChannelsCount = createSelector([selector], (state) => state.affiliateChannelsCount);

const campaignsAffiliateChannelsCount = createSelector([selector], (state) => state.campaignsAffiliateChannelsCount);

const affiliateChannelHistoryCount = createSelector([selector], (state) => state.affiliateChannelHistoryCount);

const affiliateFilters = createSelector([selector], (state) => state.affiliateFilters);

const affiliateChannelFilters = createSelector([selector], (state) => state.affiliateChannelFilters);

const affiliateChannelDetailsTabs = createSelector([selector], (state) => state.affiliateChannelDetailsTabs);

export default {
  affiliateChannelsCount,
  affiliateChannels,
  isAffiliateChannelsLoading,
  channel,
  creditUnions,
  creditUnionsLoading,
  affiliatesCount,
  currentAffiliateById,
  externalServiceById,
  isAffiliateChannelPostingDetailsLoading,
  affiliateChannelPostingDetails,
  simplifiedAffiliates,
  simplifiedAffiliateChannels,
  isLoading,
  isUpdateAffiliateChannelStatusLoading,
  isDeleteAffiliateLoading,
  isDeleteAffiliateChannelLoading,
  isAffiliateChannelHistoryLoading,
  affiliateChannelHistory,
  affiliates,
  isAffiliatesLoading,
  isUpdateAffiliateStatusLoading,
  dataFormats,
  successfully,
  isUpdateAffiliateChannelLoading,
  isCreateAffiliateChannelLoading,
  isCreateAffiliateLoading,
  campaignsAffiliateChannelsCount,
  affiliateChannelHistoryCount,
  affiliateFilters,
  affiliateChannelFilters,
  affiliateChannelDetailsTabs,
};
