import { Box, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';

import { IApplicationJourneyGroup, IApplicationJourneyStep } from '../../helpers/CreateApplicationJourneyData';

import ApplicationJourneyResendLoanDocument from './ApplicationJourneyResendEmail';
import ApplicationJourneyStepStatus from './ApplicationJourneyStepStatus';

import classes from '../../assets/scss/journey.module.scss';

const ApplicationJourneyStep = ({
  group,
  step,
  index,
}: {
  group: IApplicationJourneyGroup;
  step: IApplicationJourneyStep;
  index: number;
}) => (
  <Box className={`${classes.step} ${step.className}`}>
    <Box className={classes.stepContainer}>
      <Box className={`${classes.stepHeader}`} display="flex" justifyContent="center" alignItems="center">
        <Typography
          sx={{ whiteSpace: 'break-spaces', overflowWrap: 'break-word', width: '100%' }}
          className={classes.stepHeaderTitle}
        >
          {step.title}
        </Typography>
      </Box>
    </Box>
    <Permission permission={Permissions.resendLoanDocument}>
      <ApplicationJourneyResendLoanDocument step={step} />
    </Permission>
    {group.steps[index + 1] ? <ApplicationJourneyStepStatus className={step.className} /> : null}
  </Box>
);

export default ApplicationJourneyStep;
