import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Box, SxProps, Theme } from '@mui/material';
import { copyToClipboard } from 'helpers/CopyText';
import { beautifyJson } from 'utils/index';

export interface ExpendedComponentProps {
  onExtendClick: () => void;
  extended?: boolean;
  content: string;
  sxStyles?: SxProps<Theme>;
}

export const ExtendedComponent = ({ onExtendClick, extended, content, sxStyles }: ExpendedComponentProps) => (
  <Box
    sx={{
      height: '100%',
      position: 'relative',
      '&::-webkit-scrollbar': { backgroundColor: '#F4F7FE', width: '10px' },
      '&::-webkit-scrollbar-thumb': { backgroundColor: '#E0E6F4', borderRadius: 6, maxHeight: '96px', height: '95px' },
      ...sxStyles,
    }}
  >
    <Box
      sx={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        maxWidth: '70%',
        color: '#7B61FF',
      }}
    >
      {beautifyJson(content)}
    </Box>
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        right: 10,
        top: 8,
      }}
    >
      <Box>
        <ContentCopyIcon
          color="secondary"
          sx={{
            cursor: 'pointer',
            width: '18px',
            height: '18px',
          }}
          onClick={() => copyToClipboard(beautifyJson(content))}
        />
      </Box>
      <Box>
        {extended ? (
          <CloseFullscreenIcon
            sx={{
              cursor: 'pointer',
              width: '18px',
              height: '18px',
            }}
            onClick={onExtendClick}
          />
        ) : (
          <OpenInFullIcon sx={{ cursor: 'pointer', width: '18px', height: '18px' }} onClick={onExtendClick} />
        )}
      </Box>
    </Box>
  </Box>
);
