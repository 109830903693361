import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { tagsMiddleware, tagsSelector } from '../../../redux/slices/tags';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

export interface IDeleteTagModalProps {
  name: string;
  color: number;
  page: number;
  targetId: string;
}

const DeleteTagModal = ({ name, color, page, targetId }: IDeleteTagModalProps) => {
  const tagsLoading = useAppSelector(tagsSelector.tagsLoading);

  const onDelete = () => {
    dispatch(tagsMiddleware.fetchRemoveTag(name, color, page, targetId, 'delete'));
  };

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteTagModal));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Delete Tag"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            Are you sure you want to delete this tag everywhere in the project?
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" loading={tagsLoading} onClick={onDelete}>
              Delete
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteTagModal;
