import { FormProvider, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import { dateTime } from 'helpers';
import { createSendingData, isValid } from 'helpers/manualReviewReasonValidations';

import { ILeadsManualReviewReasonDocument } from '@types';

import { dispatch } from '../../redux/hooks';
import { manualReviewMiddleware } from '../../redux/slices/manualReviews';
import { CustomButton } from '../shared';

import SingleExternalDocument from './SingleExternalDocument';

export interface IExternalDocumentGroupProps {
  fileGroup: { dateTime: string; documents: ILeadsManualReviewReasonDocument[] };
  reasonId: string;
  canReviewReason: boolean;
}

const ExternalDocumentGroup = ({ fileGroup, reasonId, canReviewReason }: IExternalDocumentGroupProps) => {
  const notSubmitted = fileGroup.documents.every((el) => el.status === 3);

  const methods = useForm({
    defaultValues: { data: fileGroup.documents },
    mode: 'onSubmit',
  });

  const { watch, handleSubmit } = methods;

  const onSubmit = (data: { data: ILeadsManualReviewReasonDocument[] }) => {
    dispatch(manualReviewMiddleware.fetchManualReviewReasonSubmit(reasonId, createSendingData(data.data)));
  };

  return (
    <Box sx={{ marginY: '20px' }}>
      <Typography sx={{ marginBottom: '8px', color: '#95A1B1', fontWeight: 'bold' }}>
        {dateTime(fileGroup.dateTime)}
      </Typography>
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Box sx={{ border: '1px solid #E2E8F0', borderRadius: '12px' }}>
          <FormProvider {...methods}>
            <Box className="last_block_m_1">
              {fileGroup.documents.map((file, index) => (
                <SingleExternalDocument
                  key={file.name}
                  file={file}
                  notSubmitted={notSubmitted}
                  index={index}
                  canReviewReason={canReviewReason}
                />
              ))}
            </Box>
          </FormProvider>
          {notSubmitted && hasPermission(Permissions.stipulationApproveNotAccept) && canReviewReason ? (
            <Box padding="20px" textAlign="right">
              <CustomButton type="submit" disabled={isValid(watch('data'))}>
                Submit
              </CustomButton>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalDocumentGroup;
