import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { IDocumentsMenu, IDocumentType } from '@types';

import { DocumentTabs, DocumentTypes } from '../../../pages/Leads/Documents';
import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import FileAttachment, { IFile } from '../../FileUploadModal/FileAttachment';
import { CustomButton } from '../../shared';

import useDocumentsUploadFilesModal from './hooks/useDocumentsUploadFilesModal';

export interface IDocumentsUploadFilesModalProps {
  leadId: string;
  mode: 'Manual' | 'Document' | 'Fundings';
  reasonId?: string;
  documentCategoryId: string;
  documentTypeId: string;
}

const DocumentsUploadFilesModal = ({
  leadId,
  mode,
  reasonId,
  documentCategoryId,
  documentTypeId,
}: IDocumentsUploadFilesModalProps) => {
  const { onClose } = useDocumentsUploadFilesModal();
  const [files, setFiles] = useState<File[]>([]);
  const [documentCategory, setDocumentCategory] = useState<IDocumentsMenu>();
  const [documentType, setDocumentType] = useState<IDocumentType | null>(null);
  const documentsUploadTypes = useAppSelector(leadsSelector.documentsUploadTypes);
  const documentsUploadLoading = useAppSelector(leadsSelector.documentsUploadLoading);
  const documentsMenuLoading = useAppSelector(leadsSelector.documentsMenuLoading);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxSize: 20971520,
    accept: {
      'image/*': ['.jpeg', '.png', '.pdf', '.jpg'],
    },
  });

  const removeFile = (deletedIndex: number) => {
    const arr = files.filter((el, index) => index !== deletedIndex);

    setFiles([...arr]);
  };

  const uploadFiles = () => {
    if (mode === 'Fundings') {
      dispatch(leadsMiddleware.fetchUploadFiles(files, leadId, documentCategoryId, mode, reasonId, documentTypeId));
    } else if (documentCategory) {
      dispatch(leadsMiddleware.fetchUploadFiles(files, leadId, documentCategory?.id, mode, reasonId, documentType?.id));
    }
  };

  useEffect(() => {
    dispatch(leadsMiddleware.fetchDocumentsMenuItems());
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (acceptedFiles.length) {
      setFiles((prev) => [...prev, ...acceptedFiles]);
    } else {
      return () => {
        dispatch(leadsMiddleware.fetchClearFileList());
      };
    }
  }, [acceptedFiles]);

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle textAlign="center">
        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Upload Files</Typography>
        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            right: '10px',
          }}
        >
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ padding: '40px 75px' }}>
          <Box
            {...getRootProps()}
            sx={{
              width: '450px',
              height: '240px',
              border: '2px dashed #E2E6EA',
              borderRadius: '24px',
              backgroundColor: '#F4F7FE',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <input {...getInputProps()} />
            <Typography sx={{ marginBottom: '10px' }}>Click to browse or drag and drop your files</Typography>
            <Typography>File size should be less than 20MB</Typography>
          </Box>
          <Typography sx={{ marginTop: '16px' }}>Supported formats: PDF, JPG, JPEG, or PNG</Typography>
          {fileRejections.length ? (
            <Box sx={{ marginTop: '10px' }}>
              {fileRejections.map((item: any) => (
                <Typography sx={{ fontSize: '12px', color: '#FF497F', marginTop: '2px' }} key={item.file.name}>
                  <Typography component="span" sx={{ fontWeight: 600, color: '#FF497F', fontSize: '12px' }}>
                    {item.file.name}
                  </Typography>{' '}
                  - Upload fail:{' '}
                  {`${
                    item.errors[0]?.code === 'file-too-large'
                      ? 'The file is too large. Please upload files less than 20MB.'
                      : 'Unsupported format. Please use PDF, JPG, JPEG, or PNG.'
                  }`}
                </Typography>
              ))}
            </Box>
          ) : null}
          {files?.length ? (
            <Box>
              {documentsMenuLoading ? (
                <Box textAlign="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  {mode !== 'Fundings' ? (
                    <Box>
                      <Box margin="16px 0">
                        <FormLabel>Document Category</FormLabel>
                        <Select fullWidth required>
                          {documentsUploadTypes?.map((uploadType) => (
                            <MenuItem
                              key={uploadType.id}
                              onClick={() => {
                                setDocumentCategory(uploadType);
                                setDocumentType(null);
                              }}
                              value={uploadType.id}
                            >
                              {DocumentTabs[uploadType.name] ?? uploadType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      {documentCategory?.documentTypes?.length ? (
                        <Box margin="16px 0">
                          <FormLabel>Document Type</FormLabel>
                          <Select fullWidth required>
                            {documentCategory?.documentTypes?.map((uploadSubtype) => (
                              <MenuItem
                                onClick={() => setDocumentType(uploadSubtype)}
                                value={uploadSubtype.id}
                                key={uploadSubtype.id}
                              >
                                {DocumentTypes[uploadSubtype.name] ?? uploadSubtype.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              )}
              <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="8px">
                <Typography>Selected - {files?.length} files</Typography>
                <CloseIcon fontSize="small" onClick={() => setFiles([])} sx={{ cursor: 'pointer' }} />
              </Box>
              <Box sx={{ marginBottom: '30px', maxHeight: '200px', overflow: 'auto' }}>
                {files.map((file, index) => (
                  <FileAttachment
                    key={file.name}
                    file={file as IFile}
                    index={index}
                    removeFile={removeFile}
                    fileState={documentsUploadLoading?.find((el) => el.index === index)?.fileState}
                  />
                ))}
              </Box>
              <CustomButton disabled={mode !== 'Fundings' && !documentCategory} fullWidth onClick={uploadFiles}>
                Upload File
              </CustomButton>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsUploadFilesModal;
