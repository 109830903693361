import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { RequestFieldsTableHeader } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { useGenerateTableData } from '../Hooks';
import { Permissions } from '../Permissions/constants';
import Permission from '../Permissions/Permission';
import { CustomButton } from '../shared';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';
import BaseTabs from '../UI/Tabs';

export interface IRow {
  name: string;
  calories?: string;
  fat?: string;
  carbs?: string;
  protein?: string;
}

const createData = (name: string, calories?: string, fat?: string, carbs?: string, protein?: string) => ({
  name,
  calories,
  fat,
  carbs,
  protein,
});

export enum ChannelsPostingDetailsTabs {
  xmlExample = 'XML_EXAMPLE',
  requestFields = 'REQUEST_FIELDS',
  responses = 'RESPONSES',
}

const PostingDetails = ({ campaignId, dataTest }: { campaignId: string; dataTest?: string }) => {
  const { id: affiliateChannelId } = useParams();

  const affiliateChannelPostingDetails = useAppSelector(affiliatesSelector.affiliateChannelPostingDetails);
  const isAffiliateChannelPostingDetailsLoading = useAppSelector(
    affiliatesSelector.isAffiliateChannelPostingDetailsLoading,
  );

  const campaignFields = useAppSelector(campaignsSelector.campaignFields);

  const [tabValue, setTabValue] = useState<number | string>(ChannelsPostingDetailsTabs.xmlExample);
  const [rows, setRows] = useState<IRow[]>([]);
  const [dataIsAlreadyThere, setDataIsAlreadyThere] = useState<any[]>([]);

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    createSortHandler,
    handleChangeRowsPerPage,
    handleChangePage,
    sortTableData,
    setOrder,
    setOrderBy,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere,
  });

  const resetPassword = () => {
    dispatch(affiliatesMiddleware.fetchAffiliateChannelResetPassword(affiliateChannelId ?? '0'));
  };

  useEffect(() => {
    dispatch(campaignsMiddleware.fetchCampaignFieldsByCampaignId(campaignId));
  }, []);

  useEffect(() => {
    const getDataType = (typeValidator: number) => {
      switch (typeValidator) {
        case 1:
          return 'String';
        case 2:
          return 'Number';
        case 3:
          return 'DateTime';
        case 4:
          return 'DateOfBirth';
        default:
          return 'None';
      }
    };

    setDataIsAlreadyThere(
      campaignFields?.map(
        (item: { templateField: string; validator: number; description: string; isRequired: boolean }, i) => ({
          id: i.toString() + Math.random(),
          campaignName: item.templateField,
          dataType: getDataType(item.validator),
          description: item.description,
          required: item.isRequired ? 'Required' : 'Optional',
        }),
      ) ?? [],
    );
  }, [campaignFields]);

  // TODO: Need to change this to real data

  useEffect(() => {
    if (affiliateChannelPostingDetails) {
      setRows([
        createData('Request Format:', '', affiliateChannelPostingDetails.requestFormat),
        createData('Request Service:', '', affiliateChannelPostingDetails.requestService),
        createData('ChannelID:', '', affiliateChannelPostingDetails.channelId),
        createData('Password:', '', affiliateChannelPostingDetails.password),
        createData('Response Format:', '', affiliateChannelPostingDetails.responseFormat),
        createData('Response Encoding', '', affiliateChannelPostingDetails.responseEncoding),
        createData('Post Specification Url:', '', affiliateChannelPostingDetails.postSpecificationUrl),
        createData('Tech Contact:', '', affiliateChannelPostingDetails.techContact),
      ]);
    }
  }, [affiliateChannelPostingDetails]);

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchAffiliateChannelPostingDetails(affiliateChannelId ?? '0'));
  }, [affiliateChannelId]);

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFFFFF',
        paddingTop: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 30px',
          marginBottom: '20px',
        }}
      >
        <Typography sx={{ marginTop: '10px' }}>Request Details</Typography>
      </Box>
      {isAffiliateChannelPostingDetailsLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            backgroundColor: '#F4F7FE',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            padding: '30px',
            borderRadius: '8px',
          }}
        >
          <Box>
            <TableContainer>
              <Table
                sx={{
                  '& td': {
                    borderBottom: 'none',
                  },
                }}
                size="small"
              >
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        variant="head"
                        data-test={`${dataTest ?? ''}-info-label-${row.name}`}
                        sx={{
                          fontWeight: 700,
                          fontSize: '15px',
                          lineHeight: '18px',
                          letterSpacing: '0.5px',
                          minWidth: '220px',
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.calories}</TableCell>
                      <TableCell data-test={`${dataTest ?? ''}-info-value-${row.fat}`}>{row.fat}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Permission permission={Permissions.resetPassword}>
            <Box marginLeft="10px">
              <CustomButton
                data-test="affiliate-channel-edit-page-tab-posting-details-button-reset-password"
                variant="outlined"
                onClick={resetPassword}
              >
                Reset Password
              </CustomButton>
            </Box>
          </Permission>
        </Box>
      )}

      <Box sx={{ marginTop: '70px' }}>
        <BaseTabs
          component="div"
          tabs={[
            { id: 'XML_EXAMPLE', label: 'XML Example' },
            { id: 'REQUEST_FIELDS', label: 'Request Fields' },
            { id: 'RESPONSES', label: 'Responses' },
          ]}
          dataTest={[
            'affiliate-channel-edit-page-tab-posting-details-button-tab-xml-example',
            'affiliate-channel-edit-page-tab-posting-details-button-tab-request-fields',
            'affiliate-channel-edit-page-tab-posting-details-button-tab-responses',
          ]}
          tabValue={tabValue}
          sx={{ width: '100%', marginBottom: '24px' }}
          setTabValue={setTabValue}
        />
        {tabValue === ChannelsPostingDetailsTabs.xmlExample && (
          <Grid
            container
            sx={{
              backgroundColor: '#F4F7FE',
              padding: '30px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {affiliateChannelPostingDetails?.postSamples?.map((el) => (
              <Grid xs={6} item key={el.tabName} sx={{ padding: '5px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '18px' }}>{el.tabName}</Typography>
                </Box>
                <Box
                  sx={{
                    padding: '20px',
                    borderRadius: '8px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                  }}
                >
                  {el.codeSample}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        {tabValue === ChannelsPostingDetailsTabs.requestFields && (
          <Box
            sx={{
              backgroundColor: '#F4F7FE',
              padding: '30px',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                padding: '8px',
                borderRadius: '12px',
              }}
            >
              <DenseTable
                dataTest="lead-sources-source-channels-list-request-fields"
                selectedTableHeaders={RequestFieldsTableHeader}
                setOrder={setOrder}
                isDeleted={false}
                setOrderBy={setOrderBy}
                sortTableData={sortTableData}
                data={tableData}
                editButton={false}
                setTableData={setTableData}
                order={order as string}
                isAction={false}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                handleRequestSort={handleRequestSort}
                createSortHandler={createSortHandler}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                handleSort={() => {}}
                handleSortCount={handleSortCount}
              />
            </Box>
          </Box>
        )}
        {tabValue === ChannelsPostingDetailsTabs.responses && (
          <Grid
            container
            sx={{
              backgroundColor: '#F4F7FE',
              padding: '30px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {affiliateChannelPostingDetails?.responseSamples?.map((el) => (
              <Grid
                xs={
                  12 / Number(affiliateChannelPostingDetails?.responseSamples?.length) < 3
                    ? 3
                    : 12 / Number(affiliateChannelPostingDetails?.responseSamples?.length)
                }
                item
                key={el.tabName}
                sx={{ padding: '5px' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: '15px', lineHeight: '18px' }}>{el.tabName}</Typography>
                </Box>
                <Box
                  sx={{
                    padding: '20px',
                    borderRadius: '8px',
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                  }}
                >
                  {el.codeSample}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PostingDetails;
