/* eslint-disable */
export const renderValue = (value?: string | number) => {
  if (typeof value === 'number' && value === 0) {
    return value;
  }

  return value || '-';
};

export const renderUserName = (firstName?: string, lastName?: string, isHidden = false) =>
  isHidden ? 'Hidden' : firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName || '-';

export const renderAddress = (
  address?: string,
  unit?: string,
  city?: string,
  state?: string,
  zip?: string,
  country?: string,
  isHidden = false,
) =>
  isHidden
    ? 'Hidden'
    : [address, unit, city, state, zip, country && country.toLowerCase() !== 'us' && country]
        .filter(Boolean)
        .join(', ') || '-';
