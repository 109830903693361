import { Box, Grid } from '@mui/material';
import GeneralInformationSectionItem from 'components/ApplicationGeneralInforamtion/GeneralInformationSectionItem';

import { useAppSelector } from '../../redux/hooks';
import { leadsSelector } from '../../redux/slices/leads';

interface IGeneralInformationSectionProps {
  category: string;
}

const GeneralInformationSection = ({ category }: IGeneralInformationSectionProps) => {
  const generalInformationData = useAppSelector(leadsSelector.generalInformationData);

  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '20px',
        paddingX: '10px',
        paddingBottom: '20px',
        borderBottom: '1px solid #E5E8F0',
        '&:last-child': {
          border: 'none',
        },
      }}
    >
      <Box sx={{ width: '300px', color: '#454256', fontSize: '15px', lineHeight: '18px', fontWeight: 500 }}>
        {category}
      </Box>
      <Box width="100%">
        <Grid container width="100%" spacing={2}>
          {generalInformationData?.[category].map((item) => (
            <Grid item xs={4} key={item.title}>
              <GeneralInformationSectionItem item={item} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default GeneralInformationSection;
