export interface ICampaign {
  id: string;
  name: string;
  status: number;
  statusName: string;
  description: string;
  dataTemplate: string;
  campaignFields: ICampaignField[];
  createdBy: string;
  createdDate: string;
  updatedDate: string;
}

export interface ICampaignDetails {
  id: string;
  description?: string;
  dataTemplate?: string;
  name: string;
  status: number;
}

export interface IValidatorSettings {
  name: string;
  value: string | number;
}

export interface ICampaignForm {
  [key: string]: ICampaignField[];
}

export interface ICampaignFieldsMatchingForm {
  campaignFieldsMatchingValues: {
    id: string;
    campaignId: string;
    campaignFieldId: number;
    externalServiceFieldId: string;
    fixedValue: string;
    fieldMatchings: [];
  }[];
}

export interface IExternalServiceCampaignFieldsMatchingCreate {
  id: string;
  campaignId: string;
  campaignFieldId: number;
  externalServiceFieldId: number;
  fixedValue: string;
  fieldMatchings: [
    {
      id: string;
      externalServiceFieldId: string;
      inputValue: string;
      outputValue: string;
    },
  ];
}

export interface ICampaignField {
  localId?: string;
  id?: string | number;
  campaignId: number | string;
  templateField: string;
  databaseField: string;
  parentField: string;
  fixedValue?: string;
  dataType?: { id: string; name: string };
  systemField?: string;
  description: string;
  isRequired: boolean;
  isHash: boolean;
  validator: number;
  validatorSettings: IValidatorSettings[];
  validatorSettingsXml?: string;
  name: string;
  status: string;
  isDeleted?: boolean;
}

export interface IHistory {
  actionType: string;
  entityId: string;
  fields: { name: string; newValue: string; oldValue: string }[];
  timeStamp: string;
  userId: number;
  userName: string;
}

export interface IHistoryResponse {
  data: IHistory[];
  itemsCount: number;
  pagesCount: number;
}

export const campaignModel = {
  campaignId: '0',
  templateField: '',
  databaseField: '',
  parentField: '',
  description: '',
  dataType: '',
  isRequired: false,
  isHash: false,
  validatorSettings: [
    {
      name: '',
      value: '',
    },
  ],
  id: '0',
};
