import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';

import { ITagColor } from './TagsTypes';

const TagColor = ({ color, selected }: { color: ITagColor; selected?: boolean }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '7px',
      borderRadius: '6px',
      cursor: 'pointer',
      '&:hover': { backgroundColor: '#F9F8FE' },
    }}
  >
    <Box display="flex">
      <Box
        sx={{
          borderRadius: '50%',
          backgroundColor: color.hex,
          width: '18px',
          height: '18px',
          border: `1px solid ${color.border}`,
          marginRight: '10px',
        }}
      />
      {color.name}
    </Box>
    {selected && <CheckIcon />}
  </Box>
);

export default TagColor;
