import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { ITenantState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<ITenantState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },

  getLoading(state, action) {
    state.isLoading = action.payload;
  },

  getLogoSuccess(state, action) {
    state.logo = action.payload;
  },
});

export default reducers;
