import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box } from '@mui/material';

const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => navigate(-1)}
      sx={{
        border: '1px solid #95A1B14D',
        borderRadius: '8px',
        width: '32px',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      <ArrowBackIcon sx={{ color: '#707EAE' }} />
    </Box>
  );
};

export default GoBackButton;
