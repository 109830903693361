import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Journey } from '../../components/Journey/Journey';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import { ILeadJourneyActionType } from '../../types';

import classes from '../../assets/scss/journey.module.scss';

export const LeadJourneyActionTypes: { [key: number]: ILeadJourneyActionType } = {
  1: { title: 'Field Validation', className: classes.bgColor1 },
  2: { title: 'List Validation', className: classes.bgColor2 },
  4: { title: '3rd party API Validations', className: classes.bgColor4 },
  7: { title: 'Expression', className: classes.bgColor3 },
};

const MindMap = () => {
  const { id: leadId = '0' } = useParams();

  const journey = useAppSelector(leadsSelector.journey);
  const journeyStatus = useAppSelector(leadsSelector.journeyStatus);
  const isLeadsJourneyLoading = useAppSelector(leadsSelector.isLeadsJourneyLoading);

  useEffect(() => {
    dispatch(leadsMiddleware.fetchLeadsJourney(leadId));
  }, [leadId]);

  if (isLeadsJourneyLoading || !journey) {
    return <Loading />;
  }

  return !journey.length ? (
    <Title sx={{ padding: 0 }} title="No Data" />
  ) : (
    <Journey leadId={leadId} journey={journey} status={journeyStatus} />
  );
};

export default MindMap;
