import { createSlice } from '@reduxjs/toolkit';
import reducers from 'redux/slices/systemFields/reducers';

import { getInitialState } from './initialState';

const slice = createSlice({
  name: 'systemFields',
  initialState: getInitialState(),
  reducers,
});

export default slice;
