export const buttonsWrapper = {
  mt: 3,
  display: 'flex',
  justifyContent: 'space-between',
};

export const buttonReject = {
  borderColor: '#FF497F',
  color: '#FF497F',
};
