import { Box, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useConfirmListIntegrationDeleteControls from './hooks/useConfirmListIntegrationDeleteControls';

export interface IConfirmListIntegrationDeleteModalProps {
  id: string;
  name: string;
}

const ConfirmListIntegrationDeleteModal = ({ id, name }: IConfirmListIntegrationDeleteModalProps) => {
  const { onConfirm, onClose } = useConfirmListIntegrationDeleteControls(id, name);

  return (
    <Dialog data-test="confirm-list-integration-delete-modal" open onClose={onClose}>
      <Stack padding="10px 30px 30px 30px">
        <DialogTitle>
          <Title
            title="Delete List"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogActions>
          <Stack>
            <Typography variant="h4">Are you sure you want to delete this field?</Typography>
            <Box marginTop="25px">
              <CustomButton
                data-test="confirm-list-integration-delete-modal-button-cancel"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
              <CustomButton data-test="confirm-list-integration-delete-modal-button-delete" onClick={onConfirm}>
                Delete
              </CustomButton>
            </Box>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmListIntegrationDeleteModal;
