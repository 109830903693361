import { Dispatch, SetStateAction } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { TableHeader } from '@types';

const SelectTableHeaders = ({
  tableHeaders,
  selectedTableHeaders,
  setSelectedTableHeaders,
  dataTest,
}: {
  tableHeaders: TableHeader[];
  selectedTableHeaders: TableHeader[];
  setSelectedTableHeaders: Dispatch<SetStateAction<TableHeader[]>>;
  dataTest?: string;
}) => {
  const handleChange = ({ target: { value } }: SelectChangeEvent<TableHeader[]>) => {
    setSelectedTableHeaders(
      [...tableHeaders].filter((selectedTableHeader: TableHeader) =>
        (value as TableHeader[]).some((tableHeader: TableHeader) => tableHeader.value === selectedTableHeader.value),
      ),
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ width: 200 }} data-test={dataTest ?? ''}>
        <InputLabel id="table-headers-label" shrink data-test={dataTest ? `${dataTest}-label` : ''}>
          Custom Column
        </InputLabel>
        <Select
          data-test={dataTest ? `${dataTest}-select` : ''}
          labelId="table-headers-label"
          id="table-headers"
          multiple
          value={selectedTableHeaders}
          onChange={handleChange}
          input={<OutlinedInput label="Custom Column" />}
          renderValue={(selected: TableHeader[]) =>
            selected
              .filter((tableHeader: TableHeader) => !tableHeader.disabled)
              .map((tableHeader: TableHeader) => tableHeader.title)
              .join(', ')
          }
        >
          {tableHeaders.map((tableHeader) =>
            !tableHeader.disabled ? (
              <MenuItem key={tableHeader.value} value={tableHeader as any} disabled={tableHeader.disabled}>
                <Checkbox
                  checked={
                    selectedTableHeaders.findIndex(
                      (selectedTableHeader: TableHeader) => selectedTableHeader.value === tableHeader.value,
                    ) >= 0
                  }
                  disabled={tableHeader.disabled}
                />
                <ListItemText primary={tableHeader.title} />
              </MenuItem>
            ) : null,
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectTableHeaders;
