import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IFunding } from './initialState';

const createReducer = <T extends SliceCaseReducers<IFunding>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setFundingLoading(state, action) {
    state.fundingLoading = action.payload;
  },
  setFundingList(state, action) {
    state.fundingList = action.payload;
  },
  setFundingListFilters(state, action) {
    state.fundingListFilters = action.payload;
  },
  setFundersList(state, action) {
    state.fundersList = action.payload;
  },
  setFundersListLoading(state, action) {
    state.fundersListLoading = action.payload;
  },
  setIsDownloadFundingReportLoading(state, action) {
    state.isDownloadFundingReportLoading = action.payload;
  },
});

export default reducers;
