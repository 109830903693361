import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useTurnOnIntegrationModal from './hooks/useTurnOnIntegrationModal';

export interface ITurnOnIntegrationModal {
  item: {
    status: number;
    serviceName: string;
  };
}

const TurnOnIntegrationModal = ({ item }: ITurnOnIntegrationModal) => {
  const { onClose, onConfirm } = useTurnOnIntegrationModal();

  return (
    <Dialog data-test="turn-on-integration-modal" open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title="Turn on Integration"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            Are you sure want to turn {item.status ? 'off' : 'on'} {item.serviceName} integration?
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton data-test="turn-on-integration-modal-button-cancel" variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton data-test="turn-on-integration-modal-button-turn-on" variant="contained" onClick={onConfirm}>
              Turn {item.status ? 'off' : 'on'}
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default TurnOnIntegrationModal;
