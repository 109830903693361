import { dispatch } from 'redux/hooks';
import { snackbarMiddleware } from 'redux/slices/snackbar';
import { SeveritiesType } from 'types/snackbar';

export const copyToClipboard = (copyText: string) => {
  navigator.clipboard.writeText(copyText);
  dispatch(
    snackbarMiddleware.fetchOpenSnackbar({
      open: true,
      message: 'Copied to clipboard',
      variant: 'alert',
      alert: { color: SeveritiesType.success, variant: 'filled' },
      close: true,
      action: false,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      transition: 'Fade',
      actionButton: false,
    }),
  );
};
