import { IExternalDocuments, ILeadsManualReviewReasonDocument } from '@types';

export const isValid = (data: ILeadsManualReviewReasonDocument[]) => {
  let notValid = false;

  data.forEach((el) => {
    if (el.status === 3) {
      notValid = true;
    }

    if (el.status === 2 && !el.notes?.length) {
      notValid = true;
    }
  });

  return notValid;
};

export const createSendingData = (data: ILeadsManualReviewReasonDocument[]) =>
  data.map((el) => ({ id: el.id, status: el.status, notes: el.status === 2 ? el.notes : '' }));

export const canAccept = (data: IExternalDocuments[] | undefined) => {
  if (!data?.length) {
    return false;
  }

  let acceptDisable = false;

  data.forEach((el) => {
    if (el.documents.some((item) => item.status === 3)) {
      acceptDisable = true;
    }
  });

  return acceptDisable;
};
