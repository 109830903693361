import { CircularProgress, IconButton } from '@mui/material';

import { ButtonProps } from './Button';

const CustomIconButton = ({ onClick, loading, children, ...props }: ButtonProps) => (
  <IconButton {...props} onClick={loading ? undefined : (event, ...rest) => onClick?.(event, ...rest)}>
    {loading ? <CircularProgress size={24} /> : children}
  </IconButton>
);

export default CustomIconButton;
