import { useState } from 'react';
import { Box, Dialog, DialogActions, Stack, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../../redux/slices/campaigns';
import { CustomButton } from '../../shared';
import InputTextField from '../../UI/InputTextField';

import useConfirmCreateCampaignControls from './hooks/useConfirmCreateCampaignControls';

const ConfirmCreateCampaignModal = () => {
  const isCreateCampaignLoading = useAppSelector(campaignsSelector.isCreateCampaignLoading);

  const { onClose } = useConfirmCreateCampaignControls();
  const [campaignName, setCampaignName] = useState('');

  const disabledSubmitButton = () => !campaignName || campaignName.length < 2;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(campaignsMiddleware.fetchCreateCampaign({ id: '0', name: campaignName, status: 0 }));
  };

  return (
    <Dialog data-test="confirm-create-campaign-modal" open onClose={onClose}>
      <Stack width="300px">
        <DialogActions>
          <Box
            onSubmit={onSubmit}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '10px',
              width: '100%',
              maxWidth: '350px',
            }}
            component="form"
          >
            <Stack alignItems="center" borderBottom="2px solid #7B61FF" padding="5px 0">
              <Typography color="#7B61FF" fontWeight="700" fontSize="15px" lineHeight="18px">
                Create New Product
              </Typography>
            </Stack>
            <Stack spacing={2} marginTop="20px">
              <Typography variant="h4" textAlign="start" fontWeight="bold" color="#707D9D">
                Product Name
              </Typography>
              <Box>
                <InputTextField
                  data-test="confirm-create-campaign-modal-input-campaign-name"
                  id="campaignName"
                  onChange={(e) => {
                    e.target.value = e.target.value.trimStart();
                    setCampaignName(e.target.value);
                  }}
                  value={campaignName}
                  placeholder="Name your Product"
                  name="campaignName"
                  fullWidth
                />
              </Box>

              <Box width="100%">
                <CustomButton
                  data-test="confirm-create-campaign-modal-button-new-product"
                  disabled={!!disabledSubmitButton() || isCreateCampaignLoading}
                  loading={isCreateCampaignLoading}
                  type="submit"
                  fullWidth
                >
                  {isCreateCampaignLoading ? null : 'New Product'}
                </CustomButton>
              </Box>
            </Stack>
          </Box>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmCreateCampaignModal;
