import { Status } from '../../types';

export const Statuses: { [key: number]: Status } = {
  0: {
    color: '#984C0C',
    bgColor: '#FFF1E7',
    border: '1px solid #FECBA1',
    title: 'Processing',
  },
  1: {
    color: '#0AA2C0',
    bgColor: '#E6F9FD',
    border: '1px solid #9EEAF9',
    title: 'Waiting',
  },
  2: {
    color: '#984C0C',
    bgColor: '#FFF1E7',
    border: '1px solid #FECBA1',
    title: 'Processing',
  },
  3: {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Success',
  },
  4: {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Reject',
  },
  5: {
    color: '#AB296A',
    bgColor: '#F7D6E680',
    border: '1px solid #EFADCE',
    title: 'Canceled',
  },
};

export const LeadsFundingStatuses: { [key: string]: Status } = {
  '0': {
    color: '#984C0C',
    bgColor: '#FFF1E7',
    border: '1px solid #FECBA1',
    title: 'To Review',
  },
  '1': {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Approved',
  },
  '-1': {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Rejected',
  },
};

export const LeadsLogsStatuses: { [key: string]: Status } = {
  Waiting: {
    color: '#0AA2C0',
    bgColor: '#E6F9FD',
    border: '1px solid #9EEAF9',
    title: 'Waiting',
  },
  BadRequest: {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Bad Request',
  },
  Success: {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Success',
  },
  Started: {
    color: '#593559',
    bgColor: '#F7F4FB',
    border: '1px solid #C5B3E6',
    title: 'Started',
  },
  Pending: {
    color: '#997404',
    bgColor: '#FFF8E5',
    border: '1px solid #FFE69C',
    title: 'Pending',
  },
  Reject: {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Reject',
  },
};

export const LeadsDocumentsStatuses: { [key: string]: Status } = {
  '0': {
    color: '#0AA2C0',
    bgColor: '#E6F9FD',
    border: '1px solid #9EEAF9',
    title: 'Not Assigned',
  },
  '1': {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Accepted',
  },
  '2': {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Rejected',
  },
  '3': {
    color: '#997404',
    bgColor: '#FFF8E5',
    border: '1px solid #FFE69C',
    title: 'In Review',
  },
};

export const ManualReviewStatuses: { [key: string]: Status } = {
  '0': {
    color: '#0AA2C0',
    bgColor: '#E6F9FD',
    border: '1px solid #9EEAF9',
    title: 'Not Assigned',
  },
  '1': {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Accepted',
  },
  '2': {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Rejected',
  },
  '3': {
    color: '#997404',
    bgColor: '#FFF8E5',
    border: '1px solid #FFE69C',
    title: 'In Review',
  },
  '4': {
    color: '#59359A',
    bgColor: '#F7F4FB',
    border: '1px solid #C5B3E6',
    title: 'Pending',
  },
};

export const ManualReviewUploadedDocumentStatuses: { [key: string]: Status } = {
  '1': {
    color: '#146C43',
    bgColor: '#E8F9F4',
    border: '1px solid #A3CFBB',
    title: 'Approved',
  },
  '2': {
    color: '#AB296A',
    bgColor: '#FAEAF2',
    border: '1px solid #EFADCE',
    title: 'Not Accepted',
  },
};
