import { dispatch } from '../../hooks';

import slice from './slice';

const { activeItem, openDrawer } = slice.actions;

const setActiveItem = (id: string[]) => async () => {
  dispatch(activeItem(id));
};

const setOpenDrawer = (value: boolean) => async () => {
  dispatch(openDrawer(value));
};

export default {
  setActiveItem,
  setOpenDrawer,
};
