import { Box, Dialog, DialogActions, DialogTitle, Grid, Stack, Typography } from '@mui/material';

import { AffiliateChannelFormValues } from '../../../pages/Affiliates/AffiliateChannelCreateOrEditPage';
import { CustomButton } from '../../shared';

import useConfirmAffiliateChannelEditSaveControls from './hooks/useConfirmAffiliateChannelEditSaveControls';

export interface IConfirmAffiliateChannelEditSaveModalProps {
  data: AffiliateChannelFormValues;
  affiliateChannelId: string;
}

const ConfirmAffiliateChannelEditSaveModal = ({
  data,
  affiliateChannelId,
}: IConfirmAffiliateChannelEditSaveModalProps) => {
  const { onConfirm, onClose } = useConfirmAffiliateChannelEditSaveControls(data, affiliateChannelId);

  return (
    <Dialog data-test="confirm-affiliate-channel-edit-save-modal" open onClose={onClose}>
      <Stack sx={{ width: '350px' }}>
        <DialogTitle>
          <Stack width="100%" justifyContent="center" alignItems="center">
            <Typography fontSize="1.25rem" fontWeight="700">
              Save Changes
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography textAlign="center">Do you want to save your changes?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CustomButton
                  data-test="confirm-affiliate-channel-edit-save-modal-button-cancel"
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </CustomButton>
                <CustomButton data-test="confirm-affiliate-channel-edit-save-modal-button-save" onClick={onConfirm}>
                  Save
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmAffiliateChannelEditSaveModal;
