import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import HeaderWithFilters from 'components/HeaderWithFilters';
import { createDropdownOptions } from 'helpers/createDropdownOptions';
import moment from 'moment';

import LineDownSvg from '../../components/Icons/LineDownSvg';
import LineUpSvg from '../../components/Icons/LineUpSvg';
import StraightLineIcon from '../../components/Icons/StraightLineIcon';
import { ISelectOption } from '../../components/UI/CustomAutocomplete';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { reportsMiddleware, reportsSelector } from '../../redux/slices/reports';
import { IAffiliatesComparisonReports, ISimplifiedList } from '../../types';

import { AffiliatesComparisonReportsFilters } from './AffiliatesComparisonReportsFilters';

import classes from '../../assets/scss/affiliateReports.module.scss';

interface IAffiliatesComparisonReportsFormatedDateData {
  reportDate: string;
  firstComparisonDate: string;
  secondComparisonDate: string;
}

const AffiliatesComparisonReports = () => {
  const comparisonReports = useAppSelector(reportsSelector.comparisonReports);
  const isComparisonReportsLoading = useAppSelector(reportsSelector.isComparisonReportsLoading);
  const [formattedDateData, setFormattedDateData] = useState<IAffiliatesComparisonReportsFormatedDateData>();
  const [reportDate, setReportDate] = useState<Date>(moment().toDate());
  const [affiliateDropdownOptions, setAffiliateDropdownOptions] = useState<ISelectOption[]>([]);
  const [affiliatesChannelDropdownOptions, setAffiliatesChannelDropdownOptions] = useState<ISelectOption[]>([]);
  const [filteredAffiliateData, setFilteredAffiliateData] = useState<ISelectOption[]>([]);
  const [filteredAffiliateChannelData, setFilteredAffiliateChannelData] = useState<ISelectOption[]>([]);
  const [firstComparisonDate, setFirstComparisonDate] = useState<Date>(moment().subtract(1, 'day').toDate());
  const [secondComparisonDate, setSecondComparisonDate] = useState<Date>(moment().subtract(2, 'day').toDate());
  const affiliateChannelsList = useAppSelector(affiliatesSelector.simplifiedAffiliateChannels);
  const affiliateList = useAppSelector(affiliatesSelector.simplifiedAffiliates);

  const renderLeadsIcon = (leads: number, comparisonLeads: number) => {
    if (leads > comparisonLeads) {
      return <LineDownSvg />;
    }

    if (leads === comparisonLeads) {
      return <StraightLineIcon />;
    }

    return <LineUpSvg />;
  };

  const resetFilters = () => {
    dispatch(
      reportsMiddleware.fetchAffiliatesComparisonReports({
        reportDate,
        firstComparisonDate,
        secondComparisonDate,
        affiliates: [-1],
        affiliateChannels: [-1],
        campaigns: [-1],
        pageIndex: 1,
        pageSize: DEFAULT_ROWS_PAGE,
      }),
    );

    setReportDate(moment().toDate());
    setFirstComparisonDate(moment().subtract(1, 'day').toDate());
    setSecondComparisonDate(moment().subtract(2, 'day').toDate());
    setFilteredAffiliateChannelData(affiliatesChannelDropdownOptions);
    setFilteredAffiliateData(affiliateDropdownOptions);
  };

  const applyFilters = () => {
    dispatch(
      reportsMiddleware.fetchAffiliatesComparisonReports({
        campaigns: [-1],
        reportDate: moment(reportDate).format(),
        firstComparisonDate: moment(firstComparisonDate).format(),
        secondComparisonDate: moment(secondComparisonDate).format(),
        affiliates: filteredAffiliateData.map((item: ISelectOption) => item.id),
        affiliateChannels: filteredAffiliateChannelData.map((item: ISelectOption) => item.id),
        pageIndex: 1,
        pageSize: DEFAULT_ROWS_PAGE,
      }),
    );
  };

  useEffect(() => {
    setFormattedDateData({
      reportDate: moment(reportDate).format('MM/dd/yyyy'),
      firstComparisonDate: moment(firstComparisonDate).format('MM/dd/yyyy'),
      secondComparisonDate: moment(secondComparisonDate).format('MM/dd/yyyy'),
    });
  }, [reportDate, firstComparisonDate, secondComparisonDate]);

  useEffect(() => {
    const affiliateComparisonReportsRequestInitialData = {
      reportDate: moment(reportDate).format(),
      firstComparisonDate: moment(firstComparisonDate).format(),
      secondComparisonDate: moment(secondComparisonDate).format(),
      affiliates: [-1],
      affiliateChannels: [-1],
      campaigns: [-1],
      pageIndex: 1,
      pageSize: DEFAULT_ROWS_PAGE,
    };

    dispatch(reportsMiddleware.fetchAffiliatesComparisonReports(affiliateComparisonReportsRequestInitialData));
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliateChannels());
  }, []);

  useEffect(() => {
    const optionsAffiliates = createDropdownOptions(affiliateList as ISimplifiedList[]);
    const optionsAffiliatesChannel = createDropdownOptions(affiliateChannelsList as ISimplifiedList[]);

    if (optionsAffiliates && optionsAffiliatesChannel) {
      setAffiliateDropdownOptions(optionsAffiliates);
      setFilteredAffiliateData(optionsAffiliates);
      setAffiliatesChannelDropdownOptions(optionsAffiliatesChannel);
      setFilteredAffiliateChannelData(optionsAffiliatesChannel);
    }
  }, [affiliateChannelsList, affiliateList]);

  if (isComparisonReportsLoading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderWithFilters title="Source Comparison Reports">
        <AffiliatesComparisonReportsFilters
          reportDate={reportDate}
          setReportDate={setReportDate}
          firstComparisonDate={firstComparisonDate}
          setFirstComparisonDate={setFirstComparisonDate}
          secondComparisonDate={secondComparisonDate}
          setSecondComparisonDate={setSecondComparisonDate}
          affiliateDropdownOptions={affiliateDropdownOptions}
          filteredAffiliateData={filteredAffiliateData}
          setFilteredAffiliateData={setFilteredAffiliateData}
          affiliatesChannelDropdownOptions={affiliatesChannelDropdownOptions}
          filteredAffiliateChannelData={filteredAffiliateChannelData}
          setFilteredAffiliateChannelData={setFilteredAffiliateChannelData}
          resetFilters={resetFilters}
          applyFilters={applyFilters}
        />
      </HeaderWithFilters>
      {!comparisonReports?.reports?.length ? (
        <Title title="No Source Comparison Reports" />
      ) : (
        <TableContainer className={classes.mainRoot} sx={{ marginTop: '24px' }}>
          <Table data-test="affiliates-comparison-reports-table">
            <TableHead>
              <TableRow sx={{ cursor: 'default !important' }}>
                <TableCell />
                <TableCell />
                <TableCell>Received Applications</TableCell>
                <TableCell />
                <TableCell />
                <TableCell>Accepted Applications</TableCell>
                <TableCell />
                <TableCell />
                <TableCell>Rejected Applications</TableCell>
                <TableCell />
              </TableRow>
              <TableRow sx={{ cursor: 'default !important' }}>
                <TableCell className={classes.channels}>Channels</TableCell>
                <TableCell>
                  <Typography> {formattedDateData?.reportDate} </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.firstComparisonDate}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.secondComparisonDate}</Typography>
                </TableCell>
                <TableCell>
                  <Typography> {formattedDateData?.reportDate} </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.firstComparisonDate}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.secondComparisonDate}</Typography>
                </TableCell>
                <TableCell>
                  <Typography> {formattedDateData?.reportDate} </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.firstComparisonDate}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{formattedDateData?.secondComparisonDate}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {comparisonReports?.reports.map((report: IAffiliatesComparisonReports) => (
                <TableRow
                  sx={{ cursor: 'default !important' }}
                  data-test={`affiliate-comparison-reports-page-table-id-${report.affiliateChannelId}`}
                  key={report.affiliateChannelId}
                >
                  <TableCell>{report.affiliateChannel}</TableCell>
                  <TableCell>{report.receivedLeads}</TableCell>
                  <TableCell>
                    {report.firstComparisonReceivedLeads}
                    {renderLeadsIcon(report.receivedLeads, report.firstComparisonReceivedLeads)}
                    {`${report.firstComparisonReceivedLeadsRate}%`}
                  </TableCell>
                  <TableCell>
                    {report.secondComparisonReceivedLeads}
                    {renderLeadsIcon(report.receivedLeads, report.secondComparisonReceivedLeads)}
                    {`${report.secondComparisonReceivedLeadsRate}%`}
                  </TableCell>
                  <TableCell>{report.acceptedLeads}</TableCell>
                  <TableCell>
                    {report.firstComparisonAcceptedLeads}
                    {renderLeadsIcon(report.acceptedLeads, report.firstComparisonAcceptedLeads)}
                    {`${report.firstComparisonAcceptedLeadsRate}%`}
                  </TableCell>
                  <TableCell>
                    {report.secondComparisonAcceptedLeads}
                    {renderLeadsIcon(report.acceptedLeads, report.secondComparisonAcceptedLeads)}
                    {`${report.secondComparisonAcceptedLeadsRate}%`}
                  </TableCell>
                  <TableCell>{report.rejectedLeads}</TableCell>
                  <TableCell>
                    {report.firstComparisonRejectedLeads}
                    {renderLeadsIcon(report.rejectedLeads, report.firstComparisonRejectedLeads)}
                    {`${report.firstComparisonRejectedLeadsRate}%`}
                  </TableCell>
                  <TableCell>
                    {report.secondComparisonRejectedLeads}
                    {renderLeadsIcon(report.rejectedLeads, report.secondComparisonRejectedLeads)}
                    {`${report.secondComparisonRejectedLeadsRate}%`}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ cursor: 'default !important' }} className={classes.Total}>
                <TableCell>Total</TableCell>
                <TableCell>{comparisonReports.totalReceivedLeads}</TableCell>
                <TableCell>
                  {comparisonReports.totalFirstComparisonReceivedLeads}
                  {renderLeadsIcon(
                    comparisonReports.totalReceivedLeads,
                    comparisonReports.totalFirstComparisonReceivedLeads,
                  )}
                  {`${comparisonReports.totalFirstComparisonReceivedLeadsRate}%`}
                </TableCell>
                <TableCell>
                  {comparisonReports.totalSecondComparisonReceivedLeads}
                  {renderLeadsIcon(
                    comparisonReports.totalReceivedLeads,
                    comparisonReports.totalSecondComparisonReceivedLeads,
                  )}
                  {`${comparisonReports.totalSecondComparisonReceivedLeadsRate}%`}
                </TableCell>
                <TableCell>{comparisonReports.totalAcceptedLeads}</TableCell>
                <TableCell>
                  {comparisonReports.totalFirstComparisonAcceptedLeads}
                  {comparisonReports.totalAcceptedLeads > comparisonReports.totalFirstComparisonAcceptedLeads ? (
                    <LineDownSvg />
                  ) : (
                    <LineUpSvg />
                  )}
                  {`${comparisonReports.totalFirstComparisonAcceptedLeadsRate}%`}
                </TableCell>
                <TableCell>
                  {comparisonReports.totalSecondComparisonAcceptedLeads}
                  {comparisonReports.totalAcceptedLeads > comparisonReports.totalSecondComparisonAcceptedLeads ? (
                    <LineDownSvg />
                  ) : (
                    <LineUpSvg />
                  )}
                  {`${comparisonReports.totalSecondComparisonAcceptedLeadsRate}%`}
                </TableCell>
                <TableCell>{comparisonReports.totalRejectedLeads}</TableCell>
                <TableCell>
                  {comparisonReports.totalFirstComparisonRejectedLeads}
                  {renderLeadsIcon(
                    comparisonReports.totalRejectedLeads,
                    comparisonReports.totalFirstComparisonRejectedLeads,
                  )}

                  {`${comparisonReports.totalFirstComparisonRejectedLeadsRate}%`}
                </TableCell>
                <TableCell>
                  {comparisonReports.totalSecondComparisonRejectedLeads}
                  {renderLeadsIcon(
                    comparisonReports.totalRejectedLeads,
                    comparisonReports.totalSecondComparisonRejectedLeads,
                  )}
                  {`${comparisonReports.totalSecondComparisonRejectedLeadsRate}%`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AffiliatesComparisonReports;
