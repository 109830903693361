import React, { SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrangementOrder, GetComparator, KeyedObject } from '@types';

import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import DataService from '../../service/data.service';

export interface CreateDataType {
  Id: string;
  CreatedAt: string;
  CreatedBy: string;
  Name: string;
  StatusName: string;
  UpdatedAt: string;
  Status: number;
}

export const sorterComparator = (a: KeyedObject, b: KeyedObject, orderBy: string) => {
  if (b[orderBy]?.toString().toLowerCase() < a[orderBy]?.toString().toLowerCase()) {
    return -1;
  }

  if (b[orderBy]?.toString().toLowerCase() > a[orderBy]?.toString().toLowerCase()) {
    return 1;
  }

  return 0;
};

const getComparator: GetComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => sorterComparator(a, b, orderBy) : (a, b) => -sorterComparator(a, b, orderBy);

const stableSort = (array: CreateDataType[], comparator: (a: KeyedObject, b: KeyedObject) => number) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0] as CreateDataType, b[0] as CreateDataType);

    if (order !== 0) {
      return order;
    }

    return (a[1] as number) - (b[1] as number);
  });

  return stabilizedThis.map((el) => el[0]);
};

const useGenerateTableData = ({
  endpoint,
  type,
  dataIsAlreadyThere,
  isDispatchHistoryLoading,
  params = {},
  initialRowsPerPage = DEFAULT_ROWS_PAGE,
  paginationCount = 0,
  pagesCount = 0,
}: {
  endpoint?: any;
  type?: string;
  dataIsAlreadyThere?: any[] | null;
  isDispatchHistoryLoading?: boolean;
  params?: { [key: string]: string | number | number[] };
  initialRowsPerPage?: number;
  paginationCount?: number;
  pagesCount?: number;
}) => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableDataHeaders, setTableDataHeaders] = useState<string[]>([]);
  const [tableDataError, serTableDataError] = useState();
  const [order, setOrder] = useState<ArrangementOrder>('desc');
  const [orderBy, setOrderBy] = useState<string>('Id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [sortTableData, setSortTableData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<SetStateAction<boolean>>(false);
  const [sortCount, setSortCount] = useState<number>(1);
  const navigate = useNavigate();

  if (pagesCount) {
    // eslint-disable-next-line no-param-reassign
    paginationCount = pagesCount * rowsPerPage;
  }

  const handleRequestSort = (event: React.SyntheticEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (endpoint && !dataIsAlreadyThere) {
      (async () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await getData();
      })();
    }
  }, [endpoint, navigate, isDispatchHistoryLoading]);

  useEffect(() => {
    if (dataIsAlreadyThere && dataIsAlreadyThere.length > 0) {
      setSortTableData(
        paginationCount
          ? dataIsAlreadyThere
          : dataIsAlreadyThere.slice(rowsPerPage * page, page > 0 ? page * rowsPerPage + rowsPerPage : rowsPerPage),
      );
      setTableData(dataIsAlreadyThere);
      setIsLoading(false);
    } else {
      setTableData([]);
    }
  }, [dataIsAlreadyThere]);

  useEffect(() => {
    setSortTableData(
      stableSort(tableData, getComparator(order, orderBy)).slice(
        rowsPerPage * page,
        page > 0 ? page * rowsPerPage + rowsPerPage : rowsPerPage,
      ),
    );
  }, [order, orderBy]);

  useEffect(() => {
    if (paginationCount) {
      return;
    }

    setSortTableData(() =>
      tableData.slice(rowsPerPage * page, page > 0 ? page * rowsPerPage + rowsPerPage : rowsPerPage),
    );
  }, [rowsPerPage, page]);

  const getData = async () => {
    try {
      setIsLoading(true);

      let response: typeof tableData;

      if (type === 'post') {
        response = await DataService.post(endpoint, params);
      } else {
        response = await DataService.getJson(endpoint, params);
      }

      if (response) {
        setSortTableData(response.slice(rowsPerPage * page, page > 0 ? page * rowsPerPage + rowsPerPage : rowsPerPage));
        setTableData(response);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const createSortHandler = (property: string) => (event: React.SyntheticEvent) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    setRowsPerPage(parseInt(event?.target.value!, DEFAULT_ROWS_PAGE));
    setPage(0);
  };

  const handleStatusChange = (event: React.SyntheticEvent<HTMLButtonElement, MouseEventInit>) => {
    console.log(event);
  };

  const handleSortCount = (
    header: string,
    handleSort: (event: React.SyntheticEvent, property: string, newOrder: boolean) => void,
    e: React.SyntheticEvent,
    getCorrectTableHeader: (value: string) => string,
    rowKey: string,
  ) => {
    const sortByOrder = (count: number, newOrder: 'asc' | 'desc') => {
      setSortCount(count);
      setOrderBy(getCorrectTableHeader(header));
      setOrder(newOrder);
      handleSort(e, getCorrectTableHeader(header), newOrder === 'asc');
    };

    if (sortCount === 1) {
      if (orderBy === getCorrectTableHeader(header)) {
        sortByOrder(2, 'desc');
      } else {
        sortByOrder(1, 'asc');
      }
    } else if (sortCount === 2) {
      if (orderBy === getCorrectTableHeader(header)) {
        setOrderBy(rowKey === 'id' ? 'Id' : rowKey ?? 'Id');
        setOrder('desc');
        handleSort(e, rowKey === 'id' ? 'Id' : rowKey ?? 'Id', false);
        setSortCount(3);
      } else {
        sortByOrder(1, 'asc');
      }
    } else if (sortCount === 3) {
      if (orderBy === getCorrectTableHeader(header)) {
        sortByOrder(2, 'desc');
      } else {
        sortByOrder(1, 'asc');
      }
    }
  };

  return {
    tableData,
    tableDataHeaders,
    tableDataError,
    serTableDataError,
    setTableDataHeaders,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    handleChangeRowsPerPage,
    handleChangePage,
    handleStatusChange,
    sortTableData,
    setOrder,
    setOrderBy,
    setIsLoading,
    isLoading,
    getData,
    paginationCount,
    handleSortCount,
    sortCount,
  };
};

export default useGenerateTableData;
