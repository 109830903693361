import { Box } from '@mui/material';

import { IApplicationJourney, IApplicationJourneyGroup } from '../../helpers/CreateApplicationJourneyData';

import ApplicationJourneyStep from './ApplicationJourneyStep';
import ApplicationJourneyStepStatus from './ApplicationJourneyStepStatus';

import classes from '../../assets/scss/journey.module.scss';

const ApplicationJourneyGroup = ({
  row,
  group,
  index,
}: {
  row: IApplicationJourney;
  group: IApplicationJourneyGroup;
  index: number;
}) => (
  <Box className={`${classes.step} ${group.className}`}>
    <Box className={classes.stepBody}>
      {group.steps.map((step, stepIndex: number) => (
        <ApplicationJourneyStep key={step.id} group={group} step={step} index={stepIndex} />
      ))}
    </Box>
    {row.groups[index + 1] ? (
      <ApplicationJourneyStepStatus
        className={`${classes.stepStatusGroup} ${group.steps[group.steps.length - 1].className}`}
      />
    ) : null}
  </Box>
);

export default ApplicationJourneyGroup;
