import { useFormContext } from 'react-hook-form';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';

import { ILeadsManualReviewReasonDocument } from '@types';

import InputTextField from '../UI/InputTextField';

import SingleFileAttachment from './SingleFileAttachment';

export interface ISingleExternalDocumentProps {
  file: ILeadsManualReviewReasonDocument;
  notSubmitted: boolean;
  index: number;
  canReviewReason: boolean;
}

const SingleExternalDocumentRoot = styled(Box)({
  borderBottom: '1px solid #E2E8F0',
  padding: '20px',
  ':last-child': {
    border: 'none',
  },
});

const SingleExternalDocument = ({ file, notSubmitted, index, canReviewReason }: ISingleExternalDocumentProps) => {
  const { watch, setValue } = useFormContext();

  return (
    <SingleExternalDocumentRoot>
      <SingleFileAttachment file={file} showStatus canView />
      {file.notes ? (
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>NOTE:</Typography> <Typography>{file.notes}</Typography>
        </Box>
      ) : null}
      {notSubmitted && hasPermission(Permissions.stipulationApproveNotAccept) && canReviewReason ? (
        <Box>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                value={2}
                onChange={() => setValue(`data[${index}].status`, 2)}
                control={<Radio />}
                label="Not Accepted"
                sx={{ color: '#FF497F', fontSize: '16px', fontWeight: 500 }}
              />
              <FormControlLabel
                value={1}
                onChange={() => setValue(`data[${index}].status`, 1)}
                control={<Radio />}
                label="Approve"
                sx={{ color: '#146C43', fontSize: '16px', fontWeight: 500 }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ) : null}
      {watch(`data[${index}].status`) === 2 && (
        <Box>
          <InputTextField
            value={watch(`data[${index}].notes`)}
            disabled={!notSubmitted || !hasPermission(Permissions.stipulationApproveNotAccept)}
            fullWidth
            onChange={(event) => setValue(`data[${index}].notes`, event.target.value)}
          />
        </Box>
      )}
    </SingleExternalDocumentRoot>
  );
};

export default SingleExternalDocument;
