import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.reports;

const comparisonReports = createSelector([selector], (state) => state.comparisonReports);

const isComparisonReportsLoading = createSelector([selector], (state) => state.isComparisonReportsLoading);

const affiliateReports = createSelector([selector], (state) => state.affiliateReports);

const isReportsLoading = createSelector([selector], (state) => state.isReportsLoading);

const isDownloadLeadListReportLoading = createSelector([selector], (state) => state.isDownloadLeadListReportLoading);

export default {
  comparisonReports,
  isComparisonReportsLoading,
  affiliateReports,
  isReportsLoading,
  isDownloadLeadListReportLoading,
};
