import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Checkbox, Dialog, Grid, TextField, Typography } from '@mui/material';

import { PagesSearch } from '../../Layout/MainLayout/Header/SearchSection';
import { CustomButton } from '../../shared';

import usePostedToAPIModal from './hooks/usePostedToAPIModal';

const postingData = [
  { post: true, name: 'Name', response: '' },
  { post: true, name: 'Country', response: '' },
  { post: true, name: 'State', response: '' },
  { post: true, name: 'Zip Code', response: '' },
  { post: true, name: 'DOB', response: '' },
  { post: true, name: 'IP', response: '' },
  { post: true, name: 'Rent or own', response: '' },
  { post: true, name: 'Marital Status', response: '' },
  { post: true, name: 'DLNUMBER', response: '' },
  { post: true, name: 'SSN', response: '' },
  { post: true, name: 'Armed Forces', response: '' },
  { post: true, name: 'Requested Amount', response: '' },
  { post: true, name: 'Education', response: '' },
  { post: true, name: 'Bank', response: '' },
  { post: true, name: 'Account Type', response: '' },
  { post: true, name: 'Payday Freaquency', response: '' },
  { post: true, name: 'Rent or own', response: '' },
  { post: true, name: 'Martial Status', response: '' },
  { post: true, name: 'DLNUMBER', response: '' },
  { post: true, name: 'SSN', response: '' },
];

const PostedToAPIModal = () => {
  const { onClose, onConfirm } = usePostedToAPIModal();

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: { data: postingData },
  });

  const { control, watch, register, handleSubmit } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'data',
  });

  const ArrayMiddle = Math.floor(fields.length / 2);

  const RightArray = fields.slice(0, ArrayMiddle);

  const LeftArray = fields.slice(ArrayMiddle);

  return (
    <Dialog data-test="posted-to-api-modal" open onClose={onClose} maxWidth="lg">
      <Box onSubmit={handleSubmit(() => {})} component="form">
        <Grid container>
          <Grid item xs={12} textAlign="center" paddingY="25px" bgcolor="#F9F8FE" fontSize="18px" fontWeight="700">
            Posting To The Outbound API
          </Grid>
          <Grid item xs={12} paddingX="40px">
            <Typography padding="10px 20px">Check the fields you want to post</Typography>
            <PagesSearch dataTest="posted-to-api-modal-input-search" />
          </Grid>
          <Grid item xs={6} padding="40px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="20px 40px"
              border="1px solid #f4f7fe"
            >
              <Box display="flex" gap="20px">
                <Typography>Post</Typography>
                <Typography>Field Name</Typography>
              </Box>
              <Box minWidth="180px">
                <Typography>Response</Typography>
              </Box>
            </Box>
            {RightArray.map((item, index) => (
              <Box
                data-test={`posted-to-api-modal-table-id-${index}`}
                key={item.name}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="10px 40px"
                border="1px solid #f4f7fe"
              >
                <Checkbox
                  data-test={`posted-to-api-modal-table-id-${index}-checkbox`}
                  checked={watch(`data.${index}.post`)}
                  {...register(`data.${index}.post`)}
                />
                <Box textAlign="left" width="50%">
                  <Typography>{item.name}</Typography>
                </Box>
                <Controller
                  data-test={`posted-to-api-modal-table-id-${index}-input-response`}
                  control={control}
                  name={`data.${index}.response`}
                  render={({ field }) => <TextField sx={{ minWidth: '10px !important' }} {...field} />}
                />
              </Box>
            ))}
          </Grid>
          <Grid item xs={6} padding="40px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="20px 40px"
              border="1px solid #f4f7fe"
            >
              <Box display="flex" gap="20px">
                <Typography>Post</Typography>
                <Typography>Field Name</Typography>
              </Box>
              <Box minWidth="180px">
                <Typography>Response</Typography>
              </Box>
            </Box>
            {LeftArray.map((item, index) => (
              <Box
                data-test={`posted-to-api-modal-table-id-${ArrayMiddle + index}`}
                key={item.name}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="10px 40px"
                border="1px solid #f4f7fe"
              >
                <Checkbox
                  data-test={`posted-to-api-modal-table-id-${ArrayMiddle + index}-checkbox`}
                  checked={watch(`data.${ArrayMiddle + index}.post`)}
                  {...register(`data.${ArrayMiddle + index}.post`)}
                />
                <Box textAlign="left" width="50%">
                  <Typography>{item.name}</Typography>
                </Box>
                <Controller
                  data-test={`posted-to-api-modal-table-id-${ArrayMiddle + index}-input-response`}
                  control={control}
                  name={`data.${ArrayMiddle + index}.response`}
                  render={({ field }) => <TextField sx={{ minWidth: '10px !important' }} {...field} />}
                />
              </Box>
            ))}
          </Grid>
        </Grid>
        <Box display="flex" paddingX="50%" justifyContent="space-around" marginTop="30px">
          <CustomButton data-test="posted-to-api-modal-button-back" variant="outlined" onClick={onClose}>
            Back
          </CustomButton>
          <CustomButton data-test="posted-to-api-modal-button-save" type="submit" onClick={onConfirm}>
            Save
          </CustomButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PostedToAPIModal;
