import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ClickAwayListener from '@mui/base/ClickAwayListener';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Permissions } from 'components/Permissions/constants';
import Permission, { hasPermission } from 'components/Permissions/Permission';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { tagsMiddleware, tagsSelector } from '../../redux/slices/tags';
import { ITag } from '../../redux/slices/tags/initialState';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

import TagDetails from './TagDetails';
import { colors } from './TagsTypes';

export interface ITagsSelectProps {
  tags: ITag[];
  defaultValues?: ITag[];
  targetId?: string;
  page: number;
}

const MessageTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { title, children } = props;

  return (
    <Tooltip title={title} placement="bottom" classes={{ popper: className }}>
      {children}
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F4F7FE',
    border: '1px solid #c4cbe2',
    color: '#000',
    fontSize: 16,
  },
}));

const TagChip = ({ color, children }: { color: number; children: React.ReactNode }) => (
  <Box
    component="span"
    sx={{
      paddingX: '6px',
      borderRadius: '3px',
      margin: '3px',
      display: 'flex',
      alignItems: 'center',
      color: '#37352F',
      backgroundColor: colors.find((el) => el.id === color)?.hex,
    }}
  >
    {children}
  </Box>
);

const Tag = ({
  tag,
  onRemove,
  close,
  sx,
}: {
  tag: ITag;
  onRemove?: (value: ITag) => void;
  close?: boolean;
  sx?: SxProps<Theme>;
}) => (
  <TagChip color={tag.color}>
    <Typography sx={sx}>{tag.name}</Typography>
    {close && (
      <CloseIcon
        sx={{ width: '15px', height: '15px', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          onRemove!(tag);
        }}
      />
    )}
  </TagChip>
);

const TagsSelect = ({ tags, defaultValues, targetId, page }: ITagsSelectProps) => {
  const [selectedTag, setSelectedTag] = useState<ITag>();
  const [selectedTagChange, setSelectedTagChange] = useState<ITag>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [defaultTags, setDefaultTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const tagsLoading = useAppSelector(tagsSelector.tagsLoading);
  const canEdit = hasPermission(Permissions.editTags);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, tag: ITag) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedTag(tag);
    setSelectedTagChange(tag);
  };

  const onChange = (color: number, name: string) => {
    if (
      selectedTag &&
      selectedTagChange?.name.length &&
      (selectedTagChange?.name !== selectedTag.name || color !== selectedTag.color)
    ) {
      dispatch(
        tagsMiddleware.fetchChangeTag(color, name, selectedTag.id, page, targetId!, setLoading, selectedTag.name),
      );
      setLoading(true);
      setAnchorEl(null);
      setOpen(false);
    }
  };

  const onDelete = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DeleteTagModal,
        props: {
          name: selectedTag?.name,
          color: selectedTag?.color,
          page,
          targetId,
        },
      }),
    );
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClose = () => {
    if (selectedTag) {
      onChange(selectedTag?.color, selectedTagChange?.name!);
    }

    setAnchorEl(null);
  };

  const onSelect = (name: string, color: number) => {
    if (!defaultValues?.filter((el) => el.name === name).length) {
      setLoading(true);
      dispatch(tagsMiddleware.fetchSelectTag(name, color, targetId!, page, 'add', setLoading));
      setOpen(false);
    }
  };

  const createTag = (name: string) => {
    setLoading(true);
    dispatch(tagsMiddleware.fetchSelectTag(name, 0, targetId!, page));
    setOpen(false);
  };

  const onRemove = (tag: ITag) => {
    setLoading(true);
    dispatch(tagsMiddleware.fetchRemoveTag(tag.name, tag.color, page, targetId!, 'remove', setLoading));
    setOpen(false);
  };

  const id = anchorEl ? 'simple-popover' : undefined;

  const filterOptions = createFilterOptions({
    trim: true,
    stringify: (option: ITag) => option.name,
  });

  useEffect(() => {
    if (defaultValues) {
      setDefaultTags(defaultValues);
    }
  }, [defaultValues]);

  if (loading && tagsLoading) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box maxWidth="250px" minWidth="max-content">
      {open ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box>
            <Autocomplete
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                  width: '200px',
                },
              }}
              multiple
              fullWidth
              options={tags}
              disableClearable
              disableCloseOnSelect
              value={defaultTags}
              popupIcon={
                <ArrowDropDownIcon
                  onClick={(e) => {
                    if (open) {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpen(false);
                    }
                  }}
                />
              }
              isOptionEqualToValue={(option, value) => option.name === value.name}
              noOptionsText={<Typography>No Tags</Typography>}
              limitTags={2}
              open={open}
              onChange={(event, value, reason) => {
                if (reason !== 'removeOption') {
                  setDefaultTags(value);
                }
              }}
              getOptionLabel={(option) => option.name}
              renderTags={(options) =>
                options.map((option) => (
                  <Tag
                    key={option.name}
                    tag={option}
                    onRemove={onRemove}
                    close={defaultTags.includes(option)}
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '55px',
                      overflow: 'hidden',
                      display: 'inline-block',
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  onClick={() => {
                    if (canEdit) {
                      setOpen(true);
                    }
                  }}
                />
              )}
              renderOption={(props, option) => {
                if (option.id === 'newTag') {
                  return (
                    <MenuItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between !important',
                        paddingY: 0,
                        cursor: 'default',
                      }}
                      key={option.id}
                    >
                      <Button
                        sx={{ color: '#454256', textTransform: 'none' }}
                        onClick={() => createTag(option.name.trim())}
                        data-test="button-create-tag"
                      >
                        <Typography
                          component="span"
                          sx={{ display: 'inline-block', marginRight: '5px', fontWeight: 'bold' }}
                        >
                          Create
                        </Typography>{' '}
                        <TagChip color={0}>{option.name}</TagChip>
                      </Button>
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between !important',
                      paddingY: 0,
                    }}
                    {...props}
                    key={option.id}
                    onClick={() => onSelect(option.name, option.color)}
                  >
                    <Tag
                      tag={option}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '92px',
                        overflow: 'hidden',
                        display: 'inline-block',
                      }}
                    />
                    <IconButton aria-describedby={id} onClick={(e) => handleClick(e, option)}>
                      <MoreVertIcon />
                    </IconButton>
                  </MenuItem>
                );
              }}
              filterOptions={(options, params) => {
                const filtered = filterOptions(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue.toLowerCase().trim() === option.name.toLowerCase().trim(),
                );

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    id: 'newTag',
                    name: inputValue.trim(),
                    color: 0,
                  });
                }

                return filtered;
              }}
            />
            <Popover
              id={id}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              sx={{
                '.css-55h3fy-MuiPaper-root-MuiPopover-paper': {
                  boxShadow: '0px 4px 4px 0px #C5C8D2',
                },
              }}
            >
              <TagDetails
                selectedTag={selectedTagChange!}
                setSelectedTag={setSelectedTagChange}
                onChange={onChange}
                onDelete={onDelete}
              />
            </Popover>
          </Box>
        </ClickAwayListener>
      ) : (
        <Box
          onClick={() => {
            if (canEdit) {
              setOpen(true);
            }
          }}
        >
          {defaultValues?.length ? (
            <MessageTooltip
              title={
                <Box display="flex" flexWrap="wrap">
                  {defaultValues.map((element) => (
                    <Tag key={element.name} tag={element} />
                  ))}
                </Box>
              }
            >
              <Box display="flex" alignItems="center">
                {[defaultValues[0], ...(defaultValues[1] ? [defaultValues[1]] : [])]?.map((el) => (
                  <Tag
                    key={el.name}
                    tag={el}
                    onRemove={onRemove}
                    close={canEdit}
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '55px',
                      overflow: 'hidden',
                      display: 'inline-block',
                    }}
                  />
                ))}
                {defaultValues.length > 2 && <Box>+{defaultValues.length - 2}</Box>}
                <Permission permission={Permissions.editTags}>
                  <AddToPhotosIcon sx={{ color: '#95A1B1', marginRight: '8px', fontSize: '16px' }} />
                </Permission>
              </Box>
            </MessageTooltip>
          ) : (
            <Box display="flex" alignItems="center">
              <AddToPhotosIcon
                sx={{
                  color: canEdit ? '#95A1B1' : '#00000042',
                  marginRight: '8px',
                  fontSize: '16px',
                }}
              />
              <Typography sx={{ color: canEdit ? '' : '#00000042' }}>Add Tag</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TagsSelect;
