import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

import { Permissions } from '../../Permissions/constants';
import { hasPermission } from '../../Permissions/Permission';

export interface IControlledSelectOptions {
  id?: string | number;
  value?: string | number | boolean;
  label?: string;
  name?: string;
}

interface ControlledSelectWithPermissionsProps {
  options: IControlledSelectOptions[];
  label?: string;
  width?: string;
  maxHeight?: string;
  name: string;
  nameKey?: keyof IControlledSelectOptions;
  valueKey?: keyof IControlledSelectOptions;
  permission?: Permissions;
  disabled?: boolean;
  labelSx?: SystemCssProperties<Theme>;
  defaultValue?: IControlledSelectOptions | string | number;
  menuItemsSx?: SystemCssProperties<Theme>;
  dataTest?: string;
  required?: boolean;
}

const FormSelect = ({
  options,
  name,
  label,
  width = '160px',
  nameKey = 'label',
  valueKey = 'value',
  maxHeight = '300px',
  permission,
  labelSx,
  defaultValue,
  menuItemsSx,
  dataTest,
  required,
  disabled,
}: ControlledSelectWithPermissionsProps) => {
  const { control, setValue } = useFormContext();
  const fieldState = () => {
    if (disabled) {
      return true;
    }

    return permission ? !hasPermission(permission) : false;
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(
        name,
        typeof defaultValue === 'string' || typeof defaultValue === 'number' ? defaultValue : defaultValue[valueKey],
      );
    }
  }, [defaultValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth sx={{ width }}>
          {label ? <Typography sx={labelSx}>{label}</Typography> : null}
          <Select
            required={required}
            disabled={fieldState()}
            {...field}
            MenuProps={{ PaperProps: { sx: { maxHeight } } }}
            data-test={dataTest}
          >
            {options?.map((el) => (
              // @ts-ignore
              <MenuItem key={el[valueKey]} value={el[valueKey]} sx={menuItemsSx}>
                {el[nameKey]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
