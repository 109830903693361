import React from 'react';

const LineUpSvg = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="30" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.35091 15.8906C2.50046 16.0365 2.74167 16.0365 2.89605 15.8906L9.56787 9.41624L15.7235 15.4577C15.8731 15.6036 16.1191 15.6036 16.2686 15.4577L29.5544 2.5043C29.704 2.35844 29.704 2.11847 29.5544 1.97261L27.644 0.109349C27.5715 0.0392971 27.4736 0 27.3715 0C27.2694 0 27.1714 0.0392971 27.0989 0.109349L16.0033 10.9266L9.85732 4.89454C9.78479 4.82449 9.68684 4.78519 9.58476 4.78519C9.48268 4.78519 9.38473 4.82449 9.31219 4.89454L0.445366 13.491C0.373543 13.5617 0.333252 13.6572 0.333252 13.7568C0.333252 13.8564 0.373543 13.9519 0.445366 14.0226L2.35091 15.8906Z"
      fill="#27E7AD"
    />
  </svg>
);

export default LineUpSvg;
