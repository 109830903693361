import { Box, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@mui/material';

import { useAppSelector } from '../../../redux/hooks';
import { affiliatesSelector } from '../../../redux/slices/affiliates';
import { CustomButton } from '../../shared';

import useConfirmAffiliateChannelCreateSuccessControls from './hooks/useConfirmAffiliateChannelCreateSuccessControls';

const ConfirmAffiliateChannelCreateSuccessModal = () => {
  const channel = useAppSelector(affiliatesSelector.channel);

  const { onConfirm, onClose } = useConfirmAffiliateChannelCreateSuccessControls(+(channel?.id ?? 0));

  return (
    <Dialog data-test="confirm-affiliate-channel-create-success-modal" open onClose={onClose}>
      <Grid sx={{ padding: '10px' }}>
        <DialogTitle>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: '18px',
              lineHeight: '22px',
              color: '#27E7AD',
            }}
          >
            SUCCESS!
          </Typography>
          <Typography
            sx={{
              marginTop: '10px',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '19px',
              textAlign: 'center',
              color: '#454256',
            }}
          >
            Source channel is created successfully.
          </Typography>
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '19px',
              textAlign: 'center',
              color: '#454256',
            }}
          >
            Now you need to add Decision Rules to your channel.
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomButton
              data-test="confirm-affiliate-channel-create-success-modal-button-cancel"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </CustomButton>
            <CustomButton
              data-test="confirm-affiliate-channel-create-success-modal-button-add-decision-trees"
              onClick={onConfirm}
            >
              Add Decision Trees
            </CustomButton>
          </Box>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAffiliateChannelCreateSuccessModal;
