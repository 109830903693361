import * as Yup from 'yup';

import { RequiredError } from '../validationErrors';

export const createOrEditSourceChannel = Yup.object().shape({
  channelName: Yup.string().required(RequiredError('Source Channel Name')),
  campaignId: Yup.string().required(RequiredError('Product')),
  affiliateId: Yup.string().required(RequiredError('Source')),
  dataFormat: Yup.number().required(RequiredError('Data Format')),
  status: Yup.number().required(RequiredError('Status')),
});
