import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, Divider, FormHelperText, TextField } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';

import { ITag } from '../../redux/slices/tags/initialState';

import TagColor from './TagColor';
import { colors } from './TagsTypes';

const TagDetails = ({
  selectedTag,
  setSelectedTag,
  onChange,
  onDelete,
}: {
  selectedTag: ITag;
  setSelectedTag: (value: ITag) => void;
  onChange: (color: number, name: string) => void;
  onDelete: () => void;
}) => (
  <Box
    sx={{
      padding: '10px',
      width: '150px',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
    }}
  >
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onChange(selectedTag.color, selectedTag.name);
      }}
    >
      <TextField
        value={selectedTag.name}
        onChange={(e) => setSelectedTag({ ...selectedTag, name: e.target.value })}
        sx={{ '&.MuiFormControl-root': { minWidth: '130px' } }}
      />
      {!selectedTag.name.length && <FormHelperText error>Name is required</FormHelperText>}
    </form>
    <Permission permission={Permissions.deleteTag}>
      <Button
        onClick={onDelete}
        fullWidth
        sx={{ color: '#454256', gap: '10px', paddingLeft: '4px', justifyContent: 'left' }}
      >
        <DeleteOutlineIcon /> Delete
      </Button>
    </Permission>
    <Divider />
    {colors.map((color) => (
      <Box
        key={color.id}
        onClick={() => {
          onChange(color.id, selectedTag.name);
        }}
      >
        <TagColor color={color} selected={color.id === selectedTag.color} />
      </Box>
    ))}
  </Box>
);

export default TagDetails;
