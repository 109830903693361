import { Box } from '@mui/material';

import classes from '../../assets/scss/journey.module.scss';

const ApplicationJourneyStepStatus = ({ className }: { className: string }) => (
  <Box className={`${classes.stepStatus} ${className}`}>
    <Box className={classes.statusLine}>
      <Box className={classes.circle} />
      <Box className={`${classes.line} ${classes.line0}`} />
      <Box className={`${classes.arrow} ${classes.arrowLeft}`} />
      <Box className={`${classes.arrow} ${classes.arrowRight}`} />
    </Box>
    {className ? <Box className={classes.statusCross} /> : null}
  </Box>
);

export default ApplicationJourneyStepStatus;
