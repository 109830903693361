import { Box } from '@mui/material';

import { ILeadJourneyGroup, ILeadJourneyRow, ILeadJourneyStep } from '@types';

import { LeadJourneyActionTypes } from '../../pages/Leads/MindMap';

import { JourneyExpressionStep } from './JourneyExpressionStep';
import { JourneyStep } from './JourneyStep';
import { JourneyStepStatus } from './JourneyStepStatus';

import classes from '../../assets/scss/journey.module.scss';

export const JourneyGroup = ({
  row,
  group,
  index,
}: {
  row: ILeadJourneyRow;
  group: ILeadJourneyGroup;
  index: number;
}) => {
  if (group.actionType === 7) {
    return (
      <Box className={`${classes.step} ${group.className}`}>
        <Box className={`${classes.stepHeader} ${LeadJourneyActionTypes[group.actionType].className}`}>{row.title}</Box>
        <Box className={classes.stepBody}>
          {group.steps.map((step: ILeadJourneyStep, stepIndex: number) => (
            <JourneyExpressionStep key={step.id} group={group} step={step} index={stepIndex} />
          ))}
        </Box>
        {row.groups[index + 1] ? (
          <JourneyStepStatus
            className={`${classes.stepStatusGroup} ${group.steps[group.steps.length - 1].className}`}
          />
        ) : null}
      </Box>
    );
  }

  return (
    <Box className={`${classes.step} ${group.className}`}>
      <Box className={`${classes.stepHeader} ${LeadJourneyActionTypes[group.actionType].className}`}>{row.title}</Box>
      <Box className={classes.stepBody}>
        {group.steps.map((step: ILeadJourneyStep, stepIndex: number) => (
          <JourneyStep key={step.id} group={group} step={step} index={stepIndex} />
        ))}
      </Box>
      {row.groups[index + 1] ? (
        <JourneyStepStatus className={`${classes.stepStatusGroup} ${group.steps[group.steps.length - 1].className}`} />
      ) : null}
    </Box>
  );
};
