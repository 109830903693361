import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { camelCaseToSeparateWords } from 'utils/index';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

export interface IRerunModalProps {
  applicationId: string;
  selectedStep: string;
}

const RerunModal = ({ selectedStep, applicationId }: IRerunModalProps) => {
  const rerunLoading = useAppSelector(leadsSelector.rerunLoading);

  const onRerun = () => {
    dispatch(leadsMiddleware.rerunStep(applicationId, selectedStep));
  };

  const onClose = () => {
    if (rerunLoading) {
      return;
    }

    dispatch(viewsMiddleware.closeModal(ModalName.RerunModal));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px', mb: '20px' }}>
        <DialogTitle>
          <Title
            title="Re-run application"
            component="p"
            dataTest="application-journey-rerun-dialog"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            {`Are you sure you want to rerun this application starting from the ${camelCaseToSeparateWords(
              selectedStep,
            )}?`}
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton variant="outlined" onClick={onClose} disabled={rerunLoading}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" loading={rerunLoading} onClick={onRerun}>
              Re-run
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default RerunModal;
