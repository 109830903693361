import { boolean, object, string } from 'yup';

export const platformSettingsValidationSchema = object({
  emailNotifications: boolean(),
  email: string().when('emailNotifications', {
    is: true,
    then: string().email('Must be a valid email').max(255).required('Email is required'),
  }),
  isSlackChannel: boolean(),
  slackChannelName: string().when('isSlackChannel', {
    is: true,
    then: string().max(255).required('Slack Channel URL is required'),
  }),
});
