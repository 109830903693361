import { useEffect, useRef, useState } from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { IconLogout } from '@tabler/icons';

import { dispatch } from '../../../../../redux/hooks';
import { authMiddleware } from '../../../../../redux/slices/auth';

const ProfileSection = () => {
  const [selectedIndex] = useState<number>(-1);
  const [open] = useState(false);
  const anchorRef = useRef<HTMLInputElement>(null);

  const prevOpen = useRef(open);
  const logout = () => {
    dispatch(authMiddleware.fetchLogout());
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box data-test="logout">
      <List
        component="nav"
        sx={{
          borderRadius: '10px',
          marginBottom: '10px',
        }}
      >
        <ListItemButton onClick={logout} sx={{ borderRadius: '5px' }} selected={selectedIndex === 4}>
          <ListItemIcon>
            <IconLogout stroke={1.5} size="1.3rem" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default ProfileSection;
