import { useState } from 'react';
import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import CustomRangePicker from 'components/CustomRangePicker/CustomRangePicker';
import { CustomButton } from 'components/shared';
import moment from 'moment/moment';

import { DateRange } from '@types';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { reportsMiddleware, reportsSelector } from '../../redux/slices/reports';

const statuses = [
  { value: -1, label: 'All' },
  { value: 2, label: 'Processing' },
  { value: 3, label: 'Success' },
  { value: 4, label: 'Reject' },
];

const LeadListReports = () => {
  const [status, setStatus] = useState(-1);
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: moment().subtract(1, 'year').month(9).format(),
    endDate: moment().format(),
  });
  const isDownloadLeadListReportLoading = useAppSelector(reportsSelector.isDownloadLeadListReportLoading);

  const onDownload = () => {
    dispatch(
      reportsMiddleware.downloadLeadListReport({
        dateFrom: moment(dateTimeState?.startDate).format(),
        dateTo: moment(dateTimeState?.endDate).format(),
        status,
      }),
    );
  };

  return (
    <Box display="flex" gap="24px" alignItems="end">
      <Box>
        <InputLabel>Status</InputLabel>
        <Select sx={{ minWidth: '200px' }} value={status}>
          {statuses.map((el) => (
            <MenuItem onClick={() => setStatus(el.value)} value={el.value} key={el.label}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <CustomRangePicker
        key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
        dataTest="leads-page-input-range"
        dateTimeState={dateTimeState}
        setDateTimeState={setDateTimeState}
      />
      <CustomButton
        onClick={onDownload}
        loading={isDownloadLeadListReportLoading}
        disabled={isDownloadLeadListReportLoading}
      >
        Download
      </CustomButton>
    </Box>
  );
};

export default LeadListReports;
