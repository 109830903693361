import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import { INote } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';

export interface IDeleteNoteModalProps {
  note: INote;
  leadId: string;
  rowsPerPage?: number;
  selectedUser?: number;
}

const DeleteNoteModal = ({ note, leadId, rowsPerPage, selectedUser }: IDeleteNoteModalProps) => {
  const notesAddOrEditLoading = useAppSelector(leadsSelector.notesAddOrEditLoading);

  const onDelete = () => {
    if (note.noteId) {
      dispatch(leadsMiddleware.deleteNote(note.noteId, leadId, 1, rowsPerPage, selectedUser));
    }
  };

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteNoteModal));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <DialogTitle textAlign="center">
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Delete Note</Typography>
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                right: '10px',
              }}
            >
              <IconButton onClick={onClose} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box>
            <Typography>Are you sure that you wish to delete this note?</Typography>
            <Typography>You will not be able to retrieve this information once it has been deleted.</Typography>
          </Box>
          <Box display="flex" justifyContent="space-around" marginTop="30px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={onDelete}
              loading={notesAddOrEditLoading}
              color="error"
              sx={{ backgroundColor: '#FF497F', borderColor: notesAddOrEditLoading ? '' : '#FF497F' }}
            >
              Yes, Delete
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeleteNoteModal;
