import { CookieManager } from 'helpers/CookieManager';
import { responseErrorHandler } from 'helpers/ErrorHandler';
import NotificationService from 'helpers/Notifications/NotificationService';
import { PersistManager } from 'helpers/PersistManager';
import { ACCESS_TOKEN } from 'lib/constants';
import { dispatch } from 'redux/hooks';

import {
  API_RECOVERY_PASSWORD,
  API_RECOVERY_PASSWORD_REQUEST,
  API_SET_PASSWORD,
  API_SIGNIN,
  API_SIGNOUT,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { SeveritiesType } from '../../../types/snackbar';
import axios from '../../../utils/axios';
import { settingsMiddleware } from '../settings';
import { viewsMiddleware } from '../views';

import slice from './slice';

const {
  setLogoutLoading,
  hasError,
  setIsForgotPasswordLoading,
  setIsResetPasswordLoading,
  setIsForgotPasswordSuccess,
  setIsResetPasswordSuccess,
  setIsLoginLoading,
  setIsLoggedIn,
  setIsCheckingLoading,
  setIsSetPasswordLoading,
  setSetPasswordError,
  setPasswordChanged,
} = slice.actions;

const fetchLogout = () => async () => {
  try {
    dispatch(setLogoutLoading(true));
    await DataService.post(API_SIGNOUT(), {});
    CookieManager.removeCookie(ACCESS_TOKEN);
    PersistManager.clear();
    localStorage.removeItem('permissions');
    window.location.reload();
  } catch (error) {
    dispatch(hasError(error));
  } finally {
    dispatch(setLogoutLoading(false));
  }
};

const fetchRecoveryPasswordRequest = (email: string) => async () => {
  try {
    dispatch(setIsForgotPasswordLoading(true));
    await DataService.post(API_RECOVERY_PASSWORD_REQUEST(), {
      type: 1,
      value: email,
    });
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.success,
          description: 'Recovery message send to your email',
        },
      }),
    );
    dispatch(setIsForgotPasswordLoading(false));
    dispatch(setIsForgotPasswordSuccess(true));
  } catch (error: any) {
    responseErrorHandler(error);
    dispatch(setIsForgotPasswordLoading(false));
    dispatch(hasError(error));
  }
};

const fetchRecoverPassword = (token: string, newPassword: string, confirmPassword: string) => async () => {
  try {
    dispatch(setIsResetPasswordLoading(true));
    await DataService.post(API_RECOVERY_PASSWORD(), {
      token,
      newPassword,
      confirmPassword,
    });
    dispatch(setIsResetPasswordSuccess(true));
    dispatch(setIsResetPasswordLoading(false));
  } catch (error: any) {
    // needs to be checked after implementation
    responseErrorHandler(error, error?.response?.data?.error?.description.NewPassword?.[0]);
    dispatch(setIsResetPasswordLoading(false));
    dispatch(hasError(error));
  }
};

const fetchChangeForgetPasswordSuccess = (value: boolean) => () => {
  dispatch(setIsForgotPasswordSuccess(value));
};

const setSession = (accessToken?: string | null) => {
  if (accessToken) {
    CookieManager.setAccessToken(accessToken);
    NotificationService.getInstance(accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    DataService.setupInstance();
  } else {
    CookieManager.removeCookie(ACCESS_TOKEN);
    delete axios.defaults.headers.common.Authorization;
  }
};

const fetchLogin = (loginData: any) => async () => {
  try {
    dispatch(setIsLoginLoading(true));

    const { email, ...data } = loginData;

    const requestData = { ...data, domain: 'string', userName: email };

    const { data: response } = await DataService.post(API_SIGNIN(), requestData);

    PersistManager.setVersion();

    if (response.mfaRequired) {
      dispatch(settingsMiddleware.fetchSetVerificationEnabled(true));
    } else {
      const { accessToken } = response; // remove default value after merging with QA

      dispatch(setIsLoggedIn(true));
      setSession(accessToken);
    }

    dispatch(setIsLoginLoading(false));
  } catch (error: any) {
    responseErrorHandler(error, 'Login was unsuccessful.');

    dispatch(setIsLoginLoading(false));
    dispatch(hasError(error));
    // needs to be checked after implementation
    dispatch(settingsMiddleware.fetchSetVerificationError(error?.response?.data?.error?.description));
  }
};

const fetchCheckLogin = () => () => {
  try {
    dispatch(setIsCheckingLoading(true));

    const accessToken = CookieManager.getCookie(ACCESS_TOKEN);

    if (accessToken) {
      setSession(accessToken);
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
    }

    dispatch(setIsCheckingLoading(false));
  } catch (error) {
    dispatch(setIsCheckingLoading(false));

    dispatch(hasError(error));
  }
};

const fetchSetPassword = (token: string, newPassword: string, confirmPassword: string) => async () => {
  try {
    dispatch(setIsSetPasswordLoading(true));

    await DataService.post(API_SET_PASSWORD(), {
      token,
      newPassword,
      confirmPassword,
    });

    dispatch(setPasswordChanged(true));
  } catch (error: any) {
    // needs to be checked after implementation
    if (error?.response?.data?.error?.description.NewPassword?.[0]) {
      dispatch(setSetPasswordError(error?.response?.data?.error?.description.NewPassword?.[0]));
    } else {
      responseErrorHandler(error, error?.response?.data?.error?.description.NewPassword?.[0]);
    }
  } finally {
    dispatch(setIsSetPasswordLoading(false));
  }
};

export default {
  fetchLogout,
  fetchRecoveryPasswordRequest,
  fetchRecoverPassword,
  fetchChangeForgetPasswordSuccess,
  fetchLogin,
  setSession,
  fetchCheckLogin,
  fetchSetPassword,
};
