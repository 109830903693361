import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import FormSelect from 'components/shared/Select/FormSelect';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUserInfo } from 'redux/slices/userManagement/types';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { trimProperties } from '../../../helpers';
import { inviteNewUserModalValidationSchema } from '../../../validation/userManagement/inviteNewUserModal';
import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useInviteNewUserModal from './hooks/useInviteNewUserModal';

const getDomainFromEmail = (email: string) => {
  const parts = email.split('@');
  const { length } = parts;

  if (length > 1) {
    return `${parts[length - 1]}`;
  }

  return '';
};

const domainMatch = (email: string, domains: string[]) =>
  !domains.length || !!domains.find((item) => item === getDomainFromEmail(email));

const InviteNewUserModal = () => {
  const [restrictionError, setRestrictionError] = useState('');
  const { onClose } = useInviteNewUserModal();
  const sendInvitationLoading = useAppSelector(userManagementSelector.sendInvitationLoading);
  const domainsData = useAppSelector(userManagementSelector.domainsData);
  const rolesList = useAppSelector(userManagementSelector.rolesList);
  const rolesListLoading = useAppSelector(userManagementSelector.rolesListLoading);

  const methods = useForm<IUserInfo>({
    mode: 'onSubmit',
    defaultValues: {},
    resolver: yupResolver(inviteNewUserModalValidationSchema),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const email = watch('email');

  const submit = (userInfo: IUserInfo) => {
    if (domainsData && (!domainsData.restrictionEnabled || domainMatch(userInfo.email, domainsData.domains))) {
      dispatch(userManagementMiddleware.sendInvitation(trimProperties(userInfo, ['firstName', 'lastName'])));
    } else {
      setRestrictionError(`${getDomainFromEmail(userInfo.email)} domain is not approved`);
    }
  };

  useEffect(() => {
    // @TODO check if it is possible to handle with yup resolver
    if (restrictionError) {
      setRestrictionError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (!domainsData) {
      dispatch(userManagementMiddleware.getRestrictDomainsData());
    }

    if (!rolesList) {
      dispatch(userManagementMiddleware.getRolesList());
    }

    return () => {
      userManagementMiddleware.resetRestrictedDomains();
    };
  }, [domainsData]);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '560px' }}>
        <Title
          title="Invite New User"
          sx={{
            margin: 0,
            padding: '16px 0',
            display: 'block',
            textAlign: 'center',
            width: '100%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogContent sx={{ p: 3 }}>
          <Box onSubmit={handleSubmit(submit)} component="form">
            <FormProvider {...methods}>
              <Grid container>
                <Grid item xs={12}>
                  {rolesListLoading ? (
                    <Box display="flex" justifyContent="center">
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box sx={{ marginBottom: '15px' }}>
                      <InputLabel required>Assign Role</InputLabel>
                      <FormSelect name="roleId" valueKey="id" nameKey="name" options={rolesList ?? []} width="100%" />
                      {errors.roleId?.message ? (
                        <FormHelperText error sx={{ marginLeft: '14px', marginRight: '14px' }}>
                          {errors.roleId?.message}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  )}
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field, formState }) => (
                      <FormControl sx={{ marginBottom: '15px' }} fullWidth required>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                          helperText={formState.errors.firstName?.message ?? ''}
                          error={Boolean(formState.errors.firstName?.message)}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="lastName"
                    render={({ field, formState }) => (
                      <FormControl sx={{ marginBottom: '15px' }} fullWidth required>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                          helperText={formState.errors.lastName?.message ?? ''}
                          error={Boolean(formState.errors.lastName?.message)}
                          {...field}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    control={control}
                    name="email"
                    render={({ field, formState }) => (
                      <FormControl fullWidth required>
                        <InputLabel>Invite via email</InputLabel>
                        <TextField
                          helperText={formState.errors.email?.message ?? restrictionError ?? ''}
                          error={Boolean(formState.errors.email?.message ?? !!restrictionError)}
                          {...field}
                          placeholder="name@email.com"
                        />
                      </FormControl>
                    )}
                  />
                  <Box
                    sx={{
                      marginTop: '24px',
                      borderRadius: '8px',
                      p: '3px 8px 14px 24px',
                      backgroundColor: '#FAFAFA',
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box component="span" sx={{ color: '#95A1B1', fontSize: '13px' }}>
                        Approved domains:
                      </Box>
                      <Box>
                        <CustomButton
                          variant="text"
                          onClick={() => {
                            dispatch(
                              viewsMiddleware.openModal({
                                name: ModalName.DomainRestrictionsModal,
                                props: {},
                              }),
                            );
                          }}
                        >
                          Edit
                        </CustomButton>
                      </Box>
                    </Box>
                    <Typography fontSize={13} color="#454256">
                      {domainsData?.domains.join(', ')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} marginTop="24px">
                  <CustomButton loading={sendInvitationLoading} fullWidth type="submit">
                    Send Invite
                  </CustomButton>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default InviteNewUserModal;
