import { dateTime } from 'helpers';

export const roles: { [key: string]: 'Admin' | 'Underwriter' | 'User' | 'Funder' } = {
  '1': 'Admin',
  '2': 'Underwriter',
  '3': 'User',
  '4': 'Funder',
};

export const userStatuses: { [key: number]: string } = {
  1: 'Inactive',
  2: 'Active',
  5: 'Pending Password Reset',
  6: 'Deactivated',
  7: 'Invitation Pending',
};

export interface IRestrictDomains {
  domains: string[];
  restrictionEnabled: boolean;
}

export interface IAction {
  key: string;
  name: string;
}

export interface IPermission {
  type: string;
  key: string;
  name: string;
  actions: IAction[];
}

export interface IPermissionsList {
  [key: string]: IPermission[];
}

export interface IRolesList {
  id: string;
  name: string;
  usersCount: string;
  latestUpdate: string;
  updatedBy: string;
  isSystemDefined: boolean;
  piiEnabled: boolean;
}

export interface IUsers {
  errorMessages: '';
  isLoading: boolean;
  usersList: IUser[];
  isUserValid: boolean | null;
  deactivateLoading: boolean;
  editRoleLoading: boolean;
  resendInviteLoading: boolean;
  resetPasswordLoading: boolean;
  sendInvitationLoading: boolean;
  deleteLoading: boolean;
  restrictDomainsLoading: boolean;
  domainsData: null | IRestrictDomains;
  userPermissionsLoading: boolean;
  permissionsList: IPermissionsList | null;
  permissionsListLoading: boolean;
  rolesList: IRolesList[] | null;
  rolesListLoading: boolean;
  rolePermissions: string[] | null;
  roleDeleteLoading: boolean;
  roleDeleteId: IRolesList | null;
}

export interface IUserDto {
  id: string;
  role: string; // @todo check if role is needed and used
  email: string;
  status: number;
  joined: string;
  roleName: string;
  roleId: string;
  fullName: string;
  lastLogin: string;
}

export class IUser {
  id: string;

  role: string;

  email: string;

  joined: string;

  roleName: string;

  userStatus: string;

  fullName: string;

  lastLogin: string;

  constructor(dto: IUserDto) {
    this.id = dto.id;
    this.email = dto.email;
    this.userStatus = userStatuses[dto.status] || userStatuses[2]; // @todo need to rename after table status logic change
    this.fullName = dto.fullName;
    this.role = dto.role;
    this.roleName = dto.roleName;
    this.joined = dateTime(dto.joined);
    this.lastLogin = dto.lastLogin && dateTime(dto.lastLogin);
  }
}

export const getInitialState = (): IUsers => ({
  errorMessages: '',
  isLoading: false,
  usersList: [],
  isUserValid: null,
  editRoleLoading: false,
  deactivateLoading: false,
  sendInvitationLoading: false,
  resendInviteLoading: false,
  resetPasswordLoading: false,
  deleteLoading: false,
  restrictDomainsLoading: false,
  domainsData: null,
  userPermissionsLoading: true,
  permissionsList: null,
  permissionsListLoading: false,
  rolesList: null,
  rolesListLoading: false,
  rolePermissions: null,
  roleDeleteLoading: false,
  roleDeleteId: null,
});
