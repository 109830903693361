import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUser } from 'redux/slices/userManagement/initialState';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

export interface IResetPasswordModalProps {
  user: IUser;
}

const ResetPasswordModal = ({ user }: IResetPasswordModalProps) => {
  const resetPasswordLoading = useAppSelector(userManagementSelector.resetPasswordLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ResetPasswordModal));
  };

  const onConfirm = () => {
    dispatch(userManagementMiddleware.resetPassword(user.email));
  };

  return (
    <Dialog data-test="reset password-modal" open onClose={onClose}>
      <Grid sx={{ width: '600px' }}>
        <DialogTitle>
          <Title
            title="Reset Password"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px" component="p">
            {`Please confirm sending the password reset link to "${user.fullName}" user.`}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton
              data-test="reset-password-modal-button-confirm"
              disabled={resetPasswordLoading}
              loading={resetPasswordLoading}
              onClick={onConfirm}
            >
              Confirm
            </CustomButton>
          </Stack>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default ResetPasswordModal;
