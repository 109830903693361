import uuid from 'react-uuid';

import classes from '../assets/scss/journey.module.scss';

export interface IApplicationJourneyStep {
  id: string;
  title: string;
  className: string;
  status: string;
}

export interface IApplicationJourneyGroup {
  id: string;
  steps: IApplicationJourneyStep[];
  className: string;
}

export interface IApplicationJourney {
  id: string;
  groups: IApplicationJourneyGroup[];
}

const journeyStepsSuccess: { [key: string]: string } = {
  ValidationStep: 'Fields Validation',
  DecisionStep: 'Decision Rules Fields',
  SocureFirstStep: 'Socure Data Validation',
  SocureSecondStep: 'Socure ID Validation',
  SoftPullStep: 'Experian Soft Pull',
  DecisionRuleStep: 'Decision Rules API',
  OfferGenerationStep: 'Offers Generation',
  WaitForAcceptedOfferResponse: 'Wait for accepted offer response',
  AcceptedOfferStep: 'Receive Accepted Offer',
  DocumentSignStep: 'Documentation Prep and Signing',
  WaitForDocumentToBeSigned: 'Document Signature Pending',
  DocuSignResponseStep: 'Received Signed Documentation',
  HardPullStep: 'Experian Hard Pull',
  WaitForFundingRequest: 'Received Funding Request',
  FundingStep: 'Funding Request Approval',
  EquiantStep: 'Equiant API Integration',
  WaitForManualReview: 'Awaiting Manual Review',
  ManualReviewCheckStep: 'Manual Review Check',
  GenerateManualReviewStep: 'Manual Review Rule',
  DailyCapCheckStep: 'Daily Cap Check',
  ResolveSocureFirstStepStatusStep: 'Resolve Socure First Step Status',
  AddManualReviewLinkForSocureStep: 'Add Manual Review Link For Socure',
  DailyCapTriggerStep: 'Daily Cap Trigger',
  ChangeOrderStep: 'Change Order',
  GetLoanScheduleStep: 'Get Loan Schedule',
  ChangeOrderDocumentSignStep: 'Change Order Document Sign',
  AddAppTagsStep: 'Add App Tags',
  FundingRequestStep: 'Funding Request',
  GatherLastFundingStep: 'Gather Last Funding',
  CheckHardPullStep: 'Check Hard Pull',
  ManualReviewResolutionStep: 'Manual Review Resolution',
  StartManualReviewStep: 'Start Manual Review',
};

// for future InProgress messages, now using WaitForDocumentToBeSigned, need to check for deletion of this mapping
const journeyStepsInProgress: { [key: string]: string } = {
  ValidationStep: 'Fields Validation',
  DecisionStep: 'Decision Rules Fields',
  SocureFirstStep: 'Socure Data Validation',
  SocureSecondStep: 'Socure ID Validation',
  SoftPullStep: 'Experian Soft Pull',
  DecisionRuleStep: 'Decision Rules API',
  OfferGenerationStep: 'Offers Generation',
  WaitForAcceptedOfferResponse: 'Wait for accepted offer response',
  AcceptedOfferStep: 'Receive Accepted Offer',
  DocumentSignStep: 'Documentation Prep and Signing',
  WaitForDocumentToBeSigned: 'Document Signature Pending',
  DocuSignResponseStep: 'Received Signed Documentation',
  HardPullStep: 'Experian Hard Pull',
  WaitForFundingRequest: 'Awaiting Funding Request',
  FundingStep: 'Funding Request Approval',
  EquiantStep: 'Equiant API Integration',
  WaitForManualReview: 'Awaiting Manual Review',
  ManualReviewCheckStep: 'Manual Review Check',
  GenerateManualReviewStep: 'Manual Review Rule',
  DailyCapCheckStep: 'Daily Cap Check',
  ResolveSocureFirstStepStatusStep: 'Resolve Socure First Step Status',
  AddManualReviewLinkForSocureStep: 'Add Manual Review Link For Socure',
  DailyCapTriggerStep: 'Daily Cap Trigger',
  ChangeOrderStep: 'Change Order',
  GetLoanScheduleStep: 'Get Loan Schedule',
  ChangeOrderDocumentSignStep: 'Change Order Document Sign',
  AddAppTagsStep: 'Add App Tags',
  FundingRequestStep: 'Funding Request',
  GatherLastFundingStep: 'Gather Last Funding',
  CheckHardPullStep: 'Check Hard Pull',
  ManualReviewResolutionStep: 'Manual Review Resolution',
  StartManualReviewStep: 'Start Manual Review',
};

export const CreateApplicationJourneyData = (journey: { [key: string]: string }, rows: IApplicationJourney[] = []) => {
  let isSuccess = true;

  rows.push({
    id: uuid(),
    groups: [],
  });

  Object.keys(journey).forEach((journeyItem: any) => {
    const [item] = journeyItem.split('-');
    const isReject = journey[item] === 'Reject' || journey[journeyItem] === 'Reject';
    const step: IApplicationJourneyStep = {
      id: item,
      title: (journey[item] === 'Success' ? journeyStepsSuccess[item] : journeyStepsInProgress[item]) || item,
      className: isReject ? classes.red : classes.green,
      status: journey[journeyItem],
    };

    const group: IApplicationJourneyGroup = {
      id: uuid(),
      steps: [step],
      className: isReject ? classes.red : classes.green,
    };

    rows[0].groups.push(group);

    if (journey[item] !== 'Success') {
      isSuccess = false;
    }
  });

  return { rows, isSuccess };
};
