import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormAutocomplete from 'formComponents/FormAutocomplete';
import { FUND_LIST_KEY, PersistManager } from 'helpers/PersistManager';
import { defaultFilterValues } from 'helpers/PersistManager/utils';
import { DEFAULT_ROWS_PAGE } from 'lib/constants';
import moment from 'moment';

import { DateRange, TableHeader } from '@types';

import { FundingListHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { fundingMiddleware } from '../../redux/slices/funding';
import { manualReviewMiddleware, manualReviewSelector } from '../../redux/slices/manualReviews';
import { tagsSelector } from '../../redux/slices/tags';
import CustomRangePicker from '../CustomRangePicker/CustomRangePicker';
import { CustomButton } from '../shared';
import SelectTableHeaders from '../UI/SelectTableHeaders';

const statuses = [
  { value: '2', label: 'All' },
  { value: '1', label: 'Approved' },
  { value: '-1', label: 'Rejected' },
  { value: '0', label: 'To Review' },
];

interface FundingListFiltersProps {
  setPage: Dispatch<SetStateAction<number>>;
  selectedTableHeaders: TableHeader[];
  setSelectedTableHeaders: Dispatch<SetStateAction<TableHeader[]>>;
}

const FundingListFilters = ({ setPage, selectedTableHeaders, setSelectedTableHeaders }: FundingListFiltersProps) => {
  const [defaultFilters] = useState(PersistManager.getData(FUND_LIST_KEY) || {});
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: defaultFilters.dateFrom ?? moment().subtract(1, 'year').format(),
    endDate: defaultFilters.dateTo ?? moment().format(),
  });
  const affiliateChannelsList = useAppSelector(affiliatesSelector.simplifiedAffiliateChannels);
  const affiliateList = useAppSelector(affiliatesSelector.simplifiedAffiliates);
  const merchantNames = useAppSelector(manualReviewSelector.merchantNames);
  const tags = useAppSelector(tagsSelector.tags);

  const emptyValues = {
    applicationId: '',
    filterBorrowerName: '',
    status: '2',
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
    source: [],
    sourceChannels: [],
    merchantName: [],
    tags: [],
  };

  const setupDefaultValues = () => ({
    applicationId: defaultFilters.applicationId ?? '',
    borrower: defaultFilters.filterBorrowerName ?? '',
    status: defaultFilters.status ?? '2',
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
    source: defaultFilterValues(affiliateList ?? [], defaultFilters.source),
    sourceChannels: defaultFilterValues(affiliateChannelsList ?? [], defaultFilters.sourceChannels),
    merchantName: !defaultFilters.merchantName
      ? []
      : defaultFilters.merchantName?.map((name: string) => ({ id: name, name })),
    tags: defaultFilterValues(tags ?? [], defaultFilters.tags, 'name'),
    searchModel: {
      pageNumber: 1,
      pageSize: DEFAULT_ROWS_PAGE,
      sortOrder: { key: 'Id', value: false },
    },
  });

  const formState = useForm({
    mode: 'onSubmit',
    defaultValues: setupDefaultValues(),
  });

  const { control, handleSubmit, reset, setValue } = formState;

  useEffect(() => {
    if (affiliateChannelsList && merchantNames && tags && affiliateList) {
      reset(setupDefaultValues());
    }
  }, [affiliateChannelsList, merchantNames, tags, affiliateList]);

  const applyFilters = (data: any) => {
    dispatch(
      fundingMiddleware.fetchGetFundingList({
        ...data,
        source: data.source?.map((el: { id: string }) => el.id),
        sourceChannels: data.sourceChannels?.map((el: { id: string }) => el.id),
        merchantName: data.merchantName?.map((el: { id: string }) => el.id),
      }),
    );
    setPage(0);
  };

  const resetFilters = () => {
    setDateTimeState({
      startDate: moment().subtract(1, 'year').format(),
      endDate: moment().format(),
    });
    reset(emptyValues);
    applyFilters(emptyValues);
  };

  const onSubmit = (data: any) => {
    const orderData = PersistManager.getData(FUND_LIST_KEY).searchModel;

    setValue('borrower', (data.borrower = data.borrower.trim()));
    setValue('applicationId', (data.applicationId = data.applicationId.trim()));

    applyFilters({
      applicationId: data.applicationId ?? '',
      dateFrom: moment(dateTimeState?.startDate).startOf('day').format(),
      dateTo: moment(dateTimeState?.endDate).endOf('day').format(),
      filterBorrowerName: data.borrower ?? '',
      source: data.source,
      sourceChannels: data.sourceChannels,
      merchantName: data.merchantName,
      tags: data.tags?.map((el: { name: string }) => el.name),
      status: data.status,
      searchModel: {
        pageNumber: 1,
        pageSize: orderData.pageSize,
        sortOrder: { key: 'Id', value: false },
      },
    });
  };

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliateChannels());
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(manualReviewMiddleware.fetchMerchantNames());
  }, []);

  return (
    <FormProvider {...formState}>
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Grid container gap="30px" sx={{ marginBottom: '24px' }}>
          <Grid item>
            <CustomRangePicker
              key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
              dataTest="funding-list-page-filters-date-range"
              dateTimeState={dateTimeState}
              setDateTimeState={setDateTimeState}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="applicationId"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel data-test="funding-list-page-filters-application-id-label">Application ID</InputLabel>
                  <TextField
                    sx={{ marginTop: '0 !important' }}
                    {...field}
                    placeholder="Search"
                    data-test="funding-list-page-filters-application-id-input"
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Source Channel"
              options={affiliateChannelsList ?? []}
              name="sourceChannels"
              dataTest="funding-list-page-filters-source-channel"
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="borrower"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel data-test="funding-list-page-filters-borrower-label">Borrower</InputLabel>
                  <TextField {...field} placeholder="Borrower" data-test="funding-list-page-filters-borrower-input" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Source"
              options={affiliateList ?? []}
              name="source"
              dataTest="funding-list-page-filters-source"
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Merchant Name"
              options={merchantNames ?? []}
              name="merchantName"
              dataTest="funding-list-page-filters-merchant-name"
            />
          </Grid>
          <Grid item>
            <FormAutocomplete title="Tags" options={tags ?? []} name="tags" dataTest="funding-list-page-filters-tags" />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }}>
                  <InputLabel data-test="funding-list-page-filters-status-label">Status</InputLabel>
                  <Select {...field} data-test="funding-list-page-filters-status-select">
                    {statuses?.map((el) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <SelectTableHeaders
              tableHeaders={FundingListHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
              dataTest="funding-list-page-filters-custom-column"
            />
          </Grid>
          <Grid item display="flex" alignItems="flex-end" gap="30px">
            <Box display="flex" alignItems="flex-end">
              <CustomButton type="submit" data-test="funding-list-page-filters-apply-button">
                Apply
              </CustomButton>
            </Box>
            <Box display="flex" alignItems="flex-end">
              <CustomButton
                type="reset"
                variant="outlined"
                onClick={resetFilters}
                data-test="funding-list-page-filters-reset-button"
              >
                Reset
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default FundingListFilters;
