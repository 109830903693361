import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IAuthState } from '../../../types/auth';

const createReducer = <T extends SliceCaseReducers<IAuthState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setLogoutLoading(state, action) {
    state.isLogoutLoading = action.payload;
  },
  hasError(state, action) {
    state.error = action.payload;
  },
  setIsResetPasswordLoading(state, action) {
    state.isResetPasswordLoading = action.payload;
  },
  setIsForgotPasswordLoading(state, action) {
    state.isForgotPasswordLoading = action.payload;
  },
  setIsForgotPasswordSuccess(state, action) {
    state.isForgotPasswordSuccess = action.payload;
  },
  setIsResetPasswordSuccess(state, action) {
    state.isResetPasswordSuccess = action.payload;
  },
  setIsLoginLoading(state, action) {
    state.isLoginLoading = action.payload;
  },
  setIsLoggedIn(state, action) {
    state.isLoggedIn = action.payload;
  },
  setIsCheckingLoading(state, action) {
    state.isCheckingLoading = action.payload;
  },
  setIsSetPasswordLoading(state, action) {
    state.isSetPasswordLoading = action.payload;
  },
  setSetPasswordError(state, action) {
    state.setPasswordError = action.payload;
  },
  setPasswordChanged(state, action) {
    state.passwordChanged = action.payload;
  },
});

export default reducers;
