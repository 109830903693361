import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ToastNotificationsController } from './components/ToastNotification/ToastNotificationsController';
import { ConfigProvider } from './context/ConfigContext';
import App from './pages/index';
import store from './redux/store';
import { BASE_PATH } from './config';

import 'assets/scss/style.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider>
      <ToastNotificationsController />
      <BrowserRouter basename={BASE_PATH}>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
);
