import { IFundingFilters } from '../../../types/funding';
import { ITag } from '../tags/initialState';

export interface IFundingList {
  id: string;
  applicationId: string;
  sourceChannel: string;
  source: string;
  merchantName: string;
  date: string;
  status: number;
  tags: ITag[];
  requestAmount: number;
  reviewerId: string;
  reviewedBy: string;
}

export interface IFundingListResponse {
  data: IFundingList[];
  itemsCount: number;
  pagesCount: number;
}

export interface IFundersList {
  id: string;
  value: string;
}

export interface IFunding {
  fundingLoading: boolean;
  fundingList: IFundingListResponse | null;
  fundingListFilters: IFundingFilters | null;
  fundersList: IFundersList[] | null;
  fundersListLoading: boolean;
  isDownloadFundingReportLoading: boolean;
}

export const getInitialState = (): IFunding => ({
  fundingLoading: false,
  fundingList: null,
  fundingListFilters: null,
  fundersList: null,
  fundersListLoading: false,
  isDownloadFundingReportLoading: false,
});
