import { useCallback } from 'react';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useInviteNewUserModal = () => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.InviteNewUserModal));
  }, []);

  return {
    onClose,
  };
};

export default useInviteNewUserModal;
