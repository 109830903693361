import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useGenerateTableData } from 'components/Hooks';
import { PagesSearch } from 'components/Layout/MainLayout/Header/SearchSection';
import { DenseTable } from 'components/Tables';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';

import { TableHeader } from '@types';

import { CustomButton } from '../../components';
import { Permissions } from '../../components/Permissions/constants';
import Permission, { hasPermission } from '../../components/Permissions/Permission';
import SelectTableHeaders from '../../components/UI/SelectTableHeaders';
import { AffiliateChannelsTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';

const AffiliatesChannelPage: FC = () => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(AffiliateChannelsTableHeaders);
  const [searchText, setSearchText] = useState<string>('');
  const [defaultStatus, setDefaultStatus] = useState(-1);

  const affiliateChannels = useAppSelector(affiliatesSelector.affiliateChannels);
  const affiliateChannelsCount = useAppSelector(affiliatesSelector.affiliateChannelsCount);
  const isAffiliateChannelsLoading = useAppSelector(affiliatesSelector.isAffiliateChannelsLoading);
  const isUpdateAffiliateChannelStatusLoading = useAppSelector(
    affiliatesSelector.isUpdateAffiliateChannelStatusLoading,
  );
  const affiliateChannelFilters = useAppSelector(affiliatesSelector.affiliateChannelFilters);

  const simplifiedAffiliates = useAppSelector(affiliatesSelector.simplifiedAffiliates);
  const simplifiedCampaigns = useAppSelector(campaignsSelector.simplifiedCampaigns);
  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: affiliateChannels,
    paginationCount: +(affiliateChannelsCount ?? 0),
  });
  const navigate = useNavigate();

  const handleChangeStatus = (row: { [p: string]: string }, checked: number) => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateChannelStatus({
        id: row.id,
        status: checked,
        defaultStatus,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
        sortOrderKey: orderBy,
        sortOrderValue: order === 'asc',
        name: searchText,
      }),
    );
  };
  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannels(
        defaultStatus,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      affiliatesMiddleware.fetchAffiliateChannels(
        defaultStatus,
        1,
        newRowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannels(defaultStatus, page + 1, rowsPerPage, property, newOrder, searchText),
    );
  };

  const handleDeleteAction = (row: { [p: string]: string }) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateChannelDeleteModal,
        props: {
          id: row.id,
          status: defaultStatus,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrderKey: orderBy,
          sortOrderValue: order === 'asc',
          searchText,
        },
      }),
    );
  };

  const handleFilterByStatus = (status: number) => {
    setDefaultStatus(status);
    dispatch(affiliatesMiddleware.fetchAffiliateChannels(status, 1, rowsPerPage, orderBy, order === 'asc', searchText));
    setPage(0);
  };

  const handleSearch = (value: string) => {
    dispatch(affiliatesMiddleware.fetchAffiliateChannels(defaultStatus, 1, rowsPerPage, 'Id', false, value));
    setSearchText(value);
    setPage(0);
  };

  useEffect(() => {
    if (affiliateChannelFilters) {
      dispatch(
        affiliatesMiddleware.fetchAffiliateChannels(
          affiliateChannelFilters.status,
          affiliateChannelFilters.pageNumber,
          affiliateChannelFilters.pageSize,
          affiliateChannelFilters.sortOrderKey,
          affiliateChannelFilters.sortOrderValue,
          affiliateChannelFilters.name,
        ),
      );
      setPage(Number(affiliateChannelFilters.pageNumber) - 1);
      setRowsPerPage(affiliateChannelFilters.pageSize);
      setOrder(affiliateChannelFilters.sortOrderValue ? 'asc' : 'desc');
      setOrderBy(affiliateChannelFilters.sortOrderKey ?? 'Id');
      setDefaultStatus(affiliateChannelFilters.status);
      setSearchText(affiliateChannelFilters.name ?? '');
    } else {
      dispatch(affiliatesMiddleware.fetchAffiliateChannels(-1, 1, rowsPerPage));
    }

    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
  }, []);

  if (isAffiliateChannelsLoading || isUpdateAffiliateChannelStatusLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ marginTop: '24px', marginBottom: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '24px' }}>
          <Title dataTest="affiliate-channels-page-header-affiliate-channels-list" title="Source Channels List" />
        </Grid>
        <Grid item container xs={12}>
          <Grid item md={3}>
            <PagesSearch
              dataTest="affiliate-channels-page-input-search"
              sx={{ margin: 0, maxWidth: '200px' }}
              handleSearch={handleSearch}
              title="Search"
              defaultValue={searchText}
            />
          </Grid>
          <Grid item md={3}>
            <SelectTableHeaders
              dataTest="affiliate-channels-page-dropdown-custom-columns"
              tableHeaders={AffiliateChannelsTableHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
            />
          </Grid>
          <Grid item md={3}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="ShowPartData-select" shrink>
                Show
              </InputLabel>
              <Select
                data-test="affiliate-channels-page-dropdown-show"
                notched
                label="Show"
                value={defaultStatus}
                onChange={(e) => {
                  handleFilterByStatus(Number(e.target.value));
                }}
              >
                <MenuItem value="-1">Show All</MenuItem>
                <MenuItem value="0">Only Inactives</MenuItem>
                <MenuItem value="1">Only Actives</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Permission permission={Permissions.createSourceChannel}>
            <Grid item md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
                <CustomButton
                  data-test="affiliate-channels-page-button-create-affiliate-channel"
                  disabled={!simplifiedAffiliates?.length || !simplifiedCampaigns?.length}
                  onClick={() => navigate('create')}
                >
                  Create Source Channel
                </CustomButton>
              </Box>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DenseTable
          dataTest="affiliate-channels-page"
          selectedTableHeaders={selectedTableHeaders}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          sortTableData={sortTableData}
          data={tableData}
          editButton={false}
          action={{ type: 'Link', linkKeys: ['id', 'name'] }}
          setTableData={setTableData}
          order={order as string}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRequestSort={handleRequestSort}
          createSortHandler={createSortHandler}
          handleChangeRowsPerPage={handleChangeRowsPerPageServer}
          handleChangePage={handleChangePageServer}
          handleSort={handleSortServer}
          handleChangeStatus={handleChangeStatus}
          handleDeleteAction={handleDeleteAction}
          paginationCount={paginationCount}
          handleSortCount={handleSortCount}
          changeStatusPermission={Permissions.sourceChannelInformationChange}
          isDeleted={hasPermission(Permissions.deleteSourceChannel)}
          isAction={hasPermission(Permissions.deleteSourceChannel)}
        />
      </Grid>
    </Grid>
  );
};

export default AffiliatesChannelPage;
