import { useState } from 'react';
import { Box } from '@mui/material';

import { ILeadJourneyActionType, ILeadJourneyRow } from '@types';

import { LeadJourneyActionTypes } from '../../pages/Leads/MindMap';

import { JourneyRow } from './JourneyRow';

import classes from '../../assets/scss/journey.module.scss';

export interface IRowHeight {
  [key: number]: number;
}

export const Journey = ({
  leadId,
  journey,
  status,
}: {
  leadId: string;
  journey: ILeadJourneyRow[];
  status: boolean;
}) => {
  const [rowHeights, setRowHeights] = useState<IRowHeight>({});

  return (
    <Box className={classes.root}>
      <Box className={classes.indicators}>
        {Object.values(LeadJourneyActionTypes).map((actionType: ILeadJourneyActionType) => (
          <Box className={classes.indicator} key={actionType.title}>
            <Box className={actionType.className} />
            {actionType.title}
          </Box>
        ))}
      </Box>
      <Box className={classes.container}>
        {journey.map((row: ILeadJourneyRow, rowIndex: number) => (
          <Box
            className={`${classes.row} ${!rowIndex && classes.rowStart}`}
            key={row.id}
            ref={(el: HTMLElement) => {
              if (el?.clientHeight && rowHeights[rowIndex] !== el?.clientHeight) {
                setRowHeights((prev: IRowHeight) => ({
                  ...prev,
                  ...{ [rowIndex]: el?.clientHeight },
                }));
              }
            }}
          >
            <JourneyRow leadId={leadId} row={row} index={rowIndex} rowHeights={rowHeights} />
          </Box>
        ))}
        <Box
          className={`${classes.row} ${classes.rowEnd} ${journey.length === 1 && classes.rowEndRight} ${
            status ? classes.green : classes.gray
          }`}
        >
          <Box className={classes.step}>Outbound API</Box>
        </Box>
      </Box>
    </Box>
  );
};
