import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { externalServicesMiddleware } from '../../../../redux/slices/externalServices';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmExternalServiceDeleteControls = (
  id: string,
  pageNumber: number,
  pageSize: number,
  sortOrderKey?: string,
  sortOrderValue?: boolean,
) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmExternalServiceDeleteModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(
      externalServicesMiddleware.fetchDeleteExternalService(id, pageNumber, pageSize, sortOrderKey, sortOrderValue),
    );
  }, []);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmExternalServiceDeleteControls;
