import React, { FC, useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { PagesSearch } from 'components/Layout/MainLayout/Header/SearchSection';

import { useGenerateTableData } from '../../components/Hooks';
import { Permissions } from '../../components/Permissions/constants';
import Permission, { hasPermission } from '../../components/Permissions/Permission';
import { CustomButton } from '../../components/shared';
import { DenseTable } from '../../components/Tables';
import Loading from '../../components/UI/Loading';
import SelectTableHeaders from '../../components/UI/SelectTableHeaders';
import Title from '../../components/UI/Title';
import { CampaignsTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { TableHeader } from '../../types';

const CampaignsPage: FC = () => {
  const [selectedTableHeaders, setSelectedTableHeaders] = useState<TableHeader[]>(CampaignsTableHeaders);
  const [searchText, setSearchText] = useState<string>('');
  const [defaultStatus, setDefaultStatus] = useState<number>(-1);

  const campaigns = useAppSelector(campaignsSelector.campaigns);
  const campaignsCount = useAppSelector(campaignsSelector.campaignsCount);
  const isCampaignHistoryLoading = useAppSelector(campaignsSelector.isCampaignHistoryLoading);
  const isTimeFormatSuccessLoading = useAppSelector(campaignsSelector.isTimeFormatSuccessLoading);
  const isCampaignsListLoading = useAppSelector(campaignsSelector.isCampaignsListLoading);
  const isDeleteCampaignLoading = useAppSelector(campaignsSelector.isDeleteCampaignLoading);
  const isUpdateCampaignStatusLoading = useAppSelector(campaignsSelector.isUpdateCampaignStatusLoading);
  const campaignFilters = useAppSelector(campaignsSelector.campaignFilters);

  const isLoading =
    isCampaignHistoryLoading ||
    isTimeFormatSuccessLoading ||
    isCampaignsListLoading ||
    isDeleteCampaignLoading ||
    isUpdateCampaignStatusLoading;

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: campaigns,
    paginationCount: Number(campaignsCount),
  });

  const handleStatusChange = (status: number) => {
    setDefaultStatus(status);
    dispatch(
      campaignsMiddleware.fetchCampaignsList(status, page + 1, rowsPerPage, orderBy, order === 'asc', searchText),
    );
    setPage(0);
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      campaignsMiddleware.fetchCampaignsList(
        defaultStatus,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      campaignsMiddleware.fetchCampaignsList(defaultStatus, 1, newRowsPerPage, orderBy, order === 'asc', searchText),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      campaignsMiddleware.fetchCampaignsList(defaultStatus, page + 1, rowsPerPage, property, newOrder, searchText),
    );
  };

  const handleChangeStatus = (row: { [p: string]: string }, checked: number) => {
    dispatch(
      campaignsMiddleware.fetchUpdateCampaignStatus(
        row.id,
        checked,
        defaultStatus,
        page + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
        searchText,
      ),
    );
  };

  const handleDeleteAction = (row: { [p: string]: string }) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmCampaignDeleteModal,
        props: {
          id: row.id,
          status: defaultStatus,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortOrderKey: orderBy,
          sortOrderValue: order === 'asc',
          searchText,
        },
      }),
    );
  };

  const handleCreateProduct = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmCreateCampaignModal,
        props: {
          mode: 'MODAL',
        },
      }),
    );
  };

  const handleSearch = (value: string) => {
    dispatch(campaignsMiddleware.fetchCampaignsList(defaultStatus, 1, rowsPerPage, 'Id', false, value));
    setSearchText(value);
    setPage(0);
  };

  useEffect(() => {
    if (campaignFilters) {
      dispatch(
        campaignsMiddleware.fetchCampaignsList(
          campaignFilters.status,
          campaignFilters.pageNumber,
          campaignFilters.pageSize,
          campaignFilters.sortOrderKey,
          campaignFilters.sortOrderValue,
          campaignFilters.name,
        ),
      );
      setPage(Number(campaignFilters.pageNumber) - 1);
      setRowsPerPage(campaignFilters.pageSize);
      setOrder(campaignFilters.sortOrderValue ? 'asc' : 'desc');
      setOrderBy(campaignFilters.sortOrderKey ?? 'Id');
      setDefaultStatus(campaignFilters.status);
      setSearchText(campaignFilters.name ?? '');
    } else {
      dispatch(campaignsMiddleware.fetchCampaignsList(-1, 1, rowsPerPage));
    }

    dispatch(campaignsMiddleware.fetchTimeFormatSuccess());
    dispatch(campaignsMiddleware.fetchValidators());
    dispatch(campaignsMiddleware.fetchIsCampaignCreated(false));
  }, []);

  return (
    <Grid container>
      <Grid item container xs={12} sx={{ marginTop: '24px', marginBottom: '16px' }}>
        <Grid item xs={12} sx={{ marginBottom: '24px' }}>
          <Title dataTest="campaigns-page-header-campaign-list" title="Product List" />
        </Grid>
        <Grid item container xs={12}>
          <Grid item md={3}>
            <PagesSearch
              sx={{ margin: 0, maxWidth: '200px' }}
              handleSearch={handleSearch}
              title="Search"
              defaultValue={searchText}
            />
          </Grid>
          <Grid item md={3}>
            <SelectTableHeaders
              dataTest="campaigns-page-dropdown-custom-columns"
              tableHeaders={CampaignsTableHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
            />
          </Grid>
          <Grid item md={3}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="ShowPartData-select" shrink>
                Show
              </InputLabel>
              <Select
                data-test="campaigns-page-dropdown-show"
                notched
                label="Show"
                labelId="ShowPartData-select"
                id="ShowPartData"
                name="ShowPartData"
                value={defaultStatus}
                onChange={(e) => {
                  handleStatusChange(Number(e.target.value));
                }}
              >
                <MenuItem value="-1">Show All</MenuItem>
                <MenuItem value="0">Only Inactives</MenuItem>
                <MenuItem value="1">Only Actives</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Permission permission={Permissions.createProduct}>
            <Grid item md={3}>
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
                <CustomButton data-test="campaigns-page-button-create-campaign" onClick={handleCreateProduct}>
                  Create Product
                </CustomButton>
              </Box>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid item xs={12}>
          <DenseTable
            dataTest="campaigns-page"
            selectedTableHeaders={selectedTableHeaders}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            sortTableData={sortTableData}
            data={tableData}
            editButton={false}
            isAction={hasPermission(Permissions.deleteProduct)}
            isDeleted={hasPermission(Permissions.deleteProduct)}
            order={order as string}
            orderBy={orderBy}
            page={page}
            action={{ type: 'Link', linkKeys: ['id', 'name'] }}
            rowsPerPage={rowsPerPage}
            setTableData={setTableData}
            handleRequestSort={handleRequestSort}
            createSortHandler={createSortHandler}
            handleChangeRowsPerPage={handleChangeRowsPerPageServer}
            handleChangePage={handleChangePageServer}
            handleSort={handleSortServer}
            handleChangeStatus={handleChangeStatus}
            handleDeleteAction={handleDeleteAction}
            paginationCount={paginationCount}
            handleSortCount={handleSortCount}
            changeStatusPermission={Permissions.editProduct}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CampaignsPage;
