export const JourneyExpressionMessageCreator = (message: string) => {
  let TooltipMessage = message;
  const startIndex = TooltipMessage.indexOf('ExperianContent');
  const endIndex = TooltipMessage.indexOf('</');

  TooltipMessage = TooltipMessage.substring(startIndex, endIndex)
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&amp;', '&');

  return TooltipMessage;
};
