import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { dispatch, useAppSelector } from 'redux/hooks';
import { userManagementMiddleware, userManagementSelector } from 'redux/slices/userManagement';
import { IUser, userStatuses } from 'redux/slices/userManagement/initialState';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useDeactivateUserModal from './hooks/useDeactivateUserModal';

export interface IDeactivateUserModalProps {
  user: IUser;
}

const DeactivateUserModal = ({ user }: IDeactivateUserModalProps) => {
  const deactivateLoading = useAppSelector(userManagementSelector.deactivateLoading);
  const deactivateMode = user.userStatus === userStatuses[2];

  const { onClose } = useDeactivateUserModal();

  const handleDeactivate = () => {
    if (deactivateMode) {
      dispatch(userManagementMiddleware.deactivateUser(user.id));
    } else {
      dispatch(userManagementMiddleware.activateUser(user.id));
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <Title
            title={`${deactivateMode ? 'Deactivate' : 'Activate'}  User`}
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Typography fontSize="18px" marginBottom="30px">
            {`Please confirm you want to ${deactivateMode ? 'de' : ''}activate "${user.fullName}" user․`}
          </Typography>
          <Box display="flex" justifyContent="space-around">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" loading={deactivateLoading} onClick={handleDeactivate}>
              {deactivateMode ? 'Deactivate' : 'Activate'}
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default DeactivateUserModal;
