import { CSSProperties, ReactNode } from 'react';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { ModalsController } from 'components/ModalsController/ModalsController';
import { drawerWidth } from 'lib/constants/grid';

import { useAppSelector } from '../../../redux/hooks';
import { menuSelector } from '../../../redux/slices/menu';
import RedirectionHandler from '../../RedirectionHandler/RedirectionHandler';

import Header from './Header';
import Sidebar from './Sidebar';

interface MainStyleProps {
  theme: Theme;
  open: boolean;
}

const Main = styled(Box)(({ theme, open }: MainStyleProps) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  }),
}));

const MainLayout = ({ children, sx }: { children?: ReactNode; sx?: CSSProperties }) => {
  const theme = useTheme();
  const drawerOpen = useAppSelector(menuSelector.drawerOpen);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          backgroundColor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      <Sidebar />
      <Main theme={theme} open={drawerOpen} sx={sx}>
        {children}
      </Main>
      <ModalsController key="modals" />
      <RedirectionHandler />
    </Box>
  );
};

export default MainLayout;
