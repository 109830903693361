import React from 'react';
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import createSafeContext from 'lib/createSafeContext';

import { validationTreeFilterModel } from '../components/dataModels/validationTree';

export const [useContext, Provider] = createSafeContext<ValidationTreeConsumerProps>();

const conditions = {
  ListIntegration: [
    {
      Id: 0,
      Name: 'Is',
    },
    {
      Id: 1,
      Name: 'Is Not',
    },
  ],
  CampaignFields: [
    {
      Id: 0,
      Name: 'Greater',
    },
    {
      Id: 1,
      Name: 'Greater/Equal',
    },
    {
      Id: 2,
      Name: 'Equals',
    },
    {
      Id: 3,
      Name: 'Less',
    },
    {
      Id: 4,
      Name: 'Not Equal',
    },
    {
      Id: 5,
      Name: 'Ends With',
    },
    {
      Id: 6,
      Name: 'Starts With',
    },
    {
      Id: 7,
      Name: 'Contains',
    },
    {
      Id: 8,
      Name: 'Doesn’t contain',
    },
    {
      Id: 9,
      Name: 'String Length',
    },
  ],
  ExternalServices: [
    {
      Id: 0,
      Name: 'Greater',
    },
    {
      Id: 1,
      Name: 'Greater/Equal',
    },
    {
      Id: 2,
      Name: 'Equals',
    },
    {
      Id: 3,
      Name: 'Less',
    },
    {
      Id: 4,
      Name: 'Not Equal',
    },
    {
      Id: 5,
      Name: 'Ends With',
    },
    {
      Id: 6,
      Name: 'Starts With',
    },
    {
      Id: 7,
      Name: 'Contains',
    },
    {
      Id: 8,
      Name: 'Doesn’t contain',
    },
    {
      Id: 9,
      Name: 'String Length',
    },
  ],
};

export interface ValidationTreeConsumerProps {
  setAppend: (appendFn: UseFieldArrayAppend<typeof validationTreeFilterModel>, id: string) => void;
  setUpdate: (appendFn: UseFieldArrayUpdate<typeof validationTreeFilterModel>, id: string) => void;
  setRemove: (removeFn: (index: number) => void, id: string) => void;
  filtersControlAppend: {
    [key: string]: UseFieldArrayAppend<typeof validationTreeFilterModel>;
  };
  filtersControlUpdate: {
    [key: string]: UseFieldArrayUpdate<typeof validationTreeFilterModel>;
  };
  filtersControlRemove: {
    [key: string]: UseFieldArrayRemove;
  };
  conditions: { [key: string]: Array<{ [key: string]: string | number }> };
}

export interface ValidationTreeProviderProps {
  children: React.ReactNode;
}

export const ValidationTreeProvider = ({ children }: React.PropsWithChildren<ValidationTreeProviderProps>) => {
  const [filtersControlAppend, setFiltersControlAppend] = React.useState<
    { [key: string]: UseFieldArrayAppend<typeof validationTreeFilterModel> } | {}
  >({});
  const [filtersControlUpdate, setFiltersControlUpdate] = React.useState<
    { [key: string]: UseFieldArrayUpdate<typeof validationTreeFilterModel> } | {}
  >({});
  const [filtersControlRemove, setFiltersControlRemove] = React.useState<
    { [key: string]: UseFieldArrayAppend<typeof validationTreeFilterModel> } | {}
  >({});

  const setAppend = (appendFn: UseFieldArrayAppend<typeof validationTreeFilterModel>, id: string): void => {
    setFiltersControlAppend(() => ({
      ...filtersControlUpdate,
      [id]: appendFn,
    }));
  };
  const setUpdate = (appendFn: UseFieldArrayUpdate<typeof validationTreeFilterModel>, id: string): void => {
    setFiltersControlUpdate(() => ({
      ...filtersControlAppend,
      [id]: appendFn,
    }));
  };

  const setRemove = (removeFn: (index: number) => void, id: string): void => {
    setFiltersControlRemove((filtersControlRemovePrev) => ({
      ...filtersControlRemovePrev,
      [id]: removeFn,
    }));
  };

  const providerValues: ValidationTreeConsumerProps = {
    setAppend,
    setUpdate,
    setRemove,
    filtersControlAppend,
    filtersControlRemove,
    filtersControlUpdate,
    conditions,
  };

  return <Provider value={providerValues}>{children}</Provider>;
};

export const useValidationProviderState = useContext;

export default ValidationTreeProvider;
