import { Box } from '@mui/material';

import { IApplicationJourney } from '../../helpers/CreateApplicationJourneyData';

import ApplicationJourneyGroup from './ApplicationJourneyGroup';
import ApplicationJourneyStepStatus from './ApplicationJourneyStepStatus';
import ApplicationJourneyStepStatusRight from './ApplicationJourneyStepStatusRight';

import classes from '../../assets/scss/journey.module.scss';

const ApplicationJourneyRow = ({
  leadId,
  row,
  index,
  rowHeights,
}: {
  leadId: string;
  row: IApplicationJourney;
  index: number;
  rowHeights: { [key: number]: number };
}) => (
  <>
    <Box className={classes.rowContainer}>
      {!index ? (
        <Box className={`${classes.step} ${classes.stepStart}`}>
          <Box className={classes.stepHeader}>Application Received</Box>
          <Box className={classes.stepBody}>Lead {leadId}</Box>
          <ApplicationJourneyStepStatus className={`${classes.stepStatusGroup}`} />
        </Box>
      ) : null}
      {row.groups.map((group, groupIndex: number) => (
        <ApplicationJourneyGroup key={group.id} row={row} group={group} index={groupIndex} />
      ))}
    </Box>
    {rowHeights[index] ? (
      <ApplicationJourneyStepStatusRight
        row={row}
        rowHeight={rowHeights[index]}
        final={!rowHeights[index + 1]}
        singleRow={Object.keys(rowHeights).length === 1}
      />
    ) : null}
  </>
);

export default ApplicationJourneyRow;
