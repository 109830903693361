import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import moment from 'moment/moment';

import { ILead } from '@types';

import { amountFormat, dateTime, hidePiiDisabledFields } from '../../../helpers';
import { renderAddress, renderUserName } from '../../../helpers/renderValue';

export interface IGeneralInfoData {
  ip: string;
  dob: string;
  dti: string;
  pti: string;
  zip: string;
  unit: string;
  city: string;
  state: string;
  email: string;
  country: string;
  address: string;
  lastname: string;
  promoTerm: string;
  firstname: string;
  campaignId: string;
  createdUtc: string;
  incomeUsed: string;
  affiliateId: string;
  phoneNumber: string;
  productCode: string;
  vantageScore: string;
  campaignName: string;
  applicationId: string;
  affiliateName: string;
  typeOfProperty: string;
  productSubType: string;
  piiProperties: string[];
  bankruptcyScore: string;
  affiliateChannelId: string;
  affiliateChannelName: string;
  requiredPropertyValue: number;
  mortgageBalance: number;
  monthlyPayments: number;
  requiredIncome: number;
  modeledIncome: number;
  statedIncome: number;
  totalDebt: number;
  cltv: number;
  term: number;
  apr: number;
  avm: number;
}

export const LeadsDataChanger = (response: any) => {
  const changedData = response?.data?.map((item: ILead) => ({
    ...item,
    borrower: renderUserName(item?.firstname, item?.lastname),
    createdUtc: dateTime(item.createdUtc),
    lastUpdatedDate: item.lastUpdatedDate ? dateTime(item.lastUpdatedDate) : '',
    approvedLoanAmount: item.approvedLoanAmount ? `$ ${item.approvedLoanAmount}` : '',
    loanAmount: item.loanAmount ? `$ ${item.loanAmount}` : '',
    affiliateChannelName: item.affiliateChannelName || 'Deleted',
    affiliateName: item.affiliateName || 'Deleted',
    campaignName: item.campaignName || 'Deleted',
  }));

  const properties = response.piiProperties.length ? [...response.piiProperties, 'borrower'] : [];

  return changedData.map((el: ILead) => hidePiiDisabledFields({ object: el, properties }));
};

export const LeadsDetailsDataChanger = (response: IGeneralInfoData) => {
  const properties = response.piiProperties.length ? [...response.piiProperties, 'borrower'] : [];

  const data: IGeneralInfoData = hidePiiDisabledFields({ object: response, properties });

  return {
    'Personal Details': [
      {
        title: 'Borrower’s Name',
        value: renderUserName(data.firstname, data.lastname, properties.includes('borrower')),
      },
      {
        title: 'Address',
        value: renderAddress(
          data.address,
          data.unit,
          data.city,
          data.state,
          data.zip,
          data.country,
          properties.includes('address'),
        ),
      },
      { title: 'Email', value: data.email },
      {
        title: 'DOB',
        value: properties.includes('dob')
          ? 'Hidden'
          : `${moment(data.dob).format('MMM Do YYYY')} (${moment().diff(data.dob, 'years')} y.o.)`,
      },
      { title: 'Phone', value: data.phoneNumber },
      { title: 'Credit score', value: data.vantageScore },
    ],
    'Application Details': [
      { title: 'Application ID', value: data.applicationId },
      {
        title: 'Product',
        value: data.campaignName || 'Deleted',
        color: '#7B61FF',
        link: hasPermission(Permissions.viewProductList) ? `/products/${data.campaignId}` : '',
      },
      {
        title: 'Source Channel ID',
        value: data.affiliateChannelId || '-',
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSourceChannelsList) ? `/sources-channel/${data.affiliateChannelId}` : '',
      },
      { title: 'Date and Time', value: dateTime(data.createdUtc) },
      {
        title: 'Source Channel',
        value: data.affiliateChannelName || 'Deleted',
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSourceChannelsList) ? `/sources-channel/${data.affiliateChannelId}` : '',
      },
      {
        title: 'Source Name',
        value: data.affiliateName || 'Deleted',
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSources) ? `/sources/${data.affiliateId}` : '',
      },
      { title: 'IP Address', value: data.ip },
      {
        title: 'Source ID',
        value: data.affiliateId || '-',
        color: '#7B61FF',
        link: hasPermission(Permissions.viewSources) ? `/sources/${data.affiliateId}` : '',
      },
    ],
    'Accepted Offer': [
      { title: 'Monthly Payments', value: amountFormat(data.monthlyPayments) },
      {
        title: 'APR',
        value: data.apr ? `${data.apr}%` : '',
      },
      {
        title: 'Product Code',
        value: data.productCode,
      },
      { title: 'Product Sub-Type', value: data.productSubType },
      {
        title: 'Term',
        value: data.term,
      },
      {
        title: 'Promo Term',
        value: data.promoTerm,
      },
    ],
    'Financial Details': [
      { title: 'DTI', value: data.dti ? `${data.dti}%` : '-' },
      { title: 'Modeled Income', value: amountFormat(data.modeledIncome) },
      { title: 'Income Used', value: data.incomeUsed },
      { title: 'PTI', value: data.pti ? `${data.pti}%` : '-' },
      {
        title: 'Min Required Income',
        value: amountFormat(data.requiredIncome),
      },
      {
        title: 'Min Required Property Value',
        value: amountFormat(data.requiredPropertyValue),
      },
      { title: 'cLTV', value: data.cltv, color: '#7B61FF' },
      {
        title: 'Stated Income',
        value: amountFormat(data.statedIncome),
      },
      {
        title: 'Total Debt (Incl. Loan Payment)',
        value: amountFormat(data.totalDebt),
      },
      {
        title: 'Bankruptcy Score',
        value: data.bankruptcyScore,
      },
    ],
    'Property Details': [
      { title: 'Mortgage balance', value: amountFormat(data.mortgageBalance) },
      { title: 'AVM value', value: amountFormat(data.avm) },
      { title: 'Type of property', value: data.typeOfProperty },
    ],
  };
};
