import React, { CSSProperties, Dispatch, ElementType, SetStateAction, SyntheticEvent } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';

interface ITab {
  id?: string;
  label: string;
  permission?: Permissions;
}

interface BaseTabsProps {
  tabs: ITab[];
  takeStateLevelUp?: (v: string, n: number) => void;
  tabValue: number | string;
  setTabValue?: Dispatch<SetStateAction<number | string>>;
  sx?: CSSProperties;
  component: ElementType;
  dataTest?: string[];
}

const BaseTabs: React.FC<BaseTabsProps> = ({
  tabs,
  takeStateLevelUp,
  tabValue,
  setTabValue,
  sx = {},
  component,
  dataTest,
}): JSX.Element => {
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (takeStateLevelUp) {
      takeStateLevelUp('tabs', newValue);
    }

    if (setTabValue) {
      setTabValue(newValue);
    }
  };

  if (!tabs?.length || !Array.isArray(tabs)) {
    return <Box component="span">empty</Box>;
  }

  return (
    <Tabs
      value={tabValue}
      variant="scrollable"
      component={component}
      onChange={handleChange}
      sx={{
        px: 1,
        '& a': {
          minWidth: 10,
          py: 1.5,
          mr: 2.25,
          color: 'grey.600',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          fontStyle: 'NORMAL',
          fontWeight: '700',
          fontSize: '15px',
          lineHeight: '18px',
          '& a.Mui-selected': {
            color: '#7B61FF',
          },
          '& a > svg': {
            marginBottom: '0px !important',
            mr: 1.25,
          },
        },
        ...sx,
      }}
    >
      {tabs.map((item, index) => {
        if (!hasPermission(item.permission)) {
          return null;
        }

        return (
          <Tab
            value={item.id}
            data-test={dataTest ? dataTest[index] : ''}
            key={item.label}
            sx={{ cursor: 'pointer', marginLeft: '30px' }}
            label={item.label}
          />
        );
      })}
    </Tabs>
  );
};

export default BaseTabs;
