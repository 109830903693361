import { useEffect, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Permission from 'components/Permissions/Permission';

import { NavItemType } from '@types';

import { NavGroupProps } from '../NavGroup';
import NavItem from '../NavItem';

interface NavCollapseProps {
  menu: NavGroupProps['item'];
  level: number;
  selectedTab: string;
  selectedTabNested: string;
}

const NavCollapse = ({ menu, level, selectedTab, selectedTabNested }: NavCollapseProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedTab === menu.id) {
      setOpen(true);
    }
  }, [selectedTab]);

  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return (
          <NavCollapse
            key={item.id}
            menu={item as unknown as NavGroupProps['item']}
            level={level + 1}
            selectedTab={selectedTab}
            selectedTabNested={selectedTabNested}
          />
        );
      case 'item':
        return (
          <Permission key={item.id} permission={item.permission}>
            <NavItem
              key={item.id}
              item={item as NavItemType}
              level={level + 1}
              selectedTab={selectedTab}
              selectedTabNested={selectedTabNested}
            />
          </Permission>
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon!;
  const menuIcon = menu.icon ? (
    <Icon strokeWidth={1.5} size="1.3rem" sx={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selectedTab === menu.id ? 8 : 6,
        height: selectedTab === menu.id ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        data-test={`menu-link-${menu.id}`}
        sx={{
          borderRadius: '5px',
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 && selectedTab === menu.id ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
        }}
        selected={selectedTab === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={selectedTab === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {menu?.children ? (
          <Box>
            {open ? (
              <KeyboardArrowUpIcon sx={{ marginTop: 'auto', marginBottom: 'auto', width: '16px', height: '16px' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ marginTop: 'auto', marginBottom: 'auto', width: '16px', height: '16px' }} />
            )}
          </Box>
        ) : null}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {open && (
          <List
            component="div"
            disablePadding
            sx={{
              position: 'relative',
              '&:after': {
                content: "''",
                position: 'absolute',
                left: '',
                top: 0,
                height: '100%',
                width: '1px',
                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light,
              },
            }}
          >
            {menus}
          </List>
        )}
      </Collapse>
    </>
  );
};

export default NavCollapse;
