import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.dashboard;

const reportsLeads = createSelector([selector], (state) => state.reportsLeads);

const reportsNoLeads = createSelector([selector], (state) => state.reportsNoLeads);

const isReportsLeadsLoading = createSelector([selector], (state) => state.isReportsLeadsLoading);

const isReportsNoLeadsLoading = createSelector([selector], (state) => state.isReportsNoLeadsLoading);

const reportsComparison = createSelector([selector], (state) => state.reportsComparison);

const isReportsComparisonLoading = createSelector([selector], (state) => state.isReportsComparisonLoading);

const reportsTopLocations = createSelector([selector], (state) => state.reportsTopLocations);

const isReportsTopLocationsLoading = createSelector([selector], (state) => state.isReportsTopLocationsLoading);

const reportsExternalServices = createSelector([selector], (state) => state.reportsExternalServices);

const isReportsExternalServicesLoading = createSelector([selector], (state) => state.isReportsExternalServicesLoading);

export default {
  reportsLeads,
  reportsNoLeads,
  isReportsLeadsLoading,
  isReportsNoLeadsLoading,
  reportsComparison,
  isReportsComparisonLoading,
  reportsTopLocations,
  isReportsTopLocationsLoading,
  reportsExternalServices,
  isReportsExternalServicesLoading,
};
