import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { Price } from '../../lib/constants/format';
import { IComparisonReport } from '../../types';

const ComparisonTableHeader = ({ align, children }: { align?: 'left' | 'right'; children: string }) => (
  <TableCell sx={{ padding: '12px' }}>
    <Typography sx={{ color: '#A0AEC0' }} align={align ?? 'center'}>
      {children}
    </Typography>
  </TableCell>
);

const ComparisonTableCell = ({ leadType, leadTypeComparison }: { leadType: number; leadTypeComparison: number }) => (
  <TableCell sx={{ padding: '12px' }}>
    <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
      {Price()?.format(Number(leadType))}
      {leadTypeComparison === 1 ? (
        <ArrowDownwardIcon sx={{ width: '16px', height: '16px', color: '#FF497F' }} />
      ) : (
        <ArrowUpwardIcon sx={{ width: '16px', height: '16px', color: '#27E7AD' }} />
      )}
    </Typography>
  </TableCell>
);

const ComparisonTable = ({ data }: { data: IComparisonReport[] }) => (
  <TableContainer>
    <Table data-test="dashboard-page-comparison-table">
      <TableHead>
        <TableRow sx={{ cursor: 'default !important' }}>
          <ComparisonTableHeader align="left">Date</ComparisonTableHeader>
          <ComparisonTableHeader>Received</ComparisonTableHeader>
          <ComparisonTableHeader>Accepted</ComparisonTableHeader>
          <ComparisonTableHeader>Rejected</ComparisonTableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          ? data?.map((item, index) => (
              <TableRow
                sx={{
                  cursor: 'default !important',
                  whiteSpace: 'nowrap',
                }}
                data-test={`dashboard-page-comparison-table-table-id-${index}`}
                key={item.date}
              >
                <TableCell align="left">
                  <Typography>{item?.date}</Typography>
                </TableCell>
                <ComparisonTableCell
                  leadType={item?.receivedLeads}
                  leadTypeComparison={item?.receivedLeadsComparison}
                />
                <ComparisonTableCell
                  leadType={item?.acceptedLeads}
                  leadTypeComparison={item?.acceptedLeadsComparison}
                />
                <ComparisonTableCell
                  leadType={item?.rejectedLeads}
                  leadTypeComparison={item?.rejectedLeadsComparison}
                />
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ComparisonTable;
