import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { viewsMiddleware } from 'redux/slices/views';
import { ModalName } from 'redux/slices/views/initialState';

import { ITenantSettings } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../../redux/slices/settings';
import { CustomButton } from '../../shared';

export interface IConfirmDailyCapResetModalProps {
  data: ITenantSettings;
}

const ResetDailyCapModal = () => {
  const isResetDailyCapLoading = useAppSelector(settingsSelector.tenantSettingsLoading);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ResetDailyCapModal));
  };

  const onConfirm = () => {
    dispatch(settingsMiddleware.resetDailyCap());
  };

  return (
    <Dialog data-test="confirm-daily-cap-reset-modal" open onClose={onClose}>
      <Grid sx={{ width: '600px' }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
              >
                Reset Daily CAP limit
              </Typography>
            </Grid>
            <Grid item>
              <IconButton data-test="confirm-daily-cap-reset-modal-button-x" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>You are about to reset the daily CAP limit</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                <CustomButton variant="outlined" onClick={onClose}>
                  Cancel
                </CustomButton>
                <CustomButton
                  data-test="confirm-daily-cap-reset-modal-button-confirm"
                  disabled={isResetDailyCapLoading}
                  loading={isResetDailyCapLoading}
                  onClick={onConfirm}
                >
                  Reset
                </CustomButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ResetDailyCapModal;
