import { useCallback } from 'react';

import { ISelectedList } from '@types';

import { dispatch, useAppSelector } from '../../../../redux/hooks';
import { listsMiddleware, listsSelector } from '../../../../redux/slices/lists';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmListIntegrationDeleteControls = (id: string, name: string) => {
  const selectedLists = useAppSelector(listsSelector.selectedLists);

  const type = 0;
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationDeleteModal));
  }, []);

  const onConfirm = useCallback(() => {
    if (name === 'header') {
      dispatch(listsMiddleware.fetchDeleteList(id, type));
    } else if (name === 'table') {
      selectedLists?.forEach((item: ISelectedList) => {
        if (item.id === id) {
          dispatch(listsMiddleware.deleteListValue(id, item.bwListId));
        }
      });
    }

    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationDeleteModal));
  }, [id, name]);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmListIntegrationDeleteControls;
