import { ISnackbarProps, SeveritiesType } from '../../../types/snackbar';

export const getInitialState = (): ISnackbarProps => ({
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  variant: 'default',
  alert: {
    color: SeveritiesType.success,
    variant: 'filled',
  },
  transition: 'Fade',
  close: true,
  actionButton: false,
});
