import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ApplicationJourney from '../../components/ApplicationJourney';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';

const LeadsJourney = () => {
  const { id: leadId = '0' } = useParams();

  const applicationJourney = useAppSelector(leadsSelector.applicationJourney);
  const applicationJourneyLoading = useAppSelector(leadsSelector.applicationJourneyLoading);

  useEffect(() => {
    dispatch(leadsMiddleware.fetchLeadsApplicationJourney(leadId));
  }, [leadId]);

  if (applicationJourneyLoading) {
    return <Loading />;
  }

  return !applicationJourney?.rows.length ? (
    <Title sx={{ padding: 0 }} title="No Data" />
  ) : (
    <ApplicationJourney leadId={leadId} journey={applicationJourney.rows} isSuccess={applicationJourney.isSuccess} />
  );
};

export default LeadsJourney;
