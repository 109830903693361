import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { ICreditUnion } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../../redux/slices/affiliates';
import { validationTreeMiddleware, validationTreeSelector } from '../../../redux/slices/validationTree';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import CustomSelect from '../../shared/Select/CustomSelect';
import Loading from '../../UI/Loading';

export interface IConfirmAffiliateChannelTreeNameModalProps {
  id?: string;
  redirect?: boolean;
}

const ConfirmAffiliateChannelTreeNameModal = ({ id, redirect }: IConfirmAffiliateChannelTreeNameModalProps) => {
  const navigate = useNavigate();
  const channel = useAppSelector(affiliatesSelector.channel);
  const creditUnions = useAppSelector(affiliatesSelector.creditUnions);
  const creditUnionsLoading = useAppSelector(affiliatesSelector.creditUnionsLoading);
  const validationTreeLoading = useAppSelector(validationTreeSelector.validationTreeLoading);
  const validationTreeTypes = useAppSelector(validationTreeSelector.validationTreeTypes);
  const validationTreeTypesLoading = useAppSelector(validationTreeSelector.validationTreeTypesLoading);

  const [validationTreeName, setValidationTreeName] = useState<string>('');
  const [error, setError] = useState(false);
  const [creditUnion, setCreditUnion] = useState<number>(0);
  const [type, setType] = useState(1);

  const treeId = id ?? channel?.id;

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchCreditUnions());
    dispatch(validationTreeMiddleware.getValidationTreeTypes());

    return () => {
      setError(false);
    };
  }, []);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelRuleNameModal));

    if (redirect) {
      navigate('/sources-channel');
    }
  };

  const onConfirm = () => {
    const treeName = validationTreeName.trim();

    if (treeName?.length && treeName?.length <= 50) {
      dispatch(
        validationTreeMiddleware.fetchCreateValidationTreeName({
          affiliateChannelId: `${treeId}`,
          creditUnion,
          name: treeName,
          channelTreeType: type,
        }),
      );
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog data-test="confirm-affiliate-channel-tree-name-modal" open onClose={onClose}>
      <Grid>
        <DialogTitle sx={{ textAlign: 'center', fontWeight: 700 }}>Create Decision Tree</DialogTitle>
        {!creditUnionsLoading && !validationTreeTypesLoading ? (
          <DialogContent>
            <Grid sx={{ mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel>Tree Name</InputLabel>
                <TextField
                  data-test="confirm-affiliate-channel-tree-name-modal-input-decision-tree-name"
                  placeholder="Decision Tree Name"
                  value={validationTreeName}
                  required
                  sx={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '19px',
                    textAlign: 'center',
                    color: '#454256',
                  }}
                  id="TreeName"
                  onChange={(e) => setValidationTreeName(e.target.value.trimStart())}
                  fullWidth
                />
                <FormHelperText error>{error ? 'The number of characters should not exceed 50.' : ''}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid sx={{ mt: 2 }}>
              <CustomSelect
                valueKey="id"
                nameKey="name"
                defaultValue={type}
                dataTest="confirm-affiliate-channel-tree-name-modal-input-decision-type"
                options={validationTreeTypes ?? []}
                width="100%"
                label="Type"
                labelSx={{ color: '#a0aec0', fontSize: '13px', marginBottom: '2px' }}
                onSelect={(value) => {
                  if (value?.id) {
                    setType(+value.id);
                  }
                }}
              />
            </Grid>
            {type === 2 && !!creditUnions.length && (
              <Grid sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Credit Union</InputLabel>
                  <Select
                    data-test="confirm-affiliate-channel-tree-name-modal-dropdown-decision-tree-credit-union"
                    sx={{ mt: '5px' }}
                    id="credit-unions-dropdown"
                    defaultValue={creditUnion}
                    fullWidth
                    onChange={(e) => setCreditUnion(+e.target.value)}
                  >
                    {creditUnions?.map((cUnion: ICreditUnion) => (
                      <MenuItem key={cUnion?.id} value={cUnion?.id}>
                        {cUnion?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </DialogContent>
        ) : (
          <Loading height={200} />
        )}
        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomButton
                data-test="confirm-affiliate-channel-tree-name-modal-button-cancel"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test="confirm-affiliate-channel-tree-name-modal-button-add-decision-trees"
                disabled={validationTreeLoading || !validationTreeName.length}
                onClick={onConfirm}
                loading={validationTreeLoading}
              >
                Create
              </CustomButton>
            </Box>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAffiliateChannelTreeNameModal;
