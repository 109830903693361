import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CardContent, CardProps, Divider, Grid, IconButton, Modal } from '@mui/material';
import MainCard from 'components/UI/MainCard';

interface BodyProps extends CardProps {
  handleClose: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
}

// TODO refactor last modals
const Body = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ sx, handleClose, open, setOpen, children }: BodyProps, ref: React.Ref<HTMLDivElement>) => (
    <Box
      ref={ref}
      tabIndex={-1}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <MainCard
        sx={{
          maxWidth: '600px',
          ...sx,
        }}
        content={false}
        secondary={
          <IconButton onClick={handleClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <CardContent>{children}</CardContent>
        <Divider />
      </MainCard>
    </Box>
  ),
);

const BaseModal = ({
  open,
  setOpen,
  children,
  sx,
  closeRedirectURL,
  dataTest,
  closeOnOutsideClick = true,
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  sx?: React.CSSProperties;
  closeRedirectURL?: string;
  dataTest?: string;
  closeOnOutsideClick?: boolean;
}) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  const navigate = useNavigate();

  const handleClose = () => {
    if (closeOnOutsideClick) {
      if (closeRedirectURL) {
        navigate(`/${closeRedirectURL}`, { replace: true });
      }

      setOpen(false);
    }
  };

  return (
    <Grid data-test={dataTest} container justifyContent="flex-end">
      <Modal open={open} onClose={handleClose}>
        <Body sx={sx} open={open} setOpen={setOpen} handleClose={handleClose}>
          {children}
        </Body>
      </Modal>
    </Grid>
  );
};

export default BaseModal;
