import { useEffect, useState } from 'react';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Box, FormLabel, MenuItem, Select } from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';
import moment from 'moment/moment';

import { DateRange, IDocumentList } from '@types';

import { dispatch } from '../../redux/hooks';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import CustomRangePicker from '../CustomRangePicker/CustomRangePicker';
import { PagesSearch } from '../Layout/MainLayout/Header/SearchSection';
import { CustomButton } from '../shared';

const statuses = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Accepted' },
  { value: '2', label: 'Rejected' },
  { value: '3', label: 'In Review' },
];

const Filters = ({
  leadId,
  data,
  setData,
}: {
  leadId: string;
  data: IDocumentList[];
  setData: (value: IDocumentList[]) => void;
}) => {
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: moment().subtract(1, 'year').format(),
    endDate: moment().format(),
  });

  const onUpload = () => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.DocumentsUploadFilesModal,
        props: { leadId, mode: 'Document' },
      }),
    );
  };

  const onSearch = (value: string) => {
    if (value) {
      const filteredData = data.filter((el) => el.name.includes(value));

      setData(filteredData);
    } else {
      setData(data);
    }
  };

  const onStatusChange = (status: string) => {
    if (status === '0') {
      setData(data);
    } else {
      const filteredStatuses = data.filter((el) => `${el.status}` === status);

      setData(filteredStatuses);
    }
  };

  useEffect(() => {
    const filteredDateData = data.filter(
      (el) => moment(el.date).diff(dateTimeState?.startDate) >= 0 && moment(el.date).diff(dateTimeState?.endDate) <= 0,
    );

    setData(filteredDateData);
  }, [dateTimeState]);

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap', gap: '8px' }}
    >
      <Box>
        <PagesSearch handleSearch={onSearch} minChar={1} sx={{ width: '200px', margin: '0px' }} />
      </Box>
      <Box>
        <CustomRangePicker dateTimeState={dateTimeState} setDateTimeState={setDateTimeState} />
      </Box>
      {/* <Box sx={{ minWidth: '200px' }}> */}
      {/*  <FormLabel>Document</FormLabel> */}
      {/*  <Select fullWidth> */}
      {/*    {documents.map((el) => ( */}
      {/*      <MenuItem value={el.value}>{el.label}</MenuItem> */}
      {/*    ))} */}
      {/*  </Select> */}
      {/* </Box> */}
      <Box sx={{ minWidth: '200px' }}>
        <FormLabel>Status</FormLabel>
        <Select fullWidth defaultValue="0">
          {statuses.map((el) => (
            <MenuItem key={el.value} onClick={() => onStatusChange(el.value)} value={el.value}>
              {el.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Permission permission={Permissions.uploadDocuments}>
        <Box>
          <CustomButton onClick={onUpload} variant="outlined">
            <IosShareIcon /> Upload
          </CustomButton>
        </Box>
      </Permission>
    </Box>
  );
};

export default Filters;
