import React, { useState } from 'react';
import { Article, CalendarToday, DeleteForever, Done, Euro, Wifi2Bar } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import RocketIcon from '@mui/icons-material/Rocket';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, FormControl, Grid, Pagination, Typography } from '@mui/material';

import { CustomButton } from '../../components/shared';
import InputTextField from '../../components/UI/InputTextField';
import Title from '../../components/UI/Title';

export interface IInvoicesResponse {
  date: string;
  price: string;
  subtitle: string;
}

const InvoicesLists: IInvoicesResponse[] = [
  { date: '2023-05-01', price: '10.99', subtitle: 'Invoice 1' },
  { date: '2023-05-15', price: '20.50', subtitle: 'Invoice 2' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
  { date: '2023-05-28', price: '5.99', subtitle: 'Invoice 3' },
];

const Billing = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(InvoicesLists.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = InvoicesLists.slice(startIndex, endIndex);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Grid container item display="flex" xs={12} justifyContent="space-between" marginX={3} marginY={3}>
        <Title title="Billing" />
      </Grid>

      <Grid container display="flex" xs={12} marginX={3} gap={5}>
        <Grid container xs={6} display="flex">
          <Grid container item display="flex" xs={12} gap={1} direction="row" justifyContent="space-between">
            <Grid
              item
              display="flex"
              xs={6}
              sx={{ bgcolor: '#7b61ff' }}
              direction="column"
              paddingY={1}
              paddingX={2}
              borderRadius={4}
            >
              <Grid xs={12} display="flex">
                <Wifi2Bar sx={{ fontSize: '40px', color: 'white' }} />
              </Grid>
              <Grid xs={12} display="flex">
                <Typography
                  component="p"
                  sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '18px', color: 'white' }}
                  marginY={1}
                >
                  4562 1122 4594 7852
                </Typography>
              </Grid>
              <Grid container xs={12} display="flex">
                <Grid xs={5} display="flex" direction="column">
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                    marginY={1}
                  >
                    Card Holder
                  </Typography>
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '600', fontSize: '15px', color: 'white' }}
                    marginY={1}
                  >
                    Jack Peterson
                  </Typography>
                </Grid>
                <Grid xs={3} display="flex" direction="column" paddingX={2}>
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                    marginY={1}
                  >
                    Expires
                  </Typography>
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '600', fontSize: '15px', color: 'white' }}
                    marginY={1}
                  >
                    11/12
                  </Typography>
                </Grid>
                <Grid xs={4} display="flex" direction="row-reverse" padding={3}>
                  <Euro sx={{ color: 'white' }} />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              display="flex"
              xs={5}
              sx={{ bgcolor: 'white', height: '200px' }}
              justifyContent="center"
              alignItems="center"
              direction="column"
              boxShadow={3}
              borderRadius={4}
            >
              <Box
                display="flex"
                sx={{ bgcolor: '#7b61ff', borderRadius: 2, width: '50px', height: '50px' }}
                justifyContent="center"
                alignItems="center"
              >
                <RocketIcon sx={{ color: 'white' }} />
              </Box>
              <Typography
                component="p"
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#454256',
                }}
                marginY={1}
              >
                Professional plan
              </Typography>
              <Typography variant="h5" sx={{ color: 'rgb(160, 174, 192)' }}>
                Monthly payment
              </Typography>
              <Divider sx={{ marginLeft: '25%', marginRight: '25%', color: 'black' }} />
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '16px', lineHeight: '19px', color: '#454256' }}
                marginY={1}
              >
                $250
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            display="flex"
            xs={12}
            sx={{ bgcolor: 'white' }}
            marginY={4}
            paddingY={4}
            borderRadius={4}
            boxShadow={3}
          >
            <Grid
              xs={7}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              direction="column"
              paddingLeft={4}
            >
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '18px', color: '#454256' }}
                marginY={1}
              >
                Payment methods
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                marginY={1}
              >
                You can delete this card after you add new
              </Typography>
            </Grid>
            <Grid
              xs={5}
              display="flex"
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="flex-start"
              paddingRight={4}
            >
              <CustomButton>Add new card</CustomButton>
            </Grid>
            <Grid xs={12} display="flex" justifyContent="center">
              <FormControl sx={{ width: '90%' }}>
                <InputTextField id="id" placeholder="XXXX XXXX XXXX 7852" sx={{ width: '90%' }} fullWidth />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            item
            display="flex"
            xs={12}
            sx={{ bgcolor: 'white' }}
            marginY={4}
            paddingY={4}
            borderRadius={4}
            boxShadow={3}
          >
            <Grid xs={12} paddingLeft={4}>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '16px', color: '#454256' }}
                marginY={1}
              >
                Billing information
              </Typography>
            </Grid>
            <Grid
              xs={6}
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              direction="column"
              paddingLeft={4}
            >
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '16px', color: '#454256' }}
                marginY={1}
              >
                Jack Peterson
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                marginY={1}
              >
                Company Name:
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                marginY={1}
              >
                Email address:
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                marginY={1}
              >
                VAT Number:
              </Typography>
            </Grid>
            <Grid xs={6} display="flex" justifyContent="center" direction="row" paddingY={2}>
              <DeleteForever sx={{ marginX: '5%', color: 'rgb(238, 93, 80)', cursor: 'pointer' }} />
              <Typography
                component="p"
                sx={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '18px',
                  color: 'rgb(238, 93, 80)',
                  cursor: 'pointer',
                }}
              >
                DELETE
              </Typography>
              <EditIcon sx={{ marginX: '5%', cursor: 'pointer' }} />
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '18px', color: '#454256', cursor: 'pointer' }}
              >
                EDIT
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={5} display="flex" sx={{ bgcolor: 'white' }} borderRadius={4} boxShadow={3}>
          <Grid container xs={12} display="flex" direction="row" paddingY={4} paddingX={2}>
            <Grid item xs={4} display="flex" alignItems="center">
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '20px', color: '#454256' }}
                marginY={1}
              >
                Invoices
              </Typography>
            </Grid>
            <Grid item xs={8} display="flex" direction="row" justifyContent="flex-end" paddingX={4} alignItems="center">
              <SearchIcon sx={{ color: 'rgb(160, 174, 192)', cursor: 'pointer' }} />
              <CalendarToday sx={{ marginX: '5%', color: 'rgb(160, 174, 192)', cursor: 'pointer' }} />
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '16px', color: 'rgb(160, 174, 192)' }}
                marginY={1}
              >
                Date range
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} display="flex" flexWrap="wrap" spacing={0} paddingX={2}>
            {currentItems.map((invoice) => (
              <Grid xs={12} display="flex" direction="row">
                <Grid xs={6} display="flex" direction="column">
                  <Grid xs={12}>
                    <Typography
                      component="p"
                      sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '18px', color: '#454256' }}
                      marginY={1}
                    >
                      {invoice.date}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography
                      component="p"
                      sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                      marginY={1}
                    >
                      {invoice.subtitle}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={6} display="flex" direction="row-reverse" paddingX={4}>
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                  >
                    PDF
                  </Typography>
                  <Article sx={{ fontSize: '16px', marginX: '5%' }} />
                  <Typography
                    component="p"
                    sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
                  >
                    {invoice.price}$
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid xs={12} display="flex" direction="row-reverse" paddingX={4}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              color="secondary"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container display="flex" xs={12} marginY={4} marginX={3} gap={5}>
        <Grid
          container
          item
          display="flex"
          xs={6}
          sx={{ bgcolor: 'white' }}
          marginY={4}
          paddingY={4}
          borderRadius={4}
          boxShadow={3}
        >
          <Grid
            xs={7}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            paddingLeft={4}
          >
            <Typography
              component="p"
              sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '18px', color: '#454256' }}
              marginY={1}
            >
              Enterprise plan
            </Typography>
            <Typography
              component="p"
              sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
              marginY={1}
            >
              For further details and activation submit a request
            </Typography>
          </Grid>
          <Grid
            xs={5}
            display="flex"
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="flex-start"
            paddingRight={4}
          >
            <CustomButton>Request for enterprise</CustomButton>
          </Grid>
          <Grid container xs={12} display="flex" paddingX={4} paddingY={1}>
            <Grid xs={6} display="flex" direction="column" justifyContent="center">
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Unlimited requests per month
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Unlimited requests per month
              </Typography>
            </Grid>
            <Grid xs={6} display="flex" direction="column" justifyContent="center">
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Unlimited requests per month
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Unlimited requests per month
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          display="flex"
          xs={5}
          sx={{ bgcolor: 'white' }}
          marginY={4}
          paddingY={4}
          borderRadius={4}
          boxShadow={3}
        >
          <Grid
            xs={7}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            direction="column"
            paddingLeft={4}
          >
            <Typography
              component="p"
              sx={{ fontStyle: 'normal', fontWeight: '700', fontSize: '18px', color: '#454256' }}
              marginY={1}
            >
              Professional plan
            </Typography>
            <Typography
              component="p"
              sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: 'rgb(160, 174, 192)' }}
              marginY={1}
            >
              Billing period:
            </Typography>
          </Grid>
          <Grid
            xs={5}
            display="flex"
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="flex-start"
            paddingRight={4}
          >
            <CustomButton>Cancel</CustomButton>
          </Grid>
          <Grid container xs={12} display="flex" paddingX={4} paddingY={1}>
            <Grid xs={6} display="flex" direction="column" justifyContent="center">
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Feature
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Feature
              </Typography>
            </Grid>
            <Grid xs={6} display="flex" direction="column" justifyContent="center">
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Feature
              </Typography>
              <Typography
                component="p"
                sx={{ fontStyle: 'normal', fontWeight: '500', fontSize: '14px', color: '#454256' }}
                marginY={1}
              >
                <Done sx={{ fontSize: '16px', marginRight: '5%', color: '#30d230' }} />
                Feature
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
