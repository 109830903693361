export interface ITag {
  id: string;
  name: string;
  color: number;
}
export interface ITagsState {
  tagsLoading: boolean;
  tags: ITag[] | null;
}

export const getInitialState = (): ITagsState => ({
  tagsLoading: false,
  tags: null,
});
