import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

const useStyles = makeStyles({
  root: {
    flexShrink: 0,
    marginLeft: 2.5,
  },
  button: {
    padding: 6,
    margin: '0!important',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    border: 0,
    borderRadius: 0,
  },
  activeButton: {
    borderBottom: '2px solid blue',
    color: 'blue',
    padding: 6,
    margin: '0!important',
    minWidth: 32,
    maxWidth: 32,
    width: 32,
    border: 0,
    borderRadius: 0,
  },
});

interface ICustomTablePaginationActionsProps {
  dataTest?: string;
  count: number;
  page: number;
  rowsPerPage?: number;
  onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

const CustomDynamicTablePaginationActions = ({
  dataTest,
  count,
  page,
  rowsPerPage,
  onPageChange,
}: ICustomTablePaginationActionsProps) => {
  const classes = useStyles();

  const handlePageChange = (newPage: number) => {
    if (onPageChange) {
      onPageChange(null, newPage);
    }
  };

  const renderPageButtons = () => {
    const buttons = [];
    const totalPages = Math.ceil(count / (rowsPerPage ?? 1));

    const startPage = Math.max(0, page - 2);
    const endPage = Math.min(totalPages - 1, page + 2);

    if (startPage > 0) {
      buttons.push(
        <Button
          data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-1`}
          size="small"
          key={0}
          variant="outlined"
          className={classes.button}
          sx={{
            borderTopLeftRadius: '6px !important',
            borderBottomLeftRadius: '6px !important',
          }}
          onClick={() => handlePageChange(0)}
        >
          1
        </Button>,
      );

      if (startPage > 1) {
        buttons.push(
          <Box
            component="span"
            key="ellipsis-start"
            sx={{ height: 32, width: 32, display: 'inline-block', padding: 1 }}
          >
            ...
          </Box>,
        );
      }
    }

    // eslint-disable-next-line no-plusplus
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-${i + 1}`}
          key={i}
          variant="outlined"
          size="small"
          className={page === i ? classes.activeButton : classes.button}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      if (endPage < totalPages - 2) {
        buttons.push(
          <Box
            component="span"
            key="ellipsis-end"
            sx={{
              height: 32,
              width: 32,
              display: 'inline-block',
              padding: 1,
            }}
          >
            ...
          </Box>,
        );
      }

      buttons.push(
        <Button
          data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-${totalPages}`}
          key={totalPages - 1}
          variant="outlined"
          size="small"
          className={classes.button}
          sx={{
            borderTopRightRadius: '6px !important',
            borderBottomRightRadius: '6px !important',
          }}
          onClick={() => handlePageChange(totalPages - 1)}
        >
          {totalPages}
        </Button>,
      );
    }

    return buttons;
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={() => handlePageChange(0)}
        disabled={page === 0}
        data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-first`}
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 0}
        data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-prev`}
      >
        <KeyboardArrowLeft />
      </IconButton>
      {renderPageButtons()}
      <IconButton
        onClick={() => handlePageChange(page + 1)}
        disabled={page >= Math.ceil(count / (rowsPerPage ?? 1)) - 1}
        data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-next`}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={() => handlePageChange(Math.ceil(count / (rowsPerPage ?? 1)) - 1)}
        disabled={page >= Math.ceil(count / (rowsPerPage ?? 1)) - 1}
        data-test={`${dataTest ?? ''}-table-footer-button-go-to-page-last`}
      >
        <LastPage />
      </IconButton>
    </div>
  );
};

export default CustomDynamicTablePaginationActions;
