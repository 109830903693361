import { IReportsState } from '../../../types';

export const getInitialState = (): IReportsState => ({
  error: null,
  isReportsLoading: false,
  isComparisonReportsLoading: false,
  affiliateReports: { data: [], pagesCount: 0, itemsCount: 0 },
  comparisonReports: null,
  isDownloadLeadListReportLoading: false,
});
