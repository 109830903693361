import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { DASHBOARD_PATH } from 'config';

import { useAppSelector } from '../../../../redux/hooks';
import { tenantSelector } from '../../../../redux/slices/tenant';

const StyledLink = styled(Link)({
  textDecoration: 'none',
  fontSize: '28px',
  color: '#454256',
});

const LogoSection = () => {
  const logo = useAppSelector(tenantSelector.logo);

  return (
    <StyledLink data-test="logo" to={DASHBOARD_PATH}>
      <img src={logo} height={50} alt="logo" />
    </StyledLink>
  );
};

export default LogoSection;
