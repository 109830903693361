import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IExternalServiceState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IExternalServiceState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },
  getLoading(state, action) {
    state.isLoading = action.payload;
  },
  setExternalServiceSuccess(state, action) {
    state.externalService = action.payload;
  },
  setExternalServiceListSuccess(state, action) {
    state.externalServiceList = action.payload;
  },
  setSimplifiedExternalServices(state, action) {
    state.simplifiedExternalServices = action.payload;
  },
  setExternalServiceListCount(state, action) {
    state.externalServiceCount = action.payload;
  },
  setExternalServiceDetailsSuccess(state, action) {
    state.externalServiceDetails = action.payload;
  },
  setExternalServiceFieldsSuccess(state, action) {
    state.externalServiceFields = action.payload;
  },
  setExternalServiceResponseFieldsSuccess(state, action) {
    state.externalServiceResponseFields = action.payload;
  },
  setExternalServiceCampaignFieldsMatchingValues(state, action) {
    state.externalServiceCampaignFieldsMatchingValues = action.payload;
  },
  setExternalServiceCampaignFieldsAutoMatchValues(state, action) {
    state.externalServiceCampaignFieldsAutoMatchValues = action.payload;
  },
  setExternalServiceDeleteLoading(state, action) {
    state.isExternalServiceDeleteLoading = action.payload;
  },
  setExternalServiceMatchingCampaignFieldsLoading(state, action) {
    state.isExternalServiceMatchingCampaignFieldsLoading = action.payload;
  },
  setExternalServiceDetailsLoading(state, action) {
    state.isExternalServiceDetailsLoading = action.payload;
  },
  setExternalServiceUpdateLoading(state, action) {
    state.isExternalServiceUpdateLoading = action.payload;
  },
  setFieldByDataTypeExternalServicesLoading(state, action) {
    state.isFieldByDataTypeExternalServicesLoading = action.payload;
  },
  setServiceFieldByDataTypeExternalServicesLoading(state, action) {
    state.isServiceFieldByDataTypeExternalServicesLoading = action.payload;
  },
  setExternalServicesCampaignFieldsAutoMatchLoading(state, action) {
    state.isExternalServicesCampaignFieldsAutoMatchLoading = action.payload;
  },
  setIsExternalServicesCreated(state, action) {
    state.isExternalServicesCreated = action.payload;
  },
});

export default reducers;
