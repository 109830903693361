import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableNoFilteredData from 'components/shared/TableNoFilteredData';

import { IDocumentList } from '@types';

import DocumentListItem from './DocumentListItem';

const DocumentsTable = ({ data }: { data: IDocumentList[] }) => (
  <Box sx={{ border: '1px solid #EEF1F9', borderRadius: '12px', overflow: 'auto' }}>
    <Table sx={{ minWidth: 700 }}>
      <TableHead>
        <TableRow>
          <TableCell>Date and Time</TableCell>
          <TableCell>Document Type</TableCell>
          <TableCell>Document</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Reviewer</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data?.length ? data.map((item) => (
            <DocumentListItem item={item} key={item.id} />
          )) :
          <TableRow sx={{ cursor: 'default !important' }}>
            <TableCell colSpan={6} sx={{ borderBottom: 0 }}>
              <TableNoFilteredData />
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  </Box>
);

export default DocumentsTable;
