import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

const selector = (state: RootState) => state.auth;

const isLogoutLoading = createSelector([selector], (state) => state.isLogoutLoading);
const isResetPasswordLoading = createSelector([selector], (state) => state.isResetPasswordLoading);
const isResetPasswordSuccess = createSelector([selector], (state) => state.isResetPasswordSuccess);
const isForgotPasswordLoading = createSelector([selector], (state) => state.isForgotPasswordLoading);
const isForgotPasswordSuccess = createSelector([selector], (state) => state.isForgotPasswordSuccess);
const isLoginLoading = createSelector([selector], (state) => state.isLoginLoading);
const isLoggedIn = createSelector([selector], (state) => state.isLoggedIn);
const isCheckingLoading = createSelector([selector], (state) => state.isCheckingLoading);
const isSetPasswordLoading = createSelector([selector], (state) => state.isSetPasswordLoading);
const setPasswordError = createSelector([selector], (state) => state.setPasswordError);
const passwordChanged = createSelector([selector], (state) => state.passwordChanged);

export default {
  isLogoutLoading,
  isResetPasswordLoading,
  isResetPasswordSuccess,
  isForgotPasswordLoading,
  isForgotPasswordSuccess,
  isLoginLoading,
  isLoggedIn,
  isCheckingLoading,
  isSetPasswordLoading,
  setPasswordError,
  passwordChanged,
};
