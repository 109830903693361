import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGenerateTableData } from 'components/Hooks';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import { DenseTable } from 'components/Tables';
import Loading from 'components/UI/Loading';
import Title from 'components/UI/Title';
import { dateTime } from 'helpers';

import { AffiliateChannelHistoryTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { IHistory } from '../../types/campaigns';

const HistoryPage = () => {
  const { id: affiliateChannelId } = useParams();
  const channel = useAppSelector(affiliatesSelector.channel);
  const data = useAppSelector(affiliatesSelector.affiliateChannelHistory);
  const affiliateChannelHistoryCount = useAppSelector(affiliatesSelector.affiliateChannelHistoryCount);
  const isAffiliateChannelHistoryLoading = useAppSelector(affiliatesSelector.isAffiliateChannelHistoryLoading);

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    isLoading,
    paginationCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: data,
    paginationCount: affiliateChannelHistoryCount,
  });
  const [modifiedTableData, setModifiedTableData] = useState(sortTableData);

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchAffiliateChannelHistory(affiliateChannelId ?? '0'));
  }, []);

  useEffect(() => {
    const tempArr = sortTableData.filter((item: IHistory) => Object.keys(item).length > 0);

    if (tempArr.length > 0) {
      const newArr = tempArr.map((item: IHistory) => {
        const temp = dateTime(item?.timeStamp);

        return {
          ...item,
          id: item.timeStamp, // unique id for rowKey default property (discussed with BE)
          timeStamp: temp,
        };
      });

      setModifiedTableData(newArr);
    }
  }, [sortTableData]);

  const handleChangePageServer = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelHistory(
        `${channel?.id}`,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelHistory(`${channel?.id}`, 1, newRowsPerPage, orderBy, order === 'asc'),
    );

    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleCustomCallback = (cell: string, historyRow: IHistory) => {
    if (hasPermission(Permissions.viewSourceChannelHistoryDetails)) {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmAffiliateChannelHistoryModal,
          props: {
            historyDetails: historyRow,
          },
        }),
      );
    }
  };

  if (isLoading || isAffiliateChannelHistoryLoading) {
    return <Loading />;
  }

  return (
    <Box>
      {!data ? (
        <Title sx={{ padding: 0, marginTop: 30 }} title="No History" />
      ) : (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            width: '100%',
            marginTop: '16px',
            '& .MuiTableContainer-root': {
              padding: 0,
            },
          }}
        >
          <DenseTable
            dataTest="affiliate-channel-details-tab-history"
            selectedTableHeaders={AffiliateChannelHistoryTableHeaders}
            setOrder={setOrder}
            isDeleted={false}
            setOrderBy={setOrderBy}
            sortTableData={modifiedTableData}
            data={tableData}
            editButton={false}
            setTableData={setTableData}
            order={order as string}
            isAction={false}
            orderBy={orderBy}
            action={{
              type: 'Click',
              id: AffiliateChannelHistoryTableHeaders[0].value,
              callback: () => null,
            }}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            createSortHandler={createSortHandler}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePageServer}
            handleCustomCallback={(cell: string, row?: Record<string, unknown>): void =>
              handleCustomCallback(cell, row as unknown as IHistory)
            }
            paginationCount={paginationCount}
          />
        </Box>
      )}
    </Box>
  );
};

export default HistoryPage;
