import { IDocumentType, ILeadsManualReviewReason, ISimplifiedList } from '@types';

import { ITag } from '../tags/initialState';

export interface IManualReview {
  id: string;
  applicationId: string;
  sourceChannelId: string;
  sourceChannelName: string;
  merchantName: string;
  date: string;
  status: string;
  tags: ITag[];
  requestAmount: number;
}

export interface IManualReviewsListResponse {
  data: IManualReview[];
  itemsCount: number;
  pagesCount: number;
}

export interface IManualReviewsReason {
  id: number;
  name: string;
  date: string;
  status: number;
  reviewerId: string;
  reviewerName: string;
}

export interface ILeadsManualReviews {
  id: number;
  dti: number;
  pti: number;
  dpi: number;
  loanAmount: number;
  reasons: IManualReviewsReason[];
}

export interface IManualReviewUnderwriter {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IManualReviewListFilters {
  applicationId?: string;
  sourceChannels?: string[];
  merchantName?: string[];
  borrowerName?: string;
  dateFrom?: string;
  dateTo?: string;
  status?: number;
  tags?: string[];
  searchModel?: IManualReviewListFiltersSearchModel;
}

export interface IManualReviewListFiltersSearchModel {
  pageNumber: number;
  pageSize: number;
  sortOrder?: {
    key: string;
    value: boolean;
  };
}

export interface IAanReason {
  id: string;
  groupName: string;
  name: string;
  manualReviewReason: string;
}

export interface IAanReasonResponse {
  id: string;
  group: 0 | 1;
  name: string;
  manualReviewReason: string;
}

export interface IManualReviews {
  isLoading: boolean;
  manualReviewsList: IManualReviewsListResponse | null;
  manualReviews: ILeadsManualReviews | null;
  manualReviewsLoading: boolean;
  manualReviewReason: ILeadsManualReviewReason | null;
  manualReviewReasonLoading: boolean;
  manualReviewUnderwriters: IManualReviewUnderwriter[] | null;
  manualReviewUnderwritersLoading: boolean;
  merchantNames: ISimplifiedList[];
  merchantNamesLoading: boolean;
  manualReviewListFilters: IManualReviewListFilters | null;
  documentTypes: IDocumentType[] | null;
  documentTypesLoading: boolean;
  updateSolarPanelDetailsLoading: boolean;
  deleteFileLoading: boolean;
  aanReasons: IAanReason[] | null;
  aanReasonsLoading: boolean;
}

export const getInitialState = (): IManualReviews => ({
  isLoading: false,
  manualReviewsList: null,
  manualReviews: null,
  manualReviewsLoading: false,
  manualReviewReason: null,
  manualReviewReasonLoading: false,
  manualReviewUnderwriters: null,
  manualReviewUnderwritersLoading: false,
  merchantNames: [],
  merchantNamesLoading: false,
  manualReviewListFilters: null,
  documentTypes: null,
  documentTypesLoading: false,
  updateSolarPanelDetailsLoading: false,
  deleteFileLoading: false,
  aanReasons: null,
  aanReasonsLoading: false,
});
