import { dispatch } from 'redux/hooks';

import { ISnackbarProps } from '../../../types/snackbar';

import slice from './slice';

const { openSnackbar, closeSnackbar } = slice.actions;

const fetchOpenSnackbar = (data: ISnackbarProps) => () => dispatch(openSnackbar(data));

const fetchCloseSnackbar = () => () => dispatch(closeSnackbar());

export default {
  fetchOpenSnackbar,
  fetchCloseSnackbar,
};
