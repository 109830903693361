import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { AffiliateDetailsTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { useGenerateTableData } from '../Hooks';
import { Permissions } from '../Permissions/constants';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';
import Title from '../UI/Title';

const AffiliatesAffiliateChannels = () => {
  const affiliateChannels = useAppSelector(affiliatesSelector.affiliateChannels);
  const affiliateChannelsCount = useAppSelector(affiliatesSelector.affiliateChannelsCount);
  const isAffiliateChannelsLoading = useAppSelector(affiliatesSelector.isAffiliateChannelsLoading);
  const isUpdateAffiliateChannelStatusLoading = useAppSelector(
    affiliatesSelector.isUpdateAffiliateChannelStatusLoading,
  );
  const { id: affiliateId } = useParams();

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    sortTableData,
    setOrder,
    setOrderBy,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: affiliateChannels,
    paginationCount: Number(affiliateChannelsCount),
  });

  const handleChangeStatus = (row: { [p: string]: string }, checked: number) => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateChannelStatus({
        id: row.id,
        status: checked,
        defaultStatus: -1,
        affiliateId: affiliateId ?? '0',
      }),
    );
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByDetailsId(
        affiliateId ?? '0',
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );

    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByDetailsId(
        affiliateId ?? '0',
        page + 1,
        newRowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByDetailsId(
        affiliateId ?? '0',
        page + 1,
        rowsPerPage,
        property,
        newOrder,
      ),
    );
  };

  if (isAffiliateChannelsLoading || isUpdateAffiliateChannelStatusLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '16px',
        '& .MuiTableContainer-root': {
          padding: '0 !important',
        },
      }}
    >
      {tableData && tableData.length ? (
        <DenseTable
          dataTest="affiliate-details-page-tab-channels"
          selectedTableHeaders={AffiliateDetailsTableHeaders}
          setOrder={setOrder}
          isDeleted={false}
          setOrderBy={setOrderBy}
          sortTableData={sortTableData}
          data={tableData}
          editButton={false}
          action={{
            type: 'Link',
            main: 'sources-channel',
          }}
          setTableData={setTableData}
          order={order as string}
          isAction={false}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRequestSort={handleRequestSort}
          handleChangeRowsPerPage={handleChangeRowsPerPageServer}
          handleChangePage={handleChangePageServer}
          handleSort={handleSortServer}
          handleChangeStatus={handleChangeStatus}
          paginationCount={paginationCount}
          handleSortCount={handleSortCount}
          changeStatusPermission={Permissions.channelsStatusChange}
        />
      ) : (
        <Title sx={{ padding: 0 }} title="No Channels" />
      )}
    </Box>
  );
};

export default AffiliatesAffiliateChannels;
