import { Box } from '@mui/material';

import { ILeadJourneyRow } from '@types';

import classes from '../../assets/scss/journey.module.scss';

export const JourneyStepStatusRight = ({
  row,
  rowHeight,
  final,
  singleRow,
}: {
  row: ILeadJourneyRow;
  rowHeight: number;
  final: boolean;
  singleRow: boolean;
}) => {
  const LINE_HEIGHT = 58;
  const START_HEIGHT = final ? -2 : 34;
  const START_ARROW_HEIGHT = 56;
  const FINAL_START_ARROW_HEIGHT = final ? 58 : 0;

  const ROW_HEIGHT = `${rowHeight - START_HEIGHT}px`;
  const ROW_TOP = `${-(rowHeight - LINE_HEIGHT - START_HEIGHT)}px`;
  const ARROW_TOP = `${-(rowHeight - LINE_HEIGHT - START_ARROW_HEIGHT + FINAL_START_ARROW_HEIGHT)}px`;

  const group = row.groups[row.groups.length - 1];
  let className;
  let showCheckmark = false;

  if (group.steps[group.steps.length - 1].className) {
    className = group.steps[group.steps.length - 1].className;
  } else if (final) {
    className = classes.green;
    showCheckmark = true;
  }

  return (
    <Box className={`${classes.stepStatusRight} ${className} ${singleRow && classes.singleRow}`}>
      <Box className={classes.statusLine}>
        <Box className={classes.circle} />
        <Box className={`${classes.line} ${classes.line1}`} />
        <Box className={`${classes.line} ${classes.line2}`} />
        <Box className={`${classes.line} ${classes.line3}`} sx={{ height: ROW_HEIGHT, top: ROW_TOP }} />
        {final ? null : (
          <>
            <Box className={`${classes.line} ${classes.line4}`} sx={{ top: ROW_TOP }} />
            <Box className={`${classes.line} ${classes.line5}`} sx={{ top: ROW_TOP }} />
          </>
        )}
        <Box className={`${classes.arrow} ${classes.arrowLeft} ${final && classes.final}`} sx={{ top: ARROW_TOP }} />
        <Box className={`${classes.arrow} ${classes.arrowRight} ${final && classes.final}`} sx={{ top: ARROW_TOP }} />
        {showCheckmark ? (
          <Box className={classes.checkmark} sx={{ top: ARROW_TOP }}>
            <Box className={classes.checkmarkCircle} />
            <Box className={classes.checkmarkStem} />
            <Box className={classes.checkmarkKick} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
