import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { dateTime, isMe } from 'helpers';

import { dispatch } from '../../redux/hooks';
import { leadsMiddleware } from '../../redux/slices/leads';
import { notificationMiddleware } from '../../redux/slices/notification';
import { ISingleNotification } from '../../redux/slices/notification/initialState';
import { ApplicationTabs } from '../../types';
import ClientNameCard from '../shared/DropdownButton/NameCard';

const UnreadIcon = () => (
  <Box
    sx={{
      backgroundColor: '#EB5757',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      position: 'absolute',
      top: '8px',
      left: '8px',
    }}
  />
);

export const BoldTypography = styled('span')({
  fontWeight: 600,
});

const Notification = ({
  nameTag,
  notification,
  handleClose,
}: {
  nameTag: string;
  notification: ISingleNotification;
  handleClose?: () => void;
}) => {
  const navigate = useNavigate();

  const createShowingText = (notificationText: ISingleNotification) => {
    switch (notificationText.type) {
      case 1:
        return (
          <Typography>
            <BoldTypography>{notificationText.applicationId}</BoldTypography> is ready for
            <BoldTypography> Manual Review</BoldTypography>
          </Typography>
        );
      case 2:
        if (isMe(notification.senderId)) {
          return (
            <Typography>
              You assigned yourself to
              <BoldTypography> Manual Review {notificationText.applicationId}</BoldTypography>
            </Typography>
          );
        }

        return (
          <Typography>
            <BoldTypography>{notificationText.assigner}</BoldTypography> assigned
            <BoldTypography> Manual Review {notificationText.applicationId} </BoldTypography>
            to you
          </Typography>
        );
      case 3:
        if (isMe(notification.senderId)) {
          return (
            <Typography>
              You unassigned yourself from
              <BoldTypography> Manual Review {notificationText.applicationId}</BoldTypography>
            </Typography>
          );
        }

        return (
          <Typography>
            <BoldTypography>{notificationText.assigner}</BoldTypography> unassigned
            <BoldTypography> Manual Review {notificationText.applicationId}</BoldTypography> from you
          </Typography>
        );
      case 4:
        return (
          <Typography>
            <BoldTypography>Funding</BoldTypography> request
            <BoldTypography> {notificationText.applicationId}</BoldTypography> was approved
          </Typography>
        );
      case 5:
        return (
          <Typography>
            <BoldTypography>Funding</BoldTypography> request
            <BoldTypography> {notificationText.applicationId}</BoldTypography> was rejected
          </Typography>
        );
      case 6:
        return (
          <Typography>
            <BoldTypography>{notificationText.applicationId}</BoldTypography> is ready for
            <BoldTypography> funding</BoldTypography>
          </Typography>
        );
      case 7:
        return (
          <Typography>
            <BoldTypography>{notificationText.applicationId}</BoldTypography>
            <BoldTypography> Manual Review</BoldTypography> approved
          </Typography>
        );
      case 8:
        return (
          <Typography>
            <BoldTypography>{notificationText.applicationId}</BoldTypography>
            <BoldTypography> Manual Review</BoldTypography> rejected
          </Typography>
        );
      case 9:
        if (isMe(notification.senderId)) {
          return (
            <Typography>
              You assigned yourself to<BoldTypography> funding</BoldTypography> request review
              <BoldTypography> {notificationText.applicationId}</BoldTypography>
            </Typography>
          );
        }

        return (
          <Typography>
            <BoldTypography>{notificationText.assigner}</BoldTypography> assigned
            <BoldTypography> {notificationText.applicationId}</BoldTypography> to you for
            <BoldTypography> funding</BoldTypography> request review
          </Typography>
        );
      case 10:
        if (isMe(notification.senderId)) {
          return (
            <Typography>
              You unassigned yourself from<BoldTypography> funding</BoldTypography> request review
              <BoldTypography> {notificationText.applicationId}</BoldTypography>
            </Typography>
          );
        }

        return (
          <Typography>
            <BoldTypography>{notificationText.assigner}</BoldTypography> unassigned
            <BoldTypography> funding</BoldTypography> request review
            <BoldTypography> {notificationText.applicationId}</BoldTypography> from you
          </Typography>
        );
      case 11:
        return (
          <Typography>
            The required <BoldTypography> documents</BoldTypography> were uploaded for the application
            <BoldTypography> {notificationText.applicationId}</BoldTypography>
          </Typography>
        );
      default:
        return ' ';
    }
  };

  const onClick = () => {
    if (handleClose) {
      handleClose();
    }

    if (notification.status === 1) {
      dispatch(notificationMiddleware.onNotificationSelect(notification.id));
    }

    navigate(`/applications/${notification.leadId}`);

    if ([4, 5, 6, 9, 10].includes(notification.type)) {
      dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.fundingRequests));
    } else {
      dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.manualReview));
    }
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        p: '16px',
        minHeight: '90px',
        border: '1px solid #F4F7FE',
        '&:hover': {
          backgroundColor: '#F9F8FE',
        },
      }}
    >
      {notification.status === 1 && <UnreadIcon />}
      <ClientNameCard firstName={nameTag.split(' ')[0][0]} lastName={nameTag.split(' ')[1][0] ?? ' '} />
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>{createShowingText(notification)}</Box>
        <Typography sx={{ fontSize: '15px', color: '#A0AEC0' }}>{dateTime(notification.date)}</Typography>
      </Box>
    </Box>
  );
};

export default Notification;
