import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmAffiliateChannelCreateSuccessControls = (id: number) => {
  const navigate = useNavigate();
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelCreateSuccessModal));
    navigate('/sources-channel', { replace: true });
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmAffiliateChannelRuleNameModal,
        props: { affiliateChannelId: id, redirect: true },
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelCreateSuccessModal));
  }, []);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmAffiliateChannelCreateSuccessControls;
