import { ICampaignProps } from '../../../types';

export const getInitialState = (): ICampaignProps => ({
  error: null,
  errorMessages: '',
  successfully: false,
  timeFormats: null,
  validators: null,
  fieldByDataType: null,
  campaignFields: null,
  campaigns: null,
  simplifiedCampaigns: null,
  campaignsCount: '0',
  currentCampaignById: null,
  createCampaignsWithStepper: null,
  isValidatorsLoading: false,
  isCampaignHistoryLoading: false,
  isTimeFormatSuccessLoading: false,
  isCampaignsListLoading: false,
  isDeleteCampaignLoading: false,
  isUpdateCampaignStatusLoading: false,
  isCreateCampaignLoading: false,
  isUpdateCampaignLoading: false,
  isUpdateCampaignDetailsLoading: false,
  isAffiliateChannelsByCampaignIdLoading: false,
  isSystemFieldsByCampaignIdLoading: false,
  isFieldByDataTypeLoading: false,
  isCampaignFieldsLoading: false,
  isCurrentCampaignByIdLoading: false,
  history: null,
  isCampaignCreated: false,
  fieldByDataTypeString: '',
  affiliateChannels: null,
  currentCampaignByIdCampaignFields: null,
  isCreateAffiliateLoading: false,
  isAffiliatesLoading: false,
  isLoading: false,
  campaignFilters: null,
});
