import { IAuthState } from '../../../types/auth';

export const getInitialState = (): IAuthState => ({
  isLogoutLoading: false,
  isForgotPasswordLoading: false,
  isForgotPasswordSuccess: false,
  isResetPasswordLoading: false,
  isResetPasswordSuccess: false,
  error: null,
  isLoginLoading: false,
  isLoggedIn: false,
  isCheckingLoading: true,
  isSetPasswordLoading: false,
  setPasswordError: '',
  passwordChanged: false,
});
