/* eslint-disable @typescript-eslint/no-shadow */
// Dashboard component need to be refactored
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import ArrowForward from '@mui/icons-material/ArrowForward';
import FlagIcon from '@mui/icons-material/Flag';
import { Box, Grid, Link, Pagination, Typography } from '@mui/material';
// import { Permissions } from 'components/Permissions/constants';
// import Permission from 'components/Permissions/Permission';
import Loading from 'components/UI/Loading';
import moment from 'moment';

import { CustomButton } from '../components';
// import CustomRangePicker from '../components/CustomRangePicker/CustomRangePicker';
import { chartDataLeads } from '../components/dataModels/chart';
import ComparisonTable from '../components/UI/ComparisonTable';
import CustomAutocomplete, { ISelectOption } from '../components/UI/CustomAutocomplete';
// import ExternalServicesDashboard from '../components/UI/ExternalServicesDashboard';
import Title from '../components/UI/Title';
import ToggleFilters from '../components/UI/ToggleFilters';
import TopLocations from '../components/UI/TopLocations';
import { createDropdownOptions } from '../helpers/createDropdownOptions';
import { dispatch, useAppSelector } from '../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../redux/slices/campaigns';
import { dashboardMiddleware, dashboardSelector } from '../redux/slices/dashboard';
import { IDashboardLeadsReqTemplate } from '../redux/slices/dashboard/middleware';
import { DateRange } from '../types';

import classes from '../assets/scss/dashboard.module.scss';

const Dashboard = () => {
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const reportsLeads = useAppSelector(dashboardSelector.reportsLeads);
  const reportsNoLeads = useAppSelector(dashboardSelector.reportsNoLeads);
  const isReportsLeadsLoading = useAppSelector(dashboardSelector.isReportsLeadsLoading);
  const isReportsNoLeadsLoading = useAppSelector(dashboardSelector.isReportsNoLeadsLoading);
  const reportsComparison = useAppSelector(dashboardSelector.reportsComparison);
  const isReportsComparisonLoading = useAppSelector(dashboardSelector.isReportsComparisonLoading);
  const reportsTopLocations = useAppSelector(dashboardSelector.reportsTopLocations);
  const isReportsTopLocationsLoading = useAppSelector(dashboardSelector.isReportsTopLocationsLoading);

  const affiliates = useAppSelector(affiliatesSelector.simplifiedAffiliates);

  const campaigns = useAppSelector(campaignsSelector.simplifiedCampaigns);
  const [affiliatesData, setAffiliatesData] = useState<ISelectOption[]>([]);
  const [filteredAffiliatesData, setFilteredAffiliatesData] = useState<ISelectOption[]>([]);
  const [campaignsData, setCampaignsData] = useState<ISelectOption[]>([]);
  const [filteredCampaignsData, setFilteredCampaignsData] = useState<ISelectOption[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: moment().subtract(1, 'year').format(),
    endDate: moment().format(),
  });
  const isDisabled = !(dateTimeState?.startDate && dateTimeState?.endDate);

  const date = new Date();
  const startDate = moment(new Date(date.setDate(date.getDate() - 6)))
    .startOf('day')
    .format();
  const endDate = moment(new Date()).endOf('day').format();

  const fetchData = (
    affiliates = filteredAffiliatesData.map((item) => item.id),
    campaigns = filteredCampaignsData.map((item) => item.id),
  ) => {
    const tempReqDataLeads: IDashboardLeadsReqTemplate = {
      startDate,
      endDate,
      affiliates,
      campaigns,
    };

    dispatch(dashboardMiddleware.fetchDashboardLeads(tempReqDataLeads));
    dispatch(
      dashboardMiddleware.fetchDashboardReportsNoLeads({
        pageIndex: 1,
        pageSize: 10,
        affiliates,
        campaigns,
        startDate,
        endDate,
      }),
    );
    dispatch(
      dashboardMiddleware.fetchDashboardReportsComparison({
        affiliates,
        campaigns,
      }),
    );
    dispatch(dashboardMiddleware.fetchDashboardReportsTopLocations(tempReqDataLeads));
  };

  const graphOptions = chartDataLeads(reportsLeads);

  const onApplyDashboardLeads = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData(['-1'], ['-1']);
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (affiliates?.length) {
      const optionsAffiliates = createDropdownOptions(affiliates);

      setAffiliatesData(optionsAffiliates);
      setFilteredAffiliatesData(optionsAffiliates);
    }
  }, [affiliates]);

  useEffect(() => {
    if (campaigns?.length) {
      const optionsCampaigns = createDropdownOptions(campaigns);

      setCampaignsData(optionsCampaigns);
      setFilteredCampaignsData(optionsCampaigns);
    }
  }, [campaigns]);

  const handleReportsNoLeadsPageChange = (event: React.ChangeEvent<unknown>, pageIndex: number) => {
    dispatch(
      dashboardMiddleware.fetchDashboardReportsNoLeads({
        affiliates: ['-1'],
        campaigns: ['-1'],
        startDate,
        endDate,
        pageIndex,
        pageSize: 10,
      }),
    );
  };

  return (
    <Box
      sx={{
        background: 'transparent',
        border: '0',
        margin: 0,
        padding: 0,
      }}
    >
      <Grid container item display="flex" alignItems="center" flexDirection="column" className={classes.root}>
        <Grid item sx={{ marginBottom: showFilters ? '' : '24px' }} className={classes.header}>
          <Title
            dataTest="dashboard-page-header-weekly-statics"
            sx={{ padding: 0, margin: 0 }}
            title="Weekly statistics"
          />
          <ToggleFilters
            dataTest="dashboard-page-toggle-filters"
            showFilters={showFilters}
            setShowFilters={setShowFilters}
          />
        </Grid>

        <Box sx={{ display: showFilters ? 'block' : 'none', marginBottom: '24px' }}>
          <Grid item container gap="30px" alignItems="flex-end">
            {campaignsData && (
              <Grid item>
                <CustomAutocomplete
                  dataTest="dashboard-page-dropdown-campaigns"
                  title="Products"
                  data={campaignsData}
                  filteredData={filteredCampaignsData}
                  setData={setFilteredCampaignsData}
                  isSelectAll
                />
              </Grid>
            )}
            {affiliatesData && (
              <Grid item>
                <CustomAutocomplete
                  dataTest="dashboard-page-dropdown-affiliates"
                  title="Sources"
                  data={affiliatesData}
                  filteredData={filteredAffiliatesData}
                  setData={setFilteredAffiliatesData}
                  isSelectAll
                />
              </Grid>
            )}
            {/* <Grid item> need open after release, based on users' feedbacks
              <CustomRangePicker
                dataTest="dashboard-page-input-range"
                dateTimeState={dateTimeState}
                setDateTimeState={setDateTimeState}
              />
            </Grid> */}
            <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <CustomButton
                data-test="dashboard-page-button-apply"
                disabled={isDisabled}
                onClick={onApplyDashboardLeads}
              >
                Apply
              </CustomButton>
            </Grid>
          </Grid>
        </Box>

        {isReportsLeadsLoading ? (
          <Loading height={400} />
        ) : (
          <Box className={`${classes.leadsTotal}`}>
            <Box className={classes.chartHeader}>
              <Typography className={classes.cardTitle}>Applications Total</Typography>
              <Box className={classes.chartHeaderSub}>
                <Typography className={classes.cardSelectTitle}>
                  <Typography
                    component="span"
                    sx={{
                      display: 'flex',
                      background: '#87ee87',
                      borderRadius: '50%',
                      width: '10.37px',
                      height: '10.37px',
                      marginRight: '10px',
                    }}
                  />
                  Accepted
                </Typography>

                <Typography className={classes.cardSelectTitle}>
                  <Typography
                    component="span"
                    sx={{
                      display: 'flex',
                      marginRight: '10px',
                      borderRadius: '50%',
                      background: '#f13939',
                      width: '10.37px',
                      height: '10.37px',
                    }}
                  />
                  Rejected
                </Typography>
                <Typography className={classes.cardSelectTitle}>
                  <Typography
                    component="span"
                    sx={{
                      display: 'flex',
                      marginRight: '10px',
                      borderRadius: '50%',
                      background: '#7b61ff',
                      width: '10.37px',
                      height: '10.37px',
                    }}
                  />
                  Manual Review
                </Typography>
              </Box>
            </Box>
            <Grid container className={classes.leadsTotalChart}>
              <Chart
                // @ts-ignore
                options={graphOptions.options}
                series={graphOptions.series}
                type="area"
                className={classes.leadsChartRoot}
                height={350}
              />
            </Grid>
          </Box>
        )}

        <Grid container item className={classes.containerDashboard}>
          <Grid container item className={classes.received}>
            <Grid item className={classes.receivedHeader} sx={{ paddingBottom: '12px' }}>
              <FlagIcon className={classes.flagIcon} />
              <Typography className={classes.cardTitle}>No Applications Received</Typography>
            </Grid>
            {isReportsNoLeadsLoading ? (
              <Loading height={306} />
            ) : (
              <Box height={306}>
                {reportsNoLeads?.data.map((item: { [key: string]: string }) => (
                  <Grid
                    data-test={`dashboard-page-no-applicants-received-table-id-${item.id}`}
                    container
                    item
                    key={item.id}
                    className={classes.tableRow}
                    sx={{ padding: '6px 0' }}
                  >
                    <Grid item sx={{ color: 'rgba(69, 66, 86, 1)' }}>
                      <Typography>{item.affiliateName ?? ''}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography />
                    </Grid>
                    <Grid item>
                      <Typography />
                    </Grid>
                    <Grid item>
                      <Link href={`/sources/${item.id}`} className={classes.link}>
                        <Typography>
                          View <ArrowForward sx={{ width: '12px', height: '12px' }} />
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            )}
            {reportsNoLeads?.pagesCount > 1 && (
              <Grid sx={{ marginTop: '16px' }}>
                <Pagination
                  count={reportsNoLeads?.pagesCount}
                  color="primary"
                  onChange={handleReportsNoLeadsPageChange}
                />
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.comparisonTableContainer}>
            <Grid item>
              <Typography className={classes.cardTitle}>Comparison Table</Typography>
            </Grid>
            <Grid container item height={260} sx={{ overflow: 'hidden' }}>
              {isReportsComparisonLoading && <Loading height={260} />}
              {reportsComparison?.length ? <ComparisonTable data={reportsComparison} /> : <Box>No Data</Box>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className={classes.containerDashboard}>
          {/* should be visible after BE implementation 
          <Permission permission={Permissions.viewIntegrations}>
            <Grid item className={classes.comparisonTableContainer}>
              <Grid item>
                <Typography className={classes.cardTitle}>3rd party APIs</Typography>
              </Grid>
              <Grid container item overflow="hidden">
                <ExternalServicesDashboard startDate={startDate} endDate={endDate} />
              </Grid>
            </Grid>
          </Permission> */}
          <Grid item className={classes.comparison}>
            <Box className={classes.locationTitleContainer}>
              <Box>
                <Typography className={classes.cardTitle}>Top Locations</Typography>
              </Box>
            </Box>
            <Grid container item>
              {isReportsTopLocationsLoading && <Loading />}
              {reportsTopLocations?.length ? <TopLocations data={reportsTopLocations} /> : <Box>No Data</Box>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
