import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from '../../redux/slices/manualReviews';
import { IManualReviewUnderwriter } from '../../redux/slices/manualReviews/initialState';
import DropdownButton, { IOption } from '../shared/DropdownButton';
import ClientNameCard from '../shared/DropdownButton/NameCard';

const convertData = (users: IManualReviewUnderwriter[], currentUser?: string | null) => {
  const ret: IOption[] = [];

  users.forEach((item: IManualReviewUnderwriter) => {
    if (item.id !== currentUser) {
      ret.push({
        key: item.id,
        value: `${item.firstName} ${item.lastName}`,
        prefix: () => <ClientNameCard firstName={item.firstName} lastName={item.lastName} />,
      });
    }
  });

  return ret;
};

const ManualReviewDetailsAssignButton = ({ id }: { id: string }) => {
  const manualReviewReason = useAppSelector(manualReviewSelector.manualReviewReason);
  const manualReviewUnderwritersLoading = useAppSelector(manualReviewSelector.manualReviewUnderwritersLoading);
  const manualReviewUnderwriters = useAppSelector(manualReviewSelector.manualReviewUnderwriters);

  const handleItemChoose = (option: IOption) => {
    if (option.key !== manualReviewReason?.reviewerId) {
      dispatch(
        manualReviewMiddleware.fetchManualReviewUnderwriterAssign(id, { userId: option.key, name: option.value }),
      );
    }
  };

  useEffect(() => {
    if (!manualReviewUnderwriters) {
      dispatch(manualReviewMiddleware.fetchManualReviewUnderwriters());
    }
  }, []);

  if (manualReviewUnderwritersLoading) {
    return <CircularProgress />;
  }

  return (
    <DropdownButton
      buttonSx={{
        background: 'transparent',
        color: '#454256',
        fontWeight: 700,
        fontSize: '15px',
        padding: 0,
      }}
      data={convertData(manualReviewUnderwriters ?? [], manualReviewReason?.reviewerId)}
      defaultValue={{
        key: manualReviewReason ? manualReviewReason.reviewerId : null,
        value: `${
          manualReviewReason?.reviewerName ?? (manualReviewUnderwriters && manualReviewUnderwriters[0].firstName)
        }`,
      }}
      handleItemChoose={handleItemChoose}
    />
  );
};

export default ManualReviewDetailsAssignButton;
