import { Box, Dialog, DialogActions, DialogTitle, Grid, Stack, Typography } from '@mui/material';

import { ICampaignHistory, ICampaignHistoryFields } from '@types';

import { CustomButton } from '../../shared';
import InputTextField from '../../UI/InputTextField';
import Title from '../../UI/Title';

import useConfirmCampaignHistoryModalControls from './hooks/useConfirmCampaignHistoryModalControls';

const styles = {
  gridItem: {
    marginTop: '16px',

    '&:first-child': {
      marginTop: '0',
    },
  },
};

export interface IConfirmCampaignHistoryModalProps {
  historyDetails: ICampaignHistory;
}

const ConfirmCampaignHistoryModal = ({ historyDetails }: IConfirmCampaignHistoryModalProps) => {
  const { onConfirm, onClose } = useConfirmCampaignHistoryModalControls();

  return (
    <Dialog
      data-test="confirm-campaign-history-modal"
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          paddingTop: '30px',
          paddingBottom: '24px',
          maxWidth: '700px',
          width: '100%',
        },
      }}
    >
      <Stack flexDirection="column">
        <DialogTitle
          sx={{
            padding: '0 0 30px',
          }}
        >
          <Title
            title="History Details"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogActions sx={{ padding: 0 }}>
          <Grid container xs={12} md={12} sm={12} sx={{ padding: '0px 20px 0px 20px' }}>
            <Grid item container xs={12} md={12} sm={12} sx={{ maxHeight: '303px', overflow: 'auto', width: '100%' }}>
              {historyDetails?.fields.map((item: ICampaignHistoryFields, index) => (
                <Grid
                  data-test={`confirm-campaign-history-modal-table-id-${index}`}
                  item
                  container
                  xs={12}
                  md={12}
                  sm={12}
                  key={item.name}
                  gap={2}
                  sx={styles.gridItem}
                >
                  <Grid item xs={3.8} md={3.8} sm={3.8}>
                    <Typography component="label" sx={{ fontSize: '13px', fontWeight: 400 }}>
                      Field Name
                    </Typography>
                    <InputTextField
                      data-test={`confirm-campaign-history-modal-table-id-${index}-input-field-name`}
                      disabled
                      defaultValue={item?.name}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={3.8} md={3.8} sm={3.8}>
                    <Typography component="label" sx={{ fontSize: '13px', fontWeight: 400 }}>
                      Old Value
                    </Typography>
                    <InputTextField
                      data-test={`confirm-campaign-history-modal-table-id-${index}-input-old-value`}
                      disabled
                      defaultValue={item?.oldValue ?? ''}
                      placeholder="Old Value"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={3.8} md={3.8} sm={3.8}>
                    <Typography component="label" sx={{ fontSize: '13px', fontWeight: 400 }}>
                      New Value
                    </Typography>
                    <InputTextField
                      data-test={`confirm-campaign-history-modal-table-id-${index}-input-new-value`}
                      disabled
                      defaultValue={item?.newValue ?? ''}
                      placeholder="New Value"
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item container xs={12} md={12} sm={12} marginTop="32px">
              <Grid item xs={12} md={12} sm={12}>
                <Typography variant="h4" sx={{ fontSize: '16px', fontWeight: 700 }}>
                  Information
                </Typography>
              </Grid>
              <Grid item container xs={12} md={12} sm={12} gap={3}>
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(33, 33, 33, 1)',
                      }}
                    >
                      User Name:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(97, 97, 97, 1)',
                      }}
                    >
                      {historyDetails?.userName}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(33, 33, 33, 1)',
                      }}
                    >
                      Action:
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(97, 97, 97, 1)',
                      }}
                    >
                      {historyDetails?.actionType}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(33, 33, 33, 1)',
                      }}
                    >
                      Modified Date:{' '}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        color: 'rgba(97, 97, 97, 1)',
                      }}
                    >
                      {historyDetails?.timeStamp}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Box width="100%" textAlign="center" marginTop="24px">
                <CustomButton data-test="confirm-campaign-history-modal-button-ok" onClick={onConfirm}>
                  OK
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmCampaignHistoryModal;
