import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface IDatePickerProps {
  date: Date;
  label: string;
  setDate: (date: Date) => void;
  maxDate?: Date | undefined;
  dataTest?: string;
}

const DatePicker = (props: IDatePickerProps) => {
  const { date, label, setDate, maxDate, dataTest } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        data-test={dataTest}
        inputFormat="MM/DD/YYYY"
        value={date}
        maxDate={maxDate}
        onChange={(newValue: Date | null) => {
          setDate(new Date(newValue as Date));
        }}
        renderInput={(newProps: TextFieldProps) => (
          <TextField {...newProps} label={label} InputLabelProps={{ shrink: true }} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
