import { Box, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ILeadJourneyGroup, ILeadJourneyStep } from '@types';

import { JourneyStepDescription } from './JourneyStepDescription';
import { JourneyStepStatus } from './JourneyStepStatus';

import classes from '../../assets/scss/journey.module.scss';

const MessageTooltip = styled(({ className, ...props }: TooltipProps) => {
  const { children } = props;

  return (
    <Tooltip {...props} arrow enterDelay={100} leaveDelay={500} placement="right" classes={{ popper: className }}>
      {children}
    </Tooltip>
  );
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#F4F7FE',
    border: '1px solid #c4cbe2',
    color: '#000',
    fontSize: '13px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#c4cbe2',
  },
}));

export const JourneyStep = ({
  group,
  step,
  index,
}: {
  group: ILeadJourneyGroup;
  step: ILeadJourneyStep;
  index: number;
}) => (
  <Box className={`${classes.step} ${step.className}`}>
    <MessageTooltip title={step.message}>
      <Box className={classes.stepContainer}>
        <Box className={`${classes.stepHeader}`}>
          <Typography className={classes.stepHeaderTitle}>{step.title}</Typography>
          <Typography className={classes.stepHeaderDesc}>{step.desc}</Typography>
        </Box>
        {step.value ? <Box className={classes.stepBody}>{step.value}</Box> : null}
      </Box>
    </MessageTooltip>

    {step.aanMessage && <JourneyStepDescription message={step.aanMessage} />}
    {group.steps[index + 1] ? <JourneyStepStatus className={step.className} /> : null}
  </Box>
);
