import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { emailValidation } from '../../../types/regExp';
import { CustomButton } from '../../shared';

const ResendDocumentModal = () => {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState<boolean>(false);
  const resendEmailLoading = useAppSelector(leadsSelector.resendEmailLoading);
  const resendEmail = useAppSelector(leadsSelector.resendEmail);
  const leadsDetails = useAppSelector(leadsSelector.leadsDetails);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.ResendDocumentModal));
  };

  const onResend = () => {
    const isValidEmail = emailValidation.test(email);

    if (isValidEmail && leadsDetails) {
      dispatch(leadsMiddleware.updateResendDocumentEmail(leadsDetails.applicationId, email));
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (leadsDetails?.applicationId) {
      dispatch(leadsMiddleware.getResendDocumentEmail(leadsDetails.applicationId));
    }
  }, [leadsDetails?.applicationId]);

  useEffect(() => {
    if (resendEmail) {
      setEmail(resendEmail);
    }
  }, [resendEmail]);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '400px' }}>
        <DialogTitle>
          <DialogTitle textAlign="center">
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Resend Documents</Typography>
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                right: '10px',
              }}
            >
              <IconButton onClick={onClose} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
        </DialogTitle>
        <DialogContent sx={{ p: '35px 40px' }}>
          {resendEmailLoading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <TextField
                sx={{
                  width: '100%',
                }}
                label="Email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {hasError && <FormHelperText error>Email is not valid</FormHelperText>}
            </Box>
          )}
          <Box display="flex" justifyContent="space-around" marginTop="30px">
            <CustomButton variant="outlined" onClick={onClose} loading={resendEmailLoading}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" loading={resendEmailLoading} onClick={onResend}>
              Submit
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default ResendDocumentModal;
