import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IUsers } from './initialState';

const createReducer = <T extends SliceCaseReducers<IUsers>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  setErrorMessage(state, action) {
    state.errorMessages = action.payload;
  },

  setIsLoading(state, action) {
    state.isLoading = action.payload;
  },

  setUsersList(state, action) {
    state.usersList = action.payload;
  },

  setIsUserValid(state, action) {
    state.isUserValid = action.payload;
  },

  setSendInvitationLoading(state, action) {
    state.sendInvitationLoading = action.payload;
  },

  setResendInvitationLoading(state, action) {
    state.resendInviteLoading = action.payload;
  },

  setResetPasswordLoading(state, action) {
    state.resetPasswordLoading = action.payload;
  },

  setEditUserRoleLoading(state, action) {
    state.editRoleLoading = action.payload;
  },

  setDeactivateLoading(state, action) {
    state.deactivateLoading = action.payload;
  },

  setDeleteLoading(state, action) {
    state.deleteLoading = action.payload;
  },

  setRestrictDomainsLoading(state, action) {
    state.restrictDomainsLoading = action.payload;
  },

  setDomainsData(state, action) {
    state.domainsData = action.payload;
  },

  setUserPermissionsLoading(state, action) {
    state.userPermissionsLoading = action.payload;
  },

  setPermissionsList(state, action) {
    state.permissionsList = action.payload;
  },

  setPermissionsListLoading(state, action) {
    state.permissionsListLoading = action.payload;
  },

  setRolesList(state, action) {
    state.rolesList = action.payload;
  },

  setRolesListLoading(state, action) {
    state.rolesListLoading = action.payload;
  },

  setRolePermissions(state, action) {
    state.rolePermissions = action.payload;
  },

  setRoleDeleteLoading(state, action) {
    state.roleDeleteLoading = action.payload;
  },

  setRoleDeleteId(state, action) {
    state.roleDeleteId = action.payload;
  },
});

export default reducers;
