import { useState } from 'react';
import { Box, Dialog, DialogActions, DialogTitle, Stack, TextField } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useConfirmListIntegrationAddModalControls from './hooks/useConfirmListIntegrationAddModalControls';

export interface IConfirmListIntegrationAddModalProps {
  name: string;
}

const ConfirmListIntegrationAddModal = () => {
  const [newListName, setNewListName] = useState<string>(' ');

  const { onConfirm, onClose } = useConfirmListIntegrationAddModalControls(newListName);

  return (
    <Dialog data-test="confirm-list-integration-add-modal" open onClose={onClose}>
      <Stack padding="10px">
        <DialogTitle>
          <Title
            title="Add New List"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '10px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogActions>
          <Stack>
            <TextField
              data-test="confirm-list-integration-add-modal-input-list-name"
              placeholder="List Name"
              sx={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '19px',
                textAlign: 'center',
                color: '#454256',
              }}
              fullWidth
              onChange={(e) => {
                setNewListName(e.target.value);
              }}
            />
            <Box
              sx={{
                marginTop: '20px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomButton
                data-test="confirm-list-integration-add-modal-button-cancel"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test="confirm-list-integration-add-modal-button-add-new-list"
                disabled={!newListName.length}
                onClick={onConfirm}
              >
                Add New List
              </CustomButton>
            </Box>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmListIntegrationAddModal;
