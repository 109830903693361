import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { GuardProps } from 'types';

import { BASE_PATH } from '../../config';
import { useAppSelector } from '../../redux/hooks';
import { authSelector } from '../../redux/slices/auth';

const GuestGuard = ({ children }: GuardProps) => {
  const isLoggedIn = useAppSelector(authSelector.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && location.pathname === '/auth/sign-in') {
      navigate(BASE_PATH, { replace: true });
    }
  }, [isLoggedIn]);

  return <Box>{children}</Box>;
};

export default GuestGuard;
