import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Permissions } from 'components/Permissions/constants';
import Permission from 'components/Permissions/Permission';

import MainLayout from '../components/Layout/MainLayout';
import Loading from '../components/UI/Loading';
import Snackbar from '../components/UI/Snackbar';
import { SIGN_IN_PATH } from '../config';
import AffiliatesPage from '../pages/Affiliates';
import AffiliateChannelPageCreate from '../pages/Affiliates/AffiliateChannelCreateOrEditPage';
import AffiliatesCreateOrEditPage from '../pages/Affiliates/AffiliatesCreateOrEditPage';
import AffiliatesChannelPage from '../pages/Affiliates/channels';
import AffiliatesDetails from '../pages/Affiliates/Details';
import AffiliatesChannelDetails from '../pages/Affiliates/Details/Channels';
import ValidationTreeCreateOrEdit from '../pages/Affiliates/Validation/ValidationTreeCreateOrEdit';
import Billing from '../pages/Billing';
import CampaignsPage from '../pages/Campaigns';
import CampaignCreatePage from '../pages/Campaigns/CampaignCreatePage';
import CampaignDetails from '../pages/Campaigns/CampaignDetails';
import Dashboard from '../pages/Dashboard';
import FundingList from '../pages/FundingList';
// import ExternalService from '../pages/Integrations/ExternalService';
// import ExternalServiceAdd from '../pages/Integrations/ExternalServiceAdd';
// import ExternalServiceEdit from '../pages/Integrations/ExternalServiceEdit';
// import ListIntegration from '../pages/Integrations/ListIntegrations';
// import ApiTest from '../pages/Integrations/ApiTest';
// import Underwriting from '../pages/Integrations/Underwriting';
// import UnderwritingAdd from '../pages/Integrations/UnderwritingAdd';
// import UnderwritingEdit from '../pages/Integrations/UnderwritingEdit';
import Leads from '../pages/Leads';
import LeadsDetails from '../pages/Leads/LeadsDetails';
import ManualReviewList from '../pages/ManualReviewList';
import Notifications from '../pages/Notifications';
import PageNotFound from '../pages/PageNotFound404';
import Report from '../pages/Report';
import AffiliatesComparisonReports from '../pages/Report/AffiliatesComparisonReports';
import FundingReport from '../pages/Report/FundingReport';
import LeadListReports from '../pages/Report/LeadListReports';
// import ErrorApplicantsReport from '../pages/Report/ErrorApplicantsReport';
// import MerchantActivityReport from '../pages/Report/MerchantActivityReport';
// import OffersReport from '../pages/Report/OffersReport';
// import ServiceCategoryReport from '../pages/Report/ServiceCategoryReport';
import Settings from '../pages/Settings';
import RolesList from '../pages/UserManagement/RolesList';
import UsersList from '../pages/UserManagement/UsersList';
import { ValidationTreeProvider } from '../Providers/ValidationTreeProvider';
import { dispatch, useAppSelector } from '../redux/hooks';
import { tenantMiddleware } from '../redux/slices/tenant';
import { userManagementMiddleware, userManagementSelector } from '../redux/slices/userManagement';
import LoggedInGuard from '../utils/route-guard/LoggedInGuard';

const withPermission = (component: JSX.Element, permission: Permissions) => (
  <Permission permission={permission} fallbackComponent={<PageNotFound />}>
    {component}
  </Permission>
);

const MainRoutes = () => {
  const userPermissionsLoading = useAppSelector(userManagementSelector.userPermissionsLoading);

  useEffect(() => {
    dispatch(tenantMiddleware.fetchGetLogo());
    dispatch(userManagementMiddleware.getUserPermissions());
  }, []);

  if (userPermissionsLoading) {
    return <Loading />;
  }

  return (
    <LoggedInGuard>
      <MainLayout sx={{ backgroundColor: '#F4F7FE', border: 0, paddingLeft: '30px' }}>
        <Routes>
          <Route path={SIGN_IN_PATH} element={withPermission(<Dashboard />, Permissions.viewDashboard)} />
          <Route path="/" element={withPermission(<Dashboard />, Permissions.viewDashboard)} />
          <Route path="/products" element={withPermission(<CampaignsPage />, Permissions.viewProductList)} />
          <Route path="/products/:Id" element={withPermission(<CampaignDetails />, Permissions.viewProductList)} />
          <Route
            path="/products/create"
            element={withPermission(<CampaignCreatePage mode="CREATE" />, Permissions.createProduct)}
          />
          <Route path="/sources" element={withPermission(<AffiliatesPage />, Permissions.viewSources)} />
          <Route
            path="/sources/create"
            element={withPermission(<AffiliatesCreateOrEditPage />, Permissions.createSource)}
          />
          <Route path="/sources/:id" element={withPermission(<AffiliatesDetails />, Permissions.viewSources)} />
          <Route
            path="/sources-channel"
            element={withPermission(<AffiliatesChannelPage />, Permissions.viewSourceChannelsList)}
          />
          <Route
            path="/sources-comparison-reports"
            element={withPermission(<AffiliatesComparisonReports />, Permissions.viewSourceComparisonReportsPage)}
          />
          <Route
            path="/application-list-reports"
            element={withPermission(<LeadListReports />, Permissions.viewApplicationListReportsPage)}
          />
          <Route
            path="/funding-report"
            element={withPermission(<FundingReport />, Permissions.viewFundingReportPage)}
          />
          <Route
            path="/sources-channel/create"
            element={withPermission(<AffiliateChannelPageCreate />, Permissions.createSourceChannel)}
          />
          <Route
            path="/sources-channel/:id"
            element={withPermission(
              <ValidationTreeProvider>
                <AffiliatesChannelDetails />
              </ValidationTreeProvider>,
              Permissions.viewSourceChannelsList,
            )}
          />
          <Route
            path="/sources-channel/:id/decision-rules/:treeId"
            element={withPermission(
              <ValidationTreeProvider>
                <ValidationTreeCreateOrEdit MODE="EDIT" />
              </ValidationTreeProvider>,
              Permissions.viewSourceChannelDecisionTree,
            )}
          />
          <Route
            path="/sources-channel/create/decision-rules"
            element={withPermission(
              <ValidationTreeProvider>
                <ValidationTreeCreateOrEdit MODE="CREATE" />
              </ValidationTreeProvider>,
              Permissions.createSourceChannelDecisionTree,
            )}
          />
          {/* <Route @deprecated
            path="/integrations/third-party-APIs"
            element={withPermission(<ExternalService />, Permissions.viewIntegrations)}
          />
          <Route
            path="/integrations/third-party-APIs/create"
            element={withPermission(<ExternalServiceAdd />, Permissions.viewIntegrations)}
          />
          <Route
            path="/integrations/third-party-APIs/edit/:id"
            element={withPermission(<ExternalServiceEdit />, Permissions.viewIntegrations)}
          />
          <Route
            path="/integrations/list-integration"
            element={withPermission(<ListIntegration />, Permissions.viewIntegrations)}
          /> */}
          {/* 
          need to implement after release
            <Route
            path="/integrations/outbound"
            element={withPermission(<Underwriting />, Permissions.viewIntegrations)}
          />
          <Route
            path="/integrations/outbound/add"
            element={withPermission(<UnderwritingAdd />, Permissions.viewIntegrations)}
          />
          <Route
            path="/integrations/outbound/:id"
            element={withPermission(<UnderwritingEdit />, Permissions.viewIntegrations)}
          /> */}
          {/* <Route path="/integrations/api-test" element={withPermission(<ApiTest />, Permissions.viewIntegrations)} /> */}
          {/* <Route */}
          {/*  path="/integrations/list-integration/details/:id" */}
          {/*  element={withPermission(<ListIntegrationDetails />, Permissions.viewIntegrations)} */}
          {/* /> */}
          <Route path="/applications" element={<Leads />} />
          <Route path="/applications/:id" element={<LeadsDetails />} />
          <Route
            path="/manual-reviews"
            element={withPermission(<ManualReviewList />, Permissions.viewManualReviewList)}
          />
          <Route path="/funding-list" element={withPermission(<FundingList />, Permissions.viewFundList)} />
          <Route path="/source-reports" element={withPermission(<Report />, Permissions.viewSourceReportsPage)} />
          {/*
          implement after release
          <Route path="/error-applicants-report" element={<ErrorApplicantsReport />} />
          <Route path="/offers-report" element={<OffersReport />} />
          <Route path="/merchant-activity-report" element={<MerchantActivityReport />} />
          <Route path="/service-category-report" element={<ServiceCategoryReport />} /> */}
          <Route path="/user-list" element={withPermission(<UsersList />, Permissions.userManagement)} />
          <Route path="/role-list" element={withPermission(<RolesList />, Permissions.userManagement)} />
          {/* <Route path="/user-management/:id" element={<EditRole />} /> At this moment we don't need these paths */}
          {/* <Route path="/user-management/new-role" element={<AddRole />} /> */}
          <Route path="/settings" element={<Settings />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/notifications" element={<Notifications />} />
        </Routes>
        <Snackbar />
      </MainLayout>
    </LoggedInGuard>
  );
};

export default MainRoutes;
