import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { SolarPanel } from 'components/ManualReview/SolarPanelDetailsTable';
import FormSelect from 'components/shared/Select/FormSelect';
import FormTextArea from 'components/shared/TextField/FormTextArea';
import FormTextField from 'components/shared/TextField/FormTextField';

import { ISolarPanelDetails } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { manualReviewMiddleware, manualReviewSelector } from '../../../redux/slices/manualReviews';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { solarPanelModalValidationSchema } from '../../../validation/leads/solarPanelModal';
import { CustomButton } from '../../shared';

export interface ISolarPanelEditModalProps {
  reasonId: string;
  solarPanelDetails: ISolarPanelDetails[];
}

const SolarPanelEditModal = ({ reasonId, solarPanelDetails }: ISolarPanelEditModalProps) => {
  const updateSolarPanelDetailsLoading = useAppSelector(manualReviewSelector.updateSolarPanelDetailsLoading);
  const [panelIds, setPanelIds] = useState<Record<string, string>>({});
  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.SolarPanelEditModal));
  };

  const schema = solarPanelModalValidationSchema(Object.keys(panelIds));

  const methods = useForm<Record<string, string | boolean>>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { setValue, handleSubmit } = methods;

  const onSubmit = (data: Record<string, string | boolean>) => {
    const sendingData: { id: string; value: string | boolean }[] = [];

    Object.keys(data).forEach((el) => {
      sendingData.push({
        id: panelIds[el],
        value: data[el],
      });
    });

    dispatch(manualReviewMiddleware.updateSolarPanelDetails(reasonId, sendingData));
  };

  useEffect(() => {
    if (solarPanelDetails && solarPanelDetails.length) {
      const ids: Record<string, string> = {};

      solarPanelDetails.forEach((el) => {
        setValue(el.key, el.value);
        ids[el.key] = el.id;
      });

      setPanelIds(ids);
    }
  }, [solarPanelDetails]);

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px' }}>
        <DialogTitle>
          <DialogTitle textAlign="center">
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Edit Solar Panel Details</Typography>
            <Box
              sx={{
                position: 'absolute',
                top: '5px',
                right: '10px',
              }}
            >
              <IconButton onClick={onClose} size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </DialogTitle>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <Box>
                {solarPanelDetails?.map((detail) => {
                  if (detail.key === 'allAddersRelate') {
                    return (
                      <Box sx={{ margin: '10px' }}>
                        <FormSelect
                          width="100%"
                          options={[
                            { label: 'Yes', value: 'YES' },
                            { label: 'No', value: 'NO' },
                          ]}
                          name={detail.key}
                          label={SolarPanel[detail.key as keyof typeof SolarPanel]}
                        />
                      </Box>
                    );
                  }

                  if (detail.key === 'nonRelatedAdders') {
                    return (
                      <Box sx={{ margin: '10px' }}>
                        <FormTextArea name={detail.key} label={SolarPanel[detail.key as keyof typeof SolarPanel]} />
                      </Box>
                    );
                  }

                  return (
                    <Box sx={{ margin: '10px' }}>
                      <FormTextField
                        name={detail.key}
                        label={SolarPanel[detail.key as keyof typeof SolarPanel] ?? detail.key}
                      />
                    </Box>
                  );
                })}
              </Box>
            </FormProvider>
            <Box display="flex" justifyContent="space-around" marginTop="20px">
              <CustomButton variant="outlined" onClick={onClose}>
                Cancel
              </CustomButton>
              <CustomButton type="submit" loading={updateSolarPanelDetailsLoading}>
                Save
              </CustomButton>
            </Box>
          </form>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default SolarPanelEditModal;
