import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { listsMiddleware } from '../../../../redux/slices/lists';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmListIntegrationDetailsAddModalControls = (newRowName: string, bwListId: string) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationDetailsAddModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(
      listsMiddleware.postListValues({
        bwListId,
        value: newRowName,
      }),
    );
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmListIntegrationDetailsAddModal));
  }, [newRowName, bwListId]);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmListIntegrationDetailsAddModalControls;
