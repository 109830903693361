import { CSSProperties, ElementType } from 'react';
import { Typography } from '@mui/material';

const Title = ({
  title,
  sx,
  dataTest,
  component = 'h3',
}: {
  title: string;
  sx?: CSSProperties;
  dataTest?: string;
  component?: ElementType<any>;
}) => (
  <Typography
    component={component}
    data-test={dataTest ?? ''}
    sx={{
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '29px',
      color: '#454256',
      my: 4,
      padding: 0,
      ...sx,
    }}
  >
    {title}
  </Typography>
);

export default Title;
