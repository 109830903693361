import { useEffect } from 'react';
import NavigationScroll from 'components/Layout/NavigationScroll';
import { Routes } from 'router/Router';
import { ThemeCustomization } from 'themes';

import Loader from '../components/UI/Loader';
import { dispatch, useAppSelector } from '../redux/hooks';
import { authMiddleware, authSelector } from '../redux/slices/auth';

const App = () => {
  const isCheckingLoading = useAppSelector(authSelector.isCheckingLoading);

  useEffect(() => {
    dispatch(authMiddleware.fetchCheckLogin());
  }, []);

  if (isCheckingLoading) {
    return <Loader />;
  }

  return (
    <ThemeCustomization>
      <NavigationScroll>
        <Routes />
      </NavigationScroll>
    </ThemeCustomization>
  );
};

export default App;
