import { responseErrorHandler } from 'helpers/ErrorHandler';
import { dispatch } from 'redux/hooks';

import {
  API_GET_COMPARISON_REPORTS,
  API_GET_EXTERNAL_SERVICES_REPORTS,
  API_GET_LEADS_REPORTS,
  API_GET_NO_LEADS_REPORTS,
  API_GET_TOP_LOCATIONS_REPORTS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';

import slice from './slice';

export interface IDashboardLeadsReqTemplate {
  startDate: Date | string;
  endDate: Date | string;
  affiliates: string[];
  campaigns: string[];
}

export interface IDashboardNoLeadsReqTemplate {
  pageIndex: number;
  pageSize: number;
  startDate: Date | string;
  endDate: Date | string;
  affiliates: string[];
  campaigns: string[];
  sortOrder?: {
    key: string;
    value: boolean;
  };
}

export interface IDashboardExternalServices {
  pageIndex: number;
  pageSize: number;
  startDate: Date | string;
  endDate: Date | string;
  sortOrder?: {
    key: string;
    value: boolean;
  };
}

export interface IDashboardComparisonTable {
  affiliates: string[];
  campaigns: string[];
}

export interface IDashboardTopLocation {
  acceptedLeadsRate: string;
  leadsCount: number;
  name: string;
}

export interface ILeadsDashboard {
  dates: string[];
  acceptedLeads: number[];
  rejectedLeads: number[];
  manualReviews: number[];
}

const {
  setDashboardReportsLeadsLoadingSuccess,
  getDashboardLeadsSuccess,
  hasError,
  setDashboardReportsNoLeadsLoadingSuccess,
  getDashboardReportsNoLeadsSuccess,
  setDashboardReportsExternalServicesLoadingSuccess,
  getDashboardReportsExternalServicesSuccess,
  setDashboardReportsComparisonLoadingSuccess,
  getDashboardReportsComparisonSuccess,
  setDashboardReportsTopLocationsLoadingSuccess,
  getDashboardReportsTopLocationsSuccess,
} = slice.actions;

const fetchDashboardLeads = (data: IDashboardLeadsReqTemplate) => async () => {
  dispatch(setDashboardReportsLeadsLoadingSuccess(true));

  try {
    const { data: response } = await DataService.post(API_GET_LEADS_REPORTS(), data);

    const newData: ILeadsDashboard = {
      dates: [],
      acceptedLeads: [],
      rejectedLeads: [],
      manualReviews: [],
    };

    response.leadDateReports.forEach(
      (item: { acceptedLeads: number; rangeStartDate: string; rejectedLeads: number; manualReviews: number }) => {
        newData.dates.push(new Date(item.rangeStartDate).toString().split(' ')[0]);
        newData.acceptedLeads.push(item.acceptedLeads);
        newData.rejectedLeads.push(item.rejectedLeads);
        newData.manualReviews.push(item.manualReviews);
      },
    );

    dispatch(getDashboardLeadsSuccess(newData));
    dispatch(setDashboardReportsLeadsLoadingSuccess(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setDashboardReportsLeadsLoadingSuccess(false));
  }
};

const fetchDashboardReportsNoLeads = (data: IDashboardNoLeadsReqTemplate) => async () => {
  dispatch(setDashboardReportsNoLeadsLoadingSuccess(true));

  try {
    const { data: response } = await DataService.post(API_GET_NO_LEADS_REPORTS(), data);

    dispatch(getDashboardReportsNoLeadsSuccess(response));
    dispatch(setDashboardReportsNoLeadsLoadingSuccess(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setDashboardReportsNoLeadsLoadingSuccess(false));
  }
};

const fetchDashboardReportsExternalServices = (data: IDashboardExternalServices) => async () => {
  dispatch(setDashboardReportsExternalServicesLoadingSuccess(true));

  try {
    const { data: response } = await DataService.post(API_GET_EXTERNAL_SERVICES_REPORTS(), data);

    response.data = response.data.map((item: { latency: number; cost: string }) => ({
      ...item,
      latency: `${Math.round(item.latency * 100) / 100} ms`,
      cost: item.cost ? `$ ${item.cost}` : '',
    }));

    dispatch(getDashboardReportsExternalServicesSuccess(response));
    dispatch(setDashboardReportsExternalServicesLoadingSuccess(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setDashboardReportsExternalServicesLoadingSuccess(false));
  }
};

const fetchDashboardReportsComparison = (data: IDashboardComparisonTable) => async () => {
  dispatch(setDashboardReportsComparisonLoadingSuccess(true));

  try {
    const { data: response } = await DataService.post(API_GET_COMPARISON_REPORTS(), data);

    dispatch(getDashboardReportsComparisonSuccess(response));
    dispatch(setDashboardReportsComparisonLoadingSuccess(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setDashboardReportsComparisonLoadingSuccess(false));
  }
};

const fetchDashboardReportsTopLocations = (data: IDashboardLeadsReqTemplate) => async () => {
  dispatch(setDashboardReportsTopLocationsLoadingSuccess(true));

  try {
    const { data: response } = await DataService.post(API_GET_TOP_LOCATIONS_REPORTS(), data);

    dispatch(
      getDashboardReportsTopLocationsSuccess(
        response.map((item: IDashboardTopLocation) => ({
          ...item,
          color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        })),
      ),
    );
    dispatch(setDashboardReportsTopLocationsLoadingSuccess(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setDashboardReportsTopLocationsLoadingSuccess(false));
  }
};

export default {
  fetchDashboardLeads,
  fetchDashboardReportsNoLeads,
  fetchDashboardReportsExternalServices,
  fetchDashboardReportsComparison,
  fetchDashboardReportsTopLocations,
};
