import { useState } from 'react';
import AuthCode from 'react-auth-code-input';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../../redux/slices/settings';
import { CustomButton } from '../../shared';
import Loading from '../../UI/Loading';
import Title from '../../UI/Title';

import useTwoFactorAuthenticationModal from './hooks/useTwoFactorAuthenticationModal';

const TwoFactorAuthenticationModal = () => {
  const [verificationCode, setVerificationCode] = useState<string>('');

  const { onClose } = useTwoFactorAuthenticationModal();

  const initializationLoading = useAppSelector(settingsSelector.initializationLoading);
  const qrInfo = useAppSelector(settingsSelector.qrInfo);
  const verificationError = useAppSelector(settingsSelector.verificationError);
  const handleVerificationCode = (res: string) => {
    setVerificationCode(res);
  };

  const handleNext = () => {
    dispatch(settingsMiddleware.fetchCheckVerificationCode(verificationCode));
  };

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          paddingTop: 0,
        },
      }}
    >
      <Grid sx={{ width: '500px' }}>
        <DialogTitle
          sx={{
            backgroundColor: 'rgba(249, 248, 254, 1)',
          }}
        >
          <Title
            title="Two-Factor Authentication"
            sx={{
              margin: 0,
              padding: 0,
              display: 'block',
              textAlign: 'center',
              width: '100%',
              fontSize: '18px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        {initializationLoading && !qrInfo ? (
          <Loading />
        ) : (
          <DialogContent sx={{ p: 3 }}>
            <Box marginY="15px">
              <Typography>
                Please scan the QR Code with your Google Authentication App and enter the verification code below.
              </Typography>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography sx={{ fontSize: '16px' }}>Manual Key</Typography>
              {qrInfo ? (
                <Box>
                  <Box sx={{ border: '1px solid #E2E8F0', padding: '30px 0', wordBreak: 'break-all' }}>
                    {qrInfo.manualKey}
                  </Box>
                  <img src={qrInfo.imageUrl ?? ''} alt="QR" loading="lazy" />
                </Box>
              ) : (
                <Loading />
              )}
            </Box>
            <Box>
              <Typography marginTop="15px" marginBottom="10px">
                Verification Code
              </Typography>
              {verificationError ? (
                <Typography sx={{ color: 'red', marginBottom: '10px' }}>{verificationError}</Typography>
              ) : null}
              <Box
                textAlign="center"
                sx={{
                  '& div': {
                    display: 'flex',
                    justifyContent: 'space-between',

                    '& input': {
                      margin: 0,
                    },
                  },
                }}
              >
                <AuthCode inputClassName="two-factor" onChange={handleVerificationCode} />
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(123, 97, 255, 1)',
                    fontWeight: '500 !important',
                    lineHeight: '20px',
                    fontSize: '14px !important',
                    display: 'none',
                  }}
                >
                  Do you need help?
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CustomButton sx={{ width: '100%' }} onClick={handleNext}>
                  Next
                </CustomButton>
              </Box>
            </Box>
          </DialogContent>
        )}
      </Grid>
    </Dialog>
  );
};

export default TwoFactorAuthenticationModal;
