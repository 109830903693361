import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { validationTreeMiddleware } from '../../../../redux/slices/validationTree';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmAffiliateChannelTreeDeleteControls = (id: string, affiliateChannelId: string) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmAffiliateChannelTreeDeleteModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(validationTreeMiddleware.fetchDeleteAffiliateChannelTree(id, affiliateChannelId));
  }, []);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmAffiliateChannelTreeDeleteControls;
