import { responseErrorHandler } from 'helpers/ErrorHandler';

import { IEmailNotificationsSendingData, IPlatformSettings, ITenantSettings } from '@types';

import {
  API_EMAIL_NOTIFICATIONS_TYPES,
  API_NOTIFICATIONS_TYPES,
  API_SETTINGS_MFA,
  API_SETTINGS_MFA_INIT,
  API_SETTINGS_MFA_STATUS,
  API_SLACK_INSTALL,
  API_TENANT_SETTINGS,
  API_TENANT_SETTINGS_RESET,
  API_USER_SETTINGS,
} from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';
import { SeveritiesType } from '../../../types/snackbar';
import { dispatch } from '../../hooks';
import { viewsMiddleware } from '../views';
import { ModalName } from '../views/initialState';

import { createEmailNotificationsData } from './helper';
import slice from './slice';

const {
  hasError,
  setQRInfo,
  setSettingsLoading,
  setVerificationError,
  setVerificationEnabled,
  setSettingsStatus,
  setInitializationLoading,
  setPlatformSettings,
  setPlatformSettingsLoading,
  setTenantSettings,
  setTenantSettingsLoading,
  setNotificationTypes,
  setDuplicatedNotificationTypes,
  setNotificationTypesLoading,
} = slice.actions;

const fetchMFAInitialization = () => async () => {
  try {
    dispatch(setInitializationLoading(true));

    const { data: response } = await DataService.getData(API_SETTINGS_MFA_INIT(), {});

    dispatch(setQRInfo(response));

    dispatch(setInitializationLoading(false));
  } catch (error) {
    responseErrorHandler(error);
    dispatch(hasError(error));
    dispatch(setInitializationLoading(false));
  }
};

const fetchSetVerificationEnabled = (enabled: boolean) => async () => {
  dispatch(setVerificationEnabled(enabled));

  if (enabled) {
    localStorage.setItem('mfa', 'true');
  } else {
    localStorage.removeItem('mfa');
  }
};

const fetchCheckVerificationCode = (verificationCode: string) => async () => {
  try {
    dispatch(setSettingsLoading(true));

    await DataService.put(API_SETTINGS_MFA(true, verificationCode));

    dispatch(viewsMiddleware.closeModal(ModalName.TwoFactorAuthenticationModal));
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.TwoFactorAuthenticationResultModal,
        props: { enabled: true },
      }),
    );
    dispatch(fetchSetVerificationEnabled(true));
    dispatch(setSettingsLoading(false));
  } catch (error: any) {
    dispatch(setVerificationError(error?.response?.data?.error?.description));
    dispatch(hasError(error));
    dispatch(setSettingsLoading(false));
  }
};

const fetchSetVerificationError = (message: string) => () => {
  dispatch(setVerificationError(message));
};

// @todo outdated
const fetchSettingsStatus = () => async () => {
  try {
    dispatch(setSettingsLoading(true));

    const { data: response } = await DataService.getData(API_SETTINGS_MFA_STATUS());

    dispatch(setSettingsStatus(response));
    dispatch(setSettingsLoading(false));
  } catch (error: any) {
    dispatch(setVerificationError(error?.response.data)); // need to check @todo
    dispatch(hasError(error));
    dispatch(setSettingsLoading(false));
  }
};

const fetchDisableVerification = () => async () => {
  try {
    dispatch(setSettingsLoading(true));

    await DataService.put(API_SETTINGS_MFA(false));
    dispatch(viewsMiddleware.closeModal(ModalName.TwoFactorAuthenticationDisableModal));
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.TwoFactorAuthenticationResultModal,
        props: { enabled: false },
      }),
    );
    dispatch(fetchSetVerificationEnabled(false));
    dispatch(setSettingsLoading(false));
  } catch (error: any) {
    dispatch(hasError(error));
    dispatch(setSettingsLoading(false));
  }
};

const fetchPlatformSettings = () => async () => {
  try {
    dispatch(setPlatformSettingsLoading(true));

    const { data: response } = await DataService.getData(API_USER_SETTINGS());

    dispatch(setPlatformSettings(response));
  } catch (error: any) {
    console.log(error);
  } finally {
    dispatch(setPlatformSettingsLoading(false));
  }
};

const updatePlatformSettings = (data: IPlatformSettings) => async () => {
  try {
    dispatch(setPlatformSettingsLoading(true));

    const { data: response } = await DataService.post(API_USER_SETTINGS(), data);

    dispatch(setPlatformSettings(response));
  } catch (error: any) {
    console.log(error);

    if (error?.response?.data?.errors?.SlackChannelName) {
      dispatch(
        viewsMiddleware.setToastNotificationPopUpState({
          // coming with an old format
          open: true,
          props: {
            severityType: SeveritiesType.error,
            description: error?.response?.data?.errors?.SlackChannelName[0],
          },
        }),
      );
    }
  } finally {
    dispatch(setPlatformSettingsLoading(false));
  }
};

const installSlack = () => async () => {
  try {
    dispatch(setPlatformSettingsLoading(true));

    const { data: response } = await DataService.getData(API_SLACK_INSTALL());

    window.open(response.installUrl);
  } catch (error: any) {
    console.log(error);
  } finally {
    dispatch(setPlatformSettingsLoading(false));
  }
};

const getTenantSettings = () => async () => {
  try {
    dispatch(setTenantSettingsLoading(true));

    const { data: response } = await DataService.getData(API_TENANT_SETTINGS());

    dispatch(setTenantSettings(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setTenantSettingsLoading(false));
  }
};

const updateTenantSettings = (data: ITenantSettings) => async () => {
  try {
    dispatch(setTenantSettingsLoading(true));

    await DataService.put(API_TENANT_SETTINGS(), data);
    await DataService.getData(API_TENANT_SETTINGS());

    // @TODO: Vahe, add local handling
    const { data: response } = await DataService.getData(API_TENANT_SETTINGS());

    dispatch(setTenantSettings(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setTenantSettingsLoading(false));
  }
};

const resetDailyCap = () => async () => {
  try {
    dispatch(setTenantSettingsLoading(true));

    await DataService.put(API_TENANT_SETTINGS_RESET());

    dispatch(viewsMiddleware.closeModal(ModalName.ResetDailyCapModal));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setTenantSettingsLoading(false));
  }
};

const getNotificationTypes = () => async () => {
  try {
    dispatch(setNotificationTypesLoading(true));

    const { data: response } = await DataService.getData(API_NOTIFICATIONS_TYPES());

    const { notificationTypes, duplicatedTypes } = createEmailNotificationsData(response);

    dispatch(setNotificationTypes(notificationTypes));
    dispatch(setDuplicatedNotificationTypes(duplicatedTypes));
  } catch (error) {
    responseErrorHandler(error);
  } finally {
    dispatch(setNotificationTypesLoading(false));
  }
};

const updateEmailNotifications = (data: IEmailNotificationsSendingData[]) => async () => {
  try {
    dispatch(setNotificationTypesLoading(true));

    await DataService.post(API_EMAIL_NOTIFICATIONS_TYPES(), data);
  } catch (error) {
    responseErrorHandler(error);
  } finally {
    dispatch(setNotificationTypesLoading(false));
  }
};

export default {
  fetchMFAInitialization,
  fetchCheckVerificationCode,
  fetchSetVerificationEnabled,
  fetchDisableVerification,
  fetchSettingsStatus,
  fetchSetVerificationError,
  fetchPlatformSettings,
  updatePlatformSettings,
  installSlack,
  getTenantSettings,
  updateTenantSettings,
  resetDailyCap,
  getNotificationTypes,
  updateEmailNotifications,
};
