import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  // MenuItem,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Permissions } from 'components/Permissions/constants';
import { hasPermission } from 'components/Permissions/Permission';
import { ApplicationTabs, IFunds } from 'types';

import { CookieManager } from '../../helpers/CookieManager';
import { LeadsFundingStatuses } from '../../lib/constants/statuses';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { fundingMiddleware, fundingSelector } from '../../redux/slices/funding';
import { leadsMiddleware, leadsSelector } from '../../redux/slices/leads';
import FundsDropdownButton from '../shared/FundsDropdownButton';
// import { PagesSearch } from '../Layout/MainLayout/Header/SearchSection';
import { StatusBadgeTitle } from '../StatusBadgeTitle';
import Loading from '../UI/Loading';
import Title from '../UI/Title';

// const Statuses = [
//   { label: 'Rejected', value: 0 },
//   { label: 'Accepted', value: 1 },
//   { label: 'Waiting For Review', value: 2 }
// ];

interface IManualReviewProps {
  setSelectedRow: (item: IFunds) => void;
  setMode: (mode: string) => void;
}

const FundingRequestsTable = ({ setSelectedRow, setMode }: IManualReviewProps) => {
  const funds = useAppSelector(leadsSelector.funds);
  const fundsLoading = useAppSelector(leadsSelector.fundsLoading);
  const fundersList = useAppSelector(fundingSelector.fundersList);
  const user = CookieManager.getUser();

  const { id: leadId } = useParams();

  const handleRowClick = (item: IFunds) => {
    setSelectedRow(item);
    setMode('view');
  };

  useEffect(() => {
    if (leadId) {
      dispatch(leadsMiddleware.fetchFunds(leadId));
    }

    if (
      !fundersList?.length &&
      (hasPermission(Permissions.changeFunder) || hasPermission(Permissions.fundSelfAssignUnassign)) &&
      user
    ) {
      dispatch(fundingMiddleware.getFundersList());
    }

    return () => {
      dispatch(leadsMiddleware.changeLeadDetailsInitialTab(ApplicationTabs.generalInfo));
    };
  }, [leadId]);

  if (fundsLoading) {
    return <Loading />;
  }

  if (!funds?.length) {
    return (
      <Box sx={{ marginTop: '24px' }}>
        <Title sx={{ fontSize: '16px' }} title="We couldn’t find any results" />
      </Box>
    );
  }

  return (
    <Grid container marginTop="24px">
      {/* <Grid item xs={4}> need to be hidden for TGUC MVP release
        <PagesSearch
          handleSearch={() => {}}
          minChar={1}
          dataTest="lead-details-page-tab-funding-request-table-input-search"
        />
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        <Select fullWidth data-test="lead-details-page-tab-funding-request-table-dropdown-status">
          {Statuses.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid> */}
      <Grid
        item
        xs={12}
        sx={{
          // marginTop: '24px',
          '& .MuiTableContainer-root': {
            padding: '0',
          },
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Request Id</TableCell>
                <TableCell>Date and Time</TableCell>
                <TableCell>Request Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Assigned to</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {funds.map((item, index) => (
                <TableRow
                  data-test={`leads-page-tab-funding-requests-table-id-${item.id}`}
                  key={item.leadId}
                  onClick={() => handleRowClick(item)}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.requestedDate}</TableCell>
                  <TableCell>$ {item.requestedAmount}</TableCell>
                  <TableCell>
                    <StatusBadgeTitle Statuses={LeadsFundingStatuses} status={`${item.status}`} />
                  </TableCell>
                  <TableCell>
                    <Box onClick={(e) => e.stopPropagation()}>
                      <FundsDropdownButton
                        data={fundersList ?? []}
                        defaultValue={{ id: item.reviewerId, value: item.reviewedBy }}
                        fundId={item.id}
                        mode="request"
                        status={item.status}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default FundingRequestsTable;
