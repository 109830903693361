import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

import { INote } from '@types';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { leadsMiddleware, leadsSelector } from '../../../redux/slices/leads';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import CustomTextArea from '../../shared/CustomInputs/CustomTextArea';

export interface IAddNoteModalProps {
  leadId: string;
  mode: 'Add' | 'Edit';
  page?: number;
  rowsPerPage?: number;
  defaultNote?: INote;
  selectedUser?: number;
}

const AddNoteModal = ({ leadId, mode, page, rowsPerPage, defaultNote, selectedUser }: IAddNoteModalProps) => {
  const [note, setNote] = useState<string>(defaultNote?.note ?? '');
  const notesAddOrEditLoading = useAppSelector(leadsSelector.notesAddOrEditLoading);

  const onSave = () => {
    if (leadId && note) {
      if (mode === 'Add') {
        dispatch(leadsMiddleware.createNote(leadId, note, selectedUser, rowsPerPage));
      } else if (defaultNote?.noteId) {
        dispatch(leadsMiddleware.updateNote(defaultNote.noteId, note, leadId, page, rowsPerPage, selectedUser));
      }
    }
  };

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.AddNoteModal));
  };

  return (
    <Dialog open onClose={onClose}>
      <Grid sx={{ width: '500px', mb: '20px' }}>
        <DialogTitle textAlign="center">
          <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{mode} Note</Typography>
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Box>
            <CustomTextArea
              onChange={({ target }) => setNote(target.value.trimStart())}
              label="Note"
              placeholder="Note"
              defaultValue={defaultNote?.note}
            />
          </Box>
          <Box display="flex" justifyContent="space-around" marginTop="20px">
            <CustomButton variant="outlined" onClick={onClose}>
              Cancel
            </CustomButton>
            <CustomButton variant="contained" onClick={onSave} loading={notesAddOrEditLoading} disabled={!note?.length}>
              Save
            </CustomButton>
          </Box>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default AddNoteModal;
