import { Dispatch, SetStateAction } from 'react';
import { Box, Grid } from '@mui/material';

import { CustomButton } from '../../components';
import DatePicker from '../../components/DatePicker';
import CustomAutocomplete, { ISelectOption } from '../../components/UI/CustomAutocomplete';

export interface IComparisonReportsFilterProps {
  reportDate: Date;
  setReportDate: Dispatch<SetStateAction<Date>>;
  firstComparisonDate: Date;
  setFirstComparisonDate: Dispatch<SetStateAction<Date>>;
  secondComparisonDate: Date;
  setSecondComparisonDate: Dispatch<SetStateAction<Date>>;
  affiliateDropdownOptions: ISelectOption[];
  filteredAffiliateData: ISelectOption[];
  setFilteredAffiliateData: Dispatch<SetStateAction<ISelectOption[]>>;
  affiliatesChannelDropdownOptions: ISelectOption[];
  filteredAffiliateChannelData: ISelectOption[];
  setFilteredAffiliateChannelData: Dispatch<SetStateAction<ISelectOption[]>>;
  resetFilters: () => void;
  applyFilters: () => void;
}

export const AffiliatesComparisonReportsFilters = ({
  reportDate,
  setReportDate,
  firstComparisonDate,
  setFirstComparisonDate,
  secondComparisonDate,
  setSecondComparisonDate,
  affiliateDropdownOptions,
  filteredAffiliateData,
  setFilteredAffiliateData,
  affiliatesChannelDropdownOptions,
  filteredAffiliateChannelData,
  setFilteredAffiliateChannelData,
  resetFilters,
  applyFilters,
}: IComparisonReportsFilterProps) => (
  <Grid item container display="flex" alignItems="flex-end" gap="30px">
    <Grid item>
      <DatePicker
        dataTest="affiliate-comparison-reports-page-input-report-date"
        date={reportDate}
        maxDate={new Date()}
        setDate={setReportDate}
        label="Report Date"
      />
    </Grid>
    <Grid item>
      <DatePicker
        dataTest="affiliate-comparison-reports-page-input-first-comparison-date"
        date={firstComparisonDate}
        maxDate={new Date()}
        setDate={setFirstComparisonDate}
        label="First Comparison Date"
      />
    </Grid>
    <Grid item>
      <DatePicker
        dataTest="affiliate-comparison-reports-page-input-second-comparison-date"
        date={secondComparisonDate}
        maxDate={new Date()}
        setDate={setSecondComparisonDate}
        label="Second Comparison Date"
      />
    </Grid>
    <Grid item>
      <CustomAutocomplete
        dataTest="affiliate-comparison-reports-page-dropdown-affiliate"
        title="Source"
        data={affiliateDropdownOptions}
        filteredData={filteredAffiliateData}
        setData={setFilteredAffiliateData}
        isSelectAll
      />
    </Grid>
    <Grid item>
      <CustomAutocomplete
        dataTest="affiliate-comparison-reports-page-dropdown-affiliate-channel"
        title="Source Channel"
        data={affiliatesChannelDropdownOptions}
        filteredData={filteredAffiliateChannelData}
        setData={setFilteredAffiliateChannelData}
        isSelectAll
      />
    </Grid>
    <Grid item display="flex" gap="30px">
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
        <CustomButton data-test="affiliate-comparison-reports-page-button-apply" onClick={applyFilters}>
          Apply
        </CustomButton>
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" height="100%">
        <CustomButton
          variant="outlined"
          data-test="affiliate-comparison-reports-page-button-apply"
          onClick={resetFilters}
        >
          Reset
        </CustomButton>
      </Box>
    </Grid>
  </Grid>
);
