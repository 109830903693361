import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { dispatch, useAppSelector } from '../../../redux/hooks';
import { userManagementMiddleware, userManagementSelector } from '../../../redux/slices/userManagement';
import { IRolesList } from '../../../redux/slices/userManagement/initialState';
import { viewsMiddleware } from '../../../redux/slices/views';
import { ModalName } from '../../../redux/slices/views/initialState';
import { CustomButton } from '../../shared';
import CustomSelect from '../../shared/Select/CustomSelect';
import { IControlledSelectOptions } from '../../shared/Select/FormSelect';
import Loading from '../../UI/Loading';
import Title from '../../UI/Title';
import PermissionsTable from '../../UserRoles/RoleModal/PermissionsTable';
import { Description, Subtitle } from '../../UserRoles/RoleModal/RoleModalCoomponents';

export interface ICreateRoleModalProps {
  defaultRole: IRolesList;
}

const CreateRoleModal = ({ defaultRole }: ICreateRoleModalProps) => {
  const permissionsList = useAppSelector(userManagementSelector.permissionsList);
  const permissionsListLoading = useAppSelector(userManagementSelector.permissionsListLoading);
  const rolesList = useAppSelector(userManagementSelector.rolesList);
  const rolePermissions = useAppSelector(userManagementSelector.rolePermissions);
  const [selectedRole, setSelectedRole] = useState<IRolesList | null>(null);
  const [error, setError] = useState(false);

  const onClose = () => {
    dispatch(viewsMiddleware.closeModal(ModalName.CreateRoleModal));
  };

  const onSubmit = (role: any) => {
    const permissions: string[] = [];

    Object.entries(role.permissions).forEach(([key, value]) => {
      if (value) {
        permissions.push(key);
      }
    });

    if (permissions.length) {
      dispatch(
        userManagementMiddleware.createRole({ name: role.name.trim(), piiEnabled: role.piiEnabled, permissions }),
      );
      setError(false);
    } else {
      setError(true);
    }
  };

  const methods = useForm<{ name: string; piiEnabled: boolean; permissions: { [key: string]: boolean } }>({
    defaultValues: {
      name: '',
      piiEnabled: false,
      permissions: {},
    },
  });

  const { control, handleSubmit, reset, setValue, watch } = methods;

  useEffect(() => {
    if (!permissionsList) {
      dispatch(userManagementMiddleware.getPermissionsList());
    }

    return () => {
      dispatch(userManagementMiddleware.clearRolePermissions());
      setSelectedRole(null);
      reset();
    };
  }, []);

  useEffect(() => {
    if (rolePermissions) {
      reset();
      rolePermissions.forEach((el: string) => {
        setValue(`permissions.${el}`, true);
      });
      setValue('piiEnabled', selectedRole?.piiEnabled ?? false);
    }
  }, [rolePermissions]);

  useEffect(() => {
    if (selectedRole?.id) {
      dispatch(userManagementMiddleware.getRolePermissions(selectedRole.id));
      setValue('piiEnabled', selectedRole.piiEnabled);
    }
  }, [selectedRole]);

  useEffect(() => {
    if (defaultRole) {
      setSelectedRole(defaultRole);
    }
  }, [defaultRole]);

  return (
    <Dialog open onClose={onClose} PaperProps={{ style: { height: '100%' } }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ height: '100%' }}>
        <Box>
          <Title
            title="Create New Role"
            sx={{
              marginBottom: 0,
              padding: 0,
              marginTop: '16px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '5px',
              right: '10px',
            }}
          >
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Grid sx={{ width: '500px', overflow: 'auto', height: 'calc(100% - 110px)' }}>
          <DialogContent sx={{ p: 3 }}>
            <Box>
              <Box paddingTop="10px">
                <Subtitle text="Templates" />
                <Description text="Start creating the role based on a predefined template." />
                <Box marginTop="10px">
                  <CustomSelect
                    width="100%"
                    options={rolesList ?? []}
                    nameKey="name"
                    valueKey="id"
                    onSelect={setSelectedRole as (value: IControlledSelectOptions) => void}
                    defaultValue={defaultRole?.id}
                  />
                </Box>
              </Box>
              <Box sx={{ marginY: '24px' }}>
                <Subtitle text="Role Name" />
                <Description text="The displayed name for the role." />
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                      <TextField required {...field} placeholder="Enter Role Name" />
                    </FormControl>
                  )}
                />
              </Box>
              <Box>
                <Subtitle text="Role Permissions" />
                <Description text="Account Permissions for features in an account." />
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Subtitle text="Personal Identification Information (PII)" />
                <FormControl>
                  <RadioGroup row>
                    <FormControlLabel
                      onChange={() => setValue('piiEnabled', false)}
                      control={<Radio checked={!watch('piiEnabled')} color="secondary" />}
                      label="Disabled"
                    />
                    <FormControlLabel
                      onChange={() => setValue('piiEnabled', true)}
                      control={<Radio checked={watch('piiEnabled')} color="secondary" />}
                      label="Enabled"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {permissionsListLoading ? (
                <Loading />
              ) : (
                <FormProvider {...methods}>
                  <Box>
                    {permissionsList &&
                      Object.keys(permissionsList).map((el) => (
                        <PermissionsTable key={el} data={permissionsList} type={el} />
                      ))}
                  </Box>
                  {error ? (
                    <Box marginTop="8px">
                      <FormHelperText error>You need to choose permission</FormHelperText>
                    </Box>
                  ) : null}
                </FormProvider>
              )}
            </Box>
          </DialogContent>
        </Grid>
        <Box display="flex" justifyContent="space-around" marginTop="24px">
          <CustomButton variant="outlined" onClick={onClose}>
            Cancel
          </CustomButton>
          <CustomButton variant="contained" type="submit">
            Save
          </CustomButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateRoleModal;
