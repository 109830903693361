import { ReactNode } from 'react';
import { Box } from '@mui/material';

import Title from '../../UI/Title';
import GoBackButton from '../Button/GoBackButton';

interface TitleWithBackButtonProps {
  title: string;
  dataTest: string;
  children?: ReactNode;
}

const TitleWithBackButton = ({ title, dataTest, children }: TitleWithBackButtonProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
    <GoBackButton />
    <Title dataTest={dataTest} title={title} sx={{ whiteSpace: 'nowrap' }} />
    {children}
  </Box>
);

export default TitleWithBackButton;
