import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

import ALlCaughtUp from '../../components/Icons/AllCaughtUp.svg';
import Notification from '../../components/Notifications/Notification';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { notificationMiddleware, notificationSelector } from '../../redux/slices/notification';
import { ISingleNotification } from '../../redux/slices/notification/initialState';

const NotificationTabs: string[] = ['All Notifications', 'Read', 'Unread'];

const NotificationTab = ({
  title,
  onClick,
  isActive,
}: {
  title: string;
  onClick: (tab: string) => void;
  isActive: boolean;
}) => (
  <Box
    sx={{
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      width: '200px',
      padding: '14px 16px',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '16px',
      margin: '6px',
      color: isActive ? '#7B61FF' : '#A3AED0',
    }}
    onClick={() => {
      onClick(title);
    }}
  >
    {title}
  </Box>
);

const Notifications = () => {
  const [activeNotificationTab, setActiveNotificationTab] = useState<string>(NotificationTabs[0]);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const notificationsList = useAppSelector(notificationSelector.notificationsList);
  const notificationsByIdLoading = useAppSelector(notificationSelector.notificationsByIdLoading);
  const isNotificationListEnd = useAppSelector(notificationSelector.isNotificationListEnd);

  const [filteredNotifications, setFilteredNotifications] = useState<ISingleNotification[]>(notificationsList ?? []);

  const changeActiveTab = (tabName: string) => {
    setActiveNotificationTab(tabName);

    if (notificationsList) {
      if (tabName === 'Read') {
        setFilteredNotifications(notificationsList?.filter((el) => el.status === 2));
      } else if (tabName === 'Unread') {
        setFilteredNotifications(notificationsList?.filter((el) => el.status === 1));
      } else {
        setFilteredNotifications(notificationsList);
      }
    }
  };

  const handleGetMoreData = () => {
    if (!notificationsByIdLoading && notificationsList) {
      dispatch(notificationMiddleware.fetchNotificationsWithId(notificationsList[notificationsList.length - 1].id));
    }
  };

  const handleScroll = () => {
    const scrollableContainer = scrollableContainerRef.current;

    if (scrollableContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableContainer;

      if (
        scrollTop + clientHeight >= scrollHeight - 50 &&
        activeNotificationTab === NotificationTabs[0] &&
        !isNotificationListEnd
      ) {
        handleGetMoreData();
      }
    }
  };

  const handleMarkAllAsRead = () => {
    if (notificationsList?.filter((el) => el.status === 1).length) {
      dispatch(notificationMiddleware.onMarkAllAsReadClick());
    }
  };

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef.current;

    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableContainer) {
        scrollableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [notificationsList, notificationsByIdLoading, activeNotificationTab, isNotificationListEnd]);

  useEffect(() => {
    if (notificationsList) {
      changeActiveTab(activeNotificationTab);
    }
  }, [notificationsList]);

  useEffect(() => {
    dispatch(notificationMiddleware.fetchNotificationsWithId());

    return () => {
      dispatch(notificationMiddleware.clearNotificationsList());
    };
  }, []);

  return (
    <Box>
      <Grid container>
        <Grid item xs={3}>
          {NotificationTabs.map((tab) => (
            <NotificationTab key={tab} title={tab} onClick={changeActiveTab} isActive={activeNotificationTab === tab} />
          ))}
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              width: '470px',
            }}
          >
            <Box padding="12px 16px" display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography sx={{ fontWeight: 600 }}>Notifications</Typography>
              </Box>
              <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleMarkAllAsRead}>
                <Typography>Mark all as read</Typography>
              </Box>
            </Box>
            <Box>
              <Box
                ref={scrollableContainerRef}
                sx={{
                  overflow: 'auto',
                  maxHeight: '500px',
                }}
              >
                <Box>
                  {filteredNotifications?.length ? (
                    <Box>
                      {filteredNotifications?.map((el) => (
                        <Notification key={el.id} nameTag={el.assigner || 'N A'} notification={el} />
                      ))}
                      {notificationsByIdLoading ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '20px',
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : null}
                    </Box>
                  ) : (
                    <Box textAlign="center" padding="16px">
                      <img src={ALlCaughtUp} alt="Notifications" height={70} />
                      <Typography>You have read all your notifications from the last 30 days.</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notifications;
