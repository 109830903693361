import { FormControl, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemCssProperties } from '@mui/system';

import { Permissions } from '../../Permissions/constants';
import { hasPermission } from '../../Permissions/Permission';

interface TextFieldWithPermissionsProps {
  label?: string;
  width?: string;
  placeholder?: string;
  permission?: Permissions;
  labelSx?: SystemCssProperties<Theme>;
  onChange?: (value: string) => void;
  defaultValue?: string;
  value?: string;
  disabled?: boolean;
  dataTest?: string;
  inputSx?: SystemCssProperties<Theme>;
}

const CustomTextField = ({
  label,
  width,
  placeholder,
  permission,
  onChange,
  labelSx,
  defaultValue,
  value,
  disabled,
  dataTest,
  inputSx,
}: TextFieldWithPermissionsProps) => {
  const fieldState = () => {
    if (disabled) {
      return true;
    }

    return permission ? !hasPermission(permission) : false;
  };

  return (
    <FormControl fullWidth sx={{ width }}>
      {label ? <Typography sx={labelSx}>{label}</Typography> : null}
      <TextField
        sx={inputSx}
        disabled={fieldState()}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        data-test={dataTest}
      />
    </FormControl>
  );
};

export default CustomTextField;
