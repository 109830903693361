import { dispatch } from 'redux/hooks';

import { API_GET_COUNTRIES, API_STATE_PROVINCES } from '../../../lib/constants/api.constants.simple';
import DataService from '../../../service/data.service';

import slice from './slice';

const { getCountriesSuccess, hasError, getStateProvincesSuccess } = slice.actions;

const fetchCountries = () => async () => {
  try {
    const { data: response } = await DataService.getJson(API_GET_COUNTRIES());

    dispatch(getCountriesSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

const fetchStates = (id: string) => async () => {
  try {
    const { data: response } = await DataService.getJson(API_STATE_PROVINCES(id));

    dispatch(getStateProvincesSuccess(response));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default {
  fetchCountries,
  fetchStates,
};
