import { useState } from 'react';
import { Box, Dialog, DialogActions, DialogTitle, Stack, TextField } from '@mui/material';

import { CustomButton } from '../../shared';
import Title from '../../UI/Title';

import useConfirmListIntegrationDetailsAddModalControls from './hooks/useConfirmListIntegrationDetailsAddModalControls';

export interface IConfirmListIntegrationDetailsAddModalProps {
  bwListId: string;
}

const ConfirmListIntegrationDetailsAddModal = ({ bwListId }: IConfirmListIntegrationDetailsAddModalProps) => {
  const [newRowName, setNewRowName] = useState<string>(' ');
  const { onConfirm, onClose } = useConfirmListIntegrationDetailsAddModalControls(newRowName, bwListId);

  return (
    <Dialog data-test="confirm-list-integration-details-add-modal" open onClose={onClose}>
      <Stack padding="10px">
        <DialogTitle>
          <Title
            title="Add New Field"
            sx={{
              margin: 0,
              padding: 0,
              paddingBottom: '20px',
              display: 'block',
              textAlign: 'center',
              width: '100%',
            }}
          />
        </DialogTitle>
        <DialogActions>
          <Stack justifyContent="center" alignContent="center">
            <TextField
              data-test="confirm-list-integration-details-add-modal-input-new-row-name"
              value={newRowName}
              autoFocus
              onChange={(e) => {
                setNewRowName(e.target.value);
              }}
            />
            <Box marginTop="20px" width="100%" display="flex" alignItems="center" justifyContent="center">
              <CustomButton
                data-test="confirm-list-integration-details-add-modal-button-cancel"
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test="confirm-list-integration-details-add-modal-button-add-new-field"
                disabled={!newRowName.length}
                onClick={onConfirm}
              >
                Add New Field
              </CustomButton>
            </Box>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default ConfirmListIntegrationDetailsAddModal;
