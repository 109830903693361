import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment/moment';

import { DateRange, TableHeader } from '@types';

import FormAutocomplete from '../../formComponents/FormAutocomplete';
import { MANUAL_REVIEW_LIST_KEY, PersistManager } from '../../helpers/PersistManager';
import { defaultFilterValues } from '../../helpers/PersistManager/utils';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { ManualReviewListTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { manualReviewMiddleware, manualReviewSelector } from '../../redux/slices/manualReviews';
import { tagsSelector } from '../../redux/slices/tags';
import CustomRangePicker from '../CustomRangePicker/CustomRangePicker';
import { CustomButton } from '../shared';
import SelectTableHeaders from '../UI/SelectTableHeaders';

const statuses = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Accepted' },
  { value: '2', label: 'Rejected' },
  { value: '3', label: 'In Review' },
  { value: '4', label: 'Pending' },
];

interface ManualReviewListFiltersProps {
  setPage: (value: number) => void;
  selectedTableHeaders: TableHeader[];
  setSelectedTableHeaders: Dispatch<SetStateAction<TableHeader[]>>;
}

const ManualReviewListFilters = ({
  setPage,
  selectedTableHeaders,
  setSelectedTableHeaders,
}: ManualReviewListFiltersProps) => {
  const [defaultFilters] = useState(PersistManager.getData(MANUAL_REVIEW_LIST_KEY) || {});
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: defaultFilters.dateFrom ?? moment().subtract(1, 'year').format(),
    endDate: defaultFilters.dateTo ?? moment().format(),
  });
  const resetFiltersFlag = useRef(false);

  const affiliateChannelsList = useAppSelector(affiliatesSelector.simplifiedAffiliateChannels);
  const merchantNames = useAppSelector(manualReviewSelector.merchantNames);
  const tags = useAppSelector(tagsSelector.tags);

  const emptyValues = {
    applicationId: '',
    borrower: '',
    status: '0',
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
    sourceChannels: [],
    merchantName: [],
    tags: [],
  };

  const setupDefaultValues = () => ({
    applicationId: defaultFilters.applicationId ?? '',
    borrower: defaultFilters.borrowerName ?? '',
    status: defaultFilters.status ?? '0',
    dateFrom: moment().subtract(1, 'year').format(),
    dateTo: moment().format(),
    sourceChannels: defaultFilterValues(affiliateChannelsList ?? [], defaultFilters.sourceChannels),
    merchantName: !defaultFilters.merchantName
      ? []
      : defaultFilters.merchantName?.map((name: string) => ({ id: name, name })),
    tags: defaultFilterValues(tags ?? [], defaultFilters.tags, 'name'),
    searchModel: {
      pageNumber: 1,
      pageSize: DEFAULT_ROWS_PAGE,
      sortOrder: { key: 'Id', value: false },
    },
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: setupDefaultValues(),
  });

  const { control, handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    if (affiliateChannelsList && merchantNames && tags) {
      reset(setupDefaultValues());
    }
  }, [affiliateChannelsList, merchantNames, tags]);

  const applyFilters = (data: any) => {
    const orderData = PersistManager.getData(MANUAL_REVIEW_LIST_KEY).searchModel;

    setValue('borrower', (data.borrower = data.borrower.trim()));
    setValue('applicationId', (data.applicationId = data.applicationId.trim()));

    dispatch(
      manualReviewMiddleware.fetchManualReviewsList({
        applicationId: data.applicationId ?? '',
        borrowerName: data.borrower ?? '',
        dateFrom: moment(dateTimeState?.startDate).startOf('day').format(),
        dateTo: moment(dateTimeState?.endDate).endOf('day').format(),
        sourceChannels: data.sourceChannels?.map((el: { id: string }) => el.id),
        status: data.status ?? 0,
        merchantName: data.merchantName?.map((el: { id: string }) => el.id),
        tags: data.tags?.map((el: { name: string }) => el.name),
        searchModel: {
          pageNumber: 1,
          pageSize: orderData.pageSize,
          sortOrder: { key: 'Id', value: false },
        },
      }),
    );
    setPage(0);
  };

  const resetFilters = () => {
    resetFiltersFlag.current = true;
    setDateTimeState({
      startDate: moment().subtract(1, 'year').format(),
      endDate: moment().format(),
    });
  };

  useEffect(() => {
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliateChannels());
    dispatch(manualReviewMiddleware.fetchMerchantNames());
  }, []);

  useEffect(() => {
    if (resetFiltersFlag.current) {
      resetFiltersFlag.current = false;
      reset(emptyValues);
      applyFilters(emptyValues);
    }
  }, [dateTimeState]);

  return (
    <FormProvider {...methods}>
      <Box onSubmit={handleSubmit(applyFilters)} component="form">
        <Grid container gap="30px" sx={{ marginBottom: '24px' }}>
          <Grid item>
            <CustomRangePicker
              dataTest="manual-reviews-page-input-range"
              key={`${dateTimeState?.startDate}${dateTimeState?.endDate}`}
              dateTimeState={dateTimeState}
              setDateTimeState={setDateTimeState}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="applicationId"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }} data-test="manual-reviews-page-input-search">
                  <InputLabel>Application ID</InputLabel>
                  <TextField sx={{ marginTop: '0 !important' }} {...field} placeholder="Search" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Source Channel"
              options={affiliateChannelsList ?? []}
              name="sourceChannels"
              dataTest="manual-reviews-page-dropdown-source-channels"
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="borrower"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }} data-test="manual-reviews-page-input-borrower">
                  <InputLabel>Borrower</InputLabel>
                  <TextField {...field} placeholder="Borrower" />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Merchant Name"
              options={merchantNames ?? []}
              name="merchantName"
              dataTest="manual-reviews-page-dropdown-merchant-name"
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <FormControl fullWidth sx={{ width: '160px' }} data-test="manual-reviews-page-dropdown-status">
                  <InputLabel>Status</InputLabel>
                  <Select {...field}>
                    {statuses?.map((el) => (
                      <MenuItem
                        key={el.value}
                        value={el.value}
                        data-test={`manual-reviews-page-dropdown-status-item-${el.value}`}
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <FormAutocomplete
              title="Tags"
              options={tags ?? []}
              name="tags"
              dataTest="manual-reviews-page-dropdown-tags"
            />
          </Grid>
          <Grid item>
            <SelectTableHeaders
              dataTest="manual-reviews-page-dropdown-custom-columns"
              tableHeaders={ManualReviewListTableHeaders}
              selectedTableHeaders={selectedTableHeaders}
              setSelectedTableHeaders={setSelectedTableHeaders}
            />
          </Grid>
          <Box display="flex" alignItems="flex-end">
            <CustomButton data-test="manual-reviews-page-button-apply" type="submit">
              Apply
            </CustomButton>
            <CustomButton
              data-test="manual-reviews-page-button-reset"
              type="reset"
              variant="outlined"
              onClick={() => resetFilters()}
            >
              Reset
            </CustomButton>
          </Box>
        </Grid>
      </Box>
    </FormProvider>
  );
};

export default ManualReviewListFilters;
