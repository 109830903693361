import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmCreateCampaignControls = () => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmCreateCampaignModal));
  }, []);

  return {
    onClose,
  };
};

export default useConfirmCreateCampaignControls;
