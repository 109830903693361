import axios from 'axios';
import { CookieManager } from 'helpers/CookieManager';
import { PersistManager } from 'helpers/PersistManager';
import { ACCESS_TOKEN } from 'lib/constants';
import { userTimeZone } from 'utils/index';

import configs from './core/configs';
import AxiosInstanceChanger from './AxiosInstance';

class DataService extends AxiosInstanceChanger {
  constructor() {
    super({});
    this.axiosInstance = axios.create({
      baseURL: configs.connection.server_url,
      headers: {
        'User-Time-Zone': userTimeZone,
        'Content-Type': 'application/json',
        authorization: `Bearer ${CookieManager.getCookie(ACCESS_TOKEN)}`,
      },
    });
  }

  getData(url: string, params = {}) {
    return this.getJson(url, params)!
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        if (error.response.status === 401) {
          CookieManager.removeCookie(ACCESS_TOKEN);
          PersistManager.clear();
          window.location.reload();
        }

        return Promise.reject(error);
      });
  }
}

export default new DataService();
