import { Box, Typography } from '@mui/material';
import { StatusBadgeTitle } from 'components/StatusBadgeTitle';
import { ManualReviewUploadedDocumentStatuses } from 'lib/constants/statuses';

import { ReactComponent as NoteDocumentIcon } from '../../assets/images/NoteDocumentIcon.svg';

export interface NoteDocumentAttachmentProps {
  name: string;
  status: number;
}

const NoteDocumentAttachment = ({ name, status }: NoteDocumentAttachmentProps) => (
  <Box
    sx={{
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: '7px',
      borderRadius: '8px',
      border: '1px solid #E2E8F0',
      minWidth: '400px',
      minHeight: '46px',
      marginBottom: '5px',
      marginTop: '5px',
    }}
  >
    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginRight: '20px', fontWeight: 700 }}>
      <NoteDocumentIcon /> {name}
    </Typography>
    <StatusBadgeTitle Statuses={ManualReviewUploadedDocumentStatuses} status={status} />
  </Box>
);

export default NoteDocumentAttachment;
