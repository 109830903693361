import { useCallback } from 'react';

import { dispatch } from '../../../../redux/hooks';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useDeleteOutboundAPIModal = () => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteOutboundAPIModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.DeleteOutboundAPIModal));
  }, []);

  return {
    onClose,
    onConfirm,
  };
};

export default useDeleteOutboundAPIModal;
