import { dispatch } from '../../hooks';

import { IOpenedAlert, IOpenedModal, ModalName, RedirectionProps } from './initialState';
import slice from './slice';

const { addModalToList, removeModalFromList, setRedirection, updateToastNotificationState } = slice.actions;

const openModal =
  <P>(value: IOpenedModal<P>) =>
  () => {
    dispatch(addModalToList(value));
  };

const closeModal = (name: ModalName) => () => {
  dispatch(removeModalFromList(name));
};

const setRedirectionState = (value: RedirectionProps) => () => {
  dispatch(setRedirection(value));
};

const setToastNotificationPopUpState =
  <P>(value: IOpenedAlert<P>) =>
  () => {
    dispatch(updateToastNotificationState(value));
  };

export default {
  openModal,
  closeModal,
  setRedirectionState,
  setToastNotificationPopUpState,
};
