import { CloseOutlined } from '@mui/icons-material';
import { Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';

import useConfirmExternalServiceSuccessControls from './hooks/useConfirmExternalServiceSuccessControls';

export interface IConfirmExternalServiceSuccessModalProps {
  error: null | string;
}

const ConfirmExternalServiceSuccessModal = ({ error }: IConfirmExternalServiceSuccessModalProps) => {
  const { onClose } = useConfirmExternalServiceSuccessControls();

  return (
    <Dialog data-test="confirm-external-service-success-modal" open onClose={onClose}>
      <Stack width="400px" direction="column" spacing={2}>
        <Grid
          sx={{
            position: 'relative',
          }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item display="flex" justifyContent="center" xs={12}>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                marginBottom: '0',
                fontWeight: 'bold',
                color: error ? '#ec4444' : '#27AE60',
              }}
            >
              {error ? 'Error!' : 'Success!'}
            </Typography>
          </Grid>
          <Grid
            xs={4}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '0',
            }}
          >
            <IconButton data-test="confirm-external-service-success-modal-button-x" sx={{ p: 0 }} onClick={onClose}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
        <Stack width="100%" alignItems="center" justifyContent="center">
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              width: '100%',
              textAlign: 'center',
              fontWeight: 'bold',
              color: error ? '#ec4444' : '#27AE60',
            }}
          >
            {error ? "The field isn't posted." : 'The field is successfully posted.'}
          </Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmExternalServiceSuccessModal;
