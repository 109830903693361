import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
// project imports
import Loadable from 'components/Loadable';

import Snackbar from '../components/UI/Snackbar';
import {
  AUTH_PATH,
  FORGOT_PASSWORD_PATH,
  RECOVERY_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  SET_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '../config';
import AuthGuard from '../utils/route-guard/AuthGuard';

// login option 1 routing
const Register = Loadable(lazy(() => import('components/Authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('components/Authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('components/Authentication/ResetPassword')));
const Login = Loadable(lazy(() => import('components/Authentication/Login')));
const RecoveryPassword = Loadable(lazy(() => import('components/Authentication/RecoveryPassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = () => (
  <AuthGuard>
    <>
      <Box
        sx={{
          width: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '40px',
          position: 'relative',
        }}
      />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={AUTH_PATH} element={<Login />} />
        <Route path={SIGN_IN_PATH} element={<Login />} />
        <Route path={SIGN_UP_PATH} element={<Register />} />
        <Route path={FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
        <Route path={SET_PASSWORD_PATH} element={<ResetPassword />} />
        <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
        <Route path={RECOVERY_PASSWORD_PATH} element={<RecoveryPassword />} />
      </Routes>
      <Snackbar />
    </>
  </AuthGuard>
);

export default AuthenticationRoutes;
