import { Box, Typography } from '@mui/material';

import { Status } from '@types';

import { LeadsLogsStatuses } from '../lib/constants/statuses';

export interface IStatusBadge {
  Statuses: { [key: string | number]: Status };
  status: string | number;
}

export const StatusBadgeTitle = ({ Statuses, status: currentStatus = '3' }: IStatusBadge) => {
  const status = Statuses[currentStatus] || {};

  return (
    <Typography
      sx={{
        textAlign: 'center',
        color: status.color,
        padding: '5px 10px',
        minWidth: '100px',
        maxWidth: '110px',
        whiteSpace: 'nowrap',
        borderRadius: '10px',
        background: status.bgColor,
        border: status.border ? status.border : '',
      }}
    >
      {status.title || ''}
    </Typography>
  );
};

export const LeadsLogsStatusBadgeTitle = ({ status = 'Success' }: { status: string }) => {
  const statusStyles = LeadsLogsStatuses[status] ?? { title: 'Unknown' };

  return (
    <Box
      sx={{
        width: '110px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: statusStyles.bgColor,
        color: statusStyles.color,
        border: statusStyles.border,
      }}
    >
      {statusStyles.title}
    </Box>
  );
};
