import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';

import { useAppSelector } from '../../../redux/hooks';
import { externalServicesSelector } from '../../../redux/slices/externalServices';
import { CustomButton } from '../../shared';

import useConfirmExternalServiceDeleteControls from './hooks/useConfirmExternalServiceDeleteControls';

export interface IConfirmExternalServiceDeleteModalProps {
  id: string;
  pageNumber: number;
  pageSize: number;
  sortOrderKey?: string;
  sortOrderValue?: boolean;
}

const ConfirmExternalServiceDeleteModal = ({
  id,
  pageNumber,
  pageSize,
  sortOrderKey,
  sortOrderValue,
}: IConfirmExternalServiceDeleteModalProps) => {
  const { onConfirm, onClose } = useConfirmExternalServiceDeleteControls(
    id,
    pageNumber,
    pageSize,
    sortOrderKey,
    sortOrderValue,
  );
  const isExternalServiceDeleteLoading = useAppSelector(externalServicesSelector.isExternalServiceDeleteLoading);

  return (
    <Dialog data-test="confirm-external-service-delete-modal" open onClose={onClose}>
      <Grid sx={{ width: '620px' }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
              >
                Are you sure you want to delete this 3rd party APIs?
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton data-test="confirm-external-service-delete-modal-button-x" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                {`After confirming this action, the 3rd party APIs will be removed
                permanently and changes can't be undone.`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={11}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <CustomButton
                  data-test="confirm-external-service-delete-modal-button-confirm"
                  disabled={isExternalServiceDeleteLoading}
                  loading={isExternalServiceDeleteLoading}
                  onClick={onConfirm}
                >
                  Confirm
                </CustomButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmExternalServiceDeleteModal;
