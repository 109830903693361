import { useState } from 'react';

import { IFunds } from '@types';

import FundingRequestsTable from '../../components/FundingRequests/FundingRequestsTable';
import FundingRequestView from '../../components/FundingRequests/FundingRequestView';

const FundingRequests = () => {
  const [mode, setMode] = useState<string>('table');
  const [selectedRow, setSelectedRow] = useState<IFunds>();

  return (
    <>
      {mode === 'table' && <FundingRequestsTable setMode={setMode} setSelectedRow={setSelectedRow} />}
      {mode === 'view' && selectedRow && <FundingRequestView setMode={setMode} fundId={selectedRow.id} />}
    </>
  );
};

export default FundingRequests;
