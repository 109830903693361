import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { CampaignDetailsTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { useGenerateTableData } from '../Hooks';
import { Permissions } from '../Permissions/constants';
import { DenseTable } from '../Tables';
import Loading from '../UI/Loading';
import MainCard from '../UI/MainCard';
import Title from '../UI/Title';

const CampaignsAffiliateChannel = () => {
  const { Id: campaignId } = useParams();

  const affiliateChannels = useAppSelector(affiliatesSelector.affiliateChannels);

  const campaignsAffiliateChannelsCount = useAppSelector(affiliatesSelector.campaignsAffiliateChannelsCount);

  const isAffiliateChannelsLoading = useAppSelector(affiliatesSelector.isAffiliateChannelsLoading);

  const {
    tableData,
    setTableData,
    order,
    orderBy,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    createSortHandler,
    sortTableData,
    setOrder,
    setOrderBy,
    isLoading,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: affiliateChannels,
    paginationCount: campaignsAffiliateChannelsCount,
  });

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByCampaignId(
        campaignId ?? '0',
        -1,
        newPage + 1,
        rowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );

    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByCampaignId(
        campaignId ?? '0',
        -1,
        page + 1,
        newRowsPerPage,
        orderBy,
        order === 'asc',
      ),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      affiliatesMiddleware.fetchAffiliateChannelByCampaignId(
        campaignId ?? '0',
        -1,
        page + 1,
        rowsPerPage,
        property,
        newOrder,
      ),
    );
  };

  const handleAffiliateChannelStatusChange = (row: Record<string, string>, newStatus: number) => {
    dispatch(
      affiliatesMiddleware.fetchUpdateAffiliateChannelStatus({
        campaignId: campaignId ?? '0',
        id: row.id,
        status: newStatus,
        pageNumber: page + 1,
        pageSize: rowsPerPage,
        sortOrderKey: orderBy,
        sortOrderValue: order === 'asc',
      }),
    );
  };

  useEffect(() => {
    if (campaignId) {
      dispatch(affiliatesMiddleware.fetchAffiliateChannelByCampaignId(campaignId));
    }
  }, [campaignId]);

  if (isAffiliateChannelsLoading || isLoading) {
    return <Loading />;
  }

  return (
    <MainCard border={null} contentSX={{ padding: 0, marginTop: '16px' }} sx={{ width: 1 }}>
      <Box
        sx={{
          '& .MuiTableContainer-root': {
            padding: 0,
          },
        }}
      >
        {tableData && tableData.length ? (
          <DenseTable
            dataTest="campaign-details-page-tab-affiliate-channels"
            selectedTableHeaders={CampaignDetailsTableHeaders}
            setOrder={setOrder}
            isDeleted={false}
            setOrderBy={setOrderBy}
            sortTableData={sortTableData}
            data={tableData}
            editButton={false}
            setTableData={setTableData}
            order={order as string}
            isAction={false}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            createSortHandler={createSortHandler}
            handleChangeRowsPerPage={handleChangeRowsPerPageServer}
            handleChangePage={handleChangePageServer}
            handleSort={handleSortServer}
            handleChangeStatus={handleAffiliateChannelStatusChange}
            paginationCount={paginationCount}
            handleSortCount={handleSortCount}
            changeStatusPermission={Permissions.changeProductSourceChannelStatus}
          />
        ) : (
          <Title sx={{ padding: 0 }} title="No Data" />
        )}
      </Box>
    </MainCard>
  );
};

export default CampaignsAffiliateChannel;
