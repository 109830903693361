import { HTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { isDeepEqual } from 'helpers';

interface ISelectBoxProps {
  title?: string;
  placeholder?: string;
  options: ISelectOption[];
  name: string;
  dataTest?: string;
  sx?: SxProps<Theme>;
}

export interface ISelectOption {
  id: string | number;
  name: string;
}

const StyledLi = styled('li')({ wordBreak: 'break-word' });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteRoot = styled(Autocomplete)({
  '&.Mui-focused .MuiAutocomplete-tag:not(:first-of-type)': {
    display: 'none',
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '63px',

    '.MuiSvgIcon-root': {
      display: 'none',
    },

    '.MuiChip-label': {
      padding: '0 5px',
    },
  },

  '&& .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input': {
    padding: '10px 0 10px 10px',
  },
  '& span.MuiAutocomplete-tag': {
    padding: '6px 4px',
  },
  '& .MuiInputBase-formControl': {
    padding: '0 40px 0 2px',
  },
});

const FormAutocomplete = (props: ISelectBoxProps) => {
  const { title, options, placeholder, dataTest, name, ...otherProps } = props;

  const { watch, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <AutocompleteRoot
          multiple
          isOptionEqualToValue={(option, val) => isDeepEqual(option, val)}
          value={value}
          limitTags={1}
          getOptionLabel={(option) => (option as { name: string }).name}
          options={options && options.length ? options : []}
          disableCloseOnSelect
          onChange={(event, values) => onChange(values)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder ?? 'Search'}
              InputLabelProps={{ shrink: true }}
              label={title}
              data-test={dataTest ?? ''}
            />
          )}
          renderOption={(renderProps: HTMLAttributes<HTMLLIElement>, option: any) => (
            <StyledLi {...renderProps} key={option.id} data-test={dataTest ? `${dataTest}-item-${option.id}` : ''}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={!!watch(name)?.find((item: ISelectOption) => item.id === option.id)}
              />
              {option.name}
            </StyledLi>
          )}
          {...otherProps}
        />
      )}
    />
  );
};

export default FormAutocomplete;
