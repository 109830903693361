import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { INotification } from './initialState';

const createReducer = <T extends SliceCaseReducers<INotification>>(reducer: T) => ({
  ...reducer,
});

const reducers = createReducer({
  setFundingCount(state, action) {
    state.fundingCount = action.payload;
  },
  setManualReviewCount(state, action) {
    state.manualReviewCount = action.payload;
  },
  setNotifications(state, action) {
    state.notifications = action.payload;
  },
  setNotificationsLoaded(state, action) {
    state.notificationsLoaded = action.payload;
  },
  setNotificationsByIdLoading(state, action) {
    state.notificationsByIdLoading = action.payload;
  },
  setNotificationsList(state, action) {
    state.notificationsList = action.payload;
  },
  setIsNotificationListEnd(state, action) {
    state.isNotificationListEnd = action.payload;
  },
});

export default reducers;
