import { Box } from '@mui/material';

import { ILeadJourneyGroup, ILeadJourneyRow } from '@types';

import { IRowHeight } from './Journey';
import { JourneyGroup } from './JourneyGroup';
import { JourneyStepStatus } from './JourneyStepStatus';
import { JourneyStepStatusRight } from './JourneyStepStatusRight';

import classes from '../../assets/scss/journey.module.scss';

export const JourneyRow = ({
  leadId,
  row,
  index,
  rowHeights,
}: {
  leadId: string;
  row: ILeadJourneyRow;
  index: number;
  rowHeights: IRowHeight;
}) => (
  <>
    <Box className={classes.rowContainer}>
      {!index ? (
        <Box className={`${classes.step} ${classes.stepStart}`}>
          <Box className={classes.stepHeader}>Start</Box>
          <Box className={classes.stepBody}>Lead {leadId}</Box>
          <JourneyStepStatus className={`${classes.stepStatusGroup}`} />
        </Box>
      ) : null}
      {row.groups.map((group: ILeadJourneyGroup, groupIndex: number) => (
        <JourneyGroup key={group.id} row={row} group={group} index={groupIndex} />
      ))}
    </Box>
    {rowHeights[index] ? (
      <JourneyStepStatusRight
        row={row}
        rowHeight={rowHeights[index]}
        final={!rowHeights[index + 1]}
        singleRow={Object.keys(rowHeights).length === 1}
      />
    ) : null}
  </>
);
