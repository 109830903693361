import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IReportsState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IReportsState>>(reducer: T) => ({ ...reducer });

const reducers = createReducer({
  // HAS ERROR
  hasError(state, action) {
    state.error = action.payload;
  },

  // GET Time Formats
  getAffiliateReportsSuccess(state, action) {
    state.affiliateReports = action.payload;
  },

  getSuccessAffiliateComparisonReports(state, action) {
    state.comparisonReports = action.payload;
  },

  setReportsLoading(state, action) {
    state.isReportsLoading = action.payload;
  },

  setComparisonReportsLoading(state, action) {
    state.isComparisonReportsLoading = action.payload;
  },

  setIsDownloadLeadListReportLoading(state, action) {
    state.isDownloadLeadListReportLoading = action.payload;
  },
});

export default reducers;
