import moment from 'moment';

import { ACCESS_TOKEN } from '../lib/constants';

import { CookieManager } from './CookieManager';
import { PersistManager } from './PersistManager';

export const dateTime = (date: string) => moment(date).format('ll LTS');

export const isMe = (userId: string) => CookieManager.getUser()?.id === userId;

export const handleApiError = (error: any) => {
  if (error.response.status === 401) {
    CookieManager.removeCookie(ACCESS_TOKEN);
    PersistManager.clear();
    window.location.reload();
  }

  throw error;
};

export const amountFormat = (amount: number) =>
  typeof amount === 'number'
    ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
    : '-';

export const trimProperties = (object: any, properties: string[]): any => {
  const trimmedObject = { ...object };

  properties.forEach((property: string) => {
    if (typeof trimmedObject[property] === 'string') {
      trimmedObject[property] = trimmedObject[property].trim();
    }
  });

  return trimmedObject;
};

export const isDeepEqual = (obj1: any, obj2: any) => {
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  return obj1 === obj2;
};

export const hidePiiDisabledFields = ({
  object,
  properties,
}: {
  object: any;
  properties: string[];
  hideBorrower?: boolean;
}): any => {
  const changedObject = { ...object };

  properties.forEach((el) => {
    changedObject[el] = 'Hidden';
  });

  return changedObject;
};

interface IAssigneeUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const moveToTopAndAddNameAssign = (arr: IAssigneeUser[]): IAssigneeUser[] => {
  const user = CookieManager.getUser();
  const index = arr.findIndex((item) => item.id === user?.id);

  if (index > -1) {
    const updatedItem = {
      ...arr[index],
      lastName: `${arr[index].lastName} (Assign to me)`,
      value: `${arr[index].firstName} ${arr[index].lastName} (Assign to me)`,
    };

    arr.splice(index, 1);

    arr.unshift(updatedItem);
  }

  return arr;
};

export const filterSelfAssigneeById = (arr: IAssigneeUser[]): IAssigneeUser[] =>{
  const user = CookieManager.getUser();

  return  arr.filter(item => item.id === user?.id);
}
