import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import HeaderWithFilters from 'components/HeaderWithFilters';
import moment from 'moment';

import { useGenerateTableData } from '../../components/Hooks';
import { DenseTable } from '../../components/Tables';
import { ISelectOption } from '../../components/UI/CustomAutocomplete';
import Loading from '../../components/UI/Loading';
import Title from '../../components/UI/Title';
import { createDropdownOptions } from '../../helpers/createDropdownOptions';
import { DEFAULT_ROWS_PAGE } from '../../lib/constants';
import { ReportsTableHeaders } from '../../lib/constants/tableHeaders';
import { dispatch, useAppSelector } from '../../redux/hooks';
import { affiliatesMiddleware, affiliatesSelector } from '../../redux/slices/affiliates';
import { campaignsMiddleware, campaignsSelector } from '../../redux/slices/campaigns';
import { reportsMiddleware, reportsSelector } from '../../redux/slices/reports';
import { DateRange, ISimplifiedList } from '../../types';

import { ReportsFilters } from './ReportsFilters';

export interface IReportSortTableData {
  acceptedLeads: number;
  acceptedLeadsRate: number | string;
  affiliate: string;
  affiliateChannel: string;
  affiliateChannelId: number;
  campaign: string;
  receivedLeads: number;
  rejectedLeads: number;
  rejectedLeadsRate: string;
}

const Report = () => {
  const [dateTimeState, setDateTimeState] = useState<DateRange | undefined | null>({
    startDate: moment().subtract(1, 'year').format(),
    endDate: moment().format(),
  });
  const [affiliateDropdownOptions, setAffiliateDropdownOptions] = useState<ISelectOption[]>([]);
  const [affiliatesChannelDropdownOptions, setAffiliatesChannelDropdownOptions] = useState<ISelectOption[]>([]);
  const [campaignsDropdownOptions, setCampaignsDropdownOptions] = useState<ISelectOption[]>([]);

  const [filteredAffiliateData, setFilteredAffiliateData] = useState<ISelectOption[]>([]);
  const [filteredAffiliateChannelData, setFilteredAffiliateChannelData] = useState<ISelectOption[]>([]);
  const [filteredCampaignsData, setFilteredCampaignsData] = useState<ISelectOption[]>(campaignsDropdownOptions);

  const affiliateReports = useAppSelector(reportsSelector.affiliateReports);
  const isReportsLoading = useAppSelector(reportsSelector.isReportsLoading);
  const affiliateChannelsList = useAppSelector(affiliatesSelector.simplifiedAffiliateChannels);
  const affiliateList = useAppSelector(affiliatesSelector.simplifiedAffiliates);

  const campaignsList = useAppSelector(campaignsSelector.simplifiedCampaigns);

  const {
    tableData,
    sortTableData,
    setTableData,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    createSortHandler,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    paginationCount,
    handleSortCount,
  } = useGenerateTableData({
    dataIsAlreadyThere: affiliateReports?.data,
    paginationCount: affiliateReports?.itemsCount,
  });

  const affiliateReportsRequestInitialData = {
    startDate: moment().subtract(1, 'year').format(),
    endDate: moment().format(),
    affiliates: [-1],
    affiliateChannels: [-1],
    campaigns: [-1],
    pageIndex: page + 1,
    pageSize: DEFAULT_ROWS_PAGE,
  };

  const handleChangePageServer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    dispatch(
      reportsMiddleware.fetchAffiliateReports({
        startDate: dateTimeState?.startDate ?? '',
        endDate: dateTimeState?.endDate ?? '',
        affiliates: filteredAffiliateData.map((item: ISelectOption) => item.id),
        affiliateChannels: filteredAffiliateChannelData.map((item: ISelectOption) => item.id),
        campaigns: filteredCampaignsData.map((item: ISelectOption) => item.id),
        pageSize: rowsPerPage,
        pageIndex: newPage + 1,
        ...(orderBy !== 'Id'
          ? {
              sortOrder: {
                key: orderBy,
                value: order === 'asc',
              },
            }
          : {}),
      }),
    );
    setPage(newPage);
  };

  const handleChangeRowsPerPageServer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const newRowsPerPage = parseInt(event?.target.value!, 10);

    dispatch(
      reportsMiddleware.fetchAffiliateReports({
        startDate: dateTimeState?.startDate ?? '',
        endDate: dateTimeState?.endDate ?? '',
        affiliates: filteredAffiliateData.map((item: ISelectOption) => item.id),
        affiliateChannels: filteredAffiliateChannelData.map((item: ISelectOption) => item.id),
        campaigns: filteredCampaignsData.map((item: ISelectOption) => item.id),
        pageIndex: 1,
        pageSize: newRowsPerPage,
        ...(orderBy !== 'Id'
          ? {
              sortOrder: {
                key: orderBy,
                value: order === 'asc',
              },
            }
          : {}),
      }),
    );
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  };

  const handleSortServer = (event: React.SyntheticEvent, property: string, newOrder: boolean) => {
    dispatch(
      reportsMiddleware.fetchAffiliateReports({
        startDate: dateTimeState?.startDate ?? '',
        endDate: dateTimeState?.endDate ?? '',
        affiliates: filteredAffiliateData.map((item: ISelectOption) => item.id),
        affiliateChannels: filteredAffiliateChannelData.map((item: ISelectOption) => item.id),
        campaigns: filteredCampaignsData.map((item: ISelectOption) => item.id),
        pageIndex: page + 1,
        pageSize: rowsPerPage,
        sortOrder: {
          key: property,
          value: newOrder,
        },
      }),
    );
  };

  // TODO : We can add countries in future
  // const [countryDropdownOptions, setCountryDropdownOptions] = useState<
  //   IReportsTempAffiliates[]
  // >([
  //   { label: "USA", id: 1, checked: true },
  //   { label: "Canada", id: 2, checked: true },
  // ]);
  // const [filteredCountryData, setFilteredCountryData] = useState<
  //   IReportsTempAffiliates[]
  // >(countryDropdownOptions);

  const resetFilters = () => {
    dispatch(reportsMiddleware.fetchAffiliateReports(affiliateReportsRequestInitialData));

    setFilteredAffiliateChannelData(affiliatesChannelDropdownOptions);
    setFilteredCampaignsData(campaignsDropdownOptions);
    setFilteredAffiliateData(affiliateDropdownOptions);
    setDateTimeState({
      startDate: moment().subtract(1, 'year').format(),
      endDate: moment().format(),
    });
  };

  const applyFilters = () => {
    if (
      dateTimeState?.endDate &&
      dateTimeState?.startDate &&
      filteredAffiliateData.length &&
      filteredAffiliateChannelData.length &&
      filteredCampaignsData.length
    ) {
      const affiliateReportsData = {
        startDate: dateTimeState?.startDate,
        endDate: dateTimeState?.endDate,
        affiliates: filteredAffiliateData.map((item: ISelectOption) => item.id),
        affiliateChannels: filteredAffiliateChannelData.map((item: ISelectOption) => item.id),
        campaigns: filteredCampaignsData.map((item: ISelectOption) => item.id),
        pageIndex: page + 1,
        pageSize: rowsPerPage,
      };

      dispatch(reportsMiddleware.fetchAffiliateReports(affiliateReportsData));
    }
  };

  useEffect(() => {
    const optionsAffiliates = createDropdownOptions(affiliateList as ISimplifiedList[]);
    const optionsAffiliatesChannel = createDropdownOptions(affiliateChannelsList as ISimplifiedList[]);
    const optionsCampaigns = createDropdownOptions(campaignsList as ISimplifiedList[]);

    if (optionsAffiliates && optionsAffiliatesChannel && optionsCampaigns) {
      setAffiliateDropdownOptions(optionsAffiliates);
      setAffiliatesChannelDropdownOptions(optionsAffiliatesChannel);
      setCampaignsDropdownOptions(optionsCampaigns);
      setFilteredAffiliateChannelData(optionsAffiliatesChannel);
      setFilteredCampaignsData(optionsCampaigns);
      setFilteredAffiliateData(optionsAffiliates);
      // setFilteredCountryData(countryDropdownOptions);
    }
  }, [
    affiliateChannelsList,
    affiliateList,
    campaignsList,
    // countryDropdownOptions,
  ]);

  useEffect(() => {
    let tempArr: IReportSortTableData[] = [];
    const result = sortTableData?.filter((elem: IReportSortTableData) => {
      const filteredAffiliateDataLabels = filteredAffiliateData?.map((item: ISelectOption) => item.label);
      const filteredAffiliateChannelDataLabels = filteredAffiliateChannelData?.map((item) => item.label);
      const filteredCampaignsDataLabel = filteredCampaignsData?.map((item) => item.label);

      return (
        filteredAffiliateChannelDataLabels?.includes(elem.affiliateChannel) &&
        filteredCampaignsDataLabel?.includes(elem.campaign) &&
        filteredAffiliateDataLabels?.includes(elem.affiliate)
      );
    });

    tempArr = [...tempArr, ...result];

    if (tempArr.length) {
      setTableData(tempArr);
    }
  }, [filteredAffiliateChannelData, filteredCampaignsData, filteredAffiliateData, sortTableData, setTableData]);

  useEffect(() => {
    setTableData(sortTableData);
  }, [sortTableData]);

  useEffect(() => {
    dispatch(reportsMiddleware.fetchAffiliateReports(affiliateReportsRequestInitialData));

    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliates());
    dispatch(affiliatesMiddleware.fetchSimplifiedAffiliateChannels());
    dispatch(campaignsMiddleware.fetchSimplifiedCampaigns());
  }, []);

  if (isReportsLoading) {
    return <Loading />;
  }

  return (
    <>
      <HeaderWithFilters title="Reports">
        <ReportsFilters
          dateTimeState={dateTimeState}
          setDateTimeState={setDateTimeState}
          affiliateDropdownOptions={affiliateDropdownOptions}
          filteredAffiliateData={filteredAffiliateData}
          setFilteredAffiliateData={setFilteredAffiliateData}
          affiliatesChannelDropdownOptions={affiliatesChannelDropdownOptions}
          filteredAffiliateChannelData={filteredAffiliateChannelData}
          setFilteredAffiliateChannelData={setFilteredAffiliateChannelData}
          campaignsDropdownOptions={campaignsDropdownOptions}
          filteredCampaignsData={filteredCampaignsData}
          setFilteredCampaignsData={setFilteredCampaignsData}
          resetFilters={resetFilters}
          applyFilters={applyFilters}
        />
      </HeaderWithFilters>
      {!affiliateReports?.data || !affiliateReports?.data.length ? (
        <Title sx={{ padding: 0, margin: 30 }} title="No Reports" />
      ) : (
        <Grid container item xs={12} marginTop="24px">
          <DenseTable
            dataTest="reports-page"
            dataTestId="affiliateChannelId"
            selectedTableHeaders={ReportsTableHeaders}
            isAction={false}
            isDeleted={false}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            sortTableData={sortTableData}
            data={tableData}
            editButton={false}
            setTableData={setTableData}
            order={order as string}
            orderBy={orderBy}
            page={page}
            rowKey="affiliateChannelId"
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            createSortHandler={createSortHandler}
            handleSort={handleSortServer}
            handleChangeRowsPerPage={handleChangeRowsPerPageServer}
            handleChangePage={handleChangePageServer}
            paginationCount={paginationCount}
            handleSortCount={handleSortCount}
          />
        </Grid>
      )}
    </>
  );
};

export default Report;
