import { Typography } from '@mui/material';

export default ({ count }: { count: number | null }) => (
  <Typography>
    {count ? (
      <Typography
        component="span"
        sx={{
          backgroundColor: ' #7B61FF1A',
          borderRadius: '16px',
          fontWeight: '800',
          minWidth: '26px',
          display: 'inline-block',
          textAlign: 'center',
          color: '#7B61FF',
          fontSize: '10px',
          p: '6px',
        }}
      >
        {count > 99 ? '99+' : count}
      </Typography>
    ) : (
      ''
    )}
  </Typography>
);
