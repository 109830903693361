import { SliceCaseReducers } from '@reduxjs/toolkit/src/createSlice';

import { IValidationTreeState } from '../../../types';

const createReducer = <T extends SliceCaseReducers<IValidationTreeState>>(reducer: T) => ({
  ...reducer,
});

const reducers = createReducer({
  hasError(state, action) {
    state.error = action.payload;
  },

  setValidationTreeLoading(state, action) {
    state.validationTreeLoading = action.payload;
  },

  setSingleValidationTree(state, action) {
    state.singleValidationTree = action.payload;
  },

  getConditions(state, action) {
    state.conditions = action.payload;
  },

  getListConditions(state, action) {
    state.listConditions = action.payload;
  },

  setValidationTree(state, action) {
    const { id, name, creditUnion, channelTreeType } = action.payload;

    state.validationTree = { id, name, creditUnion, channelTreeType };
  },

  setValidationTreeActions(state, action) {
    state.validationTreeActions = action.payload;
  },

  setValidationTreesById(state, action) {
    state.validationTreesById = action.payload;
  },

  setValidationTreesByIdLoading(state, action) {
    state.isValidationTreesByIdLoading = action.payload;
  },

  setValidationTreesUpdateLoading(state, action) {
    state.validationTreeUpdateLoading = action.payload;
  },

  setValidationTreeNameSuccess(state, action) {
    state.validationTreeNameSuccess = action.payload;
  },

  setValidationTreeSuccessfully(state, action) {
    state.validationTreeSuccessfully = action.payload;
  },

  setValidationTreeDeleteIsLoading(state, action) {
    state.isValidationTreeDeleteLoading = action.payload;
  },

  setManualReviewReasonsList(state, action) {
    state.manualReviewReasonsList = action.payload;
  },

  setManualReviewReasonsListLoading(state, action) {
    state.manualReviewReasonsListLoading = action.payload;
  },

  setExperianFields(state, action) {
    state.experianFields = action.payload;
  },

  setValidationTreeTypes(state, action) {
    state.validationTreeTypes = action.payload;
  },

  setValidationTreeTypesLoading(state, action) {
    state.validationTreeTypesLoading = action.payload;
  },

  setDownloadJsonIsLoading(state, action) {
    state.downloadJsonIsLoading = action.payload;
  },

  setUploadJsonIsLoading(state, action) {
    state.uploadJsonIsLoading = action.payload;
  },
});

export default reducers;
