import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { viewsMiddleware, viewsSelector } from '../../redux/slices/views';

const RedirectionHandler = () => {
  const redirection = useAppSelector(viewsSelector.redirection);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirection.apply) {
      dispatch(
        viewsMiddleware.setRedirectionState({
          ...redirection,
          apply: false,
        }),
      );

      const finalPath = redirection.path + (redirection.params ? `?${redirection.params}` : '');

      navigate(`${finalPath}`);
    }
  }, [navigate, redirection]);

  return null;
};

export default RedirectionHandler;
