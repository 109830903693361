import React from 'react';
import {
  IconBrandAsana,
  IconBrandGoogleAnalytics,
  IconCategory2,
  IconChartInfographic,
  IconChartLine,
  IconChartPie,
  IconClipboardList,
  IconCreditCard,
  IconExclamationMark,
  IconFileAnalytics,
  IconGitFork,
  IconLicense,
  IconList,
  IconPuzzle,
  IconReport,
  IconReportAnalytics,
  IconReportMoney,
  IconSettings,
  IconUserCircle,
  IconUsers,
  IconVocabulary,
  IconWorld,
} from '@tabler/icons';
import { OverrideIcon } from 'types';

import { Permissions } from '../components/Permissions/constants';

const icons = {
  IconChartPie,
  IconGitFork,
  IconPuzzle,
  IconFileAnalytics,
  IconChartInfographic,
  IconChartLine,
  IconVocabulary,
  IconWorld,
  IconCreditCard,
  IconReport,
  IconExclamationMark,
  IconClipboardList,
  IconBrandGoogleAnalytics,
  IconCategory2,
  IconSettings,
  IconUsers,
  IconList,
  IconLicense,
  IconReportAnalytics,
  IconReportMoney,
  IconBrandAsana,
  IconUserCircle,
};

export interface MenuProps {
  id: string;
  title: React.ReactNode | string;
  icon?: OverrideIcon | null;
  type: string;
  caption?: string;
  children: {
    id: string;
    title: React.ReactNode | string;
    parent?: string;
    type: string;
    url: string;
    icon: OverrideIcon | null;
    breadcrumbs: boolean;
    caption?: string;
    permission?: Permissions;
    showMenuCollapse?: Permissions[];
    children?: {
      id: string;
      title: React.ReactNode | string;
      parent?: string;
      type: string;
      url: string;
      icon: OverrideIcon | null;
      breadcrumbs: boolean;
      caption?: string;
      permission?: Permissions;
    }[];
  }[];
}

interface IMenuItemsMap {
  [key: string]: { parent: string; child?: string };
}

export const menuItemsMap: IMenuItemsMap = {
  '/': { parent: 'dashboard' },
  '/applications': { parent: 'applications' },
  '/manual-reviews': { parent: 'manual-reviews' },
  '/funding-list': { parent: 'funding-list' },
  '/source-reports': { parent: 'reports', child: 'source-reports' },
  '/sources-comparison-reports': { parent: 'reports', child: 'sources-comparison-reports' },
  '/application-list-reports': { parent: 'reports', child: 'application-list-reports' },
  '/funding-report': { parent: 'reports', child: 'funding-report' },
  '/products': { parent: 'lead-sources', child: 'products' },
  '/sources': { parent: 'lead-sources', child: 'sources' },
  '/sources-channel': { parent: 'lead-sources', child: 'sources-channel' },
  '/user-list': { parent: 'user-management', child: 'user-list' },
  '/role-list': { parent: 'user-management', child: 'role-list' },
  '/settings': { parent: 'settings' },
};

export const menuLinks: MenuProps[] = [
  {
    id: 'MENU',
    title: '',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/',
        permission: Permissions.viewDashboard,
        icon: icons.IconChartLine,
        breadcrumbs: false,
      },
      {
        id: 'applications',
        title: 'Applications',
        type: 'item',
        url: '/applications',
        icon: icons.IconFileAnalytics,
        breadcrumbs: false,
      },
      {
        id: 'manual-reviews',
        title: 'Manual Reviews',
        type: 'item',
        url: '/manual-reviews',
        permission: Permissions.viewManualReviewList,
        icon: icons.IconList,
        breadcrumbs: false,
      },
      {
        id: 'funding-list',
        title: 'Funding List',
        type: 'item',
        url: '/funding-list',
        permission: Permissions.viewFundList,
        icon: icons.IconLicense,
        breadcrumbs: false,
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        url: '/reports',
        icon: icons.IconChartInfographic,
        breadcrumbs: false,
        showMenuCollapse: [
          Permissions.viewSourceReportsPage,
          Permissions.viewSourceComparisonReportsPage,
          Permissions.viewApplicationListReportsPage,
          Permissions.viewFundingReportPage,
        ],
        children: [
          {
            id: 'source-reports',
            title: 'Source Reports',
            parent: 'reports',
            type: 'item',
            url: '/source-reports',
            icon: icons.IconChartInfographic,
            breadcrumbs: true,
            permission: Permissions.viewSourceReportsPage,
          },
          {
            id: 'sources-comparison-reports',
            title: 'Source Comparison Reports',
            parent: 'reports',
            type: 'item',
            url: '/sources-comparison-reports',
            icon: icons.IconReport,
            breadcrumbs: true,
            permission: Permissions.viewSourceComparisonReportsPage,
          },
          {
            id: 'application-list-reports',
            title: 'Application List Reports',
            parent: 'reports',
            type: 'item',
            url: '/application-list-reports',
            icon: icons.IconReportAnalytics,
            breadcrumbs: true,
            permission: Permissions.viewApplicationListReportsPage,
          },
          {
            id: 'funding-report',
            title: 'Funding Report',
            parent: 'reports',
            type: 'item',
            url: '/funding-report',
            icon: icons.IconReportMoney,
            breadcrumbs: true,
            permission: Permissions.viewFundingReportPage,
          },
          // need to implement after release
          // {
          //   id: 'error-applicants-report',
          //   title: 'Error Applicants Report',
          //   parent: 'reports',
          //   type: 'item',
          //   url: '/error-applicants-report',
          //   icon: icons.IconExclamationMark,
          //   breadcrumbs: true
          // },
          // {
          //   id: 'offers-report',
          //   title: 'Offers Report',
          //   parent: 'reports',
          //   type: 'item',
          //   url: '/offers-report',
          //   icon: icons.IconClipboardList,
          //   breadcrumbs: true
          // },
          // {
          //   id: 'merchant-activity-report',
          //   title: 'Merchant Activity Report',
          //   parent: 'reports',
          //   type: 'item',
          //   url: '/merchant-activity-report',
          //   icon: icons.IconBrandGoogleAnalytics,
          //   breadcrumbs: true
          // },
          // {
          //   id: 'service-category-report',
          //   title: 'Service Category Report',
          //   parent: 'reports',
          //   type: 'item',
          //   url: '/service-category-report',
          //   icon: icons.IconCategory2,
          //   breadcrumbs: true
          // }
        ],
      },
      {
        id: 'lead-sources',
        title: 'Lead Sources',
        type: 'collapse',
        url: '/sources',
        breadcrumbs: false,
        icon: icons.IconGitFork,
        showMenuCollapse: [Permissions.viewProductList, Permissions.viewSources, Permissions.viewSourceChannelsList],
        children: [
          {
            id: 'products',
            title: 'Products',
            parent: 'lead-sources',
            type: 'item',
            url: '/products',
            icon: icons.IconChartPie,
            breadcrumbs: true,
            permission: Permissions.viewProductList,
          },
          {
            id: 'sources',
            title: 'Sources',
            parent: 'lead-sources',
            type: 'item',
            url: '/sources',
            icon: icons.IconVocabulary,
            breadcrumbs: true,
            permission: Permissions.viewSources,
          },
          {
            id: 'sources-channel',
            title: 'Source Channels',
            parent: 'lead-sources',
            type: 'item',
            url: '/sources-channel',
            icon: icons.IconWorld,
            breadcrumbs: true,
            permission: Permissions.viewSourceChannelsList,
          },
        ],
      },
      // {
      //   id: 'integrations',
      //   title: 'Integrations',
      //   type: 'collapse',
      //   url: '/integrations',
      //   icon: icons.IconPuzzle,
      //   permission: Permissions.viewIntegrations,
      //   children: [
      //     {
      //       id: 'integrations/list-integration',
      //       title: 'List Integration',
      //       parent: 'integrations',
      //       type: 'item',
      //       url: '/integrations/list-integration',
      //       icon: null,
      //       breadcrumbs: true
      //     },
      //     {
      //       id: 'integrations/third-party-APIs',
      //       title: '3rd party APIs',
      //       parent: 'integrations',
      //       type: 'item',
      //       url: '/integrations/third-party-APIs',
      //       icon: null,
      //       breadcrumbs: true
      //     }
      //     // need to implement after release
      //     // {
      //     //   id: 'outbound',
      //     //   title: 'Outbound API',
      //     //   parent: 'integrations',
      //     //   type: 'item',
      //     //   url: '/integrations/outbound',
      //     //   icon: null,
      //     //   breadcrumbs: true
      //     // }
      //   ],

      //   breadcrumbs: true
      // },
      {
        id: 'user-management',
        title: 'User Management',
        type: 'collapse',
        url: '/user-list',
        breadcrumbs: false,
        icon: icons.IconBrandAsana,
        showMenuCollapse: [Permissions.userManagement],
        children: [
          {
            id: 'user-list',
            title: 'User List',
            parent: 'user-management',
            type: 'item',
            url: '/user-list',
            icon: icons.IconUsers,
            permission: Permissions.userManagement,
            breadcrumbs: true,
          },
          {
            id: 'role-list',
            title: 'Role List',
            parent: 'user-management',
            type: 'item',
            url: '/role-list',
            permission: Permissions.userManagement,
            icon: icons.IconUserCircle,
            breadcrumbs: true,
          },
        ],
      },
      {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        url: '/settings',
        icon: icons.IconSettings,
        breadcrumbs: false,
      },
      // {
      //   id: "Billing",
      //   title: "Billing",
      //   type: "item",
      //   url: "/Billing",
      //   breadcrumbs: false,
      //   icon: icons.IconCreditCard,
      // },
    ],
  },
];
