import { CloseOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';

import { useAppSelector } from '../../../redux/hooks';
import { validationTreeSelector } from '../../../redux/slices/validationTree';

import useConfirmAffiliateChannelTreeDeleteControls from './hooks/useConfirmAffiliateChannelTreeDeleteControls';

export interface IConfirmAffiliateChannelTreeDeleteModalProps {
  id: string;
  affiliateChannelId: string;
}

const ConfirmAffiliateChannelTreeDeleteModal = ({
  id,
  affiliateChannelId,
}: IConfirmAffiliateChannelTreeDeleteModalProps) => {
  const { onConfirm, onClose } = useConfirmAffiliateChannelTreeDeleteControls(id, affiliateChannelId);

  const isValidationTreeDeleteLoading = useAppSelector(validationTreeSelector.isValidationTreeDeleteLoading);

  return (
    <Dialog data-test="confirm-affiliate-channel-tree-delete-modal" open onClose={onClose}>
      <Grid sx={{ width: '100%' }}>
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={11}>
              <Typography
                sx={{
                  fontSize: '1.25rem',
                  fontWeight: 700,
                }}
              >
                Are you sure you want to delete decision tree?
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton data-test="confirm-affiliate-channel-tree-delete-modal-button-x" onClick={onClose}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                {`   After confirming this action, the decision tree will be removed
                permanently and changes can't be undone.`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <LoadingButton
                  data-test="confirm-affiliate-channel-tree-delete-modal-button-confirm"
                  loading={isValidationTreeDeleteLoading}
                  disabled={isValidationTreeDeleteLoading}
                  variant="contained"
                  onClick={onConfirm}
                  sx={{
                    width: '80px',
                    height: '40px',
                  }}
                >
                  {isValidationTreeDeleteLoading ? null : 'Confirm'}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAffiliateChannelTreeDeleteModal;
