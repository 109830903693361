import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';

export interface ActionProps {
  dataTest?: string;
  items: string[];
  setSelectedItem: (item: string) => void;
}

const ValidationTreeMoreActionMenu = ({ dataTest, items, setSelectedItem }: ActionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const id = anchorEl ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={(e) => handleClick(e)} data-test={dataTest ?? ''}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <List>
          {items?.map((item) => (
            <ListItem key={item} disablePadding onClick={() => setSelectedItem(item)}>
              <ListItemButton data-test={dataTest ? `${dataTest}-item-${item}` : ''}>
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="h5" sx={{ whiteSpace: 'nowrap' }}>
                      {item}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default ValidationTreeMoreActionMenu;
