import React, { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import { dispatch, useAppSelector } from 'redux/hooks';
import { viewsMiddleware, viewsSelector } from 'redux/slices/views';

const useStyles = makeStyles({
  snackbar: {
    width: '30vw',
    '@media (min-width: 780px)': {
      width: '40vw',
    },
  },
  alertBanner: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#ffffff',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
  },
});

export const ToastNotification = React.forwardRef(() => {
  const classes = useStyles();
  const toastNotificationPopUp = useAppSelector(viewsSelector.toastNotificationPopUp);
  const onClose = useCallback(() => {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: false,
        props: {},
      }),
    );
  }, []);

  return (
    <Snackbar
      data-test="toast-notification"
      open={toastNotificationPopUp.open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      className={classes.snackbar}
    >
      <Alert
        variant="filled"
        severity={toastNotificationPopUp.props.severityType}
        className={classes.alertBanner}
        action={
          <IconButton aria-label="close" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" sx={{ color: (theme) => theme.palette.common.white }} />
          </IconButton>
        }
      >
        <Typography sx={{ fontWeight: 600, color: (theme) => theme.palette.common.white }}>
          {toastNotificationPopUp.props?.description?.errors
            ? toastNotificationPopUp.props.description.errors[
                Object.keys(toastNotificationPopUp.props.description.errors)[0]
              ][0]
            : toastNotificationPopUp.props.description}
        </Typography>
      </Alert>
    </Snackbar>
  );
});
