import { LazyExoticComponent, Suspense } from 'react';
import { LinearProgressProps } from '@mui/material/LinearProgress';
import Loader from 'components/UI/Loader';

interface LoaderProps extends LinearProgressProps {}

const Loadable = (Component: LazyExoticComponent<() => JSX.Element>) => (props: LoaderProps) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
