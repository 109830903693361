import { useCallback } from 'react';
import { dispatch } from 'redux/hooks';

import { campaignsMiddleware } from '../../../../redux/slices/campaigns';
import { viewsMiddleware } from '../../../../redux/slices/views';
import { ModalName } from '../../../../redux/slices/views/initialState';

const useConfirmCampaignCloneControls = (cloneId: string | null) => {
  const onClose = useCallback(() => {
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmCampaignCloneModal));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(campaignsMiddleware.fetchCampaignFieldsByCampaignId(cloneId ?? '0', true));
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmCampaignCloneModal));
  }, [cloneId]);

  return {
    onConfirm,
    onClose,
  };
};

export default useConfirmCampaignCloneControls;
