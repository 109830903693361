import { ChangeEvent, useState } from 'react';
import { Box, Switch, Typography } from '@mui/material';

import { dispatch, useAppSelector } from '../../redux/hooks';
import { settingsMiddleware, settingsSelector } from '../../redux/slices/settings';
import { viewsMiddleware } from '../../redux/slices/views';
import { ModalName } from '../../redux/slices/views/initialState';
import { CustomButton } from '../shared';
import Loading from '../UI/Loading';

const Security = () => {
  const mfa = localStorage.getItem('mfa');
  const [toggle, setToggle] = useState<boolean>(mfa === 'true');
  const verificationEnabled = useAppSelector(settingsSelector.verificationEnabled);
  const settingsLoading = useAppSelector(settingsSelector.settingsLoading);

  const onToggle = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setToggle(checked);
    } else if (mfa === 'true' || verificationEnabled) {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.TwoFactorAuthenticationDisableModal,
          props: { setToggle },
        }),
      );
    } else {
      setToggle(false);
    }
  };

  const onContinueSetUp = () => {
    dispatch(settingsMiddleware.fetchMFAInitialization());
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.TwoFactorAuthenticationModal,
        props: {},
      }),
    );
  };

  return (
    <Box>
      {settingsLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            width: '100%',
            p: '30px',
            backgroundColor: '#FFFFFF',
            borderRadius: '15px',
            boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
          }}
        >
          <Box>
            <Typography fontSize="20px" fontWeight={700}>
              Security
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Switch color="secondary" checked={toggle} onChange={onToggle} />
            <Typography>
              Enable{' '}
              <Box component="span" sx={{ fontWeight: 700 }}>
                Google Two-Factor Authenticator
              </Box>
            </Typography>
          </Box>
          <Box paddingLeft="58px" marginBottom="20px">
            <Typography>
              Use a mobile authentication app to get a verification code to enter every time you log in to the system.
            </Typography>
          </Box>
          {mfa !== 'true' && toggle ? (
            <Box paddingLeft="58px">
              <CustomButton variant="contained" onClick={onContinueSetUp}>
                Continue Set Up
              </CustomButton>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Security;
